import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiContainer from "../api.container";
import { ReactComponent as Cross } from "../../assets/svg/cross.svg";
import {
  LS_KEY_NEWSHIFT_DATA,
  LS_KEY_TEMPLATES_DATA,
  apiEndpoints,
  apiMethods,
  businessRoles,
  locationPath,
} from "../../utils/constant";
import { reduceBusinessDetails } from "../../description/calendar/myCalendar.description";
import { SET_APP_DATA } from "../../redux/constant";
import {
  capitlizeWord,
  equal,
  head,
  isArray,
  length,
  ternary,
} from "../../utils/javascript";
import { showToast } from "../../utils/toastService";
import { useNavigate } from "react-router-dom";
import formDataAction from "../../redux/action/formDataAction";
import { setLocalStorageItem } from "../../utils/localStorage";
import getRolesAction from "../../redux/action/getRolesAction";
import LFButton from "../../shared/LFButton";

const RoleAndTemplateSelectionContainer = ({ formPath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loadingStatus, setLoadingStatus] = useState(false);
  const globalAttr = useSelector((state) => state?.globalAttr);
  const newShiftState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );

  const roles = useSelector((state) => state?.globalAttr?.roles);

  const businessType = useSelector((state) => state?.globalAttr?.businessType);

  const newShiftForm = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );

  const selected = newShiftForm?.business_id;
  const businessDetail = newShiftState?.businessDetail;

  const selectedOption = newShiftState?.option;
  const rolesDetail = globalAttr?.roles;
  const allRolesOption = rolesDetail?.map((role) => ({
    value: role?.attributes?.id,
    label: role?.attributes?.name,
  }));
  const pharmacyRolesOptions = [];
  const optometristRolesOptions = [];
  allRolesOption?.forEach((role) => {
    if (equal(role?.value, 4)) {
      optometristRolesOptions.push(role);
    } else {
      pharmacyRolesOptions.push(role);
    }
  });
  const isOptometrist = equal(businessType, businessRoles.optometrist);

  const defaultTemplateRegex = /^My Template \d+$/;
  let defaultTemplateNumber = 0;
  const roleId = newShiftForm?.role_id;

  const templateOptions = newShiftState?.templateDetail?.templates
    ?.map((template) => {
      const roleName = roles.find((r) =>
        equal(r?.attributes?.id, template?.attributes?.role_id),
      )?.attributes?.name;

      const finalLabel = template?.attributes?.name;
      return {
        value: template?.attributes?.id,
        subLabel: roleName,
        label:
          length(finalLabel) > 30
            ? `${finalLabel.slice(0, 30)}...`
            : finalLabel.slice(0, 30),
        roleId: template?.attributes?.role_id,
        deleteButton: (
          <LFButton
            sx={{
              marginLeft: "auto",
              minWidth: "unset",
              fontSize: "12px",
              transition: "none",
              padding: "5px 11px",
              "&.MuiButtonBase-root": {
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "transparent",
                  borderColor: "transparent",
                  "& svg": {
                    "& path": {
                      stroke: "#b69cfb",
                    },
                  },
                },
              },
            }}
            className="deleteTemplateBtn"
            onClick={() => {
              navigate(locationPath?.deleteTemplate, {
                state: {
                  fromRolesPage: true,
                  templateId: template.id,
                  templateName: template?.attributes?.name,
                  formPath: formPath,
                },
              });
            }}
          >
            <Cross />
          </LFButton>
        ),
      };
    })
    ?.filter((role) => {
      const isRole4 = equal(role?.roleId, 4);

      const result = role?.label?.trim()?.match(defaultTemplateRegex);
      if (isArray(result)) {
        const templateNumber = result[0]?.match(/\d+/)?.[0];
        defaultTemplateNumber < templateNumber &&
          (defaultTemplateNumber = templateNumber);
      }

      return ternary(isOptometrist, isRole4, !isRole4);
    });

  const templateId = newShiftForm?.template_id;

  const { performRequest } = ApiContainer();

  useEffect(() => {
    const initialApiCall = async () => {
      try {
        setLoadingStatus(true);
        const [reducedDetails, templateData] = await Promise.all([
          loadBusinesses(),
          loadTemplates(),
        ]);

        if (globalAttr?.roles && !length(globalAttr?.roles))
          dispatch(getRolesAction({ performRequest }));

        dispatch({
          type: SET_APP_DATA,
          payload: {
            [formPath?.state]: {
              ...newShiftState,
              businessDetail: reducedDetails,
              templateDetail: templateData,
              ...(!selectedOption && {
                option: { isRoles: false, isTemplate: false },
              }),
            },
          },
        });

        setLocalStorageItem(
          LS_KEY_TEMPLATES_DATA,
          JSON?.stringify(templateData?.templates),
        );
        const formDataPayload = {
          ...(!selected && { business_id: head(reducedDetails)?.id }),
        };
        dispatch(formDataAction(formDataPayload, formPath?.parent, true));

        setLoadingStatus(false);
      } catch (error) {
        setLoadingStatus(false);
        showToast("Something went wrong");
      }
    };
    initialApiCall();
  }, []);

  const loadBusinesses = async () => {
    const response = await performRequest({
      endPoint: apiEndpoints?.allBusiness,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    const reducedDetails = reduceBusinessDetails(response?.data?.business);

    return reducedDetails;
  };

  const loadTemplates = async () => {
    const response = await performRequest({
      endPoint: apiEndpoints?.templates,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    return response?.data;
  };

  const handleBusinessChange = (event) => {
    const { name, value } = event.target;
    const payload = {
      [name]: value,
      role_id: null,
      template_id: null,
    };
    dispatch(formDataAction(payload, formPath?.parent, true));
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    dispatch(formDataAction({ [name]: value }, formPath?.parent, true));
  };

  const handleSelectedOptionChange = (obj) => {
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...newShiftState,
          option: obj,
        },
      },
    });
    dispatch(
      formDataAction(
        { role_id: null, template_id: null },
        formPath?.parent,
        true,
      ),
    );
  };

  const optionButtonAttributes = [
    {
      label: "BLANK SHIFT",
      variant: "outlined",
      onClick: () =>
        handleSelectedOptionChange({ isRoles: true, isTemplate: false }),
      isActive: selectedOption?.isRoles,
    },
    {
      label: "FROM TEMPLATE",
      variant: "outlined",
      disabled: !length(templateOptions),
      onClick: () =>
        handleSelectedOptionChange({ isRoles: false, isTemplate: true }),
      isActive: selectedOption?.isTemplate,
    },
  ];

  const handleNextClick = () => {
    if (!roleId && !templateId) return;
    const payload = {
      ...(!selectedOption?.isRoles && { role_id: null }),
      ...(!selectedOption?.isTemplate && { template_id: null }),
    };
    const preValues = {
      business_id: newShiftForm?.business_id,
      end_date: newShiftForm?.end_date,
      is_urgent: newShiftForm?.is_urgent,
      role_id: newShiftForm?.role_id,
      start_date: newShiftForm?.start_date,
      template_id: newShiftForm?.template_id,
      type: newShiftForm?.type,
      recurrences: newShiftForm?.recurrences,
    };
    setLocalStorageItem(
      LS_KEY_NEWSHIFT_DATA,
      JSON.stringify({
        ...preValues,
        ...payload,
      }),
    );
    dispatch(
      formDataAction(
        { ...payload, ...(!!roleId && preValues) },
        formPath?.parent,
        !roleId,
      ),
    );
    navigate(locationPath?.newShiftAddDetails, {
      state: { templateNumber: defaultTemplateNumber },
    });
  };

  const selectBusinessAttribute = {
    name: "business_id",
    type: "select",
    label: !capitlizeWord(businessType) ? null : capitlizeWord(businessType),
    value: selected,
    options: businessDetail?.map((business) => ({
      value: business?.id,
      label: business?.name,
    })),
    onChange: handleBusinessChange,
  };
  const showSelectBusiness = length(businessDetail) > 1;

  const rolesOption = ternary(
    equal(businessType, businessRoles.optometrist),
    optometristRolesOptions,
    pharmacyRolesOptions,
  );
  return {
    loadingStatus,
    showSelectBusiness,
    selectBusinessAttribute,
    optionButtonAttributes,
    selectedOption,
    roleId,
    rolesOption,
    templateId,
    templateOptions,
    handleSelectChange,
    handleNextClick,
  };
};

export default RoleAndTemplateSelectionContainer;
