import React from "react";
import LFForm from "../shared/LFForm";
import LFFormFields from "../shared/LFFormFields";
import LFButton from "../shared/LFButton";
import FormContainer from "../container/form.container";
import {
  equal,
  isUndefined,
  keys,
  objectsAreSame,
  values,
} from "../utils/javascript";
import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_ERROR_DATA } from "../redux/constant";
import LFStack from "../shared/LFStack";
import LFGrid from "../shared/LFGrid";
import theme from "../themes/theme";
import { validateAddress } from "../utils/validation";
import { ReactComponent as EditIcon } from "../assets/svg/editIcon.svg";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  inActiveForm: {
    "& .MuiInput-root": {
      "&:hover": {
        "&::before": {
          borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
        },
      },
    },
    "& svg": {
      transition: "all 0.3s ease-in-out",
      marginTop: "-15px",
      "&.MuiCircularProgress-svg": {
        marginTop: 0,
      },
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
    },
  },
  activeForm: {
    "& svg": {
      transform: "translateY(0%) rotate(180deg)",
      transition: "all 0.3s ease-in-out",
      marginTop: "-15px",
    },
    "& .MuiInputBase-input": {
      cursor: "auto",
    },
  },
}));

const EditForm = ({
  attribute,
  defaultValues,
  formPath,
  setIsEditable,
  handleSubmit,
  isEditable,
  setEditingFieldName,
  editingFieldName,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute,
      formPath,
      defaultValues,
      isResetValues: true,
    });

  const onSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });

    if (values(validationErrors)?.some((error) => error)) return;

    if (
      !objectsAreSame(defaultValues?.location, formData?.location) &&
      !validateAddress(formData?.location)
    )
      return false;

    handleSubmit(formData);
  };

  const handleOnChange = (event) => {
    handleChange(event);

    if (equal(event?.target?.name, "password") && formData?.confirm_password) {
      dispatch({
        type: SET_FORM_ERROR_DATA,
        payload: {
          [formPath?.parent]: {
            ...formError,
            confirm_password: validate(
              "confirm_password",
              formData?.confirm_password,
              event?.target?.value,
            ),
          },
        },
      });
    }
  };

  const handleCancelClick = () => {
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: {},
      },
    });
    setIsEditable(false);
    setEditingFieldName && setEditingFieldName(null);
  };

  const actionButtons = (
    <LFGrid item xs={12}>
      <LFStack
        mt={3}
        spacing={1}
        direction={{
          md: isUndefined(editingFieldName) ? "row" : "column-reverse",
          xs: "column-reverse",
        }}
        maxWidth={{ md: "auto", xs: 380 }}
        mx={{ md: 0, xs: "auto" }}
        {...(!isUndefined(editingFieldName) && {
          position: { md: "absolute" },
          top: { md: 400 },
          width: { md: "100%" },
        })}
      >
        <LFButton
          type={"button"}
          onClick={handleCancelClick}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
            width: "100%",
            fontSize: 14,
            minHeight: 40,
            fontWeight: 400,
            border: {
              xs: "none",
              md: isUndefined(editingFieldName) ? "1px solid" : "none",
            },
            color: {
              xs: theme.palette.secondary.dark,
              md: theme.palette.primary.main,
            },
          }}
        >
          Cancel
        </LFButton>
        <LFButton
          type={"button"}
          onClick={onSubmit}
          variant={"contained"}
          isLoading={loadingStatus}
          disabled={isFormError}
          sx={{ fontSize: 14, minHeight: 40, width: "100%" }}
        >
          SAVE CHANGES
        </LFButton>
      </LFStack>
    </LFGrid>
  );

  const finalAttributes = attribute.map((attr) => {
    return {
      ...attr,
      endAdornment: (
        <EditIcon
          cursor="pointer"
          onClick={handleCancelClick}
          {...(equal(attr?.name, "email") && { display: "none" })}
        />
      ),
    };
  });

  return (
    <>
      <LFForm
        className={classNames({
          [classes.inActiveForm]: true,
          [classes.activeForm]: isEditable,
        })}
      >
        <LFFormFields
          formData={formData}
          attributes={finalAttributes}
          formError={formError}
          handleChange={handleOnChange}
          endAdornment={<EditIcon cursor="pointer" />}
          singleFieldEditable={!!isEditable}
          editable
        />
        {isUndefined(editingFieldName)
          ? actionButtons
          : editingFieldName && actionButtons}
      </LFForm>
    </>
  );
};

export default EditForm;
