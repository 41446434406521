import React from "react";
import LFBox from "../../shared/LFBox";
import PromptTemplet from "../reusable/PromptTemplet";
import { makeStyles } from "@mui/styles";
import ConfirmOverlapContainer from "../../container/confirmOvelapShift.container";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));
const ConfirmOvelapShift = ({
  handleConfirmClick,
  handleCancelClick,
  shiftDetailStr,
  formPath,
}) => {
  const classes = useStyles();

  const { buttonAttributes } = ConfirmOverlapContainer({
    handleConfirmClick,
    handleCancelClick,
    formPath,
  });

  return (
    <LFBox className={classes.promptWrapper}>
      <PromptTemplet
        mainText={"Do you want to proceed with this action?"}
        subText={`Same shift ${shiftDetailStr} already exists, are you sure you want to post another shift with the same details?`}
        buttonAttributes={buttonAttributes}
        iconSrc={true}
        alertType={true}
        bgGray={false}
      />
    </LFBox>
  );
};

export default ConfirmOvelapShift;
