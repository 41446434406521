import { makeStyles } from "@mui/styles";
import PromptTemplet from "../reusable/PromptTemplet";
import LFBox from "../../shared/LFBox";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  shiftStatuses,
} from "../../utils/constant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import ApiContainer from "../../container/api.container";
import { useSelector } from "react-redux";
import { isLessThan48Hours } from "../../utils/moment";
import { equal } from "../../utils/javascript";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));

const cancelStatusCode = "cancelled";
const formPath = {
  parent: "cancelLoader",
};

const CancelShift = () => {
  const { performRequest } = ApiContainer();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { shiftId } = useParams();
  const shiftData = location?.state?.shiftData;
  const shiftStatus = location?.state?.shiftStatus;

  useEffect(() => {
    if (!location?.state?.showCancelConfirm) {
      navigate(locationPath?.myCalendar);
    }
  }, []);

  const handleConfirmClick = async () => {
    const { statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.businessShifts}/${shiftId}`,
      method: apiMethods?.put,
      data: {
        status: cancelStatusCode,
        include_all_recurrences: location?.state?.changeAllRecurringShifts,
      },
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.parent,
      showFailedMessage: true,
    });
    if (statusCode == 200) {
      return navigate(locationPath?.cancelShiftSuccess);
    }
    return statusCode;
  };

  const handleCancelClick = async () => {
    return navigate(-1, { replace: true });
  };

  const buttonAttributes = [
    {
      label: "CONFIRM",
      onClick: handleConfirmClick,
      loadingStatus: loadingStatus,
      variant: "contained",
    },
    {
      label: "cancel",
      onClick: handleCancelClick,
      variant: "",
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        fontSize: 16,
        mt: 2,
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];

  return (
    <>
      <LFBox className={classes.promptWrapper}>
        <PromptTemplet
          mainText={
            <>
              {location?.state?.changeAllRecurringShifts
                ? "Are you sure you want to cancel all the recurring unfilled shifts?"
                : "Are you sure you want to cancel this shift"}
              {isLessThan48Hours(shiftData?.start_date) &&
                !equal(shiftStatus, shiftStatuses?.unfilled) && (
                  <>
                    <LFBox sx={{ mt: 2 }}>
                      <u>
                        Cancelling this shift will incur a cancellation fee.
                      </u>
                    </LFBox>
                  </>
                )}
            </>
          }
          subText={"This action cannot be reversed."}
          buttonAttributes={buttonAttributes}
          iconSrc={true}
          alertType={true}
          bgGray={false}
        />
      </LFBox>
    </>
  );
};

export default CancelShift;
