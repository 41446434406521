import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import LFBox from "../../shared/LFBox";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import { ternary } from "../../utils/javascript";
import {
  Confidentiality,
  cancelations,
  definitionPoints1,
  definitionPoints2,
  feesPoints,
  general,
  indemnityPoints,
  intelectualProperty,
  interpretationPoints,
  liability,
  locumFindsObligations,
  placemenRequestsPoints,
  quoteProcessPoints,
  relBetweenParties,
  replacements,
  taxPoints,
  termsAndTerminations,
  warrantiesPoints,
  workHealthSafety,
} from "../../description/securityAndPolicy/clientAgreement.description";
import { Link } from "react-router-dom";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      paddingTop: 64,
      paddingBottom: 48,
      fontSize: 36,
      lineHeight: "41px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 32,
        paddingBottom: 40,
        fontSize: 28,
        lineHeight: "32px",
      },
      [theme.breakpoints.down("xts")]: {
        fontSize: 20,
        lineHeight: "20px",
        fontWeight: 400,
        paddingTop: 36,
        paddingBottom: 24,
      },
    },
  },

  textContainer: {
    maxWidth: 935,
    width: "100%",
    margin: [[0, "auto"]],
    padding: [[0, 12]],
    paddingBottom: 160,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 100,
      padding: [[0, 13]],
    },
    [theme.breakpoints.down("xts")]: {
      paddingBottom: 75,
    },
    "& .MuiTypography-body1": {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      lineHeight: "150%",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      textDecoration: "underline",
      textTransform: "uppercase",
      marginTop: 20,
    },
  },
  smHeading: {
    "& .MuiTypography-root": {
      fontWeight: 700,
    },
  },
  row: {
    "&.MuiStack-root": {
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        display: "inline-block",
        "& .MuiTypography-root": {
          display: "inline",
        },
        "& $pointNumber": {
          display: "inline-block",
        },
      },
    },
    [theme.breakpoints.down("xts")]: {
      lineHeight: 0,
    },
  },
  lgHeading: {
    "&.MuiStack-root": {
      marginTop: 44,
      [theme.breakpoints.down("sm")]: {
        marginTop: 30,
        marginLeft: 5,
      },
    },
    "& .MuiTypography-root": {
      fontWeight: 700,
      fontSize: 16,
      textDecoration: "underline",
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
    },
  },
  pointNumber: {
    "&.MuiTypography-root": {
      maxWidth: 44,
      width: "100%",
      textDecoration: "none",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        width: "unset",
        marginRight: 5,
      },
    },
  },
  m44: {
    "&.MuiStack-root": {
      marginLeft: 44,
      gap: [[15, 0]],
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
        "& $m44": {
          marginLeft: 15,
        },
      },
    },
  },
  alphabetListWrapper: {
    "&.MuiStack-root": {
      marginTop: 20,
      gap: [[15, 0]],
      [theme.breakpoints.down("xts")]: {
        lineHeight: 0,
      },
    },
  },
}));

const ClientAgreement = () => {
  const classes = useStyles();

  const subPointHtml = ({ subkey, subPoint, grandSubPoints }) => (
    <Fragment key={subkey}>
      <LFStack className={classes.row}>
        <LFTypography className={classes.pointNumber}>({subkey})</LFTypography>
        <LFTypography>{subPoint}</LFTypography>
      </LFStack>
      {grandSubPoints && (
        <LFStack className={`${classes.m44} ${classes.alphabetListWrapper}`}>
          {grandSubPoints?.map(({ grandsubkey, grandSubPoint }) => (
            <Fragment key={grandsubkey}>
              <LFStack className={classes.row}>
                <LFTypography className={classes.pointNumber}>
                  ({grandsubkey})
                </LFTypography>
                <LFTypography>{grandSubPoint}</LFTypography>
              </LFStack>
            </Fragment>
          ))}
        </LFStack>
      )}
    </Fragment>
  );

  const arrangeDoc = ({
    key,
    point,
    subPoints,
    subpointHead = false,
    pointCover = false,
    bolderPoints = false,
  }) => {
    return (
      <Fragment key={key}>
        <LFStack
          className={`${classes.row} ${ternary(
            bolderPoints,
            classes.smHeading,
            "",
          )}`}
        >
          <LFTypography className={classes.pointNumber}>
            {pointCover ? `(${key})` : key}
          </LFTypography>
          <LFTypography>{point}</LFTypography>
        </LFStack>
        {(subPoints || subpointHead) && (
          <LFStack
            className={`${classes.m44} ${ternary(
              subpointHead,
              "",
              classes.alphabetListWrapper,
            )}`}
          >
            {subpointHead && <LFTypography>{subpointHead}</LFTypography>}
            {subpointHead && subPoints ? (
              <LFStack className={classes.alphabetListWrapper}>
                {subPoints?.map(({ subkey, subPoint, grandSubPoints }) =>
                  subPointHtml({ subkey, subPoint, grandSubPoints }),
                )}
              </LFStack>
            ) : (
              subPoints?.map(({ subkey, subPoint, grandSubPoints }) =>
                subPointHtml({ subkey, subPoint, grandSubPoints }),
              )
            )}
          </LFStack>
        )}
      </Fragment>
    );
  };

  return (
    <>
      <LFBox className={classes.textContainer}>
        <LFTypography variant="h2" color="primary" className={classes.heading}>
          Client Agreement
        </LFTypography>
        <LFStack>
          <LFTypography>
            This Client Agreement governs the terms on which LOCUMFIND PTY LTD
            (ACN 669 232 795) (<b>LOCUMFIND</b>) will supply You (the
            <b>Client</b>) with Locums to carry out Work. By registering an
            Account as a Client with LOCUMFIND, you agree to be bound by the
            terms and conditions set out in this Client Agreement. You
            acknowledge and agree that LOCUMFIND may update, vary or amend this
            Client Agreement from time to time in accordance with its terms and
            the most up-to-date version of the Client Agreement is available at{" "}
            <Link
              to={"https://www.locumfind.com.au/client-agreement"}
              target="_blank"
            >
              www.locumfind.com.au/client-agreement
            </Link>
          </LFTypography>
          <LFTypography className={classes.mdHeading}>AGREEMENT:</LFTypography>
          <LFStack spacing={"15px"}>
            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>1.</LFTypography>
              <LFTypography>DEFINITIONS AND INTERPRETATION</LFTypography>
            </LFStack>
            <LFStack className={`${classes.row} ${classes.smHeading}`}>
              <LFTypography className={classes.pointNumber}>1.1</LFTypography>
              <LFTypography>Definitions</LFTypography>
            </LFStack>
            <LFStack className={classes.m44}>
              {definitionPoints1?.map((curPoint) => (
                <LFTypography key={curPoint}>{curPoint}</LFTypography>
              ))}

              <LFStack className={classes.alphabetListWrapper}>
                <LFStack className={classes.row}>
                  <LFTypography className={classes.pointNumber}>
                    (a)
                  </LFTypography>
                  <LFTypography>
                    the minimum hourly rate for the Role as set out in the
                    Award, if any, in Item 2 of the Schedule; plus
                  </LFTypography>
                </LFStack>
                <LFStack className={classes.row}>
                  <LFTypography className={classes.pointNumber}>
                    (b)
                  </LFTypography>
                  <LFTypography>
                    any overtime, loadings, penalty rates, allowances and other
                    payments due for the proposed work hours in a Placement
                    Request in accordance with the Award, if any, set out in
                    Item 2 of the Schedule in respect of the Role.
                  </LFTypography>
                </LFStack>
              </LFStack>
              {definitionPoints2?.map((curPoint) => (
                <LFTypography key={curPoint}>{curPoint}</LFTypography>
              ))}
            </LFStack>
            <LFStack className={`${classes.row} ${classes.smHeading}`}>
              <LFTypography className={classes.pointNumber}>1.2</LFTypography>
              <LFTypography>Interpretation</LFTypography>
            </LFStack>
            <LFStack className={classes.m44}>
              <LFTypography>
                The following rules shall in this Agreement unless the context
                requires otherwise:
              </LFTypography>
              <LFStack className={classes.alphabetListWrapper}>
                {interpretationPoints?.map(
                  ({ key, point, subPoints, subpointHead }) =>
                    arrangeDoc({
                      key,
                      point,
                      subPoints,
                      subpointHead,
                      pointCover: true,
                    }),
                )}
              </LFStack>
            </LFStack>
            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>2.</LFTypography>
              <LFTypography>PLACEMENT REQUESTS</LFTypography>
            </LFStack>
            {placemenRequestsPoints?.map(
              ({ key, point, subPoints, subpointHead }) =>
                arrangeDoc({ key, point, subPoints, subpointHead }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>3.</LFTypography>
              <LFTypography>QUOTE PROCESS</LFTypography>
            </LFStack>

            {quoteProcessPoints?.map(
              ({ key, point, subPoints, subpointHead }) =>
                arrangeDoc({ key, point, subPoints, subpointHead }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>4.</LFTypography>
              <LFTypography>REPLACEMENTS</LFTypography>
            </LFStack>
            {replacements?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({ key, point, subPoints, subpointHead }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>5.</LFTypography>
              <LFTypography>CANCELLATIONS</LFTypography>
            </LFStack>
            {cancelations?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
                bolderPoints: true,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>6.</LFTypography>
              <LFTypography>RELATIONSHIP BETWEEN THE PARTIES</LFTypography>
            </LFStack>
            {relBetweenParties?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({ key, point, subPoints, subpointHead }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>7.</LFTypography>
              <LFTypography>LOCUMFIND’S OBLIGATIONS</LFTypography>
            </LFStack>
            {locumFindsObligations?.map(
              ({ key, point, subPoints, subpointHead }) =>
                arrangeDoc({ key, point, subPoints, subpointHead }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>8.</LFTypography>
              <LFTypography>FEES</LFTypography>
            </LFStack>
            {feesPoints?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({ key, point, subPoints, subpointHead }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>9.</LFTypography>
              <LFTypography>TAX</LFTypography>
            </LFStack>
            {taxPoints?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
                bolderPoints: true,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>10.</LFTypography>
              <LFTypography>WORK, HEALTH AND SAFETY</LFTypography>
            </LFStack>
            {workHealthSafety?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>11.</LFTypography>
              <LFTypography>CONFIDENTIALITY</LFTypography>
            </LFStack>
            {Confidentiality?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>12.</LFTypography>
              <LFTypography>INTELLECTUAL PROPERTY</LFTypography>
            </LFStack>
            <LFStack className={classes.m44}>
              <LFTypography>
                All Intellectual Property Rights in materials developed or
                created by Locums while they are providing Services, on the
                Client’s time, or using the Client’s resources (including its
                confidential information), whether such materials were created
                during business hours or not, will vest in the Client, and
                neither LOCUMFIND nor the Locums will have any claim to or
                interest of any nature in such Intellectual Property Rights.
                LOCUMFIND undertakes to execute, and procure the execution of,
                all further documents and render all assistance reasonable
                required for the purpose of giving effect to this clause.
              </LFTypography>
            </LFStack>
            {intelectualProperty?.map(
              ({ key, point, subPoints, subpointHead }) =>
                arrangeDoc({
                  key,
                  point,
                  subPoints,
                  subpointHead,
                }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>13.</LFTypography>
              <LFTypography>WARRANTIES</LFTypography>
            </LFStack>
            {warrantiesPoints?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
                bolderPoints: true,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>14.</LFTypography>
              <LFTypography>INDEMNITY</LFTypography>
            </LFStack>
            <LFStack
              className={`${classes.m44} ${classes.alphabetListWrapper}`}
            >
              {indemnityPoints?.map(({ key, point, subPoints, subpointHead }) =>
                arrangeDoc({
                  key,
                  point,
                  subPoints,
                  subpointHead,
                  pointCover: true,
                }),
              )}
            </LFStack>
            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>15.</LFTypography>
              <LFTypography>LIABILITY</LFTypography>
            </LFStack>
            {liability?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>16.</LFTypography>
              <LFTypography>TERM AND TERMINATION</LFTypography>
            </LFStack>
            {termsAndTerminations?.map(
              ({ key, point, subPoints, subpointHead }) =>
                arrangeDoc({
                  key,
                  point,
                  subPoints,
                  subpointHead,
                }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}>17.</LFTypography>
              <LFTypography>GENERAL</LFTypography>
            </LFStack>
            {general?.map(({ key, point, subPoints, subpointHead }) =>
              arrangeDoc({
                key,
                point,
                subPoints,
                subpointHead,
                bolderPoints: true,
              }),
            )}

            <LFStack className={`${classes.row} ${classes.lgHeading}`}>
              <LFTypography className={classes.pointNumber}> </LFTypography>
              <LFTypography>SCHEDULE</LFTypography>
            </LFStack>
            <LFStack className={classes.m44}>
              <LFStack className={classes.smHeading}>
                <LFTypography>Item 1 (Role)</LFTypography>
              </LFStack>
              <LFTypography>Pharmacy Assistant (Level 1) (Casual)</LFTypography>
              <LFTypography>Pharmacy Assistant (Level 2) (Casual)</LFTypography>
              <LFTypography>
                Pharmacy Assistant / Dispensary Assistant (Level 3) (Casual)
              </LFTypography>
              <LFTypography>
                Pharmacy Assistant / Dispensary Assistant (Level 4) (Casual)
              </LFTypography>
              <LFTypography>Pharmacy Student (Casual)</LFTypography>
              <LFTypography>Pharmacy Intern (Casual)</LFTypography>
              <LFTypography>Pharmacist (Casual)</LFTypography>
              <LFTypography>Experienced Pharmacist (Casual)</LFTypography>
              <LFTypography>Pharmacist in Charge (Casual)</LFTypography>
              <LFTypography>Pharmacist Manager (Casual)</LFTypography>
              <LFTypography>Optometrist (Casual)</LFTypography>
              <LFStack className={classes.smHeading}>
                <LFTypography>Item 2 (Award)</LFTypography>
              </LFStack>
              <LFTypography>Pharmacy Industry Award 2020</LFTypography>
              <LFTypography>Optometrist : N/A</LFTypography>
            </LFStack>
          </LFStack>
        </LFStack>
      </LFBox>
      <MobileCopyright />
    </>
  );
};

export default ClientAgreement;
