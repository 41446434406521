import React, { useRef } from "react";
import LFStack from "../../../shared/LFStack";
import LFDatePickerField from "../../../shared/LFDatePickerField";
import { makeStyles } from "@mui/styles";
import LFButton from "../../../shared/LFButton";
import { ReactComponent as DownloadIcon } from "../../../assets/svg/download.svg";
import BusinessSelector from "../../reusable/BusinessSelector";
import theme from "../../../themes/theme";
import {
  transactionsFormPath,
  defaultValues,
} from "../../../description/payments/transaction.description";
import paymentsDatePickerContainer from "../../../container/payments/paymentDatePicker.container";
import LFBox from "../../../shared/LFBox";
import TransactionContainer from "../../../container/payments/transaction.container";
import moment from "moment";
import Loader from "../../reusable/Loader";
import { length, roundToTwoDecimalPlaces } from "../../../utils/javascript";
import LFTypography from "../../../shared/LFTypograpy";

const useStyles = makeStyles((theme) => ({
  transactionTable: {
    padding: [[8, 0]],
    justifyContent: "space-between",
    borderBottom: `1px solid #DDDDDD`,
    [theme.breakpoints.down("xxsm")]: {
      flexWrap: "wrap",
      padding: [[10, 0, 7]],
    },
    "& p": {
      flex: 1,
      margin: [[0, 10, 0, "8%"]],
      [theme.breakpoints.down("xxsm")]: {
        margin: 0,
        minWidth: "100%",
        fontSize: 14,
        order: 3,
      },
    },
    "& span": {
      color: theme.palette.secondary.dark,
      fontSize: 14,
    },
  },
  downloadButton: {
    "&.MuiButtonBase-root": {
      padding: [[8, 12]],
      border: `1px solid ${theme.palette.primary.main}`,
      fontSize: 12,
      height: "max-content",
      margin: "auto 0 0 auto",
      fontWeight: 500,
      "& svg": {
        marginRight: 8,
      },
    },
  },
  tableScroll: {
    maxHeight: "calc(100vh - 492px)",
    overflow: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxHeight: "unset",
    },
    "&.scrolling": {
      "&::-webkit-scrollbar-thumb": {
        background: "#8a8a8a",
      },
    },
    "&::-webkit-scrollbar": {
      width: 4,
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "5px",
      "&:hover": {
        background: "#8a8a8a",
      },
    },
  },
}));

const Transactions = () => {
  const classes = useStyles();
  const containerRef = useRef(null);
  const { loadingStatus, transactions, apiCall, downloadClick } =
    TransactionContainer({
      formPath: transactionsFormPath,
    });

  const { startDate, endDate, onDateValueChange, onDateErrorChange } =
    paymentsDatePickerContainer({
      formPath: transactionsFormPath,
      defaultValues: defaultValues,
      apiCall: apiCall,
    });
  const handleScroll = () => {
    containerRef.current.classList.add("scrolling");
    clearTimeout(containerRef.current.timeout);
    containerRef.current.timeout = setTimeout(() => {
      containerRef.current.classList.remove("scrolling");
    }, 400);
  };
  return (
    <>
      <LFStack
        sx={{
          display: { xxsm: "none", sx: "block" },
        }}
        mb={{ md: 3, xs: 1 }}
        direction="row"
        alignItems="center"
      >
        <LFTypography
          variant="h6"
          mr={"auto"}
          sx={{
            fontWeight: 400,
            color: theme.palette.primary.main,
          }}
        >
          Transactions
        </LFTypography>
        <LFButton
          sx={{ minWidth: "unset", padding: 0 }}
          onClick={downloadClick}
        >
          <DownloadIcon />
        </LFButton>
      </LFStack>
      <LFStack mb={4} pt={1}>
        <LFStack direction="row" spacing={3}>
          <BusinessSelector
            sx={{
              maxWidth: { md: 342, sm: "100%" },
              mr: "auto",
              flex: { md: "unset", xs: 1 },
            }}
          />
          <LFStack
            justifyContent="flex-end"
            flex={1}
            display={{ xts: "flex", xs: "none" }}
          >
            <LFButton
              className={classes.downloadButton}
              onClick={downloadClick}
            >
              <DownloadIcon />
              DOWNLOAD
            </LFButton>
          </LFStack>
        </LFStack>
        <LFStack direction="row" spacing={3} mt={{ md: 4, xs: 3 }}>
          <LFDatePickerField
            dateValue={startDate}
            name={transactionsFormPath.startDate}
            onChange={onDateValueChange}
            onError={onDateErrorChange}
            label={"From"}
            showLabel={true}
          />
          <LFDatePickerField
            dateValue={endDate}
            name={transactionsFormPath.endDate}
            onChange={onDateValueChange}
            onError={onDateErrorChange}
            minDate={startDate}
            label={"To"}
            showLabel={true}
          />
        </LFStack>
      </LFStack>
      {loadingStatus ? (
        <Loader />
      ) : (
        <LFBox
          className={classes.tableScroll}
          ref={containerRef}
          onScroll={handleScroll}
        >
          {transactions &&
            (length(transactions) ? (
              <>
                {transactions?.map(({ attributes }) => (
                  <LFStack
                    className={classes.transactionTable}
                    direction="row"
                    key={attributes?.id}
                  >
                    <LFTypography variant="span">
                      {moment(attributes?.date).format("DD/MM/YYYY")}
                    </LFTypography>
                    <LFTypography>
                      {`${attributes?.user_name} Invoice #${attributes?.invoice_id}`}
                      <span>{` (shift #${attributes?.shift_id})`}</span>
                    </LFTypography>
                    <LFTypography variant="span">
                      -$
                      {roundToTwoDecimalPlaces(attributes?.amount)}
                    </LFTypography>
                  </LFStack>
                ))}
              </>
            ) : (
              <LFTypography
                variant="h6"
                mt={2}
                color={"secondary.dark"}
                textAlign="center"
              >
                No transaction found
              </LFTypography>
            ))}
        </LFBox>
      )}
    </>
  );
};

export default Transactions;
