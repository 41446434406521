import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as CheckedBox } from "../assets/svg/checkBox.svg";
import { ReactComponent as Checked } from "../assets/svg/checked.svg";
import FormControlLabel from "@mui/material/FormControlLabel";
import { equal } from "../utils/javascript";

const LFCheckbox = ({ onChange, checked, name, error = false, ...props }) => {
  const onEnterPress = (e) => {
    const key = e?.keyCode || e?.which;
    if (equal(+key, 13)) {
      e.preventDefault();
      onChange({ target: { checked: !checked, name } });
    }
  };

  if (props?.label) {
    return (
      <FormControlLabel
        control={
          <Checkbox
            icon={<CheckedBox />}
            checkedIcon={<Checked />}
            name={name}
            checked={checked}
            onChange={onChange}
            onKeyDown={onEnterPress}
            sx={{
              "& svg": {
                "& path": {
                  ...(error && { stroke: "rgba(130, 255, 205, 1)" }),
                },
              },
            }}
            {...props}
          />
        }
        label={props.label}
      />
    );
  }
  return (
    <Checkbox
      icon={<CheckedBox />}
      checkedIcon={<Checked />}
      name={name}
      checked={checked}
      onChange={onChange}
      onKeyDown={onEnterPress}
      {...props}
    />
  );
};

export default LFCheckbox;
