import { useEffect, useState } from "react";
import { equal, isArray, ternary } from "../../utils/javascript";
import { LS_KEY_NEWSHIFT_DATA, locationPath } from "../../utils/constant";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_DATA } from "../../redux/constant";
import { setLocalStorageItem } from "../../utils/localStorage";
import { ZERO } from "../../utils/constant";
import { calculateDaysDifference } from "../../utils/moment";
import moment from "moment";
import useDebounce from "../../hooks/useDebounceHook";

const isLessThan48Hours = (dateString) => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const timeDifference = inputDate - currentDate;

  return timeDifference <= 48 * 60 * 60 * 1000;
};

const NewShiftContainer = ({ formPath }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const newShiftForm = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );

  const [currentType, setCurrentType] = useState(locationPath?.singleShift);

  const [date, setDateValue] = useState(null);

  useEffect(() => {
    setDateValue({
      startDate: {
        date: newShiftForm?.start_date
          ? newShiftForm.start_date
          : (location?.state?.selectedDate &&
              moment(location?.state?.selectedDate)?._d) ??
            null,
        error: false,
      },
      endDate: {
        date: newShiftForm?.end_date ? newShiftForm.end_date : null,
        error: false,
      },
    });
  }, []);

  const updateState = () => {
    if (
      !date?.startDate?.error &&
      !date?.endDate?.error &&
      date?.startDate?.date
    ) {
      dispatch({
        type: SET_FORM_DATA,
        payload: {
          [formPath?.parent]: {
            ...newShiftForm,
            ...{
              start_date: date?.startDate?.date,
              end_date: date?.endDate?.date,
            },
          },
        },
      });
    }
  };

  useDebounce(updateState, 300, [date]);

  const onChangeDateValue = (value, name) => {
    setDateValue({
      ...date,
      [name]: {
        ...date?.[name],
        date: value?._d,
      },
    });
  };
  const onDateChangeError = (reason, name) => {
    let isError = false;
    if (reason) isError = true;

    setDateValue({
      ...date,
      [name]: {
        ...date?.[name],
        error: isError,
      },
    });
  };

  useEffect(() => {
    const currentDate = moment().startOf("day").toISOString();
    const selectedDate =
      newShiftForm?.start_date &&
      new Date(newShiftForm?.start_date).toISOString();
    if (selectedDate && selectedDate < currentDate) {
      dispatch({
        type: SET_FORM_DATA,
        payload: {
          [formPath?.parent]: {
            ...newShiftForm,
            ...{ start_date: currentDate, end_date: null },
          },
        },
      });
    }
  }, [newShiftForm]);

  const handleTabChange = (e, value) => {
    setCurrentType(value);
  };

  const handleDateChange = (value) => {
    const shiftDate = ternary(
      isArray(value),
      { start_date: value[ZERO], end_date: value[1] },
      { start_date: value, end_date: null },
    );
    dispatch({
      type: SET_FORM_DATA,
      payload: { [formPath?.parent]: { ...newShiftForm, ...shiftDate } },
    });

    const startDate = ternary(isArray(value), value[ZERO], value);
    const endDate = ternary(isArray(value), value[1], null);

    setDateValue({
      startDate: {
        date: startDate,
        error: false,
      },
      endDate: {
        date: endDate,
        error: false,
      },
    });
  };

  const handleNextClick = () => {
    if (!newShiftForm?.start_date) return;
    if (
      equal(currentType, locationPath?.recurringShift) &&
      !newShiftForm?.end_date
    )
      return;

    const isUrgent = isLessThan48Hours(newShiftForm?.start_date);
    const payload = {
      ...newShiftForm,
      type: currentType,
      is_urgent: isUrgent,
      ...(equal(currentType, locationPath?.recurringShift) && {
        recurrences: calculateDaysDifference(
          newShiftForm?.start_date,
          newShiftForm?.end_date,
        ),
      }),
    };
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: payload,
      },
    });
    setLocalStorageItem(LS_KEY_NEWSHIFT_DATA, JSON.stringify(payload));
    if (isUrgent) return navigate(locationPath?.urgentShift);
    return navigate(locationPath?.roleAndTemplate);
  };

  const isDisabled =
    !newShiftForm?.start_date ||
    (equal(currentType, locationPath?.recurringShift) &&
      !newShiftForm?.end_date) ||
    date?.startDate?.error ||
    date?.endDate?.error;

  return {
    newShiftForm,
    currentType,
    isDisabled,
    handleNextClick,
    handleTabChange,
    handleDateChange,
    onChangeDateValue,
    onDateChangeError,
  };
};

export default NewShiftContainer;
