import { useEffect } from "react";
import ApiContainer from "../../api.container";
import { apiEndpoints, apiMethods } from "../../../utils/constant";
import { useSelector, useDispatch } from "react-redux";
import { LOADING_CHANGE, SET_APP_DATA } from "../../../redux/constant";
import { equal, hardCloneObj, ternary } from "../../../utils/javascript";

const NotificationsSettingsContainer = ({ formPath, fieldsOrder }) => {
  const { performRequest } = ApiContainer();

  const dispatch = useDispatch();

  const notificationData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );
  const NotificationsLoading = useSelector(
    (state) => state?.app?.loader?.[formPath?.loader],
  );

  useEffect(() => {
    const getNotificationFields = async () => {
      dispatch({
        type: LOADING_CHANGE,
        payload: { [formPath?.loader]: true },
      });

      const res = await getNotifications();

      const finalobject = fieldsOrder?.map((cur) => {
        const { id, n_type, web, email } =
          res?.find((curObj) => equal(curObj?.n_type, cur)) || {};
        return { id, n_type, web, email };
      });

      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.parent]: ternary(!!res, finalobject, notificationData),
        },
      });

      dispatch({
        type: LOADING_CHANGE,
        payload: { [formPath?.loader]: false },
      });
    };
    getNotificationFields();
  }, []);

  const hendleSwitchChange = async ({ e, id }) => {
    const index = notificationData?.findIndex((cur) => equal(cur?.id, id));
    const curValue = notificationData?.[index]?.[e?.target?.name];

    dispatch({
      type: LOADING_CHANGE,
      payload: { [formPath?.loader]: true },
    });

    const newNotificationObject = hardCloneObj(notificationData);

    newNotificationObject[index][[e?.target?.name]] = !curValue;

    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: newNotificationObject,
      },
    });

    const response = await updateNotifications({
      bodyObj: {
        notification_permission: {
          [e?.target?.name]: !curValue,
        },
      },
      id,
    });

    newNotificationObject[index] = response;
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: ternary(
          !!response,
          newNotificationObject,
          notificationData,
        ),
      },
    });

    dispatch({
      type: LOADING_CHANGE,
      payload: { [formPath?.loader]: false },
    });
  };

  const getNotifications = async () => {
    const response = await performRequest({
      endPoint: apiEndpoints?.notificationPermissions,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
      showSuccessMessage: false,
    });
    return response?.data?.notification_permissions;
  };

  const updateNotifications = async ({ bodyObj, id }) => {
    const response = await performRequest({
      endPoint: `${apiEndpoints?.notificationPermissions}/${id}`,
      method: apiMethods?.patch,
      tokenKey: "Authorization",
      showFailedMessage: true,
      showSuccessMessage: false,
      data: bodyObj,
    });
    return response?.data?.notification_permission?.attributes;
  };

  return {
    notificationData,
    NotificationsLoading,
    hendleSwitchChange,
  };
};

export default NotificationsSettingsContainer;
