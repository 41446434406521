import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { makeStyles } from "@mui/styles";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";

const useStyles = makeStyles((theme) => ({
  notFound: {
    minHeight: "calc(100vh - 203px)",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {},
  },
  notFoundIcon: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    lineHeight: 0,

    borderRadius: "50%",
    margin: "0 auto 30px",

    "& svg": {
      width: 80,
      height: 80,
      padding: "18px 15px 20px",
      [theme.breakpoints.down("md")]: {
        width: 60,
        height: 60,
        padding: "14px 11px 16px",
      },
    },
  },
}));

const NotFoundComponent = ({ message }) => {
  const classes = useStyles();
  return (
    <LFBox className={classes.notFound}>
      <LFBox className={classes.notFoundIcon}>
        <ReportProblemOutlinedIcon />
      </LFBox>
      <LFTypography
        variant="h4"
        fontSize={{ md: "24px", sm: "20px", xs: "14px" }}
        color="secondary.dark"
      >
        {" "}
        {message}
      </LFTypography>
    </LFBox>
  );
};

export default NotFoundComponent;
