import React from "react";
import LFBox from "../../../shared/LFBox";
import LFForm from "../../../shared/LFForm";
import LFFormFields from "../../../shared/LFFormFields";
import LFButton from "../../../shared/LFButton";
import FormContainer from "../../../container/form.container";
import {
  defaultValues,
  registerBusinessAttribute,
  registerBusinessFormPath,
} from "../../../description/register/registerBusiness.description";
import RegisterBusinessContainer from "../../../container/register/registerBusiness.container";
import LFGrid from "../../../shared/LFGrid";
import { useLocation } from "react-router-dom";
import { equal, ternary } from "../../../utils/javascript";

const RegisterBusiness = () => {
  const { state } = useLocation();
  const attribute = ternary(
    state?.showRegiserBusiness,
    registerBusinessAttribute?.filter((attr) => !equal(attr?.name, "type")),
    registerBusinessAttribute,
  );
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: attribute,
      formPath: registerBusinessFormPath,
      defaultValues,
      isResetValues: state?.showRegiserBusiness,
    });

  const { loadingStatus, handleSubmit } = RegisterBusinessContainer({
    formData,
    formError,
    formPath: registerBusinessFormPath,
    validate,
    isResetValues: state?.showRegiserBusiness,
  });

  const submitLabel = ternary(state?.showRegiserBusiness, "SAVE", "NEXT");

  return (
    <>
      <LFForm>
        <LFFormFields
          attributes={attribute}
          formData={formData}
          formError={formError}
          handleChange={handleChange}
        />
        <LFGrid item xs={12}>
          <LFBox textAlign="center" width="100%">
            <LFButton
              type={"button"}
              onClick={handleSubmit}
              variant={ternary(
                state?.showRegiserBusiness,
                "contained",
                "outlined",
              )}
              isLoading={loadingStatus}
              disabled={isFormError}
              sx={{ minWidth: 280, mx: "auto", mt: 6 }}
            >
              {submitLabel}
            </LFButton>
          </LFBox>
        </LFGrid>
      </LFForm>
    </>
  );
};

export default RegisterBusiness;
