import React, { useEffect } from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import LFGrid from "../../shared/LFGrid";
import { Typography } from "@mui/material";
import LFStack from "../../shared/LFStack";
import LFContainer from "../../shared/LFContainer";
import { useLocation } from "react-router-dom";
import mission from "../../assets/svg/mission.svg";
import MobileCopyright from "./MobileCopyright";

const OurValuesSections = [
  {
    number: "1",
    heading: "COMMITMENT TO EXCELLENCE",
    desc: "We uphold the highest standards of quality in healthcare by selecting and connecting businesses with skilled and reliable locum professionals. Our commitment to excellence ensures that healthcare facilities receive the best possible support, maintaining the integrity of patient care.",
  },
  {
    number: "2",
    heading: "INNOVATION AND TECHNOLOGY",
    desc: "We embrace innovation and leverage technology to streamline the locum placement process. Our commitment to staying at the forefront of digital solutions enables us to provide a state-of-the-art platform, including a dedicated app for locums, to enhance the efficiency and convenience of managing shifts.",
  },
  {
    number: "3",
    heading: "TRANSPARENCY AND INTEGRITY",
    desc: "We prioritize transparency in all our dealings. From shift management and notifications to quick payments, we operate with integrity to build trust among our healthcare professionals and partner businesses. ",
  },
];

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    "&.MuiBox-root": {
      maxWidth: 780,
      margin: "0 auto",
      padding: "64px 0",
      [theme.breakpoints.down("xsm")]: {
        padding: "40px 0",
      },
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 32,
      lineHeight: "41px",
      textAlign: "center",
      [theme.breakpoints.down("xts")]: {
        fontSize: 20,
        lineHeight: "28px",
      },
    },
  },
  lgHeading: {
    "&.MuiTypography-root": {
      fontSize: 36,
      lineHeight: "46px",
      textAlign: "center",
      [theme.breakpoints.down("xts")]: {
        textAlign: "center",
        fontSize: 20,
        lineHeight: "28px",
      },
    },
  },
  descText: {
    "&.MuiTypography-root": {
      fontSize: 20,
      lineHeight: "26px",

      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
        lineHeight: "18px",
      },
    },
  },
  contentWrapper: {
    "&.MuiBox-root": {
      padding: "96px 30px 60px",
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        padding: "96px 0px 60px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "64px 0px 48px",
      },
    },
  },
  nuberOut: {
    "&.MuiBox-root": {
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: `2px solid ${theme.palette.secondary.main}`,
      borderRadius: "100%",
      marginTop: "-30px",
      "& .MuiTypography-root": {
        fontSize: 28,
        lineHeight: "36px",
      },
      [theme.breakpoints.down("xsm")]: {
        marginBottom: 24,
        marginTop: "unset",
      },
    },
  },
  cardContainer: {
    "&.MuiGrid-root": {
      padding: "28px 28px 164px",
      borderBottom: `1px solid ${theme.palette.white.main}`,
      [theme.breakpoints.down("sm")]: {
        padding: "24px 0px 48px",
      },
    },
  },
  missionImage: {
    [theme.breakpoints.down("xsm")]: {
      width: "52%",
    },
  },
}));
const CompanyInfo = () => {
  const { pathname } = useLocation();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <>
      <LFBox className={classes.textWrapper}>
        <LFContainer style={{ padding: 15 }}>
          <LFTypography
            variant="h2"
            color="primary"
            className={classes.heading}
            alignItems="center"
          >
            Our Mission
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{
              mt: { sm: 6, xs: 3 },
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            “Connecting High Quality Reliable Healthcare Professionals with
            Businesses Seamlessly”
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{ mt: { sm: 6, xs: 3 }, textAlign: "center" }}
          >
            At LocumFind, our mission is to seamlessly bridge the gap between
            healthcare professionals and businesses by providing a cutting-edge
            digital platform.
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{ mt: { sm: 3, xs: 2 }, textAlign: "center" }}
          >
            We strive to revolutionize the locum industry, ensuring that
            healthcare facilities have swift access to high-quality
            professionals while offering a dynamic and user-friendly experience
            for locums and businesses.
          </LFTypography>
          <LFStack alignItems="center" mt={{ xs: 4.5, xts: 9 }}>
            <img
              src={mission}
              alt="mission-img"
              className={classes.missionImage}
            />
          </LFStack>
        </LFContainer>
      </LFBox>
      <LFBox className={classes.contentWrapper}>
        <LFContainer>
          <LFBox
            sx={{
              paddingBottom: { md: "85px", xs: "48px" },
              borderBottom: "1px solid #fff",
            }}
          >
            <LFTypography
              variant="h2"
              color="secondary.light"
              className={classes.lgHeading}
            >
              Our Values
            </LFTypography>
          </LFBox>
          {OurValuesSections?.map((data, i) => {
            return (
              <LFGrid container className={classes.cardContainer} key={i}>
                <LFGrid item md={5} sm={4} xs={12} xsm={5}>
                  <LFBox>
                    <LFStack
                      direction={{ xsm: "row", xs: "column" }}
                      alignItems="center"
                    >
                      <LFBox className={classes.nuberOut}>
                        <Typography color="secondary" fontWeight="500">
                          {data.number}
                        </Typography>
                      </LFBox>
                      <LFTypography
                        color="secondary"
                        fontWeight="500"
                        fontSize={18}
                        sx={{
                          marginLeft: { md: "31px", xsm: "24px", xs: 0 },
                          textTransform: "uppercase",
                          textAlign: "center",
                          width: "100%",
                          maxWidth: { xs: 160, sm: "228px" },
                        }}
                        alignItems="center"
                      >
                        {data.heading}
                      </LFTypography>
                    </LFStack>
                  </LFBox>
                </LFGrid>
                <LFGrid item md={7} sm={8} xs={12} xsm={7}>
                  <Typography
                    color="secondary.light"
                    fontSize={{ xsm: 16, xs: 14 }}
                    sx={{
                      maxWidth: { xsm: "371px", xs: "100%" },
                      width: "100%",
                      paddingLeft: { md: 2, sm: 3.5, xsm: 2 },
                      textAlign: { xsm: "start", xs: "center" },
                      marginTop: { xsm: 0, xs: 2.6 },
                    }}
                  >
                    {data.desc}
                  </Typography>
                </LFGrid>
              </LFGrid>
            );
          })}
        </LFContainer>
      </LFBox>
      <LFBox className={classes.textWrapper}>
        <LFContainer
          sx={{
            paddingTop: { xsm: "179px" },
            paddingBottom: { xsm: "130px" },
          }}
        >
          <LFTypography
            variant="h2"
            color="primary"
            className={classes.heading}
            alignItems="center"
          >
            Our Commitment
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{
              mt: { sm: 6, xs: 3 },
              textAlign: { xs: "center", xsm: "left" },
            }}
          >
            At LocumFind, we pledge an unwavering commitment to delivering
            exceptional service to our clients. We prioritize efficient shift
            management, ensuring seamless coordination from shift posting to
            instant confirmations, guaranteeing healthcare professionals are
            matched swiftly with suitable opportunities.
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{
              mt: { sm: 2, xs: 2 },
              textAlign: { xs: "center", xsm: "left" },
            }}
          >
            Timely and hassle-free payments are a cornerstone of our dedication,
            recognizing the hard work of our professionals and reinforcing trust
            in our platform.
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{
              mt: { sm: 2, xs: 2 },
              textAlign: { xs: "center", xsm: "left" },
            }}
          >
            Additionally, our commitment extends to real-time notifications,
            keeping both healthcare professionals and businesses informed of
            shift confirmations, schedule changes, and essential updates.
          </LFTypography>
          <LFTypography
            color="secondary.dark"
            className={classes.descText}
            sx={{
              mt: { sm: 1, xs: 3 },
              textAlign: { xs: "center", xsm: "left" },
            }}
          >
            Through these commitments, we aim to be the premier choice for
            healthcare professionals and businesses alike, reshaping the
            landscape of healthcare staffing with a focus on efficiency,
            reliability, and seamless connections.
          </LFTypography>
        </LFContainer>
      </LFBox>
      <MobileCopyright />
    </>
  );
};

export default CompanyInfo;
