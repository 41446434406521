import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_APP_DATA } from "../../redux/constant";
import { equal, head, ternary } from "../../utils/javascript";
import ApiContainer from "../api.container";

import getBusinessDetailsAction from "../../redux/action/getBusinessDetailsAction";
import { maxDistanceValue, pharmacy } from "./constants";
import queryString from "query-string";
import {
  LS_KEY_BUSINESS_ID,
  apiEndpoints,
  apiMethods,
} from "../../utils/constant";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";
import { ZERO } from "../../utils/constant";
import formDataAction from "../../redux/action/formDataAction";
import { useMediaQuery } from "@mui/material";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

const SearchLocumContainer = ({ formPath, defaultValues }) => {
  const mobileMatches = useMediaQuery("(max-width: 767px)");
  const { setBgColorGray } = useOutletContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const backButton = state?.backButton;

  const { performRequest } = ApiContainer();
  const globalAttr = useSelector((state) => state?.globalAttr);

  const selectedBusinessId = globalAttr?.selectedBusinessId;
  const businessType = globalAttr?.businessType ?? pharmacy;
  const allBusinessDetail = globalAttr.businessDetail;

  const serchLocumState = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );

  const searchBussinessId = serchLocumState?.searchBussinessId;

  const searchedLocumsResultsState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (equal(allBusinessDetail?.length, ZERO))
      dispatch(getBusinessDetailsAction(performRequest));

    if (getLocalStorageItem(LS_KEY_BUSINESS_ID) != selectedBusinessId) {
      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.state]: {},
        },
      });
      setLocalStorageItem(LS_KEY_BUSINESS_ID, selectedBusinessId);
    }

    if (
      equal(serchLocumState, undefined) ||
      equal(searchBussinessId, undefined)
    ) {
      dispatch(
        formDataAction(
          {
            ...defaultValues[businessType],
            searchBussinessId: head(allBusinessDetail)?.id,
          },
          formPath?.parent,
          true,
        ),
      );
    }
  }, [selectedBusinessId, businessType, allBusinessDetail]);

  useEffect(() => {
    if (showResult) {
      setBgColorGray(true);
    }

    return () => {
      setBgColorGray(false);
    };
  }, [showResult]);

  const handleBackButtonClick = () => {
    if (showResult) {
      setShowResult(false);
    } else {
      navigate(-1);
    }
  };

  const handlSubmit = async () => {
    const {
      availability,
      start_date,
      end_date,
      skill_ids,
      start_rate,
      end_rate,
      min_distance,
      max_distance,
    } = serchLocumState.form;

    const role_ids = ternary(equal(businessType, pharmacy), [1, 2, 3], [4]);

    const sendingObj = {
      availability,
      start_date: start_date && new Date(start_date),
      end_date: end_date && new Date(end_date),
      skill_ids,
      start_rate,
      end_rate,
      role_ids,
      min_distance,
      max_distance: ternary(
        max_distance > maxDistanceValue,
        null,
        max_distance,
      ),
      business_id: searchBussinessId,
    };

    const paramsObj = queryString.stringify(
      { ...sendingObj },
      { arrayFormat: "bracket" },
    );

    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...searchedLocumsResultsState,
          isLoading: true,
        },
      },
    });
    const response = await searchLocum({ paramsObj });
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...searchedLocumsResultsState,
          isLoading: false,
          results: response,
        },
      },
    });
    if (mobileMatches) {
      setShowResult(true);
    }
  };

  const searchLocum = async ({ paramsObj }) => {
    const response = await performRequest({
      endPoint: `${apiEndpoints?.searchLocums}?${paramsObj}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });

    return response?.data?.locums;
  };

  return {
    backButton,
    showResult,
    mobileMatches,
    handlSubmit,
    handleBackButtonClick,
    businessType,
    searchedLocumsResultsState,
    selectedBusinessId,
  };
};

export default SearchLocumContainer;
// selectBusinessAttribute,
