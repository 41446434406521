import { equal } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const addLastMessageAction =
  (message, formPath, selectedTabId) => (dispatch, getState) => {
    const contactData = getState().app?.data?.[formPath?.parent];
    const unreadCount = getState().app?.data?.[formPath?.unreadCount];
    const businessId = message?.attributes?.business?.id;
    const businessConversations = contactData?.[businessId]?.data;
    const conversation = businessConversations?.find(({ attributes }) =>
      equal(attributes?.id, message?.attributes?.id),
    )?.attributes;
    const isChatOpen = equal(message?.attributes?.id, selectedTabId);

    let newContactData = [];
    if (conversation) {
      const filteredContact = businessConversations?.filter(
        ({ attributes }) => !equal(attributes?.id, message?.attributes?.id),
      );
      const messageClone = {
        ...message,
        attributes: {
          ...message.attributes,
          read: isChatOpen || message?.attributes?.read,
        },
      };
      newContactData = [messageClone, ...filteredContact];
    } else {
      newContactData = [message, ...(businessConversations || [])];
    }
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: {
          ...contactData,
          [businessId]: {
            ...contactData?.[businessId],
            data: newContactData,
          },
        },
        ...(!isChatOpen &&
          (!conversation || conversation?.read) &&
          !message?.attributes?.read && {
            [formPath?.unreadCount]: unreadCount + 1,
          }),
      },
    });
  };

export default addLastMessageAction;
