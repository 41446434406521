import { makeStyles } from "@mui/styles";
import PromptTemplet from "../../reusable/PromptTemplet";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../../utils/constant";
import { useLocation, useNavigate } from "react-router-dom";
import LFBox from "../../../shared/LFBox";
import { useEffect, useState } from "react";
import ApiContainer from "../../../container/api.container";
import { showToast } from "../../../utils/toastService";
import Loader from "../../reusable/Loader";
import { useDispatch, useSelector } from "react-redux";
import { SET_APP_DATA } from "../../../redux/constant";
import formDataAction from "../../../redux/action/formDataAction";
const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));

const DeleteTemplateSuccess = () => {
  const { performRequest } = ApiContainer();
  const classes = useStyles();

  const [templateDeleted, setTemplateDeleted] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleDoneClick = () => {
    return navigate(locationPath?.roleAndTemplate, {
      state: {
        reloadPage: true,
      },
    });
  };

  const templateId = location?.state?.templateId;
  const formPath = location?.state?.formPath;

  const newShiftState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );
  const templateOptions = newShiftState?.templateDetail?.templates;
  const curTemplate = templateOptions?.find(
    (template) => template.id === templateId,
  );
  const templateName = curTemplate?.attributes?.name;
  useEffect(() => {
    if (!location?.state?.fromRolesPage || !newShiftState || !curTemplate) {
      handleDoneClick();
    }
  }, []);

  const handleConfirmClick = async () => {
    setisLoading(true);
    const response = await performRequest({
      endPoint: `${apiEndpoints?.deleteTemplate}/${templateId}`,
      method: apiMethods?.delete,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });

    const { statusCode } = response;
    setisLoading(false);
    if (statusCode === statusCodes.OK) {
      setTemplateDeleted(true);

      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.state]: null,
        },
      });

      dispatch(formDataAction({ template_id: null }, formPath?.parent, true));

      setTemplateDeleted(true);
    } else if (statusCode === statusCodes.NOT_FOUND) {
      handleDoneClick();
    } else {
      showToast("something went wrong");
    }
  };

  const doneButtonAttributes = [
    {
      label: "DONE",
      onClick: handleDoneClick,
      variant: "contained",
    },
  ];
  const askButtonsAttributes = [
    {
      label: "Yes",
      onClick: handleConfirmClick,
      variant: "contained",
    },
    {
      label: "No",
      onClick: handleDoneClick,
      variant: "",
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        fontSize: 16,
        mt: 2,
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <LFBox className={classes.promptWrapper}>
            {templateDeleted ? (
              <PromptTemplet
                mainText={"Template has been deleted"}
                buttonAttributes={doneButtonAttributes}
                iconSrc={true}
                bgGray={false}
              />
            ) : (
              <PromptTemplet
                mainText={
                  <>
                    Are you sure you want to delete template <br />{" "}
                    {templateName}?{" "}
                  </>
                }
                subText={
                  "This action is irreversible, and all associated saved shift details will be removed."
                }
                buttonAttributes={askButtonsAttributes}
                iconSrc={true}
                bgGray={false}
              />
            )}
          </LFBox>
        </>
      )}
    </>
  );
};

export default DeleteTemplateSuccess;
