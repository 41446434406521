import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_DATA } from "../../redux/constant";
import moment from "moment";
import useDebounce from "../../hooks/useDebounceHook";
import { ternary } from "../../utils/javascript";

const PaymentsDatePickerContainer = ({ formPath, defaultValues, apiCall }) => {
  const dispatch = useDispatch();
  const dateObject = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const businessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const startDate =
    dateObject?.startDate?.date ?? moment().format("DD/MM/YYYY");
  const endDate = dateObject?.endDate?.date ?? moment().format("DD/MM/YYYY");

  const startDateError = dateObject?.startDate?.error;
  const endDateError = dateObject?.endDate?.error;

  const debounceCall = () => {
    if (!startDateError && !endDateError && businessId) {
      apiCall(startDate, endDate, businessId);
    } else if (!startDate && !endDate) {
      apiCall(startDate, endDate, businessId);
    }
  };

  useDebounce(debounceCall, 300, [
    startDate,
    endDate,
    startDateError,
    endDateError,
    businessId,
  ]);

  useEffect(() => {
    if (!dateObject) {
      dispatch({
        type: SET_FORM_DATA,
        payload: {
          [formPath?.parent]: defaultValues,
        },
      });
    }
  }, []);

  const onDateValueChange = (value, name) => {
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...dateObject,
          [name]: {
            ...dateObject[name],
            date: ternary(
              !value?._d,
              "",
              moment(value?._d).format("DD/MM/YYYY"),
            ),
          },
        },
      },
    });
  };
  const onDateErrorChange = (reason, name) => {
    let error = false;
    if (reason) error = true;
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...dateObject,
          [name]: {
            ...dateObject[name],
            error: error,
          },
        },
      },
    });
  };

  return {
    dateObject,
    startDate,
    endDate,
    businessId,
    onDateValueChange,
    onDateErrorChange,
  };
};

export default PaymentsDatePickerContainer;
