import React from "react";
import LFBox from "../../../shared/LFBox";
import LFTypography from "../../../shared/LFTypograpy";
import LFButton from "../../../shared/LFButton";
import EmailVerifyContainer from "../../../container/register/emailVerify.container";
import LFOtpInput from "../../../shared/LFOTPinput";
import { ternary } from "../../../utils/javascript";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  otpInput: {
    marginBottom: 60,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
    "& input": {
      padding: 5,
      height: 48,
      width: 48,
      margin: "0 5px",
      borderRadius: 10,
      border: "2px solid #33DFA1",
      fontSize: 24,
      color: theme.palette.primary.main,
    },
  },
}));

const EmailVerify = () => {
  const classes = useStyles();
  const {
    otp,
    otpInputNumber,
    isError,
    loadingStatus,
    handleOtpChange,
    handleOtpSubmit,
    handleResendOTP,
  } = EmailVerifyContainer();
  return (
    <LFBox maxWidth={320} textAlign="center">
      <LFTypography
        color="primary.main"
        mb={{ md: 5, xs: 3 }}
        lineHeight="21px"
      >
        We have emailed you a 5-digit code. Please enter this code below to
        verify your email address.
      </LFTypography>
      <LFBox className={classes.otpInput}>
        <LFOtpInput
          numInputs={otpInputNumber}
          otp={otp}
          handleChange={handleOtpChange}
          containerStyle={{ justifyContent: "center" }}
          inputStyle={ternary(
            isError,
            { color: "red", borderColor: "red", width: 48 },
            { width: 48 },
          )}
        />
      </LFBox>
      <LFTypography component="p" sx={{ mb: 2 }} color="secondary.dark">
        {`Didn't receive the code?`}{" "}
        <LFTypography
          onClick={handleResendOTP}
          color="primary.main"
          variant="span"
          sx={{ cursor: "pointer" }}
        >
          Resend code
        </LFTypography>
      </LFTypography>
      <LFButton
        sx={{ minWidth: 280 }}
        onClick={handleOtpSubmit}
        variant={"outlined"}
        isLoading={loadingStatus}
      >
        NEXT
      </LFButton>
    </LFBox>
  );
};

export default EmailVerify;
