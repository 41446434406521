import React from "react";
import LFDatePicker from "../../shared/LFDatePicker";
import LFGrid from "../../shared/LFGrid";
import LFDatePickerField from "../../shared/LFDatePickerField";
import { dateStringToDate } from "../../utils/javascript";

const SingleShift = ({
  startDate,
  onChange,
  onChangeDateValue,
  onDateChangeError,
}) => {
  return (
    <LFGrid container columnSpacing={3}>
      <LFGrid item md={3} xs={12}>
        <LFGrid container columnSpacing={3}>
          <LFGrid
            item
            md={12}
            xxsm={3}
            xs={6}
            mb={2}
            sx={{ marginTop: { md: "25px" } }}
          >
            <LFDatePickerField
              dateValue={startDate ? dateStringToDate(startDate) : null}
              name={"startDate"}
              onChange={onChangeDateValue}
              onError={onDateChangeError}
              label={"Date"}
              minDate={new Date()}
              showCalendar={false}
              disableIconButton={true}
            />
          </LFGrid>
        </LFGrid>
      </LFGrid>
      <LFGrid item md={7} xs={12}>
        <LFDatePicker startDate={startDate} onChange={onChange} />
      </LFGrid>
    </LFGrid>
  );
};

export default SingleShift;
