import React, { useRef } from "react";
import LFBox from "../../../shared/LFBox";
import LFStack from "../../../shared/LFStack";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LFDatePickerField from "../../../shared/LFDatePickerField";
import BusinessSelector from "../../reusable/BusinessSelector";
import theme from "../../../themes/theme";
import {
  invoiceFormPath,
  defaultValues,
} from "../../../description/payments/invoice.description";
import PaymentsDatePickerContainer from "../../../container/payments/paymentDatePicker.container";
import InvoiceCard from "./InvoiceCard";
import InvoicesContainer from "../../../container/payments/invoices.container";
import LFTypography from "../../../shared/LFTypograpy";
import Loader from "../../reusable/Loader";
import { length } from "../../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  cardScroll: {
    maxHeight: "calc(100vh - 463px)",
    overflow: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxHeight: "unset",
    },
    "&.scrolling": {
      "&::-webkit-scrollbar-thumb": {
        background: "#8a8a8a",
      },
    },

    "&::-webkit-scrollbar": {
      width: 4,
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "5px",
      "&:hover": {
        background: "#8a8a8a",
      },
    },
  },
}));

const Invoices = () => {
  const classes = useStyles();
  const containerRef = useRef(null);

  const { loadingStatus, invoices, apiCall } = InvoicesContainer({
    formPath: invoiceFormPath,
  });

  const {
    startDate,
    endDate,
    businessId,
    onDateValueChange,
    onDateErrorChange,
  } = PaymentsDatePickerContainer({
    formPath: invoiceFormPath,
    defaultValues: defaultValues,
    apiCall: apiCall,
  });

  const handleScroll = () => {
    containerRef.current.classList.add("scrolling");
    clearTimeout(containerRef.current.timeout);
    containerRef.current.timeout = setTimeout(() => {
      containerRef.current.classList.remove("scrolling");
    }, 400);
  };

  return (
    <>
      <Typography
        variant="h6"
        mb={{ md: 3, xs: 1 }}
        sx={{
          display: { xxsm: "none" },
          fontWeight: 400,
          color: theme.palette.primary.main,
        }}
      >
        Invoices
      </Typography>
      <LFStack mb={2.4} pt={1}>
        <LFStack direction="row" spacing={3}>
          <BusinessSelector sx={{ flex: { md: 1, xxsm: 0.5, xs: 1 } }} />
          <LFStack display={{ md: "none", xxsm: "flex", xs: "none" }}></LFStack>
        </LFStack>
        <LFStack direction="row" spacing={3} mt={{ md: 2, xs: 1 }}>
          <LFDatePickerField
            dateValue={startDate}
            name={invoiceFormPath.startDate}
            onChange={onDateValueChange}
            onError={onDateErrorChange}
            label={"From"}
            showLabel={true}
          />
          <LFDatePickerField
            dateValue={endDate}
            name={invoiceFormPath.endDate}
            onChange={onDateValueChange}
            onError={onDateErrorChange}
            minDate={startDate}
            label={"To"}
            showLabel={true}
          />
        </LFStack>
      </LFStack>
      {loadingStatus ? (
        <Loader />
      ) : (
        <LFBox
          className={classes.cardScroll}
          ref={containerRef}
          onScroll={handleScroll}
        >
          {invoices &&
            (length(invoices) ? (
              <>
                {invoices?.map(({ attributes }) => (
                  <InvoiceCard
                    key={attributes?.id}
                    curInvoice={attributes}
                    businessId={businessId}
                  />
                ))}
              </>
            ) : (
              <LFTypography
                variant="h6"
                mt={2}
                color={"secondary.dark"}
                textAlign="center"
              >
                No invoice found
              </LFTypography>
            ))}
        </LFBox>
      )}
    </>
  );
};

export default Invoices;
