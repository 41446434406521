import { makeStyles } from "@mui/styles";
import React from "react";
import { Outlet } from "react-router-dom";
import PublicLayoutContainer from "../../container/layout/publicLayout.container";
import LFBox from "../../shared/LFBox";
import LFContainer from "../../shared/LFContainer";
import LFGrid from "../../shared/LFGrid";
import LFTabs from "../../shared/LFTab";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  authLayout: {
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "50px 0",
  },
  authLayoutContent: {
    "& .MuiTabs-flexContainer": {
      "& .MuiButtonBase-root": {
        "&:last-child": {
          marginRight: 0,
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
      margin: "0 auto",
    },
    [theme.breakpoints.down("xxs")]: {
      maxWidth: "100%",
    },
  },
}));

const PublicLayout = ({ frame, showTab }) => {
  const classes = useStyles();
  const { currentURL, tabNavbar, handleTabChange } = PublicLayoutContainer();

  return (
    <>
      <LFBox className={classes.authLayout}>
        <LFContainer>
          <LFGrid container columnSpacing={4} alignItems="center">
            <LFGrid
              item
              sm={5}
              xs={12}
              sx={{ display: { md: "block", xs: "none" } }}
            >
              <LFBox textAlign="right">
                <img src={frame} alt="Side Frame" />
              </LFBox>
            </LFGrid>
            <LFGrid
              item
              md={showTab ? 7 : 4}
              xxsm={10}
              xs={12}
              mx={showTab ? { md: "unset", xs: "auto" } : { xs: "auto" }}
            >
              <LFBox className={classes.authLayoutContent}>
                {showTab && (
                  <LFTabs
                    value={
                      tabNavbar.find(({ value }) => value?.includes(currentURL))
                        ?.value
                    }
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    centered
                    list={tabNavbar}
                    indicatorTab
                    sx={{
                      "&.MuiTabs-root button.MuiButtonBase-root": {
                        color: "white.main",
                        letterSpacing: 0.6,
                        fontSize: { xxsm: 24, xs: 16 },
                        marginRight: { msm: 6, xs: 3 },
                      },
                    }}
                  />
                )}
                <Outlet />
              </LFBox>
            </LFGrid>
          </LFGrid>
        </LFContainer>
      </LFBox>
      <MobileCopyright
        backgroundColor={"primary.main"}
        fontColor={"white.main"}
      />
    </>
  );
};

export default PublicLayout;
