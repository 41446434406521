import React from "react";

import {
  signInAttributes,
  signInFormPath,
  defaultValues,
} from "../../description/signIn.description";
import LFForm from "../../shared/LFForm";
import LFFormFields from "../../shared/LFFormFields";
import LFGrid from "../../shared/LFGrid";
import LFButton from "../../shared/LFButton";
import { makeStyles } from "@mui/styles";

import FormContainer from "../../container/form.container";
import LFBox from "../../shared/LFBox";
import SignInContainer from "../../container/signIn.container";
import LFTypography from "../../shared/LFTypograpy";

const useStyles = makeStyles((theme) => ({
  formBox: {
    padding: "60px 37px 54px 36px",
    "& .MuiGrid-container": {
      marginLeft: 0,
      width: "100%",
      "& .MuiGrid-item": {
        padding: 0,
      },
    },
  },
  forgotPass: {
    color: theme.palette.white.main,
    display: "table",
    margin: "20px auto 50px",
    fontSize: 14,
    [theme.breakpoints.down("xts")]: {
      margin: "20px auto 20px",
    },
  },
  register: {
    color: theme.palette.white.main,
    fontWeight: 700,
    display: "table",
    margin: "0 auto",
  },
  authInput: {
    "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused": {
      color: "#000",
    },
    "& .MuiInputLabel-root": {
      fontWeight: 400,
      color: theme.palette.dark.main,
      fontSize: 12,
      transform: "scale(1.00)",
    },
    "& .MuiInput-root": {
      "&:before": {
        borderColor: theme.palette.dark.main,
      },
    },
    "& .MuiInputBase-root": {
      marginBottom: 20,
      "& .MuiInputBase-input": {
        color: "#000",
        padding: " 8px 0 8px 0",
        "& :placeholder": {
          fontWeight: 400,
          fontsize: 16,
          lineHeight: "18px",
        },
      },
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  redeemForget: {
    marginTop: "64px !important",
    [theme.breakpoints.down("xts")]: {
      marginTop: "32px !important",
    },
    "& .MuiButtonBase-root": {
      fontFamily: "'Poppins', 'sans-serif'",
      boxShadow: "0px 0px 3.56px 0px #000000B2",
      marginBottom: 0,
      width: "100%",
      paddingTop: "15.83px",
      paddingBottom: "15.83px",
      textTransform: "capitalize",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 15,
      fontSize: 18,
      lineHeight: "26.76px",
      fontWeight: 600,
      color: theme.palette.common.white,
      "&.Mui-disabled": {
        background: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xts")]: {
        width: "100%",
        fontSize: 14,
        lineHeight: "21px",
      },
      signInButton: {},
    },
  },
  pageTitle: {
    "& .MuiTypography-h4": {
      fontFamily: "'Work Sans', sans-serif",
      fontSize: 17.78,
      lineHeight: "20.85px",
      textAlign: "center",
      fontWeight: 500,
      color: theme.palette.primary.main,
      maxWidth: "325px",
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
        lineHeight: "16.42px",
        maxWidth: "300px",
      },
    },
  },
}));

const RedeemSignInForm = () => {
  const classes = useStyles();

  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: signInAttributes,
      formPath: signInFormPath,
      defaultValues,
      isResetValues: true,
    });

  const { handleSignInSubmit, loadingStatus } = SignInContainer({
    formData,
    formError,
    validate,
    formPath: signInFormPath,
    fromRedeemVoucher: true,
  });

  return (
    <LFBox className={classes.pageTitle}>
      <LFTypography variant="h4" component="h4" margin={"auto"}>
        Sign in to your LocumFind account to redeem your voucher
      </LFTypography>
      <LFBox className={classes.formBox}>
        <LFForm>
          <LFFormFields
            attributes={signInAttributes}
            handleChange={handleChange}
            formData={formData}
            formError={formError}
            classes={{
              root: classes.authInput,
            }}
          />
          <LFGrid
            xs={12}
            item
            marginTop={4}
            marginBottom={0}
            className={classes.redeemForget}
          >
            <LFButton
              type="submit"
              color="white"
              variant="contained"
              onClick={handleSignInSubmit}
              isLoading={loadingStatus}
              disabled={isFormError}
            >
              SIGN IN
            </LFButton>
          </LFGrid>
        </LFForm>
      </LFBox>
    </LFBox>
  );
};

export default RedeemSignInForm;
