import React from "react";
import MainLayout from "../presentation/layout/MainLayout";
import NotFound from "../presentation/NotFound";
import LandingPage from "../presentation/landing";
import PublicLayout from "../presentation/layout/PublicLayout";
import BusinessFrame from "../assets/svg/BusinessFrame.svg";
import BusinesUserImg from "../assets/svg/BusinessUser.svg";
import LocumFrame from "../assets/svg/LocumFrame.svg";
import { locationPath } from "../utils/constant";
import BusinesUser from "../presentation/welcome/BusinesUser";
import LocumUser from "../presentation/welcome/LocumUser";
import SignIn from "../presentation/signIn";
import AuthLayout from "../presentation/layout/AuthLayout";
import Register from "../presentation/register";
import EmailVerify from "../presentation/register/emailVerify";
import EmailSuccess from "../presentation/register/emailSuccess";
import ChoosePassword from "../presentation/register/choosePassword";
import RegisterBusiness from "../presentation/register/business";
import AccountSuccess from "../presentation/register/accountSuccess";
import ForgotPassword from "../presentation/forgotPassword";
import ResetPassword from "../presentation/resetPassword";
import CompanyInfo from "../presentation/reusable/CompanyInfo";
import TermsAndConditions from "../presentation/Security and Policy/TermsAndConditions";
import PrivacyPolicy from "../presentation/Security and Policy/PrivacyPolicy";
import ClientAgreement from "../presentation/Security and Policy/ClientAgreement";
import EmployeeAgreement from "../presentation/Security and Policy/EmploymentAgreement";
import ContactUs from "../presentation/Security and Policy/ContactUs";
import SupportLayout from "../presentation/support/SupportLayout";
import RedeemLayout from "../presentation/redeemVoucher/RedeemLayout";

const publicRoutes = [
  {
    element: <MainLayout footerDark />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },

      {
        path: locationPath.welcomeBusiness,
        element: <PublicLayout frame={BusinesUserImg} showTab={true} />,
        children: [{ index: true, element: <BusinesUser /> }],
      },
      {
        path: locationPath.welcomeLocum,
        element: <PublicLayout frame={LocumFrame} showTab={true} />,
        children: [{ index: true, element: <LocumUser /> }],
      },
      {
        path: locationPath.signIn,
        element: <PublicLayout frame={BusinessFrame} />,
        children: [{ index: true, element: <SignIn /> }],
      },
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: locationPath.clientAgreement,
        element: <ClientAgreement />,
      },
      {
        path: locationPath.employeeAgreement,
        element: <EmployeeAgreement />,
      },
    ],
  },

  {
    element: <AuthLayout />,
    children: [
      {
        path: locationPath.register,
        element: <Register />,
      },
      {
        path: locationPath.emailVerify,
        element: <EmailVerify />,
      },
      {
        path: locationPath.emailSuccess,
        element: <EmailSuccess />,
      },
      {
        path: locationPath.registerPassword,
        element: <ChoosePassword />,
      },
      {
        path: locationPath.registerBusiness,
        element: <RegisterBusiness />,
      },
      {
        path: locationPath.accountSuccess,
        element: <AccountSuccess />,
      },
      {
        path: locationPath.forgotPassword,
        element: <ForgotPassword />,
      },
      {
        path: locationPath.resetPassword,
        element: <ResetPassword />,
      },
    ],
  },
  {
    element: <MainLayout />,
    children: [
      {
        path: locationPath?.aboutUs,
        element: <CompanyInfo />,
      },
      {
        path: locationPath?.privacy,
        element: <PrivacyPolicy />,
      },
      {
        path: locationPath?.terms,
        element: <TermsAndConditions />,
      },
      {
        path: locationPath?.support,
        element: <SupportLayout />,
      },
      {
        path: locationPath?.supportType,
        element: <SupportLayout />,
      },
      {
        path: locationPath?.contact,
        element: <ContactUs />,
      },
    ],
  },
  {
    element: <MainLayout hideHeader={true} hideHelpPages={true} />,
    children: [
      {
        path: locationPath?.redeemVoucher,
        element: <RedeemLayout />,
      },
    ],
  },
];

export default publicRoutes;
