import React, { useState } from "react";
import LFTextField from "../../../shared/LFTextField";
import FromActionButtons from "./FromActionButtons";
import LFStack from "../../../shared/LFStack";
import { FormLabel } from "@mui/material";

const AvrgScriptForm = ({ setCurrentForm, defaultValue, label, onSave }) => {
  const startEndValue = defaultValue?.split("-");
  const [valueObj, setValueObj] = useState({
    start: startEndValue?.[0] ?? "",
    end: startEndValue?.[1] ?? "",
  });
  const [isError, setIsError] = useState(false);
  const { start, end } = valueObj;

  const validateValue = (value) => {
    if (Number(value?.end) < Number(value?.start)) return false;
    const avrgScriptValue = `${value?.start}-${value?.end}`;
    const regex = /^\d{1,3}-\d{1,3}$/;
    return regex.test(avrgScriptValue);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    value = value?.replace(/\D/g, "");

    if (value?.length > 3) return;
    setValueObj({ ...valueObj, [name]: value });
    setIsError(!validateValue({ ...valueObj, [name]: value }));
  };
  const handleSaveClick = () => {
    if (!validateValue(valueObj)) return setIsError(true);

    onSave(`${start}-${end}`);
  };
  return (
    <>
      <LFStack>
        <FormLabel sx={{ color: "secondary.dark", fontSize: 12 }}>
          {label}
        </FormLabel>
        <LFStack direction="row" alignItems="end" gap={5}>
          <LFTextField
            name={"start"}
            onChange={handleChange}
            value={start}
            InputLabelProps={{ shrink: true }}
            fullWidth
            error={isError}
            sx={{ maxLength: 10 }}
          />
          {" - "}
          <LFTextField
            name={"end"}
            onChange={handleChange}
            value={end}
            fullWidth
            error={isError}
          />
        </LFStack>
      </LFStack>
      <FromActionButtons
        setCurrentForm={setCurrentForm}
        handleSaveClick={handleSaveClick}
        isDisabled={isError}
      />
    </>
  );
};

export default AvrgScriptForm;
