import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { equal } from "../../utils/javascript";
import { apiEndpoints, apiMethods, locationPath } from "../../utils/constant";
import { SET_APP_DATA } from "../../redux/constant";
import { useMediaQuery } from "@mui/material";
import logoutAction from "../../redux/action/logoutAction";
import ApiContainer from "../api.container";

const AccountLayoutContainer = ({ formPath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matches = useMediaQuery("(max-width: 991px)");
  const mobileMatches = useMediaQuery("(max-width: 767px)");

  const accountStatus = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );

  const showMenu = equal(pathname, locationPath?.account);

  const { performRequest } = ApiContainer();

  useEffect(() => {
    const loadAccountStatus = async () => {
      const response = await performRequest({
        endPoint: apiEndpoints?.accountStatus,
        method: apiMethods?.get,
        tokenKey: "Authorization",
      });
      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.parent]: response?.data },
      });
    };
    loadAccountStatus();
  }, []);

  useEffect(() => {
    if (showMenu) {
      if (!matches) {
        navigate(locationPath?.profile, { replace: true });
      }
    }
  }, [pathname, matches]);

  const handleBackButtonClick = () => {
    if (mobileMatches && pathname?.includes(`${locationPath?.accountPayment}/`))
      return navigate(locationPath?.accountPayment);
    navigate(locationPath?.account);
  };

  const handleSignOutClick = () => {
    dispatch(logoutAction(() => navigate(locationPath?.signIn)));
  };

  const handleLinkClick = (path) => {
    navigate(path);
  };

  const incompleteStatus = {
    profile: equal(accountStatus?.user?.attributes?.is_complete, false),
    payments: equal(accountStatus?.user?.attributes?.payment_method_id, null),
  };

  return {
    pathname,
    showMenu,
    matches,
    mobileMatches,
    incompleteStatus,
    handleBackButtonClick,
    handleLinkClick,
    handleSignOutClick,
  };
};

export default AccountLayoutContainer;
