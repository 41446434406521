import { useSelector } from "react-redux";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import ApiContainer from "../api.container";
import { equal } from "../../utils/javascript";
import { useState } from "react";
import { showToast } from "../../utils/toastService";

const TransactionContainer = ({ formPath }) => {
  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const businessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const [transactions, setTransactions] = useState(null);

  const { performRequest } = ApiContainer();

  const apiCall = async (startDate, endDate, businessId) => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.transactions?.replace(":id", businessId)}`,
      method: apiMethods?.get,
      queryParams: { from_date: startDate, to_date: endDate },
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.parent,
      showFailedMessage: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) return setTransactions([]);
    setTransactions(data?.transactions);
  };

  const downloadClick = async () => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.transactionsDownload?.replace(
        ":id",
        businessId,
      )}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) {
      return showToast("Download failed");
    }

    const anchor = document.createElement("a");
    anchor.href = data.excel_link;
    anchor.click();
    return showToast("File downloaded successfully", "success");
  };

  return { loadingStatus, transactions, apiCall, downloadClick };
};

export default TransactionContainer;
