import { Tab, Tabs } from "@mui/material";
import React from "react";
import LFStack from "./LFStack";
import { LFStyled } from "./LFStyled";

const StyledTab = LFStyled(LFStack, {
  shouldForwardProp: (prop) => prop !== "indicatorTab",
})(({ theme, indicatorTab }) => ({
  marginTop: indicatorTab ? "0" : "10px",
  position: "relative",
  "& .MuiTabs-root": {
    minHeight: "auto",
    "& button": {
      textTransform: "none",
      backgroundColor: indicatorTab
        ? "transparent"
        : theme.palette.secondary.light,
      color: theme.palette.secondary.dark,
      borderRadius: indicatorTab ? "0" : "30px",
      position: "relative",
      minHeight: "auto",
      padding: indicatorTab ? "0 0 10px" : "10px 20px",
      marginRight: 20,
      whiteSpace: "nowrap",

      "&.Mui-selected": {
        backgroundColor: indicatorTab
          ? "transparent"
          : theme.palette.secondary.lightGreen,
        color: theme.palette.secondary.dark,
      },
    },
    "& .MuiTabScrollButton-root": {
      width: 30,
      "&.Mui-disabled": {
        display: "none",
      },
    },
  },
}));

const LFTabs = ({ list, indicatorTab, tabBordered, ...rest }) => {
  return (
    <StyledTab indicatorTab={indicatorTab}>
      <Tabs {...rest}>
        {list.map((item, index) => (
          <Tab
            label={item.label}
            key={index}
            value={item.value}
            disableRipple={tabBordered}
            disabled={rest.disabled}
          />
        ))}
      </Tabs>
    </StyledTab>
  );
};

export default LFTabs;
