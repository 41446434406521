import React, { useEffect, useRef, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import LFTextField from "./LFTextField";

const libraries = ["places"];
const google = (window.google = window.google ? window.google : {});

const LFGoogleAddress = ({
  name,
  onChange,
  readOnly,
  value,
  editable,
  ...rest
}) => {
  const inputRef = useRef({});
  const [addressObj, setAddressObj] = useState(value);

  const [suggestions, setSuggestions] = useState("");
  useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  useEffect(() => {
    !readOnly && initPlaceAPI();
  }, [suggestions]);
  useEffect(() => {
    inputRef.current.value = value?.address ?? "";
  }, [value]);

  const handleTextChange = (e) => {
    const { value, name } = e.target;
    setSuggestions(value);
    const emptyAddress = {
      ...addressObj,
      address: value,
      state: "",
      latitude: 0,
      longitude: 0,
      postal_code: "0000",
      city: "",
      address_line_1: "",
      address_line_2: "",
    };
    setAddressObj(emptyAddress);
    if (!value?.trim()) {
      return onChange({ target: { value: "", name } });
    }

    onChange({
      target: { value: { ...emptyAddress, address: value }, name },
    });
  };

  const initPlaceAPI = () => {
    if (google) {
      if (window.google.maps) {
        let autocomplete = new window.google.maps.places.Autocomplete(
          inputRef?.current,
          {
            componentRestrictions: { country: "AU" },
          },
        );
        inputRef.current.addEventListener("keydown", (e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            const place = autocomplete.getPlace();
            if (!place) {
              return false;
            }
          }
        });

        autocomplete.addListener("place_changed", () => {
          let place = autocomplete.getPlace();
          if (!place) {
            return false;
          }

          const finalAddressObj = {};
          place?.address_components?.forEach((item) => {
            const key = item?.types[0];
            finalAddressObj[key] = {
              long_name: item?.long_name,
              short_name: item?.short_name,
              types: item?.types,
            };
          });

          const city = finalAddressObj?.administrative_area_level_2?.long_name;
          const postal_code = finalAddressObj?.postal_code?.long_name;
          const state = finalAddressObj?.administrative_area_level_1?.long_name;
          const address_line_1 = finalAddressObj?.street_number?.long_name;
          const address_line_2 = finalAddressObj?.locality?.long_name;

          const obj = {
            id: place?.place_id,
            address: inputRef?.current?.value,
            city,
            postal_code,
            state,
            address_line_1,
            address_line_2,
            latitude: place.geometry?.location
              ? place.geometry.location.lat()
              : 0,
            longitude: place.geometry?.location
              ? place.geometry.location.lng()
              : 0,
          };

          setAddressObj(obj);
          onChange({ target: { value: obj, name } });
        });
      }
    }
  };
  return (
    <>
      {readOnly ? (
        <LFTextField
          sx={{ "& input": { textOverflow: "ellipsis" } }}
          {...{
            ...rest,
            type: "text",
            readOnly,
            value: value?.address,
            editable,
          }}
          multiline
        />
      ) : (
        <LFTextField
          inputRef={inputRef}
          autoComplete="off"
          label="Address"
          name={name}
          onChange={handleTextChange}
          editable
          multiline
          fullWidth
          {...rest}
        />
      )}
    </>
  );
};

export default LFGoogleAddress;
