import { useState } from "react";
import ApiContainer from "../../api.container";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { equal } from "../../../utils/javascript";
import logoutAction from "../../../redux/action/logoutAction";
import { useNavigate } from "react-router-dom";

const AccountSettingContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const loadingStatus =
    useSelector((state) => state?.app?.loader?.deleteAccount) ?? true;

  const { performRequest } = ApiContainer();

  const apiCall = async () => {
    handleOnClose();
    const response = await performRequest({
      endPoint: apiEndpoints?.deleteAccount,
      method: apiMethods?.delete,
      tokenKey: "Authorization",
      showFailedMessage: true,
      showSuccessMessage: true,
      needLoader: true,
      parent: "deleteAccount",
    });
    if (!equal(response?.statusCode, statusCodes?.OK)) return;

    dispatch(logoutAction(() => navigate(locationPath?.welcomeBusiness)));
  };

  const handleDeleteClick = () => {
    setIsOpen(true);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const dialogActions = [
    {
      label: "Yes",
      onClick: apiCall,
      variant: "contained",
    },
    {
      label: "No",
      onClick: handleOnClose,
    },
  ];
  return {
    loadingStatus,
    isOpen,
    dialogActions,
    handleOnClose,
    handleDeleteClick,
  };
};

export default AccountSettingContainer;
