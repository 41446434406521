import { useDispatch, useSelector } from "react-redux";
import { equal, keys, values } from "../utils/javascript";
import { SET_FORM_ERROR_DATA } from "../redux/constant";
import ApiContainer from "./api.container";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../utils/constant";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import logoutAction from "../redux/action/logoutAction";

const ResetPasswordContainer = ({
  formData,
  formError,
  formPath,
  validate,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const token = searchParams?.get("token");

  const { setHideCancel } = useOutletContext();
  const [isValid, setIsValid] = useState(false);

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const { performRequest } = ApiContainer();

  useEffect(() => {
    setHideCancel(true);
    const validateToken = async () => {
      const response = await performRequest({
        endPoint: apiEndpoints?.verifyToken,
        method: apiMethods?.get,
        queryParams: { token: token },
        showFailedMessage: true,
      });
      if (!equal(response?.statusCode, statusCodes?.OK)) return;

      setHideCancel(false);
      return setIsValid(true);
    };
    validateToken();
  }, []);

  const apiCall = async () => {
    const response = await performRequest({
      endPoint: apiEndpoints?.resetPassword,
      method: apiMethods?.patch,
      data: { token, password: formData?.password },
      showFailedMessage: true,
      showSuccessMessage: true,
      failedMessage: "Please provide a valid information",
      parent: formPath?.parent,
      needLoader: true,
    });

    if (!equal(response?.statusCode, statusCodes?.OK)) return;

    dispatch(logoutAction());
    return navigate(locationPath?.signIn);
  };

  const handleResetPasswordSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });
    if (values(validationErrors).some((error) => error)) return;

    return apiCall();
  };
  return { isValid, loadingStatus, handleResetPasswordSubmit };
};

export default ResetPasswordContainer;
