import React from "react";
import LFBox from "../../shared/LFBox";
import LFFormLabel from "../../shared/LFFormLabel";
import LFCheckbox from "../../shared/LFCheckbox";
import LFTypography from "../../shared/LFTypograpy";
import LFRange from "../../shared/LFRange";
import LFSwitch from "../../shared/LFSwitch";
import LocumFilterCalender from "./LocumFilterCalendar";
import LFStack from "../../shared/LFStack";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import {
  date,
  availability,
  rate,
  distance,
  maxDistanceValue,
} from "../../container/searchLocum/constants";
import LFDropDown from "../../shared/LFDropDown";
import Loader from "../reusable/Loader";

import SearchFormContainer from "../../container/searchLocum/searchForm.container";
import { isUndefined, length, ternary } from "../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  smallHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      marginBottom: 12,
      fontWeight: "400",
    },
  },
  divider: {
    "&.MuiDivider-root": {
      margin: [[20, 0]],
      borderColor: theme.palette.gray.dark,
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      marginBottom: 20,
    },
  },
  switchBox: {
    "&.MuiStack-root": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& label": {
      fontSize: 16,
      color: theme.palette.primary.main,
    },
  },
  range: {
    fontSize: 14,
    color: theme.palette.secondary.dark,
  },
  darkLabel: {
    "&.MuiFormLabel-root": {
      color: theme.palette.dark.main,
    },
  },
  removeLastDivider: {
    "& .MuiDivider-root": {
      marginBottom: 12,
      "&:last-child": {
        display: "none",
      },
    },
  },
  subHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
      margin: [[8, 0]],
      color: theme.palette.dark.main,
    },
  },
  chipContainer: {
    "&.MuiStack-root": {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 8,
      "& .MuiChip-root": {
        fontSize: 12,
        height: "auto",
        background: "transparent",
        border: `1px solid ${theme.palette.gray.dark}`,
        "& span": {
          padding: [[2, 8]],
        },
      },
    },
  },
}));

const SearchLocumFormField = ({ formPath, type, field = {} }) => {
  const {
    serchLocumState,
    handleChange,
    allBussinessesLoader,
    selectBusinessAttribute,
  } = SearchFormContainer({
    formPath,
  });

  const classes = useStyles();
  switch (type) {
    case availability:
      return (
        <>
          <LFTypography
            variant="h6"
            component="h6"
            className={classes.smallHeading}
          >
            {field?.label}
          </LFTypography>
          <LFStack direction="row" justifyContent="space-between">
            {field?.options?.map(({ label, name, id }) => {
              const isPresent = serchLocumState
                ? serchLocumState?.form?.[field?.name]?.includes(id)
                : false;

              return (
                <LFStack key={id} textAlign="center">
                  <LFFormLabel>{label}</LFFormLabel>
                  <LFCheckbox
                    name={name}
                    id={String(id)}
                    onChange={(e) => handleChange({ e, type: field?.name })}
                    checked={isPresent}
                  />
                </LFStack>
              );
            })}
          </LFStack>
          <Divider className={classes.divider} />
        </>
      );

    case date: {
      const start_date = serchLocumState?.form?.start_date;
      const end_date = serchLocumState?.form?.end_date;

      return (
        <>
          <LFStack className={classes.switchBox}>
            <LFFormLabel className={classes.darkLabel}>
              {field?.label}
            </LFFormLabel>
            <LFSwitch
              name={field?.name}
              onChange={(e) => handleChange({ e, type: "switch" })}
              checked={
                isUndefined(serchLocumState?.switches?.[field?.name])
                  ? false
                  : serchLocumState?.switches?.[field?.name]
              }
            />
          </LFStack>
          {
            <LFBox
              sx={{
                ...(!serchLocumState?.switches?.[field?.name] && {
                  display: "none",
                }),
              }}
            >
              <LocumFilterCalender
                startDate={start_date}
                endDate={end_date}
                onChange={(e) => handleChange({ e, type: field?.name })}
              />
            </LFBox>
          }
          <Divider className={classes.divider} />
        </>
      );
    }
    case distance: {
      const min_distance = serchLocumState?.form?.min_distance;
      const max_distance = serchLocumState?.form?.max_distance;
      return (
        <>
          <LFTypography
            variant="h6"
            component="h6"
            className={classes.smallHeading}
          >
            {field?.label}
          </LFTypography>
          <LFBox sx={{ mb: 3 }}>
            <LFTypography
              variant="h6"
              component="h6"
              sx={{
                fontSize: "14px",
                marginLeft: "10px",
                marginBottom: "-10px",
              }}
            >
              Business
            </LFTypography>
            {allBussinessesLoader ? (
              <Loader height="20%" />
            ) : (
              length(selectBusinessAttribute?.options) > 1 && (
                <>
                  <LFDropDown {...selectBusinessAttribute} />
                </>
              )
            )}
          </LFBox>
          <LFBox px={{ sm: 1.3, xs: "28px" }}>
            <LFRange
              min={field?.min}
              max={field?.max}
              name={field?.name}
              valueLabelFormatFunction={(value) =>
                ternary(
                  value > maxDistanceValue,
                  <>
                    {maxDistanceValue}km
                    <span style={{ fontSize: "18px" }}>+</span>
                  </>,
                  `${value}km`,
                )
              }
              value={[min_distance, max_distance]}
              onChange={(e) => handleChange({ e, type: field?.name })}
            />
          </LFBox>

          <Divider className={classes.divider} />
        </>
      );
    }

    case rate: {
      const start_rate = serchLocumState?.form?.start_rate;
      const end_rate = serchLocumState?.form?.end_rate;
      return (
        <>
          <LFTypography
            variant="h6"
            component="h6"
            className={classes.smallHeading}
          >
            {field?.label}
          </LFTypography>
          <LFBox px={{ sm: 1.3, xs: "28px" }}>
            <LFRange
              min={field?.min}
              max={field?.max}
              name={field?.name}
              valueLabelFormatFunction={(value) => `$${value}`}
              value={[start_rate, end_rate]}
              onChange={(e) => handleChange({ e, type: field?.name })}
            />
          </LFBox>
          <Divider className={classes.divider} />
        </>
      );
    }

    default:
      return null;
  }
};

export default SearchLocumFormField;
