import React from "react";
import LFBox from "../../shared/LFBox";
import LFForm from "../../shared/LFForm";
import LFFormFields from "../../shared/LFFormFields";
import LFButton from "../../shared/LFButton";
import FormContainer from "../../container/form.container";
import {
  addTemplateAttribute,
  addTemplateFormPath,
  defaultValues,
} from "../../description/addTemplate.description";
import LFGrid from "../../shared/LFGrid";
import AddTemplateContainer from "../../container/template/addTemplate.container";
import { useLocation } from "react-router";
import { ternary } from "../../utils/javascript";
import { makeStyles } from "@mui/styles";
import PromptTemplet from "../reusable/PromptTemplet";

const useStyles = makeStyles((theme) => ({
  form: {
    height: "auto",
    [theme.breakpoints.down("xxsm")]: {
      height: "100%",
    },
  },
}));

const AddTemplate = () => {
  const { state } = useLocation();
  const classes = useStyles();
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: addTemplateAttribute,
      formPath: addTemplateFormPath,
      defaultValues: {
        ...defaultValues,
        name: `My Template ${ternary(
          state?.templateNumber,
          +state?.templateNumber + 1,
          1,
        )}`,
      },
      isResetValues: false,
    });
  const { loadingStatus, handleTemplateSubmit, askOverride, dialogActions } =
    AddTemplateContainer({
      formPath: addTemplateFormPath,
      formData,
      formError,
      validate,
    });
  return (
    <LFBox
      my={{ xxsm: "auto", xs: 6 }}
      sx={{ height: { xxsm: "auto", xs: "100%" } }}
    >
      {askOverride ? (
        <PromptTemplet
          mainText={
            "Template with this name already exists. Would you like to save over the existing template?"
          }
          buttonAttributes={dialogActions}
          iconSrc={true}
          bgGray={false}
          alertType={true}
        />
      ) : (
        <LFForm className={classes.form}>
          <LFGrid
            item
            md={4}
            xxsm={6}
            xs={12}
            mx={"auto"}
            sx={{
              display: { xxsm: "block", xs: "flex" },
              flexDirection: "column",
            }}
          >
            <LFFormFields
              attributes={addTemplateAttribute}
              formData={formData}
              formError={formError}
              handleChange={handleChange}
            />
            <LFBox textAlign="center" width="100%" mt={7}>
              <LFButton
                type={"button"}
                onClick={handleTemplateSubmit}
                variant={"contained"}
                isLoading={loadingStatus}
                disabled={isFormError}
                sx={{
                  padding: "11px 68px",
                  lineHeight: 1.3,
                  fontSize: { xs: 18, xxsm: 14 },
                  minWidth: { xs: 272, xxsm: "unset" },
                }}
              >
                SAVE
              </LFButton>
            </LFBox>
          </LFGrid>
        </LFForm>
      )}
    </LFBox>
  );
};

export default AddTemplate;
