import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/svg/twitter.svg";
import { ReactComponent as Instagram } from "../../assets/svg/instagram.svg";
import { ReactComponent as Linkedin } from "../../assets/svg/linkedin.svg";
import { locationPath, socialMedia } from "../../utils/constant";
import { useSelector } from "react-redux";

const FooterContainer = () => {
  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);
  const navLink = [
    {
      label: "About",
      path: locationPath?.aboutUs,
    },
    {
      label: "Privacy",
      path: locationPath?.privacy,
    },
    {
      label: "Terms",
      path: locationPath?.terms,
    },
    {
      label: "Support",
      path: locationPath?.support,
    },
    {
      label: "Contact",
      path: locationPath?.contact,
    },
    isAuthenticated && {
      label: "Agreement",
      path: locationPath?.clientAgreement,
    },
  ];
  const socialMediaLinks = [
    { icon: <Facebook />, url: socialMedia?.facebook },
    { icon: <Twitter />, url: socialMedia?.twitter },
    { icon: <Instagram />, url: socialMedia?.instagram },
    { icon: <Linkedin />, url: socialMedia?.linkedin },
  ];
  return { navLink, socialMediaLinks };
};

export default FooterContainer;
