import moment from "moment";
export const transactionsFormPath = {
  parent: "payment-transactions",
  loader: "payment-transactions-loader",
  startDate: "startDate",
  endDate: "endDate",
};

export const defaultValues = {
  startDate: {
    date: moment().format("DD/MM/YYYY"),
    error: false,
  },
  endDate: {
    date: moment().add(7, "days").format("DD/MM/YYYY"),
    error: false,
  },
};
