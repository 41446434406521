import { makeStyles } from "@mui/styles";
import PromptTemplet from "../reusable/PromptTemplet";
import LFBox from "../../shared/LFBox";
import { locationPath } from "../../utils/constant";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));

const ShiftCanceledDone = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleDoneClick = () => {
    return navigate(locationPath?.myCalendar);
  };

  const buttonAttributes = [
    {
      label: "DONE",
      onClick: handleDoneClick,
      variant: "contained",
    },
  ];

  return (
    <>
      <LFBox className={classes.promptWrapper}>
        <PromptTemplet
          mainText={"Shift has been cancelled"}
          buttonAttributes={buttonAttributes}
          iconSrc={true}
          bgGray={false}
        />
      </LFBox>
    </>
  );
};

export default ShiftCanceledDone;
