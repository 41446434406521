import { locationPath } from "../../utils/constant";
import { ReactComponent as Calendar } from "../../assets/svg/calendar.svg";
import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import { ReactComponent as AccountIcon } from "../../assets/svg/accountIcon.svg";
import { ReactComponent as Profile } from "../../assets/svg/profile.svg";
import { ReactComponent as Businesses } from "../../assets/svg/businesses.svg";
import { ReactComponent as Payment } from "../../assets/svg/payment.svg";
import { ReactComponent as Settings } from "../../assets/svg/settings.svg";
import { ReactComponent as NotificationsIcon } from "../../assets/svg/notification.svg";
import { ReactComponent as MessagesIcon } from "../../assets/svg/messagesIcon.svg";

export const navLinks = [
  {
    link: locationPath?.myCalendar,
    icon: <Calendar />,
    title: "Calendar",
  },
  {
    link: locationPath?.searchLocum,
    icon: <SearchIcon />,
    title: "Find a Locum",
  },
  {
    link: locationPath?.messages,
    icon: <MessagesIcon />,
    title: "Messages",
    dropdown: false,
    badgeCount: true,
  },
  {
    link: locationPath?.notifications,
    icon: <NotificationsIcon />,
    title: "Notifications",
    dropdown: true,
    badgeCount: true,
  },

  {
    link: locationPath?.account,
    icon: <AccountIcon />,
    title: "Account",
    dropdown: true,
  },
];

export const menuLinks = [
  {
    label: "Profile",
    icon: <Profile />,
    redirect: locationPath?.profile,
  },
  {
    label: "Businesses",
    icon: <Businesses />,
    redirect: locationPath?.businessDetail,
  },
  {
    label: "Payments",
    icon: <Payment />,
    redirect: locationPath?.accountPayment,
  },
  {
    label: "Settings",
    icon: <Settings />,
    redirect: locationPath?.settings,
  },
];
