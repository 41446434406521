import React from "react";
import LFAccordian from "../../../shared/LFAccordian";
import LFStack from "../../../shared/LFStack";

import HowItWorks from "../HowItWorks";

import BussinessAccordianCard from "./BussinessAccordianCard";
import {
  addPaymentMethod,
  addingABusiness,
  amendShift,
  breakdownofCosts,
  browseLocums,
  calendarFilters,
  cancellation,
  editingAShift,
  finalisingShift,
  howToPostAShifts,
  inviteLocum,
  invoicesAndTransactions,
  locumFindServiceFee,
  messaging,
  notificationsAndSecurity,
  paymentProcess,
  registerAccount,
  reviewingApplicants,
  statistics,
} from "../../../description/support/bussinessSupport.description";

const sectionData = {
  8: [addPaymentMethod, addingABusiness],
  9: [howToPostAShifts],
  10: [
    reviewingApplicants,
    editingAShift,
    calendarFilters,
    browseLocums,
    messaging,
    inviteLocum,
  ],
  11: [
    paymentProcess,
    finalisingShift,
    amendShift,
    locumFindServiceFee,
    breakdownofCosts,
    cancellation,
    invoicesAndTransactions,
    statistics,
  ],
  12: [notificationsAndSecurity],
};

const BussinessSupport = ({ selectedType, selectedSection }) => {
  const renderSection = () => {
    switch (selectedSection) {
      case 7:
        return (
          <>
            <LFAccordian title={"How it Works"} id="supportBusinessHowItWorks">
              <HowItWorks selectedType={selectedType} />
            </LFAccordian>
            <BussinessAccordianCard
              cardData={registerAccount}
              id={registerAccount?.id}
            />
          </>
        );
      case 8:
      case 9:
      case 10:
      case 11:
      case 12: {
        const data = sectionData[selectedSection] || [];
        return data?.map((cardData) => (
          <BussinessAccordianCard
            key={cardData?.title}
            id={cardData?.id}
            cardData={cardData}
          />
        ));
      }
    }
  };
  return (
    <LFStack gap={{ xs: 3, xsm: 5 }} sx={{ xs: 2, xts: 4, xsm: 6 }}>
      {renderSection()}
    </LFStack>
  );
};

export default BussinessSupport;
