import React, { useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import LFBox from "../../../shared/LFBox";
import LFButton from "../../../shared/LFButton";
import LFTypography from "../../../shared/LFTypograpy";
import { LS_KEY_REGISTER_ID, locationPath } from "../../../utils/constant";
import { removeLocalStorageItem } from "../../../utils/localStorage";
import { useSelector } from "react-redux";
import { SuccessIcon } from "../../../shared/icons";

const AccountSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPath } = useOutletContext();
  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);

  useEffect(() => {
    setPath(null);
    if (!location?.state?.showAccountSuccess || !isAuthenticated) {
      navigate(locationPath?.registerBusiness, { replace: true });
    }
  }, []);

  const goToProfilePage = () => {
    navigate(locationPath?.profile);
    removeLocalStorageItem(LS_KEY_REGISTER_ID);
  };
  return (
    <LFBox maxWidth={340} textAlign="center">
      <LFBox mb={1}>
        <SuccessIcon />
      </LFBox>
      <LFTypography color="primary" mb={1}>
        Your account has been created{" "}
      </LFTypography>
      <LFTypography color="secondary.dark" mb={8}>
        Please complete your profile and link your payment information to find
        and hire Locums.
      </LFTypography>
      <LFButton
        onClick={goToProfilePage}
        variant={"outlined"}
        sx={{ minWidth: 280 }}
      >
        COMPLETE PROFILE
      </LFButton>
    </LFBox>
  );
};

export default AccountSuccess;
