import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiContainer from "../api.container";
import {
  LS_KEY_TOKEN,
  locationPath,
  socketConstants,
} from "../../utils/constant";
import { equal, head } from "../../utils/javascript";
import { getLocalStorageItem } from "../../utils/localStorage";
import { useLocation } from "react-router-dom";
import addLastMessageAction from "../../redux/action/addLastMessageAction";
import createConversationAction from "../../redux/action/createConversationAction";
import readConversationAction from "../../redux/action/readConversationAction";
import deleteMessageAction from "../../redux/action/deleteMessageAction";
import { SET_APP_DATA } from "../../redux/constant";

const MessagesContainer = ({ formPath }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const initialLoader = useSelector(
    (state) => state.app?.loader?.[formPath?.createConversation],
  );
  const contactData = useSelector(
    (state) => state.app?.data?.[formPath?.parent],
  );
  const businessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const [chatSocket, setChatSocket] = useState(null);
  const [messagesData, setMessagesData] = useState({});
  const [showChat, setShowChat] = useState(false);
  const [selectedTab, setSelectedTab] = useState({ id: null, userId: null });
  const [selectedUser, setSelectedUser] = useState(null);

  const { performRequest } = ApiContainer();
  const locumData = state?.locumData;
  const businessConversation = contactData?.[businessId];

  const handleMessageReceived = useCallback(
    (eventData) => {
      const data = JSON.parse(eventData?.data);
      const { message } = { ...data };
      if (
        equal(message?.type, socketConstants.type.conversations) ||
        message?.deleted_message
      ) {
        const { attributes, deleted_message, conversation_id, message_id } = {
          ...message,
        };
        const updateMessages = (prevConversations) => {
          const clonePrev = { ...prevConversations };
          if (deleted_message) {
            const prevMessages = clonePrev?.[conversation_id];
            const updatedMessages = prevMessages?.filter(
              (message) => !equal(message?.attributes?.id, message_id),
            );
            if (updatedMessages) {
              clonePrev[conversation_id] = updatedMessages;
            }
          } else {
            let preMessages = clonePrev?.[attributes?.id];
            if (!preMessages) {
              preMessages = [];
            }
            preMessages.unshift({ attributes: attributes?.last_message });
          }
          return clonePrev;
        };
        setMessagesData(updateMessages);
        if (deleted_message) {
          dispatch(deleteMessageAction(message, formPath));
        } else {
          dispatch(addLastMessageAction(message, formPath, selectedTab?.id));
        }
      }
    },
    [selectedTab?.id],
  );
  const connectSocket = useCallback(() => {
    try {
      const socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?token=${getLocalStorageItem(
          LS_KEY_TOKEN,
        )}`,
      );
      if (socket) {
        socket.onopen = () => {
          const subscribeConverstion = {
            command: "subscribe",
            identifier: JSON.stringify({
              channel: socketConstants.channel.conversations,
            }),
          };
          socket.send(JSON.stringify(subscribeConverstion));
        };
        socket.onerror = () => {};
        socket.onclose = () => {
          if (equal(window.location.pathname, locationPath?.messages)) {
            setTimeout(() => {
              connectSocket();
            }, 3000);
          }
        };

        socket.onmessage = handleMessageReceived;

        setChatSocket(socket);
      }
    } catch (error) {
      if (equal(window.location.pathname, locationPath?.messages)) {
        setTimeout(() => {
          connectSocket();
        }, 3000);
      }
    }
  }, []);

  useEffect(() => {
    if (locumData?.id && equal(state?.businessId, businessId)) {
      dispatch(
        createConversationAction({
          performRequest,
          locumId: locumData?.id,
          setSelectedTab,
          setSelectedUser,
          businessId: state?.businessId,
        }),
      );
      setShowChat(true);
    }
    if (!chatSocket) connectSocket();
  }, []);

  useEffect(() => {
    return () => {
      chatSocket && chatSocket.close();
    };
  }, [chatSocket]);

  useEffect(() => {
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.unreadCount]: 0,
      },
    });

    if (!selectedTab?.id && businessConversation?.data) {
      const firstConversation = head(businessConversation.data);
      const { attributes } = { ...firstConversation };
      setSelectedTab({
        id: attributes?.id,
        userId: attributes?.locum?.id,
      });
    }
  }, [contactData]);

  useEffect(() => {
    if (businessId) {
      if (businessConversation?.data) {
        const firstConversation = head(businessConversation.data);
        const { attributes } = { ...firstConversation };
        setSelectedTab({
          id: attributes?.id,
          userId: attributes?.locum?.id,
        });
      } else if (!equal(state?.businessId, businessId)) {
        setSelectedTab({});
        setSelectedUser({});
      }
    }
  }, [businessId]);

  useEffect(() => {
    if (chatSocket) {
      chatSocket.onmessage = handleMessageReceived;
    }
    const data = businessConversation?.data?.find(({ attributes }) =>
      equal(attributes?.locum?.id, selectedTab?.userId),
    );
    if (data?.attributes?.locum) {
      setSelectedUser(data?.attributes?.locum);
      if (selectedTab?.id && !data?.attributes?.read) {
        dispatch(
          readConversationAction({
            performRequest,
            id: selectedTab?.id,
            formPath,
            businessId,
          }),
        );
      }
    }
  }, [selectedTab?.userId]);

  const handleBackClick = () => {
    setShowChat(false);
  };

  return {
    initialLoader,
    businessConversation,
    showChat,
    selectedTab,
    chatSocket,
    messagesData,
    selectedUser,
    businessId,
    locumData,
    state,
    setShowChat,
    setMessagesData,
    setSelectedTab,
    handleBackClick,
  };
};

export default MessagesContainer;
