import React from "react";
import LFBox from "../../shared/LFBox";
import LFForm from "../../shared/LFForm";
import LFFormFields from "../../shared/LFFormFields";
import LFButton from "../../shared/LFButton";
import FormContainer from "../../container/form.container";
import {
  defaultValues,
  forgotPasswordAttribute,
  forgotPasswordFormPath,
} from "../../description/forgotPassword.description";
import ForgotPasswordContainer from "../../container/forgotPassword.container";
import LFGrid from "../../shared/LFGrid";

const ForgotPassword = () => {
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: forgotPasswordAttribute,
      defaultValues: defaultValues,
      formPath: forgotPasswordFormPath,
    });

  const { loadingStatus, handleForgotPasswordSubmit } = ForgotPasswordContainer(
    {
      formData,
      formError,
      formPath: forgotPasswordFormPath,
      validate,
    },
  );

  return (
    <LFBox width="100%">
      <LFForm>
        <LFFormFields
          attributes={forgotPasswordAttribute}
          formData={formData}
          formError={formError}
          handleChange={handleChange}
        />
        <LFGrid item xs={12}>
          <LFBox textAlign="center" width="100%">
            <LFButton
              type={"submit"}
              onClick={handleForgotPasswordSubmit}
              isLoading={loadingStatus}
              variant={"outlined"}
              disabled={isFormError}
              sx={{ minWidth: 280, mt: { sm: 10, xs: 5 }, mx: "auto" }}
            >
              NEXT
            </LFButton>
          </LFBox>
        </LFGrid>
      </LFForm>
    </LFBox>
  );
};

export default ForgotPassword;
