import React, { useState } from "react";

import LFBox from "../../shared/LFBox";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import RedeemSignInForm from "./RedeemSignInForm";
import { useSelector } from "react-redux";
import RedeemVoucher from "./RedeemVoucher";
import redeemCelebration from "../../assets/png/redeem-celebration.png";
import { ReactComponent as GiftSvg } from "../../assets/svg/gift.svg";
import LFTypography from "../../shared/LFTypograpy";
import redeemBackground from "../../assets/png/redeem-background.png";
import redeemCelebrationBottom from "../../assets/png/redeem-celebration-bottom.png";
import redeemCelebrationBottomBig from "../../assets/png/redeem-celebration-bottom-big.png";
import MobileCopyright from "../reusable/MobileCopyright";
import { useNavigate } from "react-router-dom";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { equal, ternary } from "../../utils/javascript";
import ApiContainer from "../../container/api.container";
import { useMediaQuery } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundImage: `url('${redeemBackground}')`,
    justifyContent: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    minHeight: 670,
    "&.MuiStack-root": {
      flex: 1,
      overflow: "auto",
      height: "100%",
    },
  },
  formBox: {
    background: theme.palette.common.white,
    maxWidth: 444.44,
    margin: "auto",
    borderRadius: 10,
    boxShadow: "0px 0px 17.78px 0px #EE357266",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  giftBox: {
    textAlign: "center",
    marginBottom: "24.89px",
    [theme.breakpoints.down("xts")]: {
      marginBottom: "9px",
      "& svg": {
        width: 20.48,
        height: 20.48,
      },
    },
    "& .MuiTypography-h6": {
      marginTop: 6,
      fontFamily: "'Work Sans', sans-serif",
      fontSize: 17.78,
      lineHeight: "20.85px",
      color: "#7D7878",
      maxWidth: 247,
      textAlign: "center",
      [theme.breakpoints.down("xts")]: {
        fontSize: "16px",
        lineHeight: "18.77px",
        marginTop: 4,
      },
    },
  },
}));

const RedeemLayout = () => {
  const mobileMatches = useMediaQuery("(max-width:480px)");
  const classes = useStyles();
  const navigate = useNavigate();
  const { performRequest } = ApiContainer();
  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);

  const [redeemSuccess, setRedeemSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRedeemAction = async (voucherCode) => {
    if (redeemSuccess) {
      return navigate(locationPath?.myCalendar, {
        state: {
          fromVoucherPage: true,
          message: "A voucher code has been applied to your next shift",
        },
      });
    }
    setIsLoading(true);
    const response = await performRequest({
      endPoint: apiEndpoints?.redeemVoucher,
      method: apiMethods?.post,
      tokenKey: "Authorization",
      data: { code: voucherCode },
      showFailedMessage: true,
    });
    if (equal(response?.statusCode, statusCodes?.OK)) {
      setRedeemSuccess(true);
    }
    setIsLoading(false);
  };

  return (
    <LFStack className={classes.mainContainer}>
      <LFBox className={classes.formBox}>
        <LFBox
          component="img"
          src={redeemCelebration}
          alt="shape-image"
          sx={{
            borderRadius: { xs: 0, xts: "10px" },
            zIndex: -1,
            mb: { xs: -2, xts: "30px" },
          }}
        />
        {!redeemSuccess && (
          <LFBox sx={{ marginTop: { xs: "-35px", xts: "-95px" } }}>
            <LFBox className={classes.giftBox}>
              <GiftSvg />
              <LFTypography variant="h6" component="h5" marginX={"auto"}>
                Redeem your LocumFind Voucher
              </LFTypography>
            </LFBox>
          </LFBox>
        )}
        {isAuthenticated ? (
          <RedeemVoucher
            handleRedeemAction={handleRedeemAction}
            redeemSuccess={redeemSuccess}
            isLoading={isLoading}
          />
        ) : (
          <RedeemSignInForm />
        )}
        <LFBox
          component="img"
          src={ternary(
            mobileMatches,
            redeemCelebrationBottom,
            redeemCelebrationBottomBig,
          )}
          alt="shape-image"
          sx={{
            ...(mobileMatches && { width: "100%" }),
            borderRadius: { xts: "10px", xs: 0 },
            transform: "translateY(7px)",
            marginTop: -3,
            display: redeemSuccess ? "block" : "none",
          }}
        />
      </LFBox>
      <MobileCopyright fontColor="#FFF" />
    </LFStack>
  );
};

export default RedeemLayout;
