import { makeStyles } from "@mui/styles";
import React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LFBox from "./LFBox";
import { ternary } from "../utils/javascript";

const useStyles = makeStyles((theme) => ({
  shiftCalendar: {
    maxWidth: 568,

    "& .react-datepicker": {
      fontFamily: theme.typography.fontFamily,
      width: "100%",
      border: 0,
      borderRadius: 10,
      "& .react-datepicker__navigation-icon::before": {
        borderWidth: "2px 2px 0 0",
      },
      "& .react-datepicker__navigation--previous": {
        [theme.breakpoints.between("xxsm", "md")]: {
          left: "auto",
          right: ({ fromSearchLocum }) => ternary(fromSearchLocum, 230, 216),
          top: ({ fromSearchLocum }) => ternary(fromSearchLocum, 5, -50),
        },
      },
      "& .react-datepicker__navigation--next": {
        [theme.breakpoints.between("xxsm", "md")]: {
          right: 0,
          top: ({ fromSearchLocum }) => ternary(fromSearchLocum, 5, -50),
        },
      },
      "& .react-datepicker__month-container": {
        float: "none",
        "& .react-datepicker__header": {
          border: 0,
          backgroundColor: "transparent",
          "& .react-datepicker__current-month": {
            textTransform: "uppercase",
            color: theme.palette.secondary.dark,
            fontWeight: 500,
            fontSize: 14,
            marginTop: -3,
            [theme.breakpoints.between("xxsm", "md")]: {
              marginTop: ({ fromSearchLocum }) =>
                ternary(fromSearchLocum, 0, -55),
              float: "right",
              marginRight: ({ fromSearchLocum }) =>
                ternary(fromSearchLocum, 0, 24),

              width: "100%",
              maxWidth: ({ fromSearchLocum }) =>
                ternary(fromSearchLocum, "100%", 200),
            },
          },

          "& .react-datepicker__day-names": {
            display: "flex",
            marginTop: 24,
            backgroundColor: theme.palette.gray.main,
            padding: 15,
            borderRadius: "10px 10px 0 0",
            [theme.breakpoints.between("xxsm", "md")]: {
              marginTop: 0,
            },
            "& .react-datepicker__day-name": {
              flex: 1,
              borderBottom: `1px solid ${theme.palette.secondary.dark}`,
              color: theme.palette.secondary.dark,
              margin: 0,
            },
          },
        },
        "& .react-datepicker__month": {
          backgroundColor: theme.palette.gray.main,
          padding: "0 15px",
          height: 325,
          display: "flex",
          flexDirection: "column",
          margin: 0,
          borderRadius: "0 0 10px 10px",
          [theme.breakpoints.down("md")]: {
            height: ({ fromSearchLocum }) =>
              ternary(fromSearchLocum, 199, "42svh"),
          },
          [theme.breakpoints.down("xxsm")]: {
            height: ({ fromSearchLocum }) =>
              ternary(fromSearchLocum, "100%", 225),
          },
          "& .react-datepicker__week": {
            display: "flex",
            height: "100%",
            "& .react-datepicker__day-name, & .react-datepicker__day, & .react-datepicker__time-name":
              {
                flex: 1,
                margin: 0,
                zIndex: 1,
                fontSize: 16,
                lineHeight: "24px",
                fontWeight: 400,
                position: "relative",
                [theme.breakpoints.down("sm")]: {
                  fontSize: 12,
                  height: 40,
                },
                "&:focus": {
                  outline: "none",
                },
                "&:before": {
                  content: "''",
                  position: "absolute",
                  left: 0,
                  width: 24,
                  height: 24,
                  right: 0,
                  borderRadius: "100%",
                  margin: "0 auto",
                  zIndex: -1,
                  [theme.breakpoints.down("sm")]: {
                    width: 25,
                    height: 25,
                  },
                },
                "&.react-datepicker__day--selected": {
                  backgroundColor: "transparent",
                  "&:before": {
                    backgroundColor: theme.palette.primary.main,
                  },
                },
                "&.react-datepicker__day--today": {
                  // "&.react-datepicker__day--selected": {
                  //   color: "#000",
                  // },
                  "&:before": {
                    backgroundColor: theme.palette.secondary.main,
                  },
                },

                "&.react-datepicker__day--keyboard-selected": {
                  backgroundColor: "transparent",
                },
                "&.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected)":
                  {
                    backgroundColor: "transparent",
                    color: theme.palette.primary.main,
                    "&:after": {
                      width: "100%",
                      backgroundColor: "#BDA6FF",
                      borderRadius: 0,
                      content: "''",
                      position: "absolute",
                      left: 0,
                      zIndex: -1,
                      height: 24,
                    },
                  },
                "&.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range":
                  {
                    color: "#000",
                    backgroundColor: "transparent",
                    "&:after": {
                      width: "100%",
                      backgroundColor: "#BDA6FF",
                      borderRadius: 0,
                      content: "''",
                      position: "absolute",
                      left: 0,
                      zIndex: -1,
                      height: 24,
                    },
                    "&.react-datepicker__day--range-start, &.react-datepicker__day--selecting-range-start":
                      {
                        "&:after": {
                          width: "50%",
                          left: "50%",
                          zIndex: -2,
                        },
                        "&.react-datepicker__day--selecting-range-end, &.react-datepicker__day--range-end":
                          {
                            "&:after": {
                              content: "normal",
                            },
                          },
                      },
                    "&.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selected)":
                      {
                        color: theme.palette.white.main,
                        "&:before": {
                          content: "''",
                          position: "absolute",
                          left: 0,
                          width: 24,
                          height: 24,
                          backgroundColor: theme.palette.primary.main,
                          right: 0,
                          borderRadius: "100%",
                          margin: "0 auto",
                          zIndex: -1,
                          [theme.breakpoints.down("sm")]: {
                            width: 25,
                            height: 25,
                          },
                        },
                        "&:after": {
                          width: "50%",
                          right: "50%",
                          zIndex: -2,
                        },
                      },
                    "&.react-datepicker__day--range-end": {
                      color: theme.palette.white.main,
                      "&:before": {
                        content: "''",
                        position: "absolute",
                        left: 0,
                        width: 24,
                        height: 24,
                        backgroundColor: theme.palette.primary.main,
                        right: 0,
                        borderRadius: "100%",
                        margin: "0 auto",
                        zIndex: -1,
                        [theme.breakpoints.down("sm")]: {
                          width: 25,
                          height: 25,
                        },
                      },
                      "&:after": {
                        width: "50%",
                        right: "50%",
                        zIndex: -2,
                      },
                    },
                    "&.react-datepicker__day--selected": {
                      color: "#fff",
                    },
                  },
              },
            "& .react-datepicker__day--outside-month": {
              visibility: "hidden",
            },
          },
        },
      },
    },
    [theme.breakpoints.down("xxsm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      margin: "auto",
    },
  },
}));

const LFDatePicker = ({
  startDate,
  endDate,
  onChange,
  isRangePicker,
  inline = true,
  fromSearchLocum = false,
  ...rest
}) => {
  const isFutureDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date >= currentDate;
  };

  const classes = useStyles({ fromSearchLocum });
  return (
    <LFBox className={classes.shiftCalendar}>
      <DatePicker
        selected={startDate && new Date(startDate)}
        selectsRange={isRangePicker}
        filterDate={isFutureDate}
        startDate={startDate && new Date(startDate)}
        endDate={endDate && new Date(endDate)}
        onChange={onChange}
        inline={inline}
        calendarStartDay={1}
        {...rest}
      />
    </LFBox>
  );
};

export default LFDatePicker;
