import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import LFChip from "../../shared/LFChip";
import { keys, ternary } from "../../utils/javascript";
import { makeStyles } from "@mui/styles";
import { Divider } from "@mui/material";
import LFStack from "../../shared/LFStack";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  skillBox: {
    textAlign: "left",
  },
  subHeading: {
    "&.MuiTypography-root": {
      fontWeight: 400,
      fontSize: 16,
      marginBottom: 12,
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      marginBottom: 12,
    },
  },
  divider: {
    "&.MuiDivider-root": {
      borderColor: theme.palette.gray.dark,
    },
  },
  chip: {
    "&.MuiStack-root": {
      gap: 8,
      flexWrap: "wrap",
      flexDirection: "row",
      "& .MuiChip-root": {
        border: `1px solid ${theme.palette.gray.dark}`,
        fontSize: 12,
        height: "unset",
        maxHeight: "20px",
        "& span": {
          padding: [[2, 8]],
        },
      },
    },
  },
  spacing: {
    "&.MuiTypography-root": {
      marginBottom: 20,
    },
  },
}));

const SkillsDetails = ({
  skillsObject,
  availability,
  extraSpacing = false,
}) => {
  const classes = useStyles();
  return (
    <LFBox className={classes.skillBox}>
      <LFTypography
        variant="h4"
        component="h4"
        color="primary"
        className={classNames(classes.heading, {
          [classes.spacing]: extraSpacing,
        })}
      >
        Skills
      </LFTypography>
      {keys(skillsObject)?.map((skillCategory) => {
        return (
          <LFBox key={skillCategory}>
            <LFTypography
              variant="h7"
              component="h5"
              className={classNames(classes.subHeading, {
                [classes.spacing]: extraSpacing,
              })}
            >
              {skillCategory}
            </LFTypography>
            <LFStack className={classes.chip}>
              {skillsObject[skillCategory]?.map((cur) => (
                <LFChip key={cur} label={cur} selected={true} />
              ))}
            </LFStack>
            <Divider
              sx={{ my: ternary(extraSpacing, 2.5, 1.5) }}
              className={classes.divider}
            />
          </LFBox>
        );
      })}
      <LFTypography
        variant="h4"
        component="h4"
        color="primary"
        className={classNames(classes.heading, {
          [classes.spacing]: extraSpacing,
        })}
      >
        Avaliability
      </LFTypography>
      <LFStack className={classes.chip}>
        {keys(availability)?.map((day) => (
          <LFChip
            key={day}
            label={day.slice(0, 3)}
            selected={availability[day]}
          />
        ))}
      </LFStack>
    </LFBox>
  );
};

export default SkillsDetails;
