import { useDispatch, useSelector } from "react-redux";
import { ZERO } from "../../utils/constant";
import { equal, isArray, keys, ternary } from "../../utils/javascript";
import { SET_FORM_DATA } from "../../redux/constant";
import { availability, date, distance, rate } from "./constants";

const SearchFormContainer = ({ formPath }) => {
  const dispatch = useDispatch();

  const globalAttr = useSelector((state) => state?.globalAttr);
  const allBusinessDetail = globalAttr.businessDetail;

  const serchLocumState = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const allBussinessesLoader = useSelector(
    (state) => state?.app?.loader?.["businessDetailsLoader"],
  );

  const searchBussinessId = serchLocumState?.searchBussinessId;

  const handleChange = ({ e, type }) => {
    switch (type) {
      case "switch": {
        const name = e?.target?.name;
        const newSerchLocumState = { ...serchLocumState };
        let switches = { ...newSerchLocumState?.switches };
        switches[name] = !switches[name];

        if (equal(e?.target?.name, date) && equal(e?.target?.checked, false)) {
          newSerchLocumState.form.end_date = "";
          newSerchLocumState.form.start_date = "";
        }
        if (
          equal(e?.target?.name, distance) &&
          equal(e?.target?.checked, false)
        ) {
          newSerchLocumState.form.min_distance = 0;
          newSerchLocumState.form.max_distance = 300;
        }

        if (equal(e?.target?.name, "all") && equal(e?.target?.checked, true)) {
          const resultObject = {};

          keys(switches)?.forEach((switchKey) => {
            if (equal(switchKey, date) || equal(switchKey, distance)) {
              resultObject[switchKey] = false; // Keep "date" ans "distance" as false
            } else {
              resultObject[switchKey] = true; // Set other properties to true
            }
          });

          switches = resultObject;
        }

        return dispatch({
          type: SET_FORM_DATA,
          payload: {
            [formPath?.parent]: {
              ...newSerchLocumState,
              switches: switches,
            },
          },
        });
      }

      case availability: {
        const id = Number(e?.target?.id);
        let newArray = [...serchLocumState.form[type]];

        if (newArray?.includes(id)) {
          newArray = newArray?.filter((curID) => curID !== id);
        } else {
          newArray.push(id);
        }
        return dispatch({
          type: SET_FORM_DATA,
          payload: {
            [formPath?.parent]: {
              ...serchLocumState,
              form: { ...serchLocumState?.form, availability: newArray },
            },
          },
        });
      }

      case rate: {
        const value = e?.target?.value;
        return dispatch({
          type: SET_FORM_DATA,
          payload: {
            [formPath?.parent]: {
              ...serchLocumState,
              form: {
                ...serchLocumState.form,
                start_rate: value[ZERO],
                end_rate: value[1],
              },
            },
          },
        });
      }
      case distance: {
        const value = e?.target?.value;
        return dispatch({
          type: SET_FORM_DATA,
          payload: {
            [formPath?.parent]: {
              ...serchLocumState,
              form: {
                ...serchLocumState.form,
                min_distance: value[ZERO],
                max_distance: value[1],
              },
            },
          },
        });
      }
      case date: {
        const value = e;

        const shiftDate = ternary(
          isArray(value),
          { start_date: value[ZERO], end_date: value[1] },
          { start_date: value, end_date: null },
        );

        return dispatch({
          type: SET_FORM_DATA,
          payload: {
            [formPath?.parent]: {
              ...serchLocumState,
              form: {
                ...serchLocumState?.form,
                ...shiftDate,
              },
            },
          },
        });
      }
    }
  };

  const handlebusinessChange = (event) => {
    const { value } = event.target;
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...serchLocumState,
          searchBussinessId: value,
        },
      },
    });
  };

  const selectBusinessAttribute = {
    name: "selectBusiness",
    type: "select",
    value: searchBussinessId,
    variant: "outlined",
    options: [
      ...(allBusinessDetail?.map((business) => ({
        value: business?.id,
        label: business?.name,
      })) || []),
    ],
    onChange: handlebusinessChange,
  };

  return {
    serchLocumState,
    handleChange,
    allBussinessesLoader,
    selectBusinessAttribute,
  };
};
export default SearchFormContainer;
