import * as React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { makeStyles } from "@mui/styles";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FormLabel } from "@mui/material";
import LFBox from "./LFBox";
import { ReactComponent as calendarIcon } from "../assets/svg/calendar.svg";
import moment from "moment";
import { ternary } from "../utils/javascript";

const useStyles = makeStyles(() => ({
  datePicker: {
    width: "100%",
    "& label": {
      fontSize: 14,
      marginBottom: 8,
      display: "block",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      "& .MuiFormLabel-root": {
        lineHeight: "11px",
      },
      "& .MuiInputBase-root": {
        borderRadius: 50,
        "& svg": {
          height: 20,
          width: 20,
        },
        "& input": {
          fontSize: 14,
          padding: [[11, 16]],
        },
      },
    },
  },
  dateField: {
    "& .MuiIconButton-root": {
      pointerEvents: "none",
    },
  },
}));

export default function LFDatePickerField({
  dateValue,
  name,
  onChange,
  onError,
  minDate,
  label,
  showCalendar,
  disableIconButton = false,
  showLabel = false,
}) {
  const classes = useStyles();
  return (
    <LFBox className={classes.datePicker}>
      <FormLabel>{label}</FormLabel>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          format="DD/MM/YYYY"
          name={name}
          value={dateValue ? moment(dateValue, "DD/MM/YYYY") : null}
          slots={{
            openPickerIcon: calendarIcon,
          }}
          label={showLabel ? "Select date" : ""}
          onChange={(value) => onChange(value, name)}
          minDate={moment(minDate, "DD/MM/YYYY")}
          onError={(reason) => onError(reason, name)}
          open={showCalendar}
          desktopModeMediaQuery={"@media (min-width: 370px)"}
          className={ternary(disableIconButton, classes.dateField, "")}
          sx={{
            "& .MuiInputLabel-shrink": {
              display: "none",
            },
            "& .MuiInputBase-root": {
              "& .MuiOutlinedInput-notchedOutline": {
                "& legend": {
                  maxWidth: 0,
                  visibility: "hidden",
                },
              },
            },
          }}
        />
      </LocalizationProvider>
    </LFBox>
  );
}
