import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import classNames from "classnames";
import LFButton from "../../shared/LFButton";
import supportLayoutContainer from "../../container/support/supportLayout.container";
import { sectionButtons } from "../../description/support/supportlayout.description";
import LFContainer from "../../shared/LFContainer";
import LFGrid from "../../shared/LFGrid";
import { Divider } from "@mui/material";
import { equal } from "../../utils/javascript";

import LocumSupport from "./Locum/LocumSupport";
import BussinessSupport from "./Business/BussinessSupport";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    "&.MuiStack-root": {
      gap: 24,
      marginBottom: 40,
      [theme.breakpoints.down("xts")]: {
        marginBottom: 24,
      },
      "& .MuiButtonBase-root": {
        padding: [[11, 20]],
        lineHeight: "18px",
        fontSize: 14,
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.light,
        border: 0,
        width: "fit-content",
        minWidth: "unset",
        "&:hover": {
          backgroundColor: theme.palette.secondary.lightGreen,
        },
      },
    },
  },

  selected: {
    "&.MuiButtonBase-root.MuiButton-root": {
      backgroundColor: theme.palette.secondary.lightGreen,
    },
  },
  singleButtonBox: {
    "&.MuiButtonBase-root": {
      padding: 0,
      height: "100%",
      borderRadius: 10,
      border: "2px solid #D9D9D9",
      backgroundColor: theme.palette.gray.main,
      width: "100%",
      "&:hover": {
        [theme.breakpoints.up("xsm")]: {
          borderWidth: "2px",
          backgroundColor: `${theme.palette.primary.main}60`,
          borderColor: "transparent",
          "& .MuiStack-root": {
            "& svg": {
              color: theme.palette.secondary.main,
            },
            "& .MuiTypography-root": {
              color: theme.palette.white.main,
            },
          },
        },
        backgroundColor: theme.palette.gray.main,
        borderColor: "#d9d9d9",
      },
    },
  },
  boxActive: {
    "&.MuiButtonBase-root": {
      borderColor: "transparent",
      borderWidth: "2px",
      borderStyle: "solid",
      backgroundColor: `${theme.palette.primary.main}`,
      "& .MuiStack-root": {
        color: "red",
        "& svg": {
          color: theme.palette.secondary.main,
        },
        "& .MuiTypography-root": {
          "&.MuiTypography-body1": {
            color: theme.palette.white.main,
          },
          "&.MuiTypography-body2": {
            color: theme.palette.white.main,
          },
        },
      },
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
      },
    },
  },
  butttonBoxMain: {
    margin: "0 auto",
    maxWidth: 684,
    [theme.breakpoints.down("xsm")]: {
      marginRight: -20,
      marginLeft: -20,
    },
    "& .MuiGrid-container": {
      [theme.breakpoints.down("xsm")]: {
        "&::-webkit-scrollbar": {
          display: "none",
        },
        flexWrap: "nowrap",
        overflowX: "auto",
        "& .MuiGrid-item": {
          minWidth: 154,
          marginRight: 16,
          "&:last-child": {
            marginRight: 0,
          },
        },
      },
    },
  },
  buttonBoxWrapper: {
    "&.MuiStack-root": {
      alignItems: "start",
      textAlign: "start",
      padding: [[22, 20]],
      gap: 16,
      height: "100%",
      width: "100%",
      [theme.breakpoints.down("xsm")]: {
        gap: 8,
        padding: [[16, 8]],
        // padding: "13px 7px",
        minWidth: "152px",
        minHeight: "118px",
      },
      "& svg": {
        width: "34px",
        height: "34px",
        strokeWidth: "1px",
        color: theme.palette.dark.main,
        transition: "all 0.3s ease-in-out",
        [theme.breakpoints.down("xsm")]: {
          width: "24px",
          height: "24px",
        },
      },
      "& .MuiTypography-root": {
        transition: "all 0.3s ease-in-out",
        textTransform: "capitalize",
        "&.MuiTypography-body1": {
          color: theme.palette.dark.main,
          fontSize: 18,
          fontWeight: 300,
          [theme.breakpoints.down("xsm")]: {
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "20.34px",
          },
        },
        "&.MuiTypography-body2": {
          letterSpacing: "0",
          color: theme.palette.secondary.dark,
          [theme.breakpoints.down("xsm")]: {
            fontSize: 12,
            lineHeight: "13.87px",
            marginTop: 2,
            marginRight: 3,
          },
        },
      },
    },
  },
}));

const SupportLayout = () => {
  const classes = useStyles();

  const {
    typeButtonAttributes,
    selectedType,
    selectedSection,
    handleChangeSection,
    types,
  } = supportLayoutContainer();

  const boxButtons = sectionButtons?.[selectedType];

  return (
    <LFBox
      sx={{
        paddingTop: { xs: "36px", xts: "64px" },
        marginBottom: "30px",
        minHeight: { xts: "120vh" },
      }}
    >
      <LFContainer maxWidth="xxsm" sx={{ padding: { xs: "20px", xxsm: "0" } }}>
        <LFStack alignItems="center" gap={{ xs: 3, xsm: 6 }}>
          <LFTypography
            variant="h4"
            component="h5"
            color="primary"
            fontWeight="400"
            textAlign="center"
            fontSize={{ xs: "20px", xsm: "36px" }}
            lineHeight="1.3056"
          >
            Support Guide
          </LFTypography>
          <LFStack gap={3} alignItems="center">
            <LFTypography
              fontSize={{ xs: "14px", xsm: "20px" }}
              color="secondary.dark"
              fontWeight={{ xs: "400", xsm: "700" }}
              textAlign="center"
              lineHeight="1.286"
            >
              Choose your account type
            </LFTypography>
            <LFStack direction="row" className={classes.buttonGroup}>
              {typeButtonAttributes?.map(
                ({ label, isActive, disabled, ...attr }, index) => (
                  <LFButton
                    {...attr}
                    disabled={disabled}
                    key={index}
                    className={classNames({ [classes.selected]: isActive })}
                  >
                    {label}
                  </LFButton>
                ),
              )}
            </LFStack>
          </LFStack>
        </LFStack>
        <LFBox className={classes.butttonBoxMain}>
          <LFGrid
            container
            spacing={{ xs: 0, xsm: 3 }}
            px={{ xsm: 0, xs: 2.5 }}
          >
            {boxButtons?.map(({ id, icon, title, desc }) => {
              return (
                <LFGrid item key={id} xs={12} xsm={4}>
                  <LFButton
                    variant="outlined"
                    disableRipple
                    className={classNames(classes.singleButtonBox, {
                      [classes.boxActive]: equal(selectedSection, id),
                    })}
                    onClick={() => handleChangeSection(id)}
                  >
                    <LFStack className={classes.buttonBoxWrapper}>
                      <LFBox>{icon}</LFBox>
                      <LFStack>
                        <LFTypography>{title}</LFTypography>
                        <LFTypography variant="body2">{desc}</LFTypography>
                      </LFStack>
                    </LFStack>
                  </LFButton>
                </LFGrid>
              );
            })}
          </LFGrid>
        </LFBox>

        <Divider
          sx={{
            margin: { xs: "24px 0", xsm: "40px 0" },
            borderColor: "primary.main",
          }}
        />
        {equal(selectedType, types?.business) ? (
          <BussinessSupport
            selectedType={selectedType}
            selectedSection={selectedSection}
            key={types?.business}
          />
        ) : (
          <LocumSupport
            selectedType={selectedType}
            selectedSection={selectedSection}
            key={types?.locum}
          />
        )}
      </LFContainer>
    </LFBox>
  );
};

export default SupportLayout;
