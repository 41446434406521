import { AppBar, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import LogoSvg from "../../assets/svg/locumFindLogo.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import { ReactComponent as Hamburger } from "../../assets/svg/hamburger.svg";
import HeaderContainer from "../../container/layout/header.container";
import { navLinks } from "../../description/layout/navBar.description";
import LFBox from "../../shared/LFBox";
import LFButton from "../../shared/LFButton";
import LFContainer from "../../shared/LFContainer";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import NavBar from "./NavBar";
import { useSelector } from "react-redux";
import { LS_KEY_HALF_REGISTER, locationPath } from "../../utils/constant";
import { displayCount, ternary } from "../../utils/javascript";
import { getLocalStorageItem } from "../../utils/localStorage";
import { notificationsFormPath } from "../../description/notifications/notifications.description";
import { messagesFormPath } from "../../description/messages/messages.description";
import { ReactComponent as LogoutIcon } from "../../assets/svg/logoutIcon.svg";

const useStyles = makeStyles((theme) => ({
  mainMenu: {
    position: "absolute",
    left: 0,
    right: 0,
    top: "100%",
    height: "calc(100vh - 40px)",
    boxSizing: "border-box",
    padding: "20px 24px",
    display: "none",
    overflowY: "auto",
    zIndex: 2,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("xxsm")]: {
      display: "none",
    },
    "& a": {
      color: theme.palette.white.main,
      textDecoration: "none",
      fontSize: 24,
      padding: 10,
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: 10,
      },
    },
  },
  show: {
    display: "block",
  },
  loginNavBar: {
    "& a": {
      borderBottom: "1px solid #000",
      padding: "15px 0",
      "& svg": {
        [theme.breakpoints.down("xxsm")]: {
          width: 24,
          height: 24,
        },
      },
    },
  },
  headerBtn: {
    "& a": {
      color: theme.palette.white.main,
    },
    "& .MuiButton-containedWhite": {
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  newMessageCounter: {
    "&.MuiTypography-root": {
      fontSize: 10,
      fontWeight: 500,
      padding: [[2, 8]],
      lineHeight: "12px",
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.dark.main,
      borderRadius: 50,
      textTransform: "uppercase",
      marginLeft: "auto",
    },
  },
  logoImage: {
    "& img": {
      height: "42px",
      width: "auto",
      [theme.breakpoints.down("md")]: {
        height: "36px",
      },
      [theme.breakpoints.down("xxsm")]: {
        height: "20px",
      },
    },
  },
}));

const Header = () => {
  const location = useLocation();
  const {
    NavButtons,
    MobileNavLink,
    handleToggleMenu,
    menuToggle,
    handleSignOutClick,
  } = HeaderContainer();
  const classes = useStyles();

  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);
  const showNavBar =
    isAuthenticated &&
    !location?.state?.registerBusiness &&
    !getLocalStorageItem(LS_KEY_HALF_REGISTER) &&
    !location.pathname?.includes(locationPath?.resetPassword);
  const logoRedirect = ternary(
    showNavBar,
    locationPath?.myCalendar,
    locationPath?.landing,
  );

  const unreadNotificationsCount = useSelector(
    (state) => state?.app?.data?.[notificationsFormPath?.newcounts],
  );
  const unreadConversationCount = useSelector(
    (state) => state.app?.data?.[messagesFormPath?.unreadCount],
  );

  return (
    <>
      <AppBar
        elevation={0}
        sx={{ borderBottom: "1px solid black" }}
        position="sticky"
        top="0"
      >
        <Toolbar disableGutters sx={{ minHeight: { xxsm: 64, xs: 40 } }}>
          <LFContainer>
            <LFStack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <LFBox
                sx={{
                  maxWidth: { md: 230, xs: 150 },
                  "& a": { display: "block", "& img": { display: "block" } },
                }}
              >
                <Link to={logoRedirect} className={classes.logoImage}>
                  <img src={LogoSvg} alt="Logo" />
                </Link>
              </LFBox>
              <LFBox
                display={{ xxsm: "none", xs: "flex" }}
                onClick={handleToggleMenu}
                color="white"
              >
                {!menuToggle ? (
                  <Hamburger />
                ) : (
                  <Close style={{ marginRight: "-10px" }} />
                )}
              </LFBox>
              <LFStack
                spacing={2}
                direction="row"
                display={{ xxsm: "block", xs: "none" }}
                className={classes.headerBtn}
              >
                {showNavBar ? (
                  <NavBar />
                ) : (
                  NavButtons.map(({ label, ...attr }, index) => (
                    <LFButton {...attr} key={index}>
                      {label}
                    </LFButton>
                  ))
                )}
              </LFStack>
              <LFBox
                className={classNames(classes.mainMenu, {
                  [classes.show]: menuToggle,
                  [classes.loginNavBar]: showNavBar,
                })}
              >
                {showNavBar ? (
                  <>
                    {navLinks?.map(
                      ({ link, icon, title, badgeCount, dropdown }) => (
                        <Link to={link} key={link} onClick={handleToggleMenu}>
                          {icon}
                          <LFTypography variant="h5" component="h5">
                            {title}
                          </LFTypography>
                          {badgeCount &&
                            (dropdown
                              ? !!unreadNotificationsCount && (
                                  <LFTypography
                                    variant="span"
                                    className={classes.newMessageCounter}
                                  >
                                    {`${displayCount(
                                      unreadNotificationsCount,
                                    )} NEW`}
                                  </LFTypography>
                                )
                              : !!unreadConversationCount && (
                                  <LFTypography
                                    variant="span"
                                    className={classes.newMessageCounter}
                                  >
                                    {`${displayCount(
                                      unreadConversationCount,
                                    )} NEW`}
                                  </LFTypography>
                                ))}
                        </Link>
                      ),
                    )}
                    <Link onClick={handleSignOutClick}>
                      <LogoutIcon />
                      Sign out
                    </Link>
                  </>
                ) : (
                  MobileNavLink.map((data, i) => {
                    return (
                      <Link to={data.link} key={i} onClick={handleToggleMenu}>
                        {data.label}
                      </Link>
                    );
                  })
                )}
              </LFBox>
            </LFStack>
          </LFContainer>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
