import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getNotificationsAction from "../../redux/action/getNotificationsAction";
import ApiContainer from "../api.container";
import { mainLayoutId } from "../../utils/constant";
import allNotificationsSeen from "../../redux/action/allNotificationsSeen";

const NotificationsContainer = ({ formPath }) => {
  const { performRequest } = ApiContainer();
  const dispatch = useDispatch();

  const notifcationsData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );
  const notificationsLoading = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const paginationsLoading = useSelector(
    (state) => state?.app?.loader?.[formPath?.paginationLoader],
  );
  const maxPages = useSelector(
    (state) => state?.app?.data?.[formPath?.maxPages],
  );
  const currentPage = useSelector(
    (state) => state?.app?.data?.[formPath?.currentPage],
  );

  useEffect(() => {
    dispatch(allNotificationsSeen(performRequest, formPath));
  }, [notifcationsData]);

  const scrollableDiv = document.getElementById(mainLayoutId);
  const handelInfiniteScroll = async () => {
    try {
      if (
        scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
        scrollableDiv.scrollHeight
      ) {
        if (currentPage < maxPages)
          dispatch(
            getNotificationsAction({
              formPath,
              performRequest,
              pageNo: currentPage + 1,
            }),
          );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    scrollableDiv?.addEventListener("scroll", handelInfiniteScroll);
    return () =>
      scrollableDiv?.removeEventListener("scroll", handelInfiniteScroll);
  }, [currentPage]);

  return {
    notifcationsData,
    notificationsLoading,
    paginationsLoading,
  };
};

export default NotificationsContainer;
