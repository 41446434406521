import { locationPath } from "../../utils/constant";
import { ReactComponent as Profile } from "../../assets/svg/profile.svg";
import { ReactComponent as Businesses } from "../../assets/svg/businesses.svg";
import { ReactComponent as Payment } from "../../assets/svg/payment.svg";
import { ReactComponent as Settings } from "../../assets/svg/settings.svg";

export const sideBarLinks = [
  {
    label: "Profile",
    icon: <Profile />,
    link: locationPath?.profile,
  },
  {
    label: "Businesses",
    icon: <Businesses />,
    link: locationPath?.businessDetail,
  },
  {
    label: "Payments",
    icon: <Payment />,
    link: locationPath?.accountPayment,
  },
  {
    label: "Settings",
    icon: <Settings />,
    link: locationPath?.settings,
  },
];

export const formPath = {
  parent: "accountStatus",
};
