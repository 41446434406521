import { useEffect, useState } from "react";
import ApiContainer from "../api.container";
import { apiEndpoints, apiMethods, locationPath } from "../../utils/constant";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ternary } from "../../utils/javascript";

const LocumDetailsContainer = ({ locumId }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const businessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const { performRequest } = ApiContainer();
  const [locumData, setLocumData] = useState({
    isLoading: false,
    data: {},
  });

  useEffect(() => {
    const getLocumDetails = async () => {
      setLocumData({ isLoading: true, data: {} });
      const response = await performRequest({
        endPoint: `${apiEndpoints?.viewLocum}/${locumId}`,
        method: apiMethods?.get,
        tokenKey: "Authorization",
        showFailedMessage: true,
      });

      const resData = response?.data?.locum;
      setLocumData({ isLoading: false, data: resData });
      return;
    };
    getLocumDetails();
  }, []);

  const handleBackButtonClick = () => {
    return navigate(ternary(state?.locumId, locationPath?.messages, -1), {
      ...(state?.locumId && {
        state: {
          locumData: { id: state?.locumId },
          businessId: state?.businessId,
        },
      }),
    });
  };

  return { locumData, businessId, handleBackButtonClick };
};

export default LocumDetailsContainer;
