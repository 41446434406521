import { useMediaQuery } from "@mui/material";
import { copyrightText } from "../../utils/javascript";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";

const MobileCopyright = ({ backgroundColor, fontColor }) => {
  const mobileMatches = useMediaQuery("(max-width: 767px)");
  return (
    <>
      {mobileMatches && (
        <LFStack alignItems="center" sx={{ backgroundColor }}>
          <LFTypography color={fontColor} fontSize={14} margin={"22px auto"}>
            {copyrightText()}
          </LFTypography>
        </LFStack>
      )}
    </>
  );
};

export default MobileCopyright;
