import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiContainer from "../api.container";
import addShiftAction from "../../redux/action/addShiftAction";
import { useLocation, useNavigate } from "react-router-dom";
import { LS_KEY_NEWSHIFT_DATA, locationPath } from "../../utils/constant";
import { setLocalStorageItem } from "../../utils/localStorage";
import setShiftFromTemplateAction from "../../redux/action/setShiftFromTemplateAction";
import { SET_APP_DATA } from "../../redux/constant";
import { getWeekDayDateAndMonth } from "../../utils/moment";

import { shiftPostValidate } from "../../utils/dateOvertimeValidation";

const AddShiftDetailsContainer = ({ formPath }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const globalAttr = useSelector((state) => state?.globalAttr);

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const newShiftForm = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const newShiftData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );

  const [showConfimPage, setShowConfirmPage] = useState(false);

  const templateName = newShiftData?.templateName;
  const showAlert = newShiftData?.showAlert;
  const roleId = newShiftForm?.role_id ?? 1;
  const businessId = newShiftForm?.business_id;
  const templateId = newShiftForm?.template_id;

  const { performRequest } = ApiContainer();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => closeAlert(), 5000);
    }
    if (templateId) {
      dispatch(
        setShiftFromTemplateAction({ performRequest, templateId, formPath }),
      );
    }
    return () => {
      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.parent]: {
            templateName: templateName,
            showAlert: false,
          },
        },
      });
    };
  }, [templateId]);

  const closeAlert = () => {
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: {
          ...newShiftData,
          showAlert: false,
        },
      },
    });
  };

  const handleCancelClick = () => {
    setShowConfirmPage(false);
  };
  const handleBackButtonClick = () => {
    navigate(-1, { replace: true });
  };

  const handlePostClick = (_, overlapValidate) => {
    if (!shiftPostValidate(newShiftForm, roleId, businessId, globalAttr))
      return;
    const redirect = (shiftId) =>
      navigate(locationPath?.addShiftSuccess, {
        state: {
          showSuccess: true,
          shiftData: { ...newShiftForm, shiftId: shiftId },
          templateNumber: state?.templateNumber,
        },
      });

    dispatch(
      addShiftAction({
        performRequest,
        formPath,
        redirect,
        setShowConfirmPage,
        overlapValidate,
      }),
    );
  };

  const handleSaveTemplateClick = () => {
    if (!shiftPostValidate(newShiftForm, roleId, businessId, globalAttr))
      return;
    setLocalStorageItem(
      LS_KEY_NEWSHIFT_DATA,
      JSON.stringify({
        ...newShiftForm,
      }),
    );
    navigate(locationPath?.addTemplate, {
      state: {
        shiftData: { ...newShiftForm },
        templateNumber: state?.templateNumber,
      },
    });
  };

  const shiftDateObj = {
    startDate: newShiftForm?.start_date,
    endDate: newShiftForm?.end_date,
  };

  const shiftDetailStr =
    shiftDateObj?.startDate &&
    `${getWeekDayDateAndMonth(shiftDateObj?.startDate)}`;

  return {
    loadingStatus,
    roleId,
    businessId,
    newShiftForm,
    shiftDateObj,
    templateName,
    templateId,
    showAlert,
    shiftDetailStr,
    showConfimPage,
    handleCancelClick,
    handleBackButtonClick,
    closeAlert,
    handlePostClick,
    handleSaveTemplateClick,
  };
};

export default AddShiftDetailsContainer;
