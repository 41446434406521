import { useEffect } from "react";
import ApiContainer from "../api.container";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { SET_APP_DATA } from "../../redux/constant";
import { equal } from "../../utils/javascript";
import { useNavigate } from "react-router-dom";

const ProfileContainer = ({ formPath }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initailLoadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.initial],
  );
  const userData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent]?.attributes,
  );

  const { performRequest } = ApiContainer();

  useEffect(() => {
    const loadUserProfile = async () => {
      const response = await performRequest({
        endPoint: apiEndpoints?.userProfile,
        method: apiMethods?.get,
        tokenKey: "Authorization",
        showFailedMessage: true,
        needLoader: true,
        parent: formPath?.initial,
      });

      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.parent]: response?.data?.user },
      });
    };
    loadUserProfile();
  }, []);

  const handleProfileEditSubmit = async (formData) => {
    const payload = {
      user: {
        ...formData,
      },
    };
    const response = await performRequest({
      endPoint: apiEndpoints?.editProfile,
      method: apiMethods?.put,
      tokenKey: "Authorization",
      data: { ...payload },
      showFailedMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });

    if (equal(response?.statusCode, statusCodes?.OK)) {
      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.parent]: response?.data?.user },
      });
      return navigate(locationPath?.changesSuccess, {
        state: { redirect: locationPath?.profile },
      });
    }
  };

  return { userData, initailLoadingStatus, handleProfileEditSubmit };
};

export default ProfileContainer;
