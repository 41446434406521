import {
  SET_GLOBAL_ROLES,
  SET_BUSINESS_DETAILS,
  SET_SELECTED_BUSINESS_ID,
  CHANGE_SKILLS_LOADING,
  SET_SKILLS_DATA,
  SET_SOCKET_INSTANCE,
  SET_BUSINESS_TYPE,
  SET_DEFAULT_BUSINESS,
  SET_PUBLIC_HOLIDAYS,
} from "../constant";

const initialState = {
  roles: [],
  businessDetail: [],
  selectedBusinessId: null,
  allRoleAndSkills: {},
  skillsLoading: false,
  socketInstance: null,
  businessType: null,
  defaultBusinesses: [],
  publicHolidays: [],
};

const GlobalAttr = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_GLOBAL_ROLES:
      return {
        ...state,
        roles: payload,
      };

    case SET_BUSINESS_DETAILS:
      return {
        ...state,
        businessDetail: payload,
      };

    case SET_SELECTED_BUSINESS_ID:
      return {
        ...state,
        selectedBusinessId: payload,
      };

    case CHANGE_SKILLS_LOADING: {
      return {
        ...state,
        skillsLoading: payload,
      };
    }

    case SET_SKILLS_DATA: {
      return {
        ...state,
        allRoleAndSkills: payload,
      };
    }

    case SET_SOCKET_INSTANCE: {
      return {
        ...state,
        socketInstance: payload,
      };
    }

    case SET_BUSINESS_TYPE: {
      return {
        ...state,
        businessType: payload,
      };
    }

    case SET_DEFAULT_BUSINESS: {
      return {
        ...state,
        defaultBusinesses: payload,
      };
    }
    case SET_PUBLIC_HOLIDAYS: {
      return {
        ...state,
        publicHolidays: payload,
      };
    }

    default:
      return state;
  }
};

export default GlobalAttr;
