import LFBox from "../../shared/LFBox";
import userImage from "../../assets/png/user.jpeg";
import LFButton from "../../shared/LFButton";
import { Link } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import SkillsDetails from "./SkillsDetails";
import LocumOverview from "./LocumOverview";
import { Divider } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import { useMediaQuery } from "@mui/material";
import { AccordionDetails, AccordionSummary } from "@mui/material";
import { DAYS_VALUES } from "../../description/searchLocum/searchLocum.description";
import { isArray, keys, length, ternary, values } from "../../utils/javascript";
import classNames from "classnames";
import { DISPENSESOFTWARE, EXPERIENCE, SERVICES } from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  locumCard: {
    backgroundColor: (props) =>
      ternary(
        props?.bgColor,
        theme.palette.gray.main,
        theme.palette.white.main,
      ),
    flexDirection: "row",
    marginBottom: 20,
    borderRadius: 10,
    padding: 24,
    [theme.breakpoints.down("md")]: {
      padding: 16,
    },
    "& .MuiPaper-root": {
      display: "flex",
      boxShadow: "none",
      background: "transparent",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
      "& .MuiAccordionSummary-content": {
        margin: [[0, 48, 0, 0]],
        [theme.breakpoints.down("md")]: {
          margin: [[0, 20, 0, 0]],
        },
        [theme.breakpoints.down("xxsm")]: {
          margin: 0,
        },
      },
    },
  },
  applicantCard: {
    "& .MuiPaper-root": {
      [theme.breakpoints.between("xsm", "md")]: {
        display: "flex",
        gap: 24,
      },
      "& .MuiAccordionSummary-root": {
        flex: "0 0 35%",
        "& .MuiAccordionSummary-content": {
          [theme.breakpoints.down("xsm")]: {
            justifyContent: "center",
          },
        },
      },
    },
  },
  searchButtons: {
    marginTop: 9,
    "& a": {
      marginTop: 16,
      minWidth: 224,
      display: "block",
      [theme.breakpoints.down("md")]: {
        maxWidth: 224,
        margin: [[16, "auto", 0]],
      },
      [theme.breakpoints.down("xxsm")]: {
        maxWidth: "unset",
      },
      "& button": {
        width: "100%",
        [theme.breakpoints.between("xsm", "md")]: {
          width: (forAppliacantsPage) =>
            ternary(forAppliacantsPage, "100%", "80%"),
        },
        fontSize: 14,
        padding: [[11, 20]],
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    "& hr": {
      display: "none",
      [theme.breakpoints.down("xsm")]: {
        display: "block",
        borderColor: theme.palette.gray.dark,
      },
    },
  },
  cardSkill: ({ forAppliacantsPage }) => ({
    "&.MuiAccordionDetails-root": {
      padding: 0,
      [theme.breakpoints.down(ternary(forAppliacantsPage, "xsm", "md"))]: {
        borderTop: `1px solid ${theme.palette.gray.dark}`,
        paddingTop: 12,
        marginTop: 12,
      },
    },
  }),
}));

const LocumCard = ({
  locumData,
  locumCardAttributes,
  businessId,
  bgColor,
  withoutAccordian,
  forAppliacantsPage = false,
}) => {
  const mobile = useMediaQuery("(max-width: 991px)");
  const classes = useStyles({ bgColor, forAppliacantsPage });
  const {
    id,
    role_name,
    first_name,
    surname,
    hourly_rate,
    avatar_url,
    availability,
    skills,
  } = locumData;

  const { buttons } = locumCardAttributes;

  const skillsObject = createSkillFormArray(skills);
  const availabilityObject = createAvailabilityFormArray(availability);

  const ButtonsStack = ({ sx }) => (
    <LFStack className={classes.searchButtons} sx={sx}>
      <Divider />
      {buttons.map((btn) => {
        const { label, link, variant, linkState, onClick } = btn;
        return (
          <Link
            style={{ textDecoration: "none" }}
            key={label}
            to={ternary(link, link?.replace(":id", id), "")}
            state={{ locumData: locumData, businessId, ...linkState }}
            onClick={onClick}
          >
            <LFButton variant={variant && "contained"}>{label}</LFButton>
          </Link>
        );
      })}
    </LFStack>
  );

  const accordianWithExpanded = (
    <Accordion expanded={true} key={"withExp"}>
      <AccordionSummary aria-controls="panel-content" sx={{ padding: 0 }}>
        <LFBox>
          <LocumOverview
            locum_name={`${first_name} ${surname}`}
            hourly_rate={hourly_rate ? hourly_rate : 0}
            avatar_url={avatar_url}
            role_name={role_name}
            userImage={userImage}
            mobileAccordion={true}
            forAppliacantsPage={forAppliacantsPage}
          />

          <ButtonsStack
            sx={{
              display: {
                xs: "none",
                ...ternary(
                  forAppliacantsPage,
                  { xsm: "block" },
                  { md: "block" },
                ),
              },
            }}
          />
        </LFBox>
      </AccordionSummary>
      <AccordionDetails className={classes.cardSkill}>
        <SkillsDetails
          availability={availabilityObject}
          skillsObject={skillsObject}
        />

        <ButtonsStack
          sx={{
            display: {
              xs: "block",
              ...ternary(forAppliacantsPage, { xsm: "none" }, { md: "none" }),
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  );

  const accordianWithoutExpanded = (
    <Accordion key={"withOutExp"}>
      <AccordionSummary aria-controls="panel-content" sx={{ padding: 0 }}>
        <LFBox>
          <LocumOverview
            locum_name={`${first_name} ${surname}`}
            hourly_rate={hourly_rate ? hourly_rate : 0}
            avatar_url={avatar_url}
            role_name={role_name}
            userImage={userImage}
            mobileAccordion={true}
            forAppliacantsPage={forAppliacantsPage}
          />

          <ButtonsStack
            sx={{
              display: {
                xs: "none",
                ...ternary(
                  forAppliacantsPage,
                  { xsm: "block" },
                  { md: "block" },
                ),
              },
            }}
          />
        </LFBox>
      </AccordionSummary>
      <AccordionDetails className={classes.cardSkill}>
        <SkillsDetails
          availability={availabilityObject}
          skillsObject={skillsObject}
        />

        <ButtonsStack
          sx={{
            display: {
              xs: "block",
              ...ternary(forAppliacantsPage, { xsm: "none" }, { md: "none" }),
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
  return (
    <LFBox
      className={classNames(classes.locumCard, {
        [classes.applicantCard]: forAppliacantsPage,
      })}
    >
      {!mobile || withoutAccordian
        ? accordianWithExpanded
        : accordianWithoutExpanded}
    </LFBox>
  );
};

export default LocumCard;

const createSkillFormArray = (dataArray) => {
  const groupedData = {};
  if (!isArray(dataArray)) {
    if (length(keys(dataArray))) {
      dataArray = [].concat(...values(dataArray));
    } else {
      return groupedData;
    }
  }

  dataArray?.forEach((item) => {
    const { type } = item;

    if (!groupedData[type]) {
      groupedData[type] = [];
    }

    groupedData[type].push(item.name);
  });
  const desiredOrder = [DISPENSESOFTWARE, SERVICES, EXPERIENCE];
  const orderedGroupedData = {};
  desiredOrder.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(groupedData, key)) {
      orderedGroupedData[key] = groupedData[key];
    }
  });

  return orderedGroupedData;
};

const createAvailabilityFormArray = (availabilityArray) => {
  let finalObj = {};
  keys(DAYS_VALUES)?.forEach((day) => {
    if (availabilityArray?.includes(DAYS_VALUES[day])) {
      return (finalObj[day] = true);
    }
    finalObj[day] = false;
  });
  return finalObj;
};
