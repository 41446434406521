export const ProfileFormPath = {
  parent: "profile",
  initial: "load-profile",
};

export const profileAttributes = [
  {
    name: "first_name",
    label: "First Name",
    type: "text",
    isRequired: true,
    error: ["First Name is required"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "surname",
    label: "Surname",
    type: "text",
    isRequired: true,
    error: ["Surname is required"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "email",
    label: "Email Address",
    type: "text",
    pattern: "email",
    isRequired: true,
    error: ["Email is required", "Please enter a valid email"],
    fullWidth: true,
    xs: 12,
    readOnlyField: true,
  },
  // {
  //   name: "telephone",
  //   label: "Telephone",
  //   type: "telephone",
  //   pattern: "telephone",
  //   error: [
  //     "Please enter a valid 10-digit phone number with an acceptable area code (02, 03, 04, 07, or 08)",
  //   ],
  //   fullWidth: true,
  //   xs: 12,
  //   autoComplete: "off",
  // },
];
