export const registerBusinessAttribute = [
  {
    name: "type",
    label: "Business Type",
    type: "select",
    isRequired: true,
    pattern: "notEmptyDropdown",
    options: [
      { label: "Pharmacy", value: "pharmacy" },
      { label: "Optometrist", value: "optometrist" },
    ],
    error: ["Select a business type"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "name",
    label: "Business Name",
    type: "searchBusiness",
    isRequired: true,
    error: ["Business name is required"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "telephone",
    label: "Telephone",
    type: "telephone",
    isRequired: true,
    pattern: "telephone",
    error: [
      "Telephone is required",
      "Please enter a valid 10-digit phone number with an acceptable area code (02, 03, 04, 07, or 08)",
    ],
    fullWidth: true,
    xs: 12,
    autoComplete: "off",
  },
  {
    name: "address",
    label: "Address",
    type: "address",
    isRequired: true,
    error: ["Address is required"],
    fullWidth: true,
    xs: 12,
  },
];

export const registerBusinessFormPath = {
  parent: "registerBusiness",
};

export const defaultValues = {
  type: "",
  name: "",
  telephone: "",
  address: "",
};
