import React from "react";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { equal, ternary } from "../utils/javascript";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LFStyled } from "./LFStyled";
import theme from "../themes/theme";

const StyledFormControl = LFStyled(FormControl)(() => ({
  "& .MuiInputLabel-outlined": {
    // marginLeft: -14,
  },
  "& .MuiSelect-select": {
    "&[aria-expanded='true']": {
      "& ~ svg": {
        rotate: "-180deg",
      },
    },
  },
  "& .MuiOutlinedInput-root": {
    marginTop: 12,
    "& .MuiSelect-outlined": {
      color: theme.palette.primary.main,
      padding: "8.5px 30px 8.5px 20px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 30,
      padding: "0 20px",
    },
  },
  "& .deleteTemplateBtn": {
    display: "none",
  },
  "& .MuiSvgIcon-root": {
    transition: "all 0.5s",
    position: "absolute",
    right: 10,
    pointerEvents: "none",
    color: theme.palette.primary.main,
  },
}));

const LFDropDown = ({
  label,
  value,
  onChange,
  options,
  preSelected = "",
  name,
  placeholder,
  error,
  sx,
  variant,
  timePeriod,
  PaperMaxHeight,
  labelProps,
  selectSx,
  shrink = false,
  ...rest
}) => {
  const defaultValue = value ?? preSelected;

  return (
    <StyledFormControl
      variant={ternary(variant, variant, "standard")}
      sx={{ minWidth: 120, ...sx }}
      error={ternary(error, true, false)}
      fullWidth
    >
      {label && (
        <>
          {shrink ? (
            <InputLabel sx={labelProps} shrink={shrink}>
              {label}
            </InputLabel>
          ) : (
            <InputLabel sx={labelProps}>{label}</InputLabel>
          )}
        </>
      )}

      <Select
        value={defaultValue}
        onChange={onChange}
        name={name}
        IconComponent={() =>
          ternary(timePeriod, null, <KeyboardArrowDownIcon />)
        }
        sx={selectSx}
        {...rest}
        MenuProps={{
          PaperProps: {
            sx: {
              borderBottom: `1px solid ${theme.palette.dark.main}`,
              boxShadow: "none",
              borderRadius: 0,
              maxHeight: PaperMaxHeight,
              "&::-webkit-scrollbar": {
                width: 3,
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#8a8a8a",
                borderRadius: "5px",
                "&:hover": {
                  background: "#8a8a8a",
                },
              },
              "& ul": {
                padding: "7px 0",
                "& li": {
                  padding: "0px 4px",
                  transition: "all 0.3s",

                  "&:hover,  &.Mui-selected": {
                    background: `${theme.palette.secondary.lightGreen} !important`,
                  },
                },
              },
              ...(equal(variant, "outlined") && {
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderRadius: "20px",
                marginTop: "12px",
                "& ul": {
                  padding: "7px 4px",
                  "& li": {
                    padding: "0px 8px",
                    minHeight: "unset",
                    borderRadius: "50px",
                    transition: "all 0.3s",
                    color: theme.palette.primary.main,
                    "&:hover, &.Mui-selected": {
                      background: `${theme.palette.secondary.lightGreen} !important`,
                    },
                  },
                },
              }),
            },
          },
        }}
      >
        {placeholder && <MenuItem value="">{placeholder}</MenuItem>}
        {options?.map((option, index) => (
          <MenuItem key={`${index}-${option?.value}`} value={option?.value}>
            {option?.label}
            {option?.subLabel && (
              <span
                style={{ color: theme.palette.gray.dark, marginLeft: "5px" }}
              >
                ({option?.subLabel})
              </span>
            )}
            {option?.deleteButton && option?.deleteButton}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default LFDropDown;
