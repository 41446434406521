import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageItem } from "../utils/localStorage";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  LS_KEY_TOKEN,
  ZERO,
  locationPath,
  socketConstants,
} from "../utils/constant";
import getBusinessDetailsAction from "../redux/action/getBusinessDetailsAction";
import ApiContainer from "../container/api.container";
import getRolesAction from "../redux/action/getRolesAction";
import { SET_SOCKET_INSTANCE } from "../redux/constant";
import { notificationsFormPath } from "../description/notifications/notifications.description";
import getNotificationsAction from "../redux/action/getNotificationsAction";
import { equal } from "../utils/javascript";
import addNotificationAction from "../redux/action/addNotificationAction";
import getConversationsAction from "../redux/action/getConversationsAction";
import { messagesFormPath } from "../description/messages/messages.description";
import addLastMessageAction from "../redux/action/addLastMessageAction";
import deleteMessageAction from "../redux/action/deleteMessageAction";
import getPublicHolidays from "../redux/action/getPublicHolidays";

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { performRequest } = ApiContainer();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);
  const socketInstance = useSelector(
    (state) => state?.globalAttr?.socketInstance,
  );
  const businessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const contactData = useSelector(
    (state) => state.app?.data?.[messagesFormPath?.parent],
  );

  const roles = useSelector((state) => state?.globalAttr?.roles);

  const handleMessageRecieved = useCallback((eventData) => {
    const data = JSON.parse(eventData?.data);
    if (equal(data?.message?.type, socketConstants.type.notification)) {
      dispatch(
        addNotificationAction(data?.message, notificationsFormPath, location),
      );
    }
    if (
      (equal(data?.message?.type, socketConstants.type.conversations) ||
        data?.message?.deleted_message) &&
      !equal(window.location.pathname, locationPath?.messages)
    ) {
      if (data?.message?.deleted_message) {
        dispatch(deleteMessageAction(data?.message, messagesFormPath));
      } else {
        dispatch(addLastMessageAction(data?.message, messagesFormPath));
      }
    }
  }, []);

  const connectSocket = useCallback(() => {
    if (!getLocalStorageItem(LS_KEY_AUTHENTICATED)) return;
    try {
      const socket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?token=${getLocalStorageItem(
          LS_KEY_TOKEN,
        )}`,
      );
      if (socket) {
        socket.onopen = () => {
          const subscribeNotification = {
            command: "subscribe",
            identifier: JSON.stringify({
              channel: socketConstants.channel.notification,
            }),
          };
          const subscribeConverstion = {
            command: "subscribe",
            identifier: JSON.stringify({
              channel: socketConstants.channel.conversations,
            }),
          };
          socket.send(JSON.stringify(subscribeConverstion));
          socket.send(JSON.stringify(subscribeNotification));
        };
        socket.onerror = () => {};
        socket.onclose = () => {
          setTimeout(() => {
            connectSocket();
          }, 3000);
        };
        socket.onmessage = handleMessageRecieved;

        dispatch({
          type: SET_SOCKET_INSTANCE,
          payload: socket,
        });
      }
    } catch (error) {
      setTimeout(() => {
        connectSocket();
      }, 3000);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    return () => {
      socketInstance && socketInstance.close();
    };
  }, [socketInstance]);

  useEffect(() => {
    if (
      getLocalStorageItem(LS_KEY_HALF_REGISTER) ||
      (!isAuthenticated && !getLocalStorageItem(LS_KEY_AUTHENTICATED))
    ) {
      navigate(locationPath?.signIn, { replace: true });
    }
  }, [location?.pathname, isAuthenticated]);

  useEffect(() => {
    dispatch(getPublicHolidays({ performRequest }));

    if (equal(roles?.length, ZERO)) {
      dispatch(getRolesAction({ performRequest, needSkills: true }));
    }
    dispatch(getBusinessDetailsAction(performRequest));
    dispatch(
      getNotificationsAction({
        formPath: notificationsFormPath,
        performRequest,
        pageNo: 1,
      }),
    );
    if (!socketInstance) connectSocket();
  }, []);

  useEffect(() => {
    if (businessId && !contactData?.[businessId]) {
      dispatch(
        getConversationsAction({
          formPath: messagesFormPath,
          performRequest,
          businessId,
        }),
      );
    }
  }, [businessId]);

  return <>{isAuthenticated ? children : null}</>;
};

export default ProtectedRoute;
