import React from "react";
import LFButton from "../../shared/LFButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import LFBox from "../../shared/LFBox";

const BackButton = ({ onClick, path, py, ...props }) => {
  return (
    <>
      {path ? (
        <LFBox py={py ? py : 1.75} {...props}>
          <LFButton
            onClick={onClick}
            disableRipple
            disableFocusRipple
            sx={{
              "& svg": { width: 16 },
              p: 0,
              minWidth: "auto",
              "&:hover": { backgroundColor: "transparent" },
              fontSize: { xsm: 16, xs: 12 },
            }}
            startIcon={<ArrowBackIosNewIcon />}
          >
            BACK
          </LFButton>
        </LFBox>
      ) : null}
    </>
  );
};

export default BackButton;
