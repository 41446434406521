import React from "react";
import LFButton from "../../../shared/LFButton";
import LFStack from "../../../shared/LFStack";
const FromActionButtons = ({ handleSaveClick, setCurrentForm, isDisabled }) => {
  const handleCancelClick = () => {
    setCurrentForm(null);
  };
  return (
    <LFStack direction="column" alignItems="center" spacing={1} mt={6}>
      <LFButton
        type={"button"}
        onClick={handleSaveClick}
        variant={"contained"}
        sx={{ padding: "11px 0", maxWidth: 174, width: "100%" }}
        disabled={isDisabled}
      >
        SAVE
      </LFButton>
      <LFButton
        sx={{
          color: "secondary.dark",
          textTransform: "capitalize",
          fontSize: 16,
          fontWeight: 400,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        disableFocusRipple
        disableRipple
        onClick={handleCancelClick}
      >
        Cancel
      </LFButton>
    </LFStack>
  );
};

export default FromActionButtons;
