import * as React from "react";
import Avatar from "@mui/material/Avatar";

const LogoAvatar = ({ sx, name, logoUrl }) => {
  const words = name?.split(" ");

  const initials = words
    ?.slice(0, 2)
    ?.map((word) => word[0]?.toUpperCase())
    ?.join("");
  return (
    <>
      {logoUrl ? (
        <img src={logoUrl} alt="logo" style={{ ...sx, borderRadius: "50%" }} />
      ) : (
        <Avatar
          sx={{
            width: 64,
            height: 64,
            fontSize: 28,
            fontWeight: 500,
            bgcolor: "primary.light",
            color: "primary.main",
            margin: "auto",
            ...sx,
          }}
          alt="Remy Sharp"
          src="/broken-image.jpg"
        >
          {initials}
        </Avatar>
      )}
    </>
  );
};

export default LogoAvatar;
