import { useState } from "react";
import LFTypography from "../../shared/LFTypograpy";
import BackButton from "../reusable/BackButton";
import LFStack from "../../shared/LFStack";
import ViewShiftContainer from "../../container/viewShift/viewShift.container";
import { viewShiftFormPath } from "../../description/viewShift/viewShift.description";
import ShiftDetails from "../shift/ShiftDetails";
import LFGrid from "../../shared/LFGrid";
import LFButton from "../../shared/LFButton";
import { makeStyles } from "@mui/styles";
import { ReactComponent as LinkIcon } from "../../assets/svg/link-icon.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/info-icon.svg";
import ConfirmOvelapShift from "../confirmOverlap";
import SingleShift from "../newShift/SingleShift";
import LFBox from "../../shared/LFBox";
import { shiftStatuses, statusCodes } from "../../utils/constant";
import NotFoundComponent from "../reusable/NotFoundComponent";
import { equal, ternary } from "../../utils/javascript";
import { Tooltip } from "@mui/material";
import PromptTemplet from "../reusable/PromptTemplet";

const useStyles = makeStyles((theme) => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
  InfoTag: {
    color: theme.palette.white.main,
    padding: [[8, 12]],
    borderRadius: 50,
    fontSize: 12,
    backgroundColor: theme.palette.dark.danger,
    alignItems: "center",
    fontWeight: 500,
    marginLeft: "auto",
    "& svg": {
      marginRight: 8,
    },
  },
  linkButton: {
    "&.MuiButtonBase-root": {
      border: `1px solid ${theme.palette.primary.main}`,
      padding: [[8, 12]],
      textTransform: "capitalize",
      fontSize: 12,
      marginLeft: "auto",
      minWidth: "unset",
      "& svg": {
        marginRight: 8,
      },
    },
  },
  actionButton: {
    margin: "0 0 32px",
    maxWidth: 568,
    "& .MuiButtonBase-root": {
      maxWidth: 280,
      width: "100%",
      padding: [[11, 30]],
      lineHeight: 1,
      marginTop: 8,
      [theme.breakpoints.down("xsm")]: {
        maxWidth: "unset",
      },
    },
  },
}));

const ViewShift = () => {
  const classes = useStyles();
  const {
    loadingStatus,
    roleId,
    businessId,
    actionButtonsAttr,
    viewShiftForm,
    applicantsData,
    shiftDateObj,
    shiftDetailStr,
    shiftStatus,
    showConfimPage,
    hideHeading,
    date,
    showCalendar,
    setShowCalendar,
    setHideHading,
    handleCancelClick,
    handleSaveClick,
    handleBackButtonClick,
    handleDateChange,
    saveDateChange,
    handleCancelDateClick,
    shiftId,
    resStatus,
    onChangeDateValue,
    onDateChangeError,
    allRecurringChangePage,
    changeAllRecurrencesButttons,
    shiftDetailsRefreshAction,
  } = ViewShiftContainer({
    formPath: viewShiftFormPath,
  });

  const [linkCoppied, setLinkCoppied] = useState(false);

  if (showConfimPage)
    return (
      <ConfirmOvelapShift
        handleCancelClick={handleCancelClick}
        handleConfirmClick={() => {
          handleSaveClick();
        }}
        shiftDetailStr={shiftDetailStr}
        formPath={viewShiftFormPath}
      />
    );

  return (
    <>
      {equal(resStatus?.code, statusCodes?.FORBIDDEN) ||
      equal(resStatus?.code, statusCodes?.NOT_FOUND) ? (
        <>
          <LFGrid item xsm={12} xs={"auto"} display="flex">
            <BackButton path={true} onClick={handleBackButtonClick} />
          </LFGrid>
          <LFTypography
            variant="h5"
            component="h5"
            color="primary"
            fontWeight="500"
            display={{ xsm: "block", xs: "none" }}
          >
            {"Shift Details"}
          </LFTypography>
          <NotFoundComponent
            message={
              equal(resStatus?.code, statusCodes?.FORBIDDEN)
                ? resStatus?.message
                : "Shift not found"
            }
          />
        </>
      ) : (
        <>
          {equal(viewShiftForm?.type, "recurring") &&
          (allRecurringChangePage?.save || allRecurringChangePage?.cancel) ? (
            <LFBox className={classes.promptWrapper}>
              <PromptTemplet
                mainText={ternary(
                  allRecurringChangePage?.cancel,
                  "Would you like to cancel all other recurring unfilled shifts listed",
                  "Would you like to update all other recurring unfilled shifts listed with the new changes ?",
                )}
                subText={""}
                buttonAttributes={changeAllRecurrencesButttons}
                iconSrc={true}
                bgGray={false}
                alertType={true}
              />
            </LFBox>
          ) : (
            <>
              <LFGrid
                container
                alignItems="center"
                columnSpacing={{ xsm: 3, xs: 1 }}
                justifyContent="end"
              >
                {!hideHeading && (
                  <>
                    <LFGrid item xsm={12} xs={"auto"} display="flex">
                      <BackButton path={true} onClick={handleBackButtonClick} />
                    </LFGrid>

                    <LFGrid
                      item
                      md={4}
                      xsm={6}
                      alignItems="center"
                      xs={"auto"}
                      display="flex"
                      ml={"auto"}
                    >
                      <LFTypography
                        variant="h5"
                        component="h5"
                        color="primary"
                        fontWeight="500"
                        display={{ xsm: "flex", xs: "none" }}
                        flexWrap="wrap"
                      >
                        {"Shift Details"}&nbsp;
                        {viewShiftForm?.id && (
                          <LFTypography
                            variant="span"
                            color="gray.dark"
                            display={{ xsm: "block", xs: "none" }}
                          >
                            {`#${viewShiftForm?.id}`}
                          </LFTypography>
                        )}
                      </LFTypography>
                      {viewShiftForm?.is_urgent && (
                        <LFStack direction="row" className={classes.InfoTag}>
                          <InfoIcon />
                          URGENT
                        </LFStack>
                      )}
                    </LFGrid>

                    <LFGrid item md={8} xsm={6} xs={"auto"} textAlign="end">
                      {viewShiftForm?.id && (
                        <LFButton
                          ml={"auto"}
                          className={classes.linkButton}
                          sx={{
                            ...((equal(shiftStatus, shiftStatuses.paid) ||
                              equal(shiftStatus, shiftStatuses.unpaid)) && {
                              visibility: "hidden",
                            }),
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${process.env.REACT_APP_WEB_URL}/view-shift?shiftId=${shiftId}`,
                            );
                            setLinkCoppied(true);
                            setTimeout(() => setLinkCoppied(false), 3000);
                          }}
                        >
                          <Tooltip title={linkCoppied ? "Link coppied" : ""}>
                            <span
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <LinkIcon />
                              Invite Locum
                            </span>
                          </Tooltip>
                        </LFButton>
                      )}
                    </LFGrid>
                  </>
                )}
              </LFGrid>
              {showCalendar ? (
                <LFBox pt={5}>
                  <SingleShift
                    startDate={date?.dateVal ?? viewShiftForm?.start_date}
                    onChange={handleDateChange}
                    onChangeDateValue={onChangeDateValue}
                    onDateChangeError={onDateChangeError}
                  />
                  <LFGrid container spacing={3} mt={-1}>
                    <LFGrid
                      item
                      md={3}
                      xs={12}
                      sx={{ display: { md: "block", xs: "none" } }}
                    />
                    <LFGrid item md={7} xs={12}>
                      <LFStack
                        alignItems="center"
                        width="100%"
                        className={classes.actionButton}
                      >
                        <LFButton
                          type={"button"}
                          onClick={saveDateChange}
                          variant={"contained"}
                          sx={{ minWidth: 272 }}
                          disabled={date?.error}
                        >
                          SAVE
                        </LFButton>
                        <LFButton
                          sx={{
                            color: "secondary.dark",
                            textTransform: "capitalize",
                            fontSize: 16,
                            fontWeight: 400,
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                          disableFocusRipple
                          disableRipple
                          onClick={handleCancelDateClick}
                        >
                          Cancel
                        </LFButton>
                      </LFStack>
                    </LFGrid>
                  </LFGrid>
                </LFBox>
              ) : (
                <LFStack pb={{ xsm: 5, xs: 1.5 }}>
                  <ShiftDetails
                    formData={viewShiftForm}
                    formPath={viewShiftFormPath}
                    actionButtonsAttr={actionButtonsAttr}
                    businessId={businessId}
                    roleId={roleId}
                    shiftDateObj={shiftDateObj}
                    shiftStatus={shiftStatus}
                    applicantsData={applicantsData}
                    loadingStatus={loadingStatus}
                    setHideHading={setHideHading}
                    setShowCalendar={setShowCalendar}
                    shiftDetailsRefreshAction={shiftDetailsRefreshAction}
                  />
                </LFStack>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default ViewShift;
