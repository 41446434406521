import { makeStyles } from "@mui/styles";
import React from "react";
import LFStack from "../../shared/LFStack";
const useStyles = makeStyles((theme) => ({
  loader: {
    width: 48,
    height: 48,
    borderRadius: "50%",
    display: "inline-block",
    borderTop: `4px solid ${theme.palette.primary.main}`,
    borderRight: "4px solid transparent",
    animation: `$rotation 1s linear infinite`,
    position: "relative",
    "&::after": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      width: 48,
      height: 48,
      borderRadius: "50%",
      borderBottom: `4px solid ${theme.palette.primary.main}`,
      borderLeft: "4px solid transparent",
    },
  },
  "@keyframes rotation": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
}));

const Loader = (props) => {
  const classes = useStyles();
  return (
    <>
      <LFStack
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100%"
        flex={1}
        {...props}
      >
        <div className={classes.loader}></div>
      </LFStack>
    </>
  );
};

export default Loader;
