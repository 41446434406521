import React, { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import classNames from "classnames";
import { locationPath, mainLayoutId } from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    "&.MuiStack-root": {
      flex: 1,
      overflow: "auto",
      height: "100%",
    },
  },
  bgGray: {
    backgroundColor: theme.palette.gray.main,
    [theme.breakpoints.down("xxsm")]: {
      backgroundColor: "transparent",
    },
  },
  grayBackground: {
    backgroundColor: theme.palette.gray.main,
  },
}));
const MainLayout = ({
  footerDark,
  hideHeader = false,
  hideHelpPages = false,
}) => {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(max-width: 767px)");
  const { pathname } = useLocation();
  const [bgColorGray, setBgColorGray] = useState(false);

  return (
    <LFStack height={{ xs: "100vh", xxsm: "100svh", md: "100vh" }}>
      {!hideHeader && <Header />}
      <LFStack
        className={classNames(classes.mainContainer, {
          [classes.bgGray]: pathname?.includes(locationPath?.searchLocum),
          [classes.grayBackground]: bgColorGray,
        })}
        id={mainLayoutId}
      >
        <Outlet context={{ setBgColorGray }} />
      </LFStack>
      {!mobileMatches && (
        <Footer footerDark={footerDark} hideHelpPages={hideHelpPages} />
      )}
    </LFStack>
  );
};

export default MainLayout;
