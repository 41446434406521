import { equal } from "../../utils/javascript";
import { useDispatch, useSelector } from "react-redux";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  shiftStatuses,
  statusCodes,
} from "../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import {
  SET_BUSINESS_TYPE,
  SET_SELECTED_BUSINESS_ID,
} from "../../redux/constant";
import ApiContainer from "../api.container";
import { showToast } from "../../utils/toastService";

const ShiftDetailsContainer = ({
  applicantsData,
  businessId,
  shiftStatus,
  shiftDetailsRefreshAction,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { shiftId } = useParams();
  const globalAttr = useSelector((state) => state?.globalAttr);
  const loaderKey = "finalizePayment";
  const prepaymentLoading = useSelector(
    (state) => state?.app?.loader?.[loaderKey],
  );

  const businessDetail = globalAttr?.businessDetail;

  const { performRequest } = ApiContainer();

  const confirmedLocum =
    shiftStatus !== shiftStatuses?.unfilled && applicantsData?.[0];

  const locumData = confirmedLocum?.attributes;

  const handlePrePaymentClick = async () => {
    const { statusCode, message } = await performRequest({
      endPoint: `${apiEndpoints?.finalizePayment?.replace(":id", shiftId)}`,
      method: apiMethods?.put,
      tokenKey: "Authorization",
      showFailedMessage: true,
      needLoader: true,
      parent: loaderKey,
    });
    if (equal(statusCode, statusCodes?.OK)) {
      showToast(message, "info");
      shiftDetailsRefreshAction();
      return;
    }
  };

  const handleBrowseLocumClick = () => {
    const businessType = businessDetail?.find((detail) =>
      equal(detail?.id, businessId),
    )?.type;

    navigate(locationPath?.searchLocum, { state: { backButton: true } });
    dispatch({
      type: SET_BUSINESS_TYPE,
      payload: businessType,
    });
  };

  const handleViewApplicantClick = () => {
    navigate(`${locationPath?.applicants.replace(":shiftId", shiftId)}`);
  };

  const handleSendMessageClick = () => {
    dispatch({
      type: SET_SELECTED_BUSINESS_ID,
      payload: businessId,
    });
  };

  return {
    locumData,
    prepaymentLoading,
    handlePrePaymentClick,
    handleBrowseLocumClick,
    handleViewApplicantClick,
    handleSendMessageClick,
  };
};

export default ShiftDetailsContainer;
