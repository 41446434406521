import React from "react";
import {
  menuLinks,
  navLinks,
} from "../../description/layout/navBar.description";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import { ReactComponent as LogoutIcon } from "../../assets/svg/logoutIcon.svg";
import { ZERO, locationPath } from "../../utils/constant";
import theme from "../../themes/theme";
import { useDispatch, useSelector } from "react-redux";
import logoutAction from "../../redux/action/logoutAction";
import NotificationsBox from "../notifications/NotificationsBox";
import { makeStyles } from "@mui/styles";
import { notificationsFormPath } from "../../description/notifications/notifications.description";
import { displayCount, equal, length } from "../../utils/javascript";
import LFBox from "../../shared/LFBox";
import NoNotification from "../../assets/png/No_Notification.png";
import { messagesFormPath } from "../../description/messages/messages.description";
import LFStack from "../../shared/LFStack";
import allNotificationsSeen from "../../redux/action/allNotificationsSeen";
import ApiContainer from "../../container/api.container";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    position: "relative",
    display: "inline-block",
    marginLeft: 36,
    "& svg": {
      height: 32,
      width: 32,
    },
  },
  newMessageCounter: {
    "&.MuiTypography-root": {
      position: "absolute",
      left: 17,
      top: -4,
      fontSize: 12,
      fontWeight: 700,
      background: theme.palette.secondary.main,
      borderRadius: 50,
      color: theme.palette.primary.main,
      border: `3px solid ${theme.palette.primary.main}`,
      height: 12,
      minWidth: 13,
      boxSizing: "content-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 1,
    },
  },
  notificationBox: {
    padding: [[12, 28, 8, 40]],
    width: "100%",
    boxSizing: "border-box",
    whiteSpace: "normal",
    position: "relative",
    "& h6": {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.2,
      color: theme.palette.primary.main,
    },
    "& span": {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
    "& p": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      background: theme.palette.gray.dark,
      left: 40,
      right: 24,
      height: 1,
      bottom: 0,
    },
  },
  new: {
    "&::after": {
      position: "absolute",
      content: "''",
      height: 8,
      width: 8,
      borderRadius: 50,
      background: theme.palette.secondary.main,
      left: 16,
      top: 18,
    },
  },
  noNotificationBox: {
    textAlign: "center",
    padding: "30px 10px",
    "& img": {
      height: "auto",
      width: "100%",
      maxWidth: "75%",
      objectFit: "contain",
    },
    "& h6": {
      fontSize: 16,
      color: "#9ba0b5",
    },
  },
}));

const NavBar = () => {
  const { performRequest } = ApiContainer();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width: 991px)");

  const notifcationsData = useSelector(
    (state) => state?.app?.data?.[notificationsFormPath?.parent],
  );
  const unreadNotificationsCount = useSelector(
    (state) => state?.app?.data?.[notificationsFormPath?.newcounts],
  );
  const unreadConversationCount = useSelector(
    (state) => state.app?.data?.[messagesFormPath?.unreadCount],
  );

  const countObject = {
    Notifications: unreadNotificationsCount,
    Messages: unreadConversationCount,
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAccount = Boolean(anchorEl?.[locationPath?.account]);

  const openNotifications = Boolean(anchorEl?.[locationPath?.notifications]);

  const handleClick = (event, link) => {
    handleClose();
    setAnchorEl({ ...anchorEl, [link]: event.currentTarget });
    if (equal(link, locationPath?.notifications)) {
      dispatch(allNotificationsSeen(performRequest, notificationsFormPath));
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuLinkClick = (path) => {
    navigate(path);
    handleClose();
  };

  const handleSignOutClick = () => {
    dispatch(logoutAction(() => navigate(locationPath?.signIn)));
    handleClose();
  };

  return (
    <>
      <LFStack direction="row">
        {navLinks?.map(({ link, dropdown, icon, badgeCount, title }) =>
          !matches && dropdown ? (
            <span
              key={link}
              onClick={(event) => handleClick(event, link)}
              style={{ cursor: "pointer" }}
              className={classes.menuIcon}
            >
              {icon}
              {badgeCount && !!countObject?.[title] && (
                <Typography
                  variant="span"
                  className={classes.newMessageCounter}
                >
                  {displayCount(countObject?.[title])}
                </Typography>
              )}
            </span>
          ) : (
            <Link to={link} key={link} className={classes.menuIcon}>
              {icon}
              {badgeCount && !!countObject?.[title] && (
                <Typography
                  variant="span"
                  className={classes.newMessageCounter}
                >
                  {displayCount(countObject?.[title])}
                </Typography>
              )}
            </Link>
          ),
        )}
      </LFStack>
      <Menu
        anchorEl={anchorEl?.[locationPath?.account]}
        open={openAccount}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              borderRadius: 4,
              overflow: "visible",
              backgroundColor: "primary.main",
              mt: 2,
              maxWidth: 224,
              width: "100%",
              padding: 1,
              "& .MuiList-root": {
                padding: 0,
                color: "white.main",
                "& .MuiMenuItem-root": {
                  padding: "6px 12px",
                  margin: "4px 0",
                  transition: "all .3s",
                  "&:hover": {
                    background: "#BDA6FF",
                    borderRadius: 50,
                    color: theme.palette.primary.main,
                  },
                  "& svg": {
                    marginRight: 1.2,
                    width: 20,
                    height: 20,
                  },
                  "&:last-child": {
                    borderTop: "1px solid #fff",
                  },
                },
              },
            },
          },
        }}
      >
        {menuLinks?.map(({ label, icon, redirect }) => (
          <MenuItem
            key={redirect}
            onClick={() => handleMenuLinkClick(redirect)}
          >
            {icon} {label}
          </MenuItem>
        ))}
        <MenuItem onClick={handleSignOutClick}>
          <LogoutIcon />
          Sign out
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl?.[locationPath?.notifications]}
        open={openNotifications}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              mt: 2,
              maxWidth: 324,
              width: "100%",
              borderRadius: 5,
              border: `1px solid ${theme.palette.secondary.dark}`,
              background: theme.palette.white.main,
              "& .MuiList-root": {
                padding: "16px 0 8",
                "& .MuiMenuItem-root": {
                  padding: 0,
                  "& svg": {
                    marginRight: 1.2,
                    width: 20,
                    height: 20,
                  },
                  "&:last-child": {
                    justifyContent: "center",
                    marginTop: 1,
                    padding: "8px 0",
                    textDecoration: "underline",
                    color: theme.palette.primary.main,
                  },
                },
              },
            },
          },
        }}
      >
        {notifcationsData?.slice(0, 4)?.map((curData) => {
          return (
            <NotificationsBox
              key={curData.id}
              data={curData}
              notifcationsData={notifcationsData}
              formPath={notificationsFormPath}
              onClick={handleClose}
              classNameBox={classes.notificationBox}
              classNameNew={classes.new}
            />
          );
        })}
        {equal(length(notifcationsData), ZERO) ? (
          <LFBox className={classes.noNotificationBox}>
            <img src={NoNotification} alt="" />
            <Typography variant="h6">
              No Notifications at the Moment.......
            </Typography>
          </LFBox>
        ) : (
          <MenuItem
            onClick={() => {
              navigate(locationPath?.notifications);
              handleClose();
            }}
          >
            See all notifications
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default NavBar;
