import { ReactComponent as Info } from "../../assets/svg/info-icon.svg";

export const messageFormAttributes = [
  {
    name: "first_name",
    label: "First Name",
    type: "text",
    error: ["First Name is required"],
    fullWidth: true,
    xs: 12,
    xsm: 6,
  },
  {
    name: "last_name",
    label: "Last Name",
    type: "text",
    error: ["Last Name is required"],
    fullWidth: true,
    xs: 12,
    xsm: 6,
  },

  {
    name: "email",
    label: "Email",
    type: "text",
    pattern: "email",
    isRequired: true,
    error: [
      <>
        <Info />
        Please fill in your email address
      </>,
      <>
        <Info />
        Please enter valid email address
      </>,
    ],
    fullWidth: true,
    xs: 12,
    xsm: 6,
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "telephone",
    isMobileNumber: true,
    fullWidth: true,
    xs: 12,
    xsm: 6,
    autoComplete: "off",
  },
  {
    name: "subject",
    label: "Select Subject",
    type: "checkboxGroup",
    isRequired: true,
    error: [
      <>
        <Info />
        Please select a subject
      </>,
    ],
    selectOne: true,
    options: [
      {
        name: "General Enquiry",
        label: "General Enquiry",
        xsm: 3,
        xs: 6,
        order: 1,
      },
      { name: "Support", label: "Support", xsm: 3, xs: 6, order: 3 },
      {
        name: "Billing/Account",
        label: "Billing/Account",
        xsm: 3,
        xs: 6,
        order: 2,
      },
      {
        name: "Feedback/ Suggestions",
        label: "Feedback/ Suggestions",
        xsm: 3,
        xs: 6,
        order: 4,
      },
    ],
    xs: 12,
  },
  {
    name: "message",
    label: "Message",
    type: "text",
    multiline: true,
    isRequired: true,
    error: [
      <>
        <Info />
        Please write a message
      </>,
    ],
    fullWidth: true,
    xs: 12,
    xsm: 12,
  },
];

export const defaultData = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  subject: "",
  message: "",
};
