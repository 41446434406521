import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { formatName } from "../../utils/javascript";

const useStyles = makeStyles((theme) => ({
  profileOverview: {
    textAlign: "center",
    "& img": {
      height: 96,
      width: 96,
      borderRadius: "50%",
      marginBottom: 19,
      [theme.breakpoints.down("xxsm")]: {
        height: 64,
        width: 64,
      },
    },
    "& h5": {
      fontSize: 28,
      fontWeight: 400,
      marginBottom: 4,
      lineHeight: 1.3,
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 24,
      },
    },
    "& p": {
      fontSize: 20,
      lineHeight: 1.3,
      marginBottom: 4,
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 16,
      },
    },
    "& span": {
      fontSize: 24,
      lineHeight: 1.3,
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 16,
      },
    },
  },
  spacing: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      textAlign: "left",
      alignItems: "center",
      "& img": {
        margin: [[0, 20, 0, 0]],
      },
    },
  },
  profileDirection: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
  },
}));
const LocumOverview = ({
  locum_name,
  avatar_url,
  userImage,
  role_name,
  hourly_rate,
  mobileAccordion = false,
  forAppliacantsPage,
}) => {
  const classes = useStyles();
  return (
    <>
      <LFBox
        className={classNames(classes.profileOverview, {
          [classes.spacing]: mobileAccordion,
          [classes.profileDirection]: forAppliacantsPage,
        })}
      >
        <img
          src={avatar_url ? avatar_url : userImage}
          style={{ objectFit: "cover" }}
          alt=""
        />
        <LFBox>
          <LFTypography
            variant="h5"
            component="h5"
            color="primary"
            maxWidth={{ md: 224 }}
            sx={{
              overflowWrap: "anywhere",
              margin: "0 auto",
            }}
          >
            {formatName(locum_name)}
          </LFTypography>
          <LFTypography
            variant="body1"
            component="p"
            color="secondary.dark"
            maxWidth={{ md: 224 }}
            sx={{ margin: "0 auto" }}
          >
            {role_name}
          </LFTypography>
          <LFTypography variant="body1" component="span">
            ${hourly_rate}/hr
          </LFTypography>
        </LFBox>
      </LFBox>
    </>
  );
};

export default LocumOverview;
