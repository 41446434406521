import React from "react";
import LFBox from "../../shared/LFBox";
import FooterContainer from "../../container/layout/footer.container";
import { Link } from "react-router-dom";
import LFContainer from "../../shared/LFContainer";
import LFGrid from "../../shared/LFGrid";
import { makeStyles } from "@mui/styles";
import LFTypography from "../../shared/LFTypograpy";
import LFStack from "../../shared/LFStack";
import { copyrightText, ternary } from "../../utils/javascript";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  footerLink: {
    [theme.breakpoints.down("msm")]: {
      textAlign: "center",
      marginBottom: 15,
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      marginRight: 15,
      fontSize: 14,
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  footerDark: {
    "& a": {
      color: theme.palette.white.main,
    },
  },
  footer: {
    position: "sticky",
    bottom: 0,
    zIndex: 2,
  },
}));

const Footer = ({ footerDark, hideHelpPages = false }) => {
  const { navLink, socialMediaLinks } = FooterContainer();
  const classes = useStyles();
  return (
    <LFBox
      backgroundColor={ternary(footerDark, "primary.main", "white.main")}
      py={1.5}
      sx={{ outline: "1px solid black" }}
      className={classNames(classes.footer, {
        [classes.footerDark]: footerDark,
      })}
    >
      <LFContainer>
        <LFGrid container justifyContent="space-between">
          <LFGrid item msm={6} lg={4} sm={6} xs={12}>
            {!hideHelpPages && (
              <LFBox className={classes.footerLink}>
                {navLink.map((link, index) => {
                  const { label, path } = { ...link };
                  return (
                    <Link key={index} to={path} target="_blank">
                      {label}
                    </Link>
                  );
                })}
              </LFBox>
            )}
          </LFGrid>
          <LFGrid
            item
            msm={6}
            lg={8}
            sm={6}
            xs={12}
            display="flex"
            flexDirection={{ xxsm: "column", md: "row-reverse" }}
            justifyContent="space-between"
            alignItems={{ sm: "end", md: "center" }}
          >
            <LFStack
              direction="row"
              justifyContent={{ msm: "flex-end", xs: "center" }}
              spacing={2}
              alignItems="center"
              sx={{
                "& a": {
                  display: "inherit",
                  color: ternary(footerDark, "white.main", "primary.main"),
                },
              }}
            >
              <LFTypography
                color={ternary(footerDark, "white.main", "primary.main")}
                variant="body2"
              >
                Connect with us
              </LFTypography>
              {socialMediaLinks.map((link, index) => {
                const { icon, url } = link;
                return (
                  <Link key={index} to={url} target="_blank">
                    {icon}
                  </Link>
                );
              })}
            </LFStack>
            <LFStack alignItems="flex-end">
              <LFTypography
                color={ternary(footerDark, "white.main", "primary.main")}
                fontSize={14}
                marginTop={{ xxsm: "22px", md: "0" }}
              >
                {copyrightText()}
              </LFTypography>
            </LFStack>
          </LFGrid>
        </LFGrid>
      </LFContainer>
    </LFBox>
  );
};

export default Footer;
