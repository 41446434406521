import { combineReducers } from "redux";

import { equal } from "../../utils/javascript";
import { LOGOUT } from "../constant";
import App from "./app.reducer";
import Form from "./form.reducer";
import GlobalAttr from "./globalAtrr.reducer";

const rootReducer = combineReducers({
  app: App,
  form: Form,
  globalAttr: GlobalAttr,
});

const appReducer = (state, action) => {
  if (equal(action.type, LOGOUT)) {
    state = undefined;
  }

  return rootReducer(state, action);
};

export default appReducer;
