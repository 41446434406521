import React from "react";
import LFContainer from "../../shared/LFContainer";
import LFGrid from "../../shared/LFGrid";
import { makeStyles } from "@mui/styles";
import { notificationsFormPath } from "../../description/notifications/notifications.description";
import NotificationsContainer from "../../container/notifications/notifications.container";
import Loader from "../reusable/Loader";
import NotificationsBox from "./NotificationsBox";
import { ZERO } from "../../utils/constant";
import { equal, length } from "../../utils/javascript";
import LFBox from "../../shared/LFBox";
import NoNotification from "../../assets/png/No_Notification.png";
import LFTypography from "../../shared/LFTypograpy";

const useStyles = makeStyles((theme) => ({
  notificationBox: {
    borderBottom: `1px solid ${theme.palette.gray.dark}`,
    padding: [[12, 0, 25]],
    "& h6": {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.2,
      color: theme.palette.primary.main,
    },
    "& span": {
      fontSize: 12,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
    "& p": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
  },
  new: {
    position: "relative",
    "&::after": {
      position: "absolute",
      content: "''",
      height: 8,
      width: 8,
      borderRadius: 50,
      background: theme.palette.secondary.main,
      left: -16,
      top: 18,
    },
  },
  noNotificationBox: {
    textAlign: "center",
    "& img": {
      height: "auto",
      width: 400,
      maxWidth: "80%",
      objectFit: "contain",
    },
    "& h6": {
      fontSize: 18,
      color: "#9ba0b5",
    },
  },
}));

const Notifications = () => {
  const classes = useStyles();

  const { notifcationsData, notificationsLoading, paginationsLoading } =
    NotificationsContainer({
      formPath: notificationsFormPath,
    });
  return (
    <>
      <LFContainer>
        <LFTypography
          mt={6}
          mb={3}
          variant="h5"
          component="h5"
          color="primary"
          fontWeight="500"
        >
          Notifications
        </LFTypography>
        <LFGrid container>
          <LFGrid item md={8} xs={12} mx={"auto"} pl={{ md: 0, xsm: 3, xs: 1 }}>
            {notificationsLoading ? (
              <Loader />
            ) : (
              <>
                {notifcationsData?.map((curData) => {
                  return (
                    <NotificationsBox
                      key={curData.id}
                      data={curData}
                      notifcationsData={notifcationsData}
                      formPath={notificationsFormPath}
                      classNameBox={classes.notificationBox}
                      classNameNew={classes.new}
                    />
                  );
                })}
                {paginationsLoading && (
                  <>
                    <Loader height={"max-content"} sx={{ padding: "50px 0" }} />
                  </>
                )}
                {equal(length(notifcationsData), ZERO) && (
                  <>
                    <LFBox className={classes.noNotificationBox}>
                      <img src={NoNotification} alt="" />
                      <LFTypography variant="h6">
                        No Notifications at the Moment.......
                      </LFTypography>
                    </LFBox>
                  </>
                )}
              </>
            )}
          </LFGrid>
        </LFGrid>
      </LFContainer>
    </>
  );
};

export default Notifications;
