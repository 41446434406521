import { useEffect, useState } from "react";
import ApiContainer from "../api.container";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { useSelector } from "react-redux";
import { equal } from "../../utils/javascript";

const PaymentMethodContainer = ({ formPath }) => {
  const initailLoadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const [showPaymentMethod, setShowPaymentMethod] = useState(false);
  const [cardNumber, setCardNumber] = useState(null);

  const { performRequest } = ApiContainer();

  useEffect(() => {
    retrievePaymentMethod();
  }, []);

  const retrievePaymentMethod = async () => {
    const { data, statusCode } = await performRequest({
      endPoint: apiEndpoints?.retrievePaymentMethod,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.parent,
      showFailedMessage: true,
    });
    if (!equal(statusCode, statusCodes.OK)) return;
    if (data?.last4) {
      return setCardNumber(data?.last4);
    }
    return setShowPaymentMethod(true);
  };

  const openForm = () => {
    setShowPaymentMethod(true);
  };
  const cancelForm = () => {
    setShowPaymentMethod(false);
  };
  const paymentMethodAttribute = {
    name: "paymentMethod",
    label: "Payment Method",
    type: "text",
    variant: "standard",
    fullWidth: true,
  };
  return {
    showPaymentMethod,
    paymentMethodAttribute,
    initailLoadingStatus,
    cardNumber,
    cancelForm,
    openForm,
    setCardNumber,
  };
};

export default PaymentMethodContainer;
