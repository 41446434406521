import { useEffect } from "react";

function useDebounce(callback, delay, dependancyArr) {
  useEffect(() => {
    const timer = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [...dependancyArr]);
}

export default useDebounce;
