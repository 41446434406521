import React from "react";
import LFBox from "../../shared/LFBox";
import LFFormFields from "../../shared/LFFormFields";
import {
  registerAttribute,
  registerFormPath,
  defaultValues,
} from "../../description/register/register.description";
import LFForm from "../../shared/LFForm";
import FormContainer from "../../container/form.container";
import RegisterContainer from "../../container/register/register.container";
import LFButton from "../../shared/LFButton";
import LFCheckbox from "../../shared/LFCheckbox";
import LFTypography from "../../shared/LFTypograpy";
import { Link } from "react-router-dom";
import LFStack from "../../shared/LFStack";
import LFGrid from "../../shared/LFGrid";
import { locationPath } from "../../utils/constant";

const Register = () => {
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: registerAttribute,
      formPath: registerFormPath,
      defaultValues,
    });
  const {
    showPage,
    loadingStatus,
    handleRegisterSubmit,
    isChecked,
    handleTermsChange,
  } = RegisterContainer({
    formData,
    formError,
    formPath: registerFormPath,
    validate,
  });

  if (!showPage) return;

  return (
    <LFForm>
      <LFFormFields
        attributes={registerAttribute}
        formData={formData}
        formError={formError}
        handleChange={handleChange}
      />
      <LFGrid item xs={12}>
        <LFStack
          direction="row"
          alignItems="center"
          width="100%"
          justifyContent="center"
          my={2}
        >
          <LFCheckbox onChange={handleTermsChange} checked={isChecked} />
          <LFTypography
            color="secondary.dark"
            variant="body2"
            sx={{
              "& a": { color: "secondary.dark" },
              fontSize: { xs: 12, xxs: 14 },
            }}
          >
            I have read and agree to the{" "}
            <Link to={`${locationPath?.terms}`} target="_blank">
              Terms of Use
            </Link>{" "}
            and{" "}
            <Link to={`${locationPath?.clientAgreement}`} target="_blank">
              Client Agreement
            </Link>
          </LFTypography>
        </LFStack>
        <LFStack
          direction="row"
          alignItems="center"
          width="100%"
          justifyContent="center"
          my={2}
        >
          <LFTypography
            color="secondary.dark"
            variant="body2"
            sx={{
              "& a": { color: "secondary.dark" },
              fontSize: { xs: 12, xxs: 14 },
            }}
          >
            Already have an account?{" "}
            <Link to={locationPath.signIn}>Sign in</Link>
          </LFTypography>
        </LFStack>
        <LFBox textAlign="center" width="100%">
          <LFButton
            type={"button"}
            onClick={handleRegisterSubmit}
            variant={"outlined"}
            isLoading={loadingStatus}
            disabled={isFormError}
            sx={{ minWidth: 280 }}
          >
            NEXT
          </LFButton>
        </LFBox>
      </LFGrid>
    </LFForm>
  );
};

export default Register;
