import React, { useState } from "react";
import { InputAdornment, TextField as TF } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { LFStyled } from "./LFStyled";

const TextField = LFStyled(TF, {
  shouldForwardProp: (prop) => prop !== "radius",
})(({ radius, theme }) => ({
  "& label": {
    fontSize: 18,
  },
  "& .MuiInputBase-root": {
    borderRadius: radius ? radius : 0,
    fontSize: 18,
    [theme.breakpoints.down("xxsm")]: {
      fontSize: 14,
    },
    "& input": {
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 14,
      },
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &[type="number"]':
        {
          WebkitAppearance: "none",
          MozAppearance: "textfield",
        },
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
        {
          WebkitBoxShadow: `0 0 0px 40rem #ffffff inset`,
          borderRadius: 0,
        },
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
  },
}));
const LFTextField = ({
  type,
  variant = "standard",
  readOnly,
  readOnlyField,
  showEyeIcon,
  endAdornment,
  startAdornment,
  adormentClick,
  radius,
  sx,
  inputRef,
  editable,
  startLogo,
  ...rest
}) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <TextField
      {...{ variant, ...rest, ...(editable && { onClick: adormentClick }) }}
      inputRef={inputRef}
      type={visibility ? "text" : type}
      sx={{ "& .MuiSvgIcon-root": { color: "gray.dark" }, ...sx }}
      radius={radius}
      InputProps={{
        ...rest.InputProps,
        readOnly: readOnly || readOnlyField,
        endAdornment: (
          <>
            {showEyeIcon && (
              <>
                {visibility ? (
                  <RemoveRedEyeOutlinedIcon
                    onClick={() => setVisibility(!visibility)}
                    cursor="pointer"
                  />
                ) : (
                  <VisibilityOffOutlinedIcon
                    onClick={() => setVisibility(!visibility)}
                    cursor="pointer"
                  />
                )}
              </>
            )}
            {endAdornment && (
              <InputAdornment position="end" onClick={adormentClick}>
                {endAdornment}
              </InputAdornment>
            )}
          </>
        ),
        startAdornment: (
          <>
            {startLogo && startLogo}
            {startAdornment && (
              <InputAdornment position="start" onClick={adormentClick}>
                {startAdornment}
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};

export default LFTextField;
