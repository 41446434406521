import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { locationPath } from "../../utils/constant";
import LFBox from "../../shared/LFBox";
import PromptTemplet from "../reusable/PromptTemplet";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));

const AddTemplateSuccess = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.showTemplateSuccess) {
      navigate(-1, { replace: true });
    }
  }, []);

  const handleDoneClick = () => {
    if (state?.shiftPosted) return navigate(locationPath?.myCalendar);
    return navigate(locationPath?.newShiftAddDetails);
  };

  const buttonAttributes = [
    {
      label: "Done",
      onClick: handleDoneClick,
      variant: "contained",
    },
  ];

  return (
    <LFBox className={classes.promptWrapper}>
      <PromptTemplet
        mainText={"Template has been saved."}
        buttonAttributes={buttonAttributes}
        iconSrc={true}
      />
    </LFBox>
  );
};

export default AddTemplateSuccess;
