import { locationPath } from "../../utils/constant";

export const tabNavbar = [
  { label: "SINGLE SHIFT", value: locationPath?.singleShift },
  { label: "RECURRING SHIFT", value: locationPath?.recurringShift },
];

export const newShiftFormPath = {
  parent: "newShift",
  details: "newShiftDetails",
  state: "newShiftState",
  initial: "newShiftInitial",
};
