import React, { useState } from "react";
import {
  defaultData,
  messageFormAttributes,
} from "../../description/contactUs/contactUs.description";
import LFBox from "../../shared/LFBox";
import LFContainer from "../../shared/LFContainer";
import LFForm from "../../shared/LFForm";
import LFFormFields from "../../shared/LFFormFields";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import theme from "../../themes/theme";
import { Divider } from "@mui/material";
import { ReactComponent as EmailIcon } from "../../assets/svg/emailicon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/svg/facebook.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svg/instagram.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/svg/linkedin.svg";
import {
  equal,
  getObject,
  head,
  keys,
  last,
  values,
} from "../../utils/javascript";
import validation from "../../utils/validation";
import { notEmpty } from "../../utils/regex";
import {
  apiEndpoints,
  apiMethods,
  socialMedia,
  statusCodes,
} from "../../utils/constant";
import { Link } from "react-router-dom";
import LFButton from "../../shared/LFButton";
import ApiContainer from "../../container/api.container";
import { showToast } from "../../utils/toastService";

const useStyle = makeStyles((theme) => ({
  mainHead: {
    "&.MuiTypography-root": {
      // fontSize: "36px",
      textAlign: "center",
      textTransform: "capitalize",
      color: theme.palette.primary.main,
    },
  },
  subHead: {
    "&.MuiTypography-root": {
      color: theme.palette.secondary.dark,
      // fontSize: "18px",
      textAlign: "center",
      fontWeight: "500",
    },
  },
  findLocum: {
    height: "calc(100vh - 114px)",
    [theme.breakpoints.between("xxsm", "md")]: {
      maxHeight: "calc(100svh - 114px)",
    },
    [theme.breakpoints.down("xxsm")]: {
      maxHeight: "unset",
    },
  },

  primaryBox: {
    padding: "45px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    position: "relative",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    minHeight: "650px",
    minWidth: "491px",
    [theme.breakpoints.down("xsm")]: {
      minWidth: "280px",
      padding: "25px",
      minHeight: "unset",
    },
    "& .MuiTypography-root": {
      color: theme.palette.white.main,
    },
    "&::before": {
      content: "''",
      position: "absolute",
      background:
        "linear-gradient(rgba(187, 166, 255, 0.4), rgba(187, 166, 255, 0))",
      height: 476,
      width: 476,
      bottom: -290,
      left: -80,
      borderRadius: "50%",
      opacity: "0.3",
      [theme.breakpoints.down("xsm")]: {
        height: 271,
        width: 271,
        bottom: -120,
        left: -40,
      },
    },
    "&::after": {
      content: "''",
      position: "absolute",
      background:
        "linear-gradient(45deg, rgba(177, 164, 213, 1),rgba(186, 165, 244, 0.26),rgba(189, 166, 255, 0))",
      height: 285,
      width: 285,
      bottom: "-33%",
      left: "60%",
      borderRadius: "50%",
      opacity: "0.3",
      [theme.breakpoints.down("xsm")]: {
        height: 174,
        width: 174,
        bottom: "-23%",
      },
    },
  },

  primaryBoxContent: {
    "&.MuiStack-root": {
      padding: "42px 0",
      flexDirection: "row",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        padding: "30px 0",
      },
      [theme.breakpoints.down("xsm")]: {
        flexDirection: "column",
        alignItems: "center",
        gap: "15px 0",
        padding: "17px 0",
      },
    },
  },
  contactForm: {
    marginTop: 35,
    paddingRight: 17,
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
      marginTop: 25,
    },
    "& MuiInputBase-root-MuiInput-root.Mui-error": {
      color: "white",
    },
    " & .MuiFormControlLabel-label": {
      fontSize: 12,
      wordBreak: "break-word",
    },
    " & .MuiInputBase-root.Mui-error::before": {
      borderBottomColor: theme.palette.secondary.main,
    },
    "& .MuiFormControl-root": {
      marginTop: 10,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.Mui-error": {
      color: "rgba(0, 0, 0, 0.6)",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: "13px",
    },
    "& .MuiFormHelperText-root": {
      display: "flex",
      alignItems: "center",
      gap: "0 6px",
      position: "absolute",
      top: "100%",
      left: "0%",
      "& svg": {
        fill: theme.palette.secondary.lightGreen,
        width: 15,
        height: 15,
      },
    },
    "& .checkboxRow": {
      [theme.breakpoints.down("1230")]: {
        flexWrap: "wrap",
      },
      "& .MuiStack-root": {
        [theme.breakpoints.down("1230")]: {
          flex: "0 0 50%",
        },
      },
    },
  },
}));

const socialMediaLinks = [
  { icon: <FacebookIcon />, url: socialMedia?.facebook },
  { icon: <TwitterIcon />, url: socialMedia?.twitter },
  { icon: <InstagramIcon />, url: socialMedia?.instagram },
  { icon: <LinkedinIcon />, url: socialMedia?.linkedin },
];

const validate = (name, value) => {
  const { isRequired, pattern, error } = getObject(messageFormAttributes, name);

  const res = validation(pattern, value);
  if (isRequired) {
    if (equal(typeof value, "object")) return null;
    if (!notEmpty(value)) {
      return head(error);
    }
  } else if (!!value && !res) {
    return last(error);
  }
  if (isRequired && !res) {
    return last(error);
  }
  return null;
};

const ContactUs = () => {
  const classes = useStyle();

  const { performRequest } = ApiContainer();

  const [formData, setFormData] = useState(defaultData);
  const [formDataError, setFormDataError] = useState(defaultData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (!formDataError?.[name]) return;

    setFormDataError({ ...formDataError, [name]: validate(name, value) });
  };

  const onSubmit = async () => {
    const newError = {};
    keys(formData)?.forEach((name) => {
      newError[name] = validate(name, formData[name]);
    });

    if (!values(newError)?.every((cur) => !cur))
      return setFormDataError(newError);

    const contact = { ...formData };
    const { statusCode } = await performRequest({
      endPoint: apiEndpoints?.contactUsForm,
      method: apiMethods.post,
      data: { contact },
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) return showToast();
    setFormData(defaultData);
    showToast("Message sent successfully", "success");
  };

  return (
    <LFBox
      sx={{ backgroundColor: "#fafafa", height: { md: "100vh", xs: "auto" } }}
    >
      <LFContainer>
        <LFBox
          sx={{
            marginTop: { xs: 4.5, xsm: 8 },
          }}
        >
          <LFStack gap={1} sx={{ marginBottom: { xs: 3, xsm: 0 } }}>
            <LFTypography
              className={classes.mainHead}
              fontSize={{ xs: "20px", xsm: "36px" }}
            >
              Contact Us
            </LFTypography>
            <LFTypography
              className={classes.subHead}
              fontSize={{ xs: "14px", xsm: "17px" }}
              padding={2}
              maxWidth={{ xs: 300, xsm: "100%" }}
              m="auto"
            >
              Have any questions or comments? We’d love to hear from you.
            </LFTypography>
          </LFStack>
          <LFBox
            sx={{
              marginTop: { xs: 0, xsm: 4 },
              marginBottom: { xs: 0, xsm: 4 },
              backgroundColor: { md: theme.palette.white.main },
              borderRadius: 1.4,
              padding: { xsm: 1.4 },
            }}
          >
            <LFStack
              container
              direction={{ xs: "column", md: "row" }}
              //  columnSpacing={{ xsm: 5 }}
              className={classes.contactBox}
              spacing={5.5}
            >
              <LFStack className={classes.primaryBox}>
                <LFTypography
                  textAlign={{ xs: "center", xsm: "start" }}
                  fontSize={{ xsm: "28px", xs: "20px" }}
                  fontWeight={{ xsm: 600, xs: 400 }}
                >
                  Contact Information
                </LFTypography>
                <LFTypography
                  sx={{
                    textAlign: { xs: "center", xsm: "start" },
                    fontSize: { xs: "14px", xsm: "18px" },
                    mt: { xs: 3, md: "45px" },
                  }}
                >
                  Email us directly or reach out to us on our social media
                  pages!
                </LFTypography>
                <LFStack sx={{ mt: { xs: "40px", md: "62px" } }}>
                  <Divider sx={{ backgroundColor: theme.palette.white.main }} />
                  <LFStack className={classes.primaryBoxContent}>
                    <LFTypography
                      sx={{
                        "&.MuiTypography-root": {
                          fontSize: "18px",
                          fontWeight: "500",
                          textTransform: "uppercase",
                          color: theme.palette.secondary.main,
                        },
                      }}
                    >
                      EMAIL
                    </LFTypography>
                    <LFStack
                      direction="row"
                      alignItems="center"
                      gap={1.5}
                      sx={{
                        "& svg": {
                          width: { xsm: "20px", xs: 14 },
                        },
                      }}
                    >
                      <EmailIcon />
                      <LFTypography fontSize={{ xsm: "16px", xs: "14px" }}>
                        support@locumfind.com.au
                      </LFTypography>
                    </LFStack>
                  </LFStack>
                  <Divider sx={{ backgroundColor: theme.palette.white.main }} />
                  <LFStack
                    className={classes.primaryBoxContent}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    position="relative"
                  >
                    <LFTypography
                      sx={{
                        "&.MuiTypography-root": {
                          fontSize: "18px",
                          fontWeight: "500",
                          color: theme.palette.secondary.main,
                          textTransform: "uppercase",
                        },
                      }}
                    >
                      social Media
                    </LFTypography>
                    <LFStack
                      direction="row"
                      alignItems="center"
                      gap={3}
                      sx={{
                        "& svg": {
                          fill: theme.palette.white.main,
                          width: { xsm: "20px", xs: 14 },
                          "& path": {
                            fill: theme.palette.white.main,
                          },
                        },
                      }}
                    >
                      {socialMediaLinks?.map((link, index) => {
                        const { icon, url } = link;
                        return (
                          <Link key={index} to={url} target="_blank">
                            {icon}
                          </Link>
                        );
                      })}
                    </LFStack>
                    <LFTypography
                      sx={{
                        position: "absolute",
                        bottom: { xsm: "10px", xs: 6 },
                        right: { xsm: 0, xs: "auto" },
                        left: { xsm: "unset", xs: "50%" },
                        transform: {
                          xsm: "translateX(0%)",
                          xs: "translateX(-50%)",
                        },
                      }}
                      fontSize={{ xsm: "16px", xs: "12px" }}
                    >
                      @Locumfindpty
                    </LFTypography>
                  </LFStack>
                  <Divider sx={{ backgroundColor: theme.palette.white.main }} />
                </LFStack>
              </LFStack>

              <LFStack
                height="100%"
                sx={{ marginTop: { xs: "0px !important", md: 4.25 } }}
              >
                <LFForm
                  rowSpacing={{ xs: 1.25, xts: 2.5, xsm: 6 }}
                  className={classes.contactForm}
                >
                  <LFFormFields
                    formData={formData}
                    attributes={messageFormAttributes}
                    formError={formDataError}
                    handleChange={handleChange}
                  />
                  <LFStack
                    justifyContent="center"
                    width="100%"
                    pl={{ md: 0, xsm: 3, xs: 2 }}
                    pb={{ md: 0, xsm: 0, xs: 2 }}
                    sx={{ marginTop: { xsm: 3 } }}
                  >
                    <LFButton
                      type={"button"}
                      onClick={onSubmit}
                      variant={"contained"}
                      sx={{
                        fontSize: 14,
                        minHeight: 40,
                        height: 40,
                        width: { xs: "100%", xsm: 195 },
                        margin: "0 auto",
                        marginTop: { md: 5.5, xs: "33px" },
                      }}
                    >
                      SEND MESSAGE
                    </LFButton>
                  </LFStack>
                </LFForm>
              </LFStack>
            </LFStack>
          </LFBox>
        </LFBox>
      </LFContainer>
    </LFBox>
  );
};

export default ContactUs;
