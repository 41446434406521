import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal } from "../../utils/javascript";
import { SET_PUBLIC_HOLIDAYS } from "../constant";

const getPublicHolidays =
  ({ performRequest }) =>
  async (dispatch) => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.publicHolidays}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });

    if (!equal(statusCode, statusCodes?.OK)) return;

    const reducePublicHolidays = data?.public_holidays?.map(
      (cur) => cur.attributes,
    );
    dispatch({
      type: SET_PUBLIC_HOLIDAYS,
      payload: reducePublicHolidays,
    });
  };

export default getPublicHolidays;
