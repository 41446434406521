import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_DATA } from "../../redux/constant";
import { getLocalStorageItem } from "../../utils/localStorage";
import { pharmacy } from "./constants";
import { equal, keys, ternary, values } from "../../utils/javascript";
import { LS_KEY_BUSINESS_ID, ZERO } from "../../utils/constant";
import formDataAction from "../../redux/action/formDataAction";

const RolesSectionContainer = ({ formPath, defaultValues }) => {
  const dispatch = useDispatch();

  const serchLocumState = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const globalAttr = useSelector((state) => state?.globalAttr);

  const rolesSwitches = serchLocumState?.rolesSwitches ?? {};
  const skillsArray = serchLocumState?.form?.skill_ids ?? [];

  const selectedBusinessId = globalAttr?.selectedBusinessId;
  const allrolesAndSkills = globalAttr?.allRoleAndSkills;
  const businessType = globalAttr?.businessType ?? pharmacy;
  const skillsLoading = globalAttr?.skillsLoading;

  const skillsObject = allrolesAndSkills[businessType];
  const switches = keys(skillsObject);

  const allSkillsIds = extractIdsFromNestedObject(skillsObject) ?? [];

  useEffect(() => {
    let newSearchLocumState = ternary(
      serchLocumState,
      { ...serchLocumState },
      { ...defaultValues[businessType] },
    );

    const switchesInitialState = {};
    switches?.forEach((switchName) => {
      switchesInitialState[switchName] = true;
    });

    newSearchLocumState.rolesSwitches = ternary(
      !equal(keys(rolesSwitches)?.length, ZERO),
      rolesSwitches,
      switchesInitialState,
    );

    if (getLocalStorageItem(LS_KEY_BUSINESS_ID) != selectedBusinessId) {
      newSearchLocumState.rolesSwitches = switchesInitialState;
    }

    if (!equal(newSearchLocumState?.form, undefined)) {
      if (getLocalStorageItem(LS_KEY_BUSINESS_ID) != selectedBusinessId) {
        newSearchLocumState.form.skill_ids = allSkillsIds ?? [];
      } else if (skillsArray?.length > ZERO) {
        newSearchLocumState.form.skill_ids = skillsArray;
      } else {
        newSearchLocumState.form.skill_ids = allSkillsIds;
        newSearchLocumState.rolesSwitches = switchesInitialState;
      }
    }

    dispatch(formDataAction(newSearchLocumState, formPath?.parent, true));
  }, [skillsObject, businessType]);

  const allSwitchToggle = ({ e }) => {
    const newSearchLocumState = { ...serchLocumState };

    const newSkillsArray = ternary(e?.target?.checked, allSkillsIds, []);
    let newRoleSwitches = {};
    keys(rolesSwitches)?.forEach((curRoleKey) => {
      newRoleSwitches[curRoleKey] = ternary(e?.target?.checked, true, false);
    });

    newSearchLocumState.rolesSwitches = newRoleSwitches;

    newSearchLocumState.form = {
      ...newSearchLocumState?.form,
      skill_ids: newSkillsArray,
    };

    return dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: newSearchLocumState,
      },
    });
  };

  const switchToggle = ({ e, skillsIdArray }) => {
    const name = e?.target?.name;
    let newSearchLocum = serchLocumState;

    if (equal(rolesSwitches[name], true)) {
      let newRolesArray = skillsArray.filter(
        (item) => !skillsIdArray?.includes(item),
      );

      newSearchLocum = {
        ...newSearchLocum,
        form: { ...newSearchLocum?.form, skill_ids: newRolesArray },
      };
    }

    newSearchLocum = {
      ...newSearchLocum,
      rolesSwitches: { ...rolesSwitches, [name]: !rolesSwitches[name] },
    };

    return dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: newSearchLocum,
      },
    });
  };

  const chipToggle = ({ id = null, allChipIds = [] }) => {
    let newRolesArray = skillsArray;
    if (allChipIds?.length > ZERO) {
      if (allChipIds?.every((element) => skillsArray?.includes(element))) {
        newRolesArray = newRolesArray?.filter(
          (curId) => !allChipIds?.includes(curId),
        );
      } else {
        newRolesArray = newRolesArray?.concat(allChipIds);
      }
      return dispatch({
        type: SET_FORM_DATA,
        payload: {
          [formPath?.parent]: {
            ...serchLocumState,
            form: { ...serchLocumState?.form, skill_ids: newRolesArray },
          },
        },
      });
    }
    if (newRolesArray?.includes(id)) {
      newRolesArray = newRolesArray?.filter((curid) => curid !== id);
    } else {
      newRolesArray.push(id);
    }
    return dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...serchLocumState,
          form: { ...serchLocumState?.form, skill_ids: newRolesArray },
        },
      },
    });
  };

  return {
    rolesSwitches,
    skillsArray,
    switchToggle,
    chipToggle,
    allSwitchToggle,
    skillsLoading,
    allSkillsArray: extractIdsFromNestedObject(allrolesAndSkills[businessType]),
    businessType,
    rolesObj: skillsObject,
  };
};

export default RolesSectionContainer;

function extractIdsFromNestedObject(obj) {
  const idArray = [];
  values(obj)?.forEach((objValue) => {
    if (objValue && equal(typeof objValue, "object")) {
      values(objValue)?.forEach((subObj) => {
        if (Array.isArray(subObj)) {
          subObj?.forEach((item) => {
            if (item?.id) {
              idArray.push(item?.id);
            }
          });
        }
      });
    }
  });

  return idArray;
}
