import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  LS_KEY_REGISTER_ID,
  LS_KEY_USER,
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { equal, length } from "../../utils/javascript";
import ApiContainer from "../api.container";
import { getLocalStorageItem } from "../../utils/localStorage";
import { useSelector } from "react-redux";

const EmailVerifyContainer = () => {
  const navigate = useNavigate();
  const { setPath } = useOutletContext();
  const [otp, setOtp] = useState();
  const [isError, setIsError] = useState(false);

  const loadingStatus = useSelector((state) => state?.app?.loader?.emailVerify);
  const resendLoadingStatus = useSelector(
    (state) => state?.app?.loader?.resendOTP,
  );

  const user = getLocalStorageItem(LS_KEY_USER);
  const registerId = getLocalStorageItem(LS_KEY_REGISTER_ID);
  const userObj = user ? JSON.parse(user) : {};

  useEffect(() => {
    setPath(locationPath?.register);
    if (!registerId || !userObj?.id)
      navigate(locationPath?.register, { replace: true });
  }, []);

  useEffect(() => {
    if (equal(length(otp), otpInputNumber)) {
      handleOtpSubmit();
    }
  }, [otp]);

  const { performRequest } = ApiContainer();

  const otpInputNumber = 5;

  const handleOtpSubmit = async () => {
    if (!equal(length(otp), otpInputNumber)) return setIsError(true);

    const response = await performRequest({
      endPoint: apiEndpoints?.emailVerify,
      method: apiMethods?.put,
      data: { id: userObj?.id, email_code: otp },
      showFailedMessage: true,
      parent: "emailVerify",
      needLoader: true,
    });

    if (!equal(response?.statusCode, statusCodes?.OK)) return setIsError(true);

    return navigate(locationPath?.emailSuccess, {
      state: { showEmailSuccess: true },
    });
  };

  const handleResendOTP = () => {
    setOtp("");
    performRequest({
      endPoint: apiEndpoints?.resendOTP,
      method: apiMethods?.put,
      data: { email: userObj?.email },
      showFailedMessage: true,
      showSuccessMessage: true,
      needLoader: true,
      parent: "resendOTP",
    });
  };

  const handleOtpChange = (otpValue) => {
    if (/^\d*$/.test(otpValue)) {
      setOtp(otpValue);
      setIsError(false);
    }
  };
  return {
    otp,
    otpInputNumber,
    isError,
    loadingStatus,
    resendLoadingStatus,
    handleOtpChange,
    handleOtpSubmit,
    handleResendOTP,
  };
};

export default EmailVerifyContainer;
