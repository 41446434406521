import React from "react";
import LFBox from "../../../shared/LFBox";
import LFForm from "../../../shared/LFForm";
import LFFormFields from "../../../shared/LFFormFields";
import FormContainer from "../../../container/form.container";
import {
  choosePasswordAttribute,
  choosePasswordFormPath,
  defaultValues,
} from "../../../description/register/choosePassword.description";
import LFButton from "../../../shared/LFButton";
import ChoosePasswordContainer from "../../../container/register/choosePassword.container";
import LFGrid from "../../../shared/LFGrid";

const ChoosePassword = () => {
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: choosePasswordAttribute,
      formPath: choosePasswordFormPath,
      defaultValues,
    });

  const { loadingStatus, handlePasswordSubmit } = ChoosePasswordContainer({
    formData,
    formError,
    formPath: choosePasswordFormPath,
    validate,
  });

  return (
    <LFBox width="100%">
      <LFForm>
        <LFFormFields
          attributes={choosePasswordAttribute}
          formData={formData}
          formError={formError}
          handleChange={handleChange}
        />
        <LFGrid item xs={12}>
          <LFBox textAlign="center" width="100%">
            <LFButton
              type={"submit"}
              onClick={handlePasswordSubmit}
              variant={"outlined"}
              isLoading={loadingStatus}
              disabled={isFormError}
              sx={{ minWidth: 280, mt: { md: 15, sm: 10, xs: 5 }, mx: "auto" }}
            >
              NEXT
            </LFButton>
          </LFBox>
        </LFGrid>
      </LFForm>
    </LFBox>
  );
};

export default ChoosePassword;
