import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_ERROR_DATA } from "../../redux/constant";
import { keys, values } from "../../utils/javascript";
import ApiContainer from "../api.container";
import {
  LS_KEY_REGISTER_ID,
  LS_KEY_TOKEN,
  LS_KEY_USER,
  apiEndpoints,
  apiMethods,
  locationPath,
} from "../../utils/constant";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";

const ChoosePasswordContainer = ({
  formData,
  formError,
  formPath,
  validate,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPath } = useOutletContext();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const registerId = getLocalStorageItem(LS_KEY_REGISTER_ID);
  const user = getLocalStorageItem(LS_KEY_USER);
  const userObj = user ? JSON.parse(user) : {};

  useEffect(() => {
    setPath(locationPath?.emailSuccess);
    if (!registerId || !userObj?.id) {
      navigate(-1, { replace: true });
    }
  }, []);

  const { performRequest } = ApiContainer();

  const apiCall = async () => {
    const payload = {
      id: userObj?.id,
      password: formData?.password,
    };
    const response = await performRequest({
      endPoint: apiEndpoints.registerPassword,
      method: apiMethods.put,
      data: { ...payload },
      showFailedMessage: true,
      failedMessage: "Please choose a valid password",
      parent: formPath?.parent,
      needLoader: true,
    });

    setLocalStorageItem(LS_KEY_TOKEN, response?.data?.token);

    return navigate(locationPath?.registerBusiness);
  };

  const handlePasswordSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });
    if (values(validationErrors).some((error) => error)) return;

    return apiCall();
  };
  return { loadingStatus, handlePasswordSubmit };
};

export default ChoosePasswordContainer;
