import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  mainAccordion: ({ expanded }) => ({
    border: "1px solid #D9D9D9",

    "&.MuiPaper-root": {
      "& .MuiAccordionSummary-root": {
        margin: "0 40px 0 41px",
        padding: 0,
        paddingLeft: 24,
        ...(expanded && {
          borderBottom: `1px solid ${theme.palette.gray.dark}`,
        }),
        [theme.breakpoints.down("xsm")]: {
          minHeight: "53px",
          margin: "0 14px",
          padding: "0 0 0 16px",
        },
      },
      boxShadow: "none",
      backgroundColor: theme.palette.gray.main,
      "&.MuiAccordion-root": {
        borderRadius: "10px",
        "&::before": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
        "&.Mui-expanded": {
          margin: "0",
        },
      },

      "& .MuiAccordionSummary-content": {
        fontSize: 18,
        fontWeight: "bold",
        padding: "26px 0px",
        margin: 0,
        [theme.breakpoints.down("xsm")]: {
          fontSize: 16,
          fontWeight: 400,
          margin: 0,
          padding: 0,
        },
      },

      "& .MuiAccordionSummary-expandIconWrapper": {
        transition: "all 0.3s ease-in-out",
      },

      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: `rotate(-180deg)`,
      },

      "& .MuiDivider-root": {
        borderWidth: "1px",
        padding: "0px 12px",
      },

      "& .MuiAccordionDetails-root": {
        color: theme.palette.secondary.dark,
        padding: "35px 56px 35px 52px",
        "& .MuiTypography-root": {
          fontSize: 16,
        },

        [theme.breakpoints.down("xsm")]: {
          padding: "16px 10px 24px 12px",
          "& .MuiTypography-root": {
            fontSize: "14px",
          },
        },
      },
    },
  }),
}));

const LFAccordian = ({ title, id, children }) => {
  const mobileview = useMediaQuery("(max-width: 574px)");
  const [expanded, setExpanded] = useState(false); // Initially open on mount

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? !!panel : false);
    if (isExpanded && mobileview) {
      const mainLayoutId = document.getElementById("mainLayoutId");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          mainLayoutId.scrollTo({
            top: element.offsetTop - 50,
            behavior: "smooth",
          });
        }, 500);
      }
    }
  };

  const classes = useStyles({ expanded });
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange("panel")}
      className={classes.mainAccordion}
      key={id}
      id={id}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default LFAccordian;
