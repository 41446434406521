import { useLocation, useNavigate } from "react-router-dom";
import { calendarSyncMessages, locationPath } from "../../utils/constant";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_APP_DATA,
  SET_FORM_DATA,
  SET_SELECTED_BUSINESS_ID,
} from "../../redux/constant";
import { capitlizeWord, equal, ternary } from "../../utils/javascript";
import { removeLocalStorageItem } from "../../utils/localStorage";
import { newShiftFormPath } from "../../description/newShift/newShift.description";
import moment from "moment";
import { showToast } from "../../utils/toastService";

const MyCalendarContainer = ({ filterMenuObject, formPath }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const messageExists = queryParams.has("message");
  const messageFlag = queryParams.get("message");

  useEffect(() => {
    if (messageExists) {
      navigate(locationPath?.myCalendar, { replace: true });
      const { message, type } = { ...calendarSyncMessages?.[messageFlag] };
      showToast(message, type);
    } else if (location?.state?.fromVoucherPage) {
      showToast(location?.state?.message, "success");
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, []);

  const initailLoadingStatus = useSelector(
    (state) => state?.app?.loader?.businessDetailsLoader,
  );
  const businessDetail = useSelector(
    (state) => state?.globalAttr.businessDetail,
  );
  const businessType = useSelector((state) => state?.globalAttr?.businessType);
  const myCalendarState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );
  const selected = myCalendarState?.selectedId;
  const filterState = myCalendarState?.filterState;
  const [isOpen, setIsOpen] = useState(false);
  const selectdBusinessDetail = businessDetail?.find((detail) =>
    equal(detail?.id, selected),
  );
  const selectedBusinessId = selectdBusinessDetail?.id;

  useEffect(() => {
    if (businessType) {
      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.state]: {
            ...myCalendarState,
            ...(!selected && { selectedId: "all" }),
            filterState: {
              roles: { ...filterMenuObject[businessType]?.defaultValues },
              status: { ...filterMenuObject?.status?.defaultValues },
            },
            selectedDate: null,
          },
        },
      });
    }
  }, [businessType]);

  const toggleFilterButton = () => {
    setIsOpen(!isOpen);
  };

  const handlebusinessChange = (event) => {
    const { value } = event.target;
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...myCalendarState,
          selectedId: value,
        },
      },
    });
    if (!equal(value, "all"))
      dispatch({
        type: SET_SELECTED_BUSINESS_ID,
        payload: value,
      });
  };

  const addNewShiftClick = (selectedDate) => {
    removeLocalStorageItem("newShiftData");
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [newShiftFormPath?.parent]: {},
      },
    });

    const currentDate = moment();
    const selDate = moment(selectedDate).local();

    navigate(locationPath?.newShift, {
      state: {
        selectedDate: ternary(
          currentDate.diff(moment(selDate), "days") <= 0,
          selectedDate,
          null,
        ),
      },
    });
  };

  const selectBusinessAttribute = {
    name: "selectBusiness",
    type: "select",
    value: selected,
    variant: "outlined",
    options: [
      { value: "all", label: "All" },
      ...(businessDetail?.map((business) => ({
        value: business?.id,
        label: business?.name,
      })) || []),
    ],
    onChange: handlebusinessChange,
  };
  const selectBusinessLabel = capitlizeWord(businessType);
  const filterType = filterMenuObject[businessType];
  const filterStatus = filterMenuObject?.status;

  return {
    initailLoadingStatus,
    selectBusinessLabel,
    selectBusinessAttribute,
    filterType,
    filterStatus,
    filterState,
    addNewShiftClick,
    toggleFilterButton,
    isOpen,
    selectedBusinessId,
  };
};

export default MyCalendarContainer;
