import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LFDropDown from "../../shared/LFDropDown";
import { capitlizeWord, equal, length } from "../../utils/javascript";
import { SET_SELECTED_BUSINESS_ID } from "../../redux/constant";

const BusinessSelector = (props) => {
  const dispatch = useDispatch();
  const globalAttr = useSelector((state) => state?.globalAttr);
  const businessDetail = globalAttr?.businessDetail;
  const selected = globalAttr?.selectedBusinessId;

  const selectdBusinessDetail = businessDetail?.find((detail) =>
    equal(detail?.id, selected),
  );
  const handlebusinessChange = (event) => {
    dispatch({
      type: SET_SELECTED_BUSINESS_ID,
      payload: event?.target?.value,
    });
  };
  const selectBusinessAttribute = {
    name: "selectBusiness",
    label: capitlizeWord(selectdBusinessDetail?.type),
    type: "select",
    value: selected,
    options: businessDetail?.map((business) => ({
      value: business?.id,
      label: business?.name,
    })),
    onChange: handlebusinessChange,
  };

  return (
    <>
      {length(businessDetail) > 1 && (
        <LFDropDown
          variant="outlined"
          labelProps={{
            "&.MuiFormLabel-root": {
              transform: "scale(1)",
              fontSize: 14,
              marginLeft: 0,
              position: "relative",
            },
          }}
          selectSx={{
            "&.MuiInputBase-root": {
              marginTop: 1,
            },
          }}
          MenuProps={{
            sx: {
              "& .MuiButtonBase-root": {
                color: "primary.main",
              },
            },
          }}
          {...props}
          {...selectBusinessAttribute}
        />
      )}
    </>
  );
};

export default BusinessSelector;
