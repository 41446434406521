import React, { useEffect, useState } from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import LFButton from "../../shared/LFButton";
import LogoAvatar from "../reusable/LogoAvatar";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import logoutAction from "../../redux/action/logoutAction";
import { useDispatch, useSelector } from "react-redux";
import LFStack from "../../shared/LFStack";
import { SuccessIcon } from "../../shared/icons";
import { TextField } from "@mui/material";
import ApiContainer from "../../container/api.container";
import { equal } from "../../utils/javascript";
import Loader from "../reusable/Loader";

const useStyles = makeStyles((theme) => ({
  redeemButton: {
    marginTop: 64,
    "&.MuiButtonBase-root": {
      fontFamily: "'Poppins', 'sans-serif'",
      marginBottom: 0,
      width: "100%",
      paddingTop: "15.83px",
      paddingBottom: "15.83px",
      textTransform: "capitalize",
      backgroundColor: theme.palette.primary.main,
      borderRadius: 15,
      fontSize: 18,
      lineHeight: "26.76px",
      fontWeight: 600,
      color: theme.palette.common.white,
      boxShadow: "0px 0px 3.56px 0px #000000B2",
      "&.Mui-disabled": {
        background: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("xts")]: {
        width: "100%",
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: 14,
        lineHeight: "21px",
      },
    },
  },
  redeemOffer: {
    padding: "0px 36px 54px 36px",
    [theme.breakpoints.down("xts")]: {
      padding: "0px 25px 40px 25px",
    },
    "& .MuiTypography-h3 ": {
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 800,
      fontSize: 35,
      lineHeight: "41.71px",
      textAlign: "center",
      color: theme.palette.primary.main,
      marginBottom: 3,
      [theme.breakpoints.down("xts")]: {
        fontSize: 25,
        lineHeight: "30.3px",
      },
    },
    "& .MuiTypography-h4 ": {
      fontFamily: "'Work Sans', sans-serif",
      fontWeight: 500,
      fontSize: 21.33,
      lineHeight: "25.02px",
      textAlign: "center",
      color: theme.palette.dark.main,
      marginBottom: "17px",
      [theme.breakpoints.down("xts")]: {
        fontSize: 16,
        lineHeight: "18.77px",
        marginBottom: "11px",
      },
    },
    "& .MuiTypography-body1": {
      fontFamily: "'Work Sans', sans-serif",
      fontSize: "17.78px",
      lineHeight: "20.85px",
      textAlign: "center",
      color: "#7D7878",
      marginBottom: "25px",
      fontWeight: 500,
      [theme.breakpoints.down("xts")]: {
        fontSize: 12,
        lineHeight: "14.08px",
        marginBottom: "19px",
      },
    },
    "& .MuiTypography-body2": {
      maxWidth: 280,
      fontSize: "16px",
      lineHeight: "20.85px",
      textAlign: "center",
      color: theme.palette.primary.main,
      marginBottom: "30px",
      [theme.breakpoints.down("xts")]: {
        marginBottom: "36px",
      },
    },
  },
  formBox: {
    marginBottom: "50px",
    [theme.breakpoints.down("xts")]: {
      marginBottom: "30px",
    },
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 5,
    },
    "& .MuiInputBase-input": {
      padding: "5px 8px 6px 8px",
      "& :placeholder": {
        fontSize: "16px",
        lineHeight: "20px",
        fontWeight: 400,
      },
    },
    "& .MuiInputBase-root": {
      "& >.Mui-focused": {
        "& > .MuiOutlinedInput": {
          borderColor: "transparent",
        },
      },
      "& > .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #8D8D8D",
      },
    },
  },
  author: {
    marginTop: "31px",
    [theme.breakpoints.down("xts")]: {
      marginTop: "20px",
    },
    "& img": {
      height: 42,
      width: 42,
      [theme.breakpoints.down("xts")]: {
        height: "30px",
        width: "30px",
      },
    },
    "& .MuiTypography-h6": {
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      color: theme.palette.dark.main,
      [theme.breakpoints.down("xts")]: {
        fontSize: "12px",
        lineHeight: "14.4px",
      },
    },
    "& .MuiTypography-a ": {
      fontFamily: "'DM Sans', sans-serif",
      cursor: "pointer",
      color: theme.palette.white.contrastText,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      textDecoration: "underline",
      [theme.breakpoints.down("xts")]: {
        fontSize: "12px",
        lineHeight: "14.4px",
      },
    },
  },
}));

const RedeemVoucher = ({ handleRedeemAction, redeemSuccess, isLoading }) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [userLoader, setUserLoader] = useState(false);
  const [voucherCode, setVoucherCode] = useState("");
  const { performRequest } = ApiContainer();
  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);

  useEffect(() => {
    const loadUserProfile = async () => {
      setUserLoader(true);
      const response = await performRequest({
        endPoint: apiEndpoints?.userProfile,
        method: apiMethods?.get,
        tokenKey: "Authorization",
        showFailedMessage: true,
        needLoader: true,
      });
      if (equal(response?.statusCode, statusCodes?.OK)) {
        const { first_business, user } = { ...response.data };

        setUserData({
          first_name: user?.attributes?.first_name,
          surname: user?.attributes?.surname,
          logoUrl: first_business?.logo_url,
          businessName: first_business?.name,
        });
      }
      setUserLoader(false);
    };

    if (isAuthenticated) loadUserProfile();
  }, [isAuthenticated]);

  const classes = useStyles();
  return (
    <LFBox className={classes.redeemOffer}>
      {!redeemSuccess ? (
        <LFBox>
          <LFTypography variant="h3" component="h3">
            100% OFF
          </LFTypography>
          <LFTypography variant="h4" component="h4">
            Service Fees for 1 x shift
          </LFTypography>
          <LFTypography variant="body1">
            Enter your voucher code below to redeem
          </LFTypography>
          <LFBox className={classes.formBox}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              placeholder="Voucher code"
              disabled={isLoading}
              onChange={(e) => setVoucherCode(e.target.value)}
            />

            {userLoader ? (
              <Loader sx={{ mt: 2 }} />
            ) : (
              <LFStack direction="row" gap={"9.5px"} className={classes.author}>
                <LogoAvatar
                  name={userData?.businessName}
                  logoUrl={userData?.logoUrl}
                  sx={{ margin: 0, width: 42, height: 42, fontSize: 15.68 }}
                />
                <LFBox>
                  <LFTypography variant="h6" component="h6" textAlign="left">
                    {userData?.first_name} {userData?.surname}
                  </LFTypography>
                  <LFTypography
                    variant="a"
                    component="a"
                    onClick={() => dispatch(logoutAction())}
                  >
                    Sign out
                  </LFTypography>
                </LFBox>
              </LFStack>
            )}
          </LFBox>
        </LFBox>
      ) : (
        <LFBox textAlign="center">
          <LFBox sx={{ marginBottom: "24px" }}>
            <SuccessIcon />
          </LFBox>
          <LFTypography
            variant="body2"
            mb={"30px"}
            mt={1}
            sx={{ margin: "auto" }}
          >
            Voucher Code successfully applied to your account
          </LFTypography>
        </LFBox>
      )}
      <LFButton
        type="submit"
        color="white"
        variant="contained"
        isLoading={isLoading}
        onClick={() => handleRedeemAction(voucherCode)}
        className={classes.redeemButton}
      >
        {redeemSuccess ? "Done" : "Redeem Voucher"}
      </LFButton>
    </LFBox>
  );
};

export default RedeemVoucher;
