import { useState } from "react";
import LFBox from "../../../shared/LFBox";
import LFDropDown from "../../../shared/LFDropDown";
import FromActionButtons from "./FromActionButtons";
import { equal, ternary } from "../../../utils/javascript";

const DropDownForm = ({
  defaultValue,
  setCurrentForm,
  options = [],
  label,
  onSave,
  isNullEqualNA,
  ...rest
}) => {
  const [selectedOpt, setSelectedOpt] = useState(
    ternary(isNullEqualNA, defaultValue, defaultValue ?? ""),
  );
  const handleOnChange = (e) => {
    setSelectedOpt(e.target.value);
  };

  const handleSaveClick = () => {
    onSave(selectedOpt);
  };

  return (
    <LFBox>
      <LFDropDown
        {...rest}
        label={label}
        value={selectedOpt}
        onChange={handleOnChange}
        options={options}
        fullWidth
        displayEmpty // Display an empty item as a placeholder
        renderValue={(selOpt) => {
          if (isNullEqualNA) {
            const findAns = selOpt ? selOpt : null;
            return options.find((opt) => opt.value === findAns)?.label;
          }
          if (
            equal(selOpt, null) ||
            equal(selOpt, undefined) ||
            equal(selOpt, "")
          ) {
            return "Select option"; // Placeholder text
          } else {
            return options?.find((opt) => opt.value === selectedOpt)?.label;
          }
        }}
        shrink={!selectedOpt}
      />
      <FromActionButtons
        setCurrentForm={setCurrentForm}
        handleSaveClick={handleSaveClick}
        isDisabled={ternary(
          isNullEqualNA,
          false,
          equal(selectedOpt, null) ||
            equal(selectedOpt, undefined) ||
            equal(selectedOpt, ""),
        )}
      />
    </LFBox>
  );
};

export default DropDownForm;
