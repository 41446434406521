import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { locationPath } from "../../utils/constant";
import { equal } from "../../utils/javascript";

const types = {
  locum: "locum",
  business: "business",
};

const supportLayoutContainer = () => {
  const navigate = useNavigate();
  const { type: selectedType } = useParams();

  const [selectedSection, setSection] = useState(1);

  useEffect(() => {
    setSection(() => (equal(selectedType, types?.business) ? 7 : 1));
  }, [selectedType]);

  useEffect(() => {
    if (!selectedType) {
      navigate(locationPath?.supportType.replace(":type", types?.business), {
        replace: true,
      });
    }
  }, []);
  const typeButtonAttributes = [
    {
      label: "LOCUM",
      variant: "outlined",
      onClick: () =>
        navigate(locationPath?.supportType.replace(":type", types?.locum)),
      isActive: equal(selectedType, types?.locum),
    },
    {
      label: "BUSINESS",
      variant: "outlined",
      onClick: () =>
        navigate(locationPath?.supportType.replace(":type", types?.business)),
      isActive: equal(selectedType, types?.business),
    },
  ];

  const handleChangeSection = (id) => {
    setSection(id);
  };

  return {
    typeButtonAttributes,
    selectedType,
    selectedSection,
    handleChangeSection,
    types,
  };
};

export default supportLayoutContainer;
