import React from "react";
import BackButton from "../reusable/BackButton";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import LFTypography from "../../shared/LFTypograpy";
import LFContainer from "../../shared/LFContainer";
import LFStack from "../../shared/LFStack";
import { makeStyles } from "@mui/styles";
import MobileCopyright from "../reusable/MobileCopyright";
import { locationPath } from "../../utils/constant";

const useStyles = makeStyles(() => ({
  stepContainer: {
    boxSizing: "border-box",
    flex: 1,
    height: "100%",
  },
  mainHeading: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "32px",
    },
  },
}));
const AppLayout = ({ showBackButton = false, backPath = -1, pageHead }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const context = useOutletContext();
  const location = useLocation();
  const handleBackButtonClick = () => {
    navigate(backPath);
  };

  const showCopyright = !(
    location?.pathname?.includes("/view-shift/") ||
    location?.pathname?.includes(locationPath.newShiftAddDetails)
  );
  return (
    <LFContainer
      sx={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LFStack className={classes.stepContainer}>
        {showBackButton && (
          <BackButton path={backPath} onClick={handleBackButtonClick} />
        )}
        <LFTypography
          variant="h6"
          component="h6"
          color="primary"
          className={classes.mainHeading}
        >
          {pageHead}
        </LFTypography>
        <Outlet context={context} />
      </LFStack>
      {showCopyright && <MobileCopyright />}
    </LFContainer>
  );
};

export default AppLayout;
