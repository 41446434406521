import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import getShiftForMonthAction from "../../redux/action/getShiftForMonthAction";
import ApiContainer from "../api.container";
import { SET_APP_DATA } from "../../redux/constant";
import { ZERO, apiEndpoints, apiMethods } from "../../utils/constant";
import queryString from "query-string";
import { equal, keys, values } from "../../utils/javascript";

const CalendarHelperContainer = ({ formPath, businessId }) => {
  const dispatch = useDispatch();
  const shiftData = useSelector(
    (state) => state?.app?.data?.[formPath?.shiftData],
  );

  const selectedDayShifts = useSelector(
    (state) => state?.app?.data?.[formPath?.selectedDayShifts],
  );
  const shiftDataLoading =
    useSelector((state) => state?.app?.data?.[formPath?.shiftDataLoading]) ??
    false;

  const statusData =
    useSelector((state) => state?.app?.data?.[formPath?.statusData]) ?? {};
  const myCalendarState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );
  const selectedDate = myCalendarState?.selectedDate ?? null;
  const filterState = myCalendarState?.filterState;
  const [calendarHead, setCalendarHead] = useState(
    moment()?.format("MMMM YYYY")?.toUpperCase(),
  );

  const calendarRef = useRef(null);
  const calendarApi = () => calendarRef?.current?.getApi();

  const { performRequest } = ApiContainer();

  const filterHandler = (e, key) => {
    const { name } = e.target;
    const calendarData = calendarApi()?.currentData;
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...myCalendarState,
          filterState: {
            ...filterState,
            [key]: {
              ...filterState?.[key],
              [name]: !filterState?.[key]?.[name],
            },
          },
        },
      },
    });
    dispatch(
      getShiftForMonthAction({
        performRequest,
        formPath,
        businessId,
        selectedMounth: calendarData?.viewTitle,
      }),
    );
  };

  const displayShiftData = selectedDate ? selectedDayShifts : shiftData;

  const filteredShiftData = {};
  keys(displayShiftData)?.forEach((curDate) => {
    const filteredObj = filterDateShifts(
      displayShiftData?.[curDate],
      filterState,
    );

    if (keys(filteredObj).length > ZERO)
      filteredShiftData[curDate] = filteredObj;
  });

  useEffect(() => {
    if (selectedDate) {
      const responseDate = calendarApi();
      responseDate.gotoDate(selectedDate);
      setCalendarHead(moment(selectedDate)?.format("MMMM YYYY")?.toUpperCase());
      const paramsObj = queryString.stringify(
        { current_date: selectedDate, business_ids: [businessId] },
        { arrayFormat: "bracket" },
      );
      getSelectedDateShifts({ paramsObj, selectedDate });
    }
  }, [businessId]);

  useEffect(() => {
    const calendarData = calendarApi()?.currentData;

    if (calendarData) {
      dispatch(
        getShiftForMonthAction({
          performRequest,
          formPath,
          businessId,
          selectedMounth: calendarData?.viewTitle,
        }),
      );
    }
  }, [calendarHead, businessId]);

  const responseDate = calendarApi();

  const handlePrevClick = () => {
    responseDate?.prev();
    const mounth = responseDate?.currentData?.viewTitle;
    setCalendarHead(mounth?.toUpperCase());
  };

  const handleNextClick = () => {
    responseDate?.next();
    const mounth = responseDate?.currentData?.viewTitle;
    setCalendarHead(mounth?.toUpperCase());
  };

  const getSelectedDateShifts = async ({ paramsObj, selectedDate }) => {
    const currentDateShifts = await getCurDayShiftData({ paramsObj });

    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.selectedDayShifts]: {
          [selectedDate]: values(currentDateShifts)?.[ZERO],
        },
        [formPath?.shiftDataLoading]: false,
      },
    });
  };

  const handleCalenderDateClick = ({ info, eventClick }) => {
    let clickedDate = info?.dateStr;

    if (eventClick) {
      clickedDate = info?.event?.start;
      clickedDate = moment(clickedDate)?.format("YYYY-MM-DD");
    }

    const sendingObj = {
      current_date: clickedDate,
      business_ids: [businessId],
    };

    const paramsObj = queryString.stringify(
      { ...sendingObj },
      { arrayFormat: "bracket" },
    );

    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...myCalendarState,
          selectedDate: equal(clickedDate, selectedDate) ? null : clickedDate,
        },
        [formPath?.shiftDataLoading]: true,
      },
    });

    if (equal(clickedDate, selectedDate)) {
      dispatch(
        getShiftForMonthAction({
          performRequest,
          formPath,
          businessId,
          selectedMounth: responseDate?.currentData?.viewTitle,
        }),
      );

      return;
    }

    getSelectedDateShifts({ paramsObj, selectedDate: clickedDate });
  };

  const getCurDayShiftData = async ({ paramsObj }) => {
    const response = await performRequest({
      endPoint: `${apiEndpoints?.dailyShifts}?${paramsObj}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    return response?.data?.shifts;
  };

  return {
    calendarRef,
    calendarHead,
    shiftDataLoading,
    statusData,
    selectedDate,
    handleNextClick,
    handlePrevClick,
    handleCalenderDateClick,
    filteredShiftData,
    filterHandler,
  };
};

export default CalendarHelperContainer;

const filterDateShifts = (curDateShiftData, filterState) => {
  const filteredShiftData = {};
  keys(curDateShiftData)?.forEach((curKey) => {
    const finterStateArray = filterStateArrayFilter(
      curDateShiftData?.[curKey],
      filterState,
    );
    if (finterStateArray.length > ZERO)
      filteredShiftData[curKey] = finterStateArray;
  });

  return filteredShiftData;
};

const filterStateArrayFilter = (currentShiftArray, filterState) => {
  const finalFilteredArray = currentShiftArray?.filter((shift) => {
    const attributes = shift?.attributes;

    const role = filterState?.roles?.[attributes?.role_name];
    const status = filterState?.status?.[attributes?.status];
    return role && status;
  });
  return finalFilteredArray;
};
