export const signInAttributes = [
  {
    name: "email",
    type: "text",
    label: "Email Address",
    isRequired: true,
    pattern: "email",
    error: ["Email is required", "Please enter a valid email"],
    variant: "standard",
    fullWidth: true,
    md: 12,
    xs: 12,
  },
  {
    name: "password",
    type: "password",
    label: "Password",
    isRequired: true,
    error: ["Password is required"],
    showEyeIcon: true,
    autoComplete: "on",
    variant: "standard",
    fullWidth: true,
    md: 12,
    xs: 12,
  },
];

export const defaultValues = {
  email: "",
  password: "",
};

export const signInFormPath = {
  parent: "signIn",
};
