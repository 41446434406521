import { useDispatch, useSelector } from "react-redux";
import { equal, keys, values } from "../../utils/javascript";
import { SET_APP_DATA, SET_FORM_ERROR_DATA } from "../../redux/constant";
import { useEffect, useState } from "react";
import { showToast } from "../../utils/toastService";
import ApiContainer from "../api.container";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  LS_KEY_REGISTER_ID,
  LS_KEY_USER,
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";
import { getCurrentTimezone } from "../../utils/moment";

const RegisterContainer = ({ formData, formError, formPath, validate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPath } = useOutletContext();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const registerState = useSelector(
    (state) => state?.app?.data?.[formPath?.state],
  );

  const [showPage, setShowPage] = useState(false);
  const isChecked = registerState?.isChecked ?? false;

  useEffect(() => {
    if (
      getLocalStorageItem(LS_KEY_AUTHENTICATED) &&
      !getLocalStorageItem(LS_KEY_HALF_REGISTER)
    ) {
      navigate(locationPath?.myCalendar);
    } else {
      setShowPage(true);
    }
    setPath(null);
  }, []);

  const { performRequest } = ApiContainer();

  const handleTermsChange = () => {
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.state]: {
          ...registerState,
          isChecked: !isChecked,
        },
      },
    });
  };

  const apiCall = async () => {
    const payload = {
      first_name: formData?.firstname,
      surname: formData?.surname,
      email: formData?.email,
      timezone: getCurrentTimezone(),
    };
    const response = await performRequest({
      endPoint: apiEndpoints.register,
      method: apiMethods.post,
      data: { ...payload },
      parent: formPath?.parent,
      needLoader: true,
      showFailedMessage: true,
    });

    if (!equal(response?.statusCode, statusCodes?.OK)) return;

    if (response?.data?.user) {
      const { user } = response.data;
      setLocalStorageItem(
        LS_KEY_USER,
        JSON.stringify({ id: user?.id, email: user?.email }),
      );
      setLocalStorageItem(LS_KEY_REGISTER_ID, user?.id);
    }

    return navigate(locationPath?.emailVerify);
  };

  const handleRegisterSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });

    if (values(validationErrors).some((error) => error)) return;

    if (!isChecked)
      return showToast("Please agree to the Terms of use and client agreement");

    return apiCall();
  };
  return {
    showPage,
    loadingStatus,
    handleRegisterSubmit,
    isChecked,
    handleTermsChange,
  };
};

export default RegisterContainer;
