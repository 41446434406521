import React from "react";
import LFAccordian from "../../../shared/LFAccordian";
import LFStack from "../../../shared/LFStack";
import LFTypography from "../../../shared/LFTypograpy";
import LFBox from "../../../shared/LFBox";
import { useMediaQuery } from "@mui/material";
import { ternary } from "../../../utils/javascript";

const LocumAccordianCard = ({ cardData, id }) => {
  const mobileMatches = useMediaQuery("(max-width: 575px)");
  const { title, contentArray } = cardData;
  return (
    <LFAccordian title={title} id={id}>
      <LFStack
        sx={{
          gap: "20px",
          "& > .MuiStack-root:last-child:has( > img ) ": {
            marginBottom: 0,
          },
        }}
      >
        {contentArray?.map((curContent, index) => {
          const { type, content, contents } = { ...curContent };
          switch (type) {
            case "paragraph":
              return (
                <LFTypography sx={{ lineHeight: "20.83px" }} key={index}>
                  {content}
                </LFTypography>
              );
            case "numberList":
              return (
                <LFBox key={index}>
                  <LFTypography>{content?.title}</LFTypography>
                  <ol
                    style={{
                      paddingLeft: 26,
                      margin: "0",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px 0",
                    }}
                  >
                    {content?.list?.map((cur) => (
                      <li key={cur}>
                        <LFTypography fontSize={{ xs: "14px", xsm: "16px" }}>
                          {cur}
                        </LFTypography>
                      </li>
                    ))}
                  </ol>
                </LFBox>
              );
            case "image":
              return (
                <LFStack
                  key={index}
                  sx={{
                    margin: { xs: "12px 0", xsm: "50px 0" },
                    "& img": {
                      objectFit: "contain",
                      maxHeight: { xs: "auto", xsm: "306px" },

                      ...(!curContent?.mobileFullSize && {
                        maxWidth: { xs: 97, xsm: 147 },
                        margin: { xs: "0 auto", xsm: 0 },
                      }),
                    },
                  }}
                >
                  <img
                    src={ternary(mobileMatches && contents, contents, content)}
                    alt=""
                  />
                </LFStack>
              );
            case "imageGrp":
              return (
                <LFStack
                  key={index}
                  direction="row"
                  justifyContent="flex-start"
                  sx={{
                    gap: { xs: "40px", xsm: "80px" },
                    minHeight: { xs: "auto", xsm: 262 },
                    maxHeight: { xs: "auto", xsm: 262 },
                    justifyContent: { xs: "center", xsm: "left" },
                    margin: { xs: "12px 0", xsm: "50px 0" },
                    "& img": {
                      width: "100%",
                      maxWidth: { xs: 97, xsm: 147 },
                    },
                  }}
                >
                  {content?.map((curImg) => {
                    return <img key={String(curImg)} src={curImg} alt="" />;
                  })}
                </LFStack>
              );
            case "sideImgTextGroup":
              return (
                <LFStack
                  key={index}
                  direction={{ xs: "column", xsm: "row" }}
                  justifyContent="center"
                  sx={{
                    margin: { xs: "12px 0", xsm: "50px 0" },
                    "& img": {
                      maxWidth: { xs: 97, xsm: "100%" },
                      margin: "auto",
                    },
                  }}
                  gap={3}
                >
                  <img src={content.image} alt="" />
                  <LFStack alignItems="flex-start" gap={4.2}>
                    {content?.sideText?.map((curText) => (
                      <LFTypography key={curText}>{curText}</LFTypography>
                    ))}
                  </LFStack>
                </LFStack>
              );
          }
        })}
      </LFStack>
    </LFAccordian>
  );
};

export default LocumAccordianCard;
