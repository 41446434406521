import { useEffect, useState } from "react";
import ApiContainer from "../api.container";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { equal } from "../../utils/javascript";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ShiftLinkCardContainer = ({ shiftId, selectedUserId, businessId }) => {
  const navigate = useNavigate();
  const [shiftData, setShiftData] = useState({});
  const [notFound, setNotFound] = useState(false);
  const { performRequest } = ApiContainer();
  useEffect(() => {
    apiCall();
  }, []);
  const apiCall = async () => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.businessShifts}/${shiftId}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
    });
    if (!equal(statusCode, statusCodes?.OK)) return setNotFound(true);
    const {
      attributes: { business, start_time, end_time, rate },
    } = { ...data?.shift };
    setShiftData({
      businessName: business?.name,
      rate: `$${rate}/hr`,
      date: moment(start_time).format("ddd D MMM YYYY"),
      time: `${moment(start_time).format("ha")} - ${moment(end_time).format(
        "ha",
      )}`,
    });
  };
  const gotoShiftDetails = () => {
    return navigate(`${locationPath?.viewShift.replace(":shiftId", shiftId)}`, {
      state: { locumData: { id: selectedUserId }, businessId },
    });
  };

  return { shiftData, notFound, gotoShiftDetails };
};

export default ShiftLinkCardContainer;
