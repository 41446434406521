import { useNavigate } from "react-router-dom";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  locationPath,
} from "../utils/constant";
import { useEffect } from "react";
import { getLocalStorageItem } from "../utils/localStorage";

const LandingContainer = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      getLocalStorageItem(LS_KEY_AUTHENTICATED) &&
      !getLocalStorageItem(LS_KEY_HALF_REGISTER)
    ) {
      navigate(locationPath?.myCalendar);
    }
  }, []);

  const goToWelcomeLocumPage = () => {
    navigate(locationPath.welcomeLocum);
  };
  const goToWelcomeBusinessPage = () => {
    navigate(locationPath.welcomeBusiness);
  };
  return { goToWelcomeLocumPage, goToWelcomeBusinessPage };
};

export default LandingContainer;
