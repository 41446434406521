import { List, ListItem, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import LFBox from "../../shared/LFBox";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      paddingTop: 64,
      paddingBottom: 48,
      fontSize: 36,
      lineHeight: "41px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 32,
        paddingBottom: 40,
        fontSize: 28,
        lineHeight: "32px",
      },
      [theme.breakpoints.down("xts")]: {
        fontSize: 20,
        lineHeight: "20px",
        fontWeight: 400,
        paddingTop: 36,
        paddingBottom: 24,
      },
    },
  },

  textContainer: {
    maxWidth: 945,
    width: "100%",
    margin: [[0, "auto"]],
    padding: [[0, 13]],
    paddingBottom: 160,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 100,
    },
    [theme.breakpoints.down("xts")]: {
      paddingBottom: 75,
    },
    "& .MuiTypography-body1": {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      lineHeight: "150%",
    },
  },
  mtText: {
    "&.MuiTypography-root": {
      marginTop: 15,
    },
  },
  petaMenuMain: {
    "&.MuiList-root": {
      padding: 0,
      paddingLeft: 44,
      paddingTop: 35,
      display: "flex",
      flexDirection: "column",
      gap: [[15, 0]],
      [theme.breakpoints.down("sm")]: {
        padding: 0,
        paddingTop: 30,
        gap: [[12, 0]],
      },
      [theme.breakpoints.down("xts")]: {
        paddingTop: 20,
      },
      "& .MuiListItem-root": {
        padding: 0,
        alignItems: "start",
      },
    },
  },
  alphabeticList: {
    "&.MuiList-root": {
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: [[15, 0]],
    },
  },
  singleBoxWrapper: {
    marginTop: 44,
  },
  subPointHeadMain: {
    "& .MuiTypography-body1": {
      fontSize: 16,
      fontWeight: 700,

      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
    },
  },
  subPointHead: {
    "&.MuiTypography-body1": {
      textDecoration: "Underline",
      marginBottom: 18,
    },
  },
  subPointMainNumber: {
    "&.MuiTypography-root": {
      maxWidth: 44,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "unset",
        width: "unset",
        marginRight: 5,
        marginLeft: 6,
      },
    },
  },
  secondPara: {
    marginTop: 44,
    [theme.breakpoints.down("sm")]: {
      marginTop: 20,
    },
  },
  alphabeticListItem: {
    "&.MuiTypography-root": {
      marginTop: 12,
      paddingLeft: 88,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
        marginTop: 12,
      },
    },
  },
}));

const subPoint = [
  [
    {
      subPointMainNumber: "1",
      subPointHead: "REGISTRATION AND ACCOUNTS",
      spListItem: [
        {
          text: (
            <>
              Using the Website or the App requires You to first register Your
              details (which may include, without limitation, Your billing
              details) and an account will be set up for You
              <b style={{ marginLeft: 4 }}>(Account)</b>.
            </>
          ),
        },
        {
          text: "You must not register multiple Accounts.",
        },
        {
          text: "You agree to provide accurate, current and complete information in order to establish an Account. You agree tomaintain and update Your details when those details change. Your right to use the Services may be terminated or the system might not accept a Request if any of the information You provide is false, inaccurate or incomplete.",
        },
        {
          text: (
            <>
              Any personal information You provide to LOCUMFIND will be treated
              in accordance with LOCUMFIND’s privacy policy which is available
              at{" "}
              <Link
                to={"https://www.locumfind.com.au/privacy"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                www.locumfind.com.au/privacy.
              </Link>{" "}
            </>
          ),
        },
        {
          text: "Clients may use their Accounts to list details of the location of their practice. Any information a Client lists     using this service (including, without limitation, the name and contact details of the Client’s staff) will be  available only to Locums. LOCUMFIND is not responsible for and accepts no liability in relation to any requests   or queries from general users of the Website or the App who request quotes from Locums otherwise than through the Website or the App.",
        },
        {
          text: "Locums may use their Accounts to list details of their qualifications and may be asked questions to upload a copy of their curriculum vitae and verify their qualifications, registrations, licences, certifications, and/or approvals. A Locum must provide truthful, accurate and complete responses to the questions posed by LOCUMFIND, including in relation to its qualifications and any failure to do so may result in LOCUMFIND declining to create an account for the Locum, or LOCUMFIND cancelling the Locum’s account.",
        },
        {
          text: (
            <>
              Once an Account has been established for You, You will be provided
              with a “User ID” and a password <b>(Your Account Information</b>).
              Your Account Information is confidential and it is Your
              responsibility to maintain its security and confidentiality. You
              must not permit Your Account to be used by any person other than
              Yourself. You agree that You will be responsible for any
              unauthorised activity that occurs as a result of any use of Your
              account, including any use which is as a result of Your failure to
              keep Your Account Information confidential and secure.
            </>
          ),
        },
        {
          text: "You agree to notify LOCUMFIND immediately if You become aware of or suspect any unauthorised use of Your Account or Your Account Information or any other breach of security of Your Account. In that event, LOCUMFIND, in its absolute discretion, may suspend, terminate and/or establish a new Account for You.",
        },
        {
          text: "Your Account allows You to create Placement Requests and accept Quotes (if You are a Client) and respond to Placement Requests with a Quote (if You are a Locum).",
        },
        {
          text: "You acknowledge and agree that LOCUMFIND may refuse to register an Account for You for any reason or may suspend or terminate an Account, in its sole discretion and will incur no liability whatsoever and howsoever arising should it do so.",
        },
        {
          text: "You may delete Your Account with immediate effect by clicking the “delete account” button in the App [or on the Website]. ",
        },
      ],
    },
    {
      subPointMainNumber: "2",
      subPointHead: "TERMS OF SUPPLY OF WORK",
      spListItem: [
        {
          text: (
            <>
              By registering an Account as a Client, you agree to be bound by
              the terms of the Client Agreement which is available at{" "}
              <Link
                to={"https://www.locumfind.com.au/client-agreement"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                www.locumfind.com.au/client-agreement
              </Link>{" "}
              . The Client Agreement sets out the terms on which LOCUMFIND
              supplies Locums to the Client to carry out Work, including the
              payment of Fees. The terms of the Client Agreement prevail to the
              extent of any inconsistency between the terms of the Client
              Agreement and any term of these Terms and Conditions of Use.
            </>
          ),
        },
        {
          text: (
            <>
              By registering an Account as a Locum, you agree to be bound by the
              terms of the Locum Casual Employment Agreement which is available
              at{" "}
              <Link
                to={"https://www.locumfind.com.au/employee-agreement"}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                www.locumfind.com.au/employee-agreement
              </Link>{" "}
              . The Locum Casual Employment Agreement sets out the terms on
              which LOCUMFIND employs the Locum and places them with the Client
              to carry out Work. The terms of the Locum Casual Employment
              Agreement prevail to the extent of any inconsistency between the
              terms of the Locum Casual Employment Agreement and any term of
              these Terms and Conditions of Use.
            </>
          ),
        },
      ],
    },
    {
      subPointMainNumber: "3",
      subPointHead: "SUSPENSION AND TERMINATION",
      spListItem: [
        {
          text: "LOCUMFIND may, in its absolute discretion, suspend or terminate Your Account if:",
          listItems: [
            "Your Client Agreement or Locum Casual Employment Agreement (as applicable) is terminated;",
            "You breach or violate these Terms and Conditions of Use; or",
            "in LOCUMFIND’s sole opinion, Your ongoing use of the Services and/or Website and/or App will bring, or may bring, the reputation of LOCUMFIND into disrepute or cause LOCUMFIND to be in breach of an applicable law.",
          ],
        },
        {
          text: "LOCUMFIND may, in its absolute discretion, suspend or terminate Your Account if:",
        },
      ],
    },
    {
      subPointMainNumber: "4",
      subPointHead: "USE OF WEBSITE AND APP",
      spListItem: [
        {
          text: "You must use the Website, the App and the Services only for the purposes set out in these Terms and Conditions of Use and must not use the Website, the App and/or the Services:",
          listItems: [
            "in breach of any applicable laws or regulations or otherwise for any unlawful or improper purpose;",
            "in a way which causes, or is likely to cause injury or damage to the Website, the App, and/or the Software;",
            "in a way which, or might reasonably be expected to, interferes with, disrupts or creates an undue burden   on the Website, the App and/or the Software; or",
            "to encourage or procure the performance of any illegal activity by a Client, a Locum or a third party.",
          ],
        },
        {
          text: "You must not, and must not attempt to:",
          listItems: [
            "use the Website, the App or any of the Services to collect, or attempt to collect, personal information about third parties without their knowledge or consent or to engage in screen scraping, database scraping or any other activity with the purpose of obtaining lists of Clients or other data;",
            "copy the details of any Placement Request and post them on any other website or application or use   them for any purpose other than the purposes set out in these Terms and Conditions of Use;",
            "provide a Client’s details to any third party who does not have an Account, including for the purposes of that party providing services similar to the Work, or providing a quote for providing such services, to a Client;",
            "authorise any other person to do any act which would, if that act were to be done by You, infringe any of LOCUMFIND’s Intellectual Property Rights or any Intellectual Property Rights of LOCUMFIND’s licensors or any third parties;",
            "reverse engineer, decompile, disassemble, modify, translate, or otherwise uncover the source code of any software forming part of the Website and/or the App, including the Software;",
            "capture or copy any software on the Website and/or the App, including the Software, for any reason whatsoever (temporary copies for facilitating a technical computing process excepted);",
            "hack, infiltrate or otherwise do any thing which may compromise the Website, the App or the Software;",
            "cause LOCUMFIND to lose (in whole or in part) the services of its internet service provider(s) (ISP) or other suppliers;",
            "introduce any computer viruses, macro viruses, trojan horses, worms or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer or to surreptitiously intercept, access without authority or expropriate any system, data or personal information, to the Website or the App;",
            "send any unsolicited advertising or promotional material, commonly referred to as “spam”;",
            "use the Website, the App, the Software or any of the Services to create, host or transmit any defamatory, offensive or obscene material or engage in activities which would cause offence to, insult, humiliate or intimidate others including on the grounds of race, religion, creed or sex;",
            "use the Website, the App, the Software or any of the Services to harm, or attempt to harm, persons (including persons under the age of 18 years) in any way;",
            "use the Website, the App, or any of the Services to create, host or transmit unsolicited advertising material to other Clients;",
            "where You are a Locum, issue Quotes to undertake Work set out in a Placement Request when You are not or may not have the required capability, qualifications, licences, registrations or approvals to undertake the Work;",
            "where You are a Locum, encourage or facilitate a Client to engage with You otherwise than through the Website or App, for the purposes of avoiding payment of the Fee; or",
            "where You are a Client, encourage or facilitate a Locum to engage with You otherwise than through the Website or App, for the purposes of avoiding payment of the Fee.",
          ],
        },
        {
          text: "Without limiting the foregoing, LOCUMFIND will not be liable for any loss or damage caused by a distributed  denial-of-service attack, viruses or other technologically harmful material that may infect Your computer equipment, computer programs, data or other proprietary material due to Your use of the Website, the App or to your downloading of any material posted on them, or on any linked sites.",
        },
      ],
    },
    {
      subPointMainNumber: "5",
      subPointHead: "ACCESS TO WEBSITE, APP AND SERVICES",
      spListItem: [
        {
          text: "LOCUMFIND will attempt to make the Website, the App and the Services available for use 24 hours a day, seven days a week. However, it will be necessary for LOCUMFIND to take-down the Website, the App and cease providing the Services for a period of time for regular maintenance and to make improvements and if circumstances beyond LOCUMFIND’s control exist, such as problems preventing access to internet or web hosting services. LOCUMFIND will endeavour to carry out the maintenance and improvements outside of normal business hours, so as to minimise disruption to business. In such circumstances, LOCUMFIND will incur no liability to You for such interruption or cessation of Services no matter how such liability would otherwise arise.",
        },
        {
          text: "The Website, the App and the Services are made available only to persons located in the following Australian States: Victoria, New South Wales, Queensland, South Australia and Western Australia. You must not use the   Website, App or the Services in the Northern Territory, Australian Capital Territory or Tasmania, or in any jurisdiction outside of Australia, and any attempt to do so will be in breach of the terms of these Terms and Conditions of Use.",
        },
      ],
    },
    {
      subPointMainNumber: "6",
      subPointHead: "INTELLECTUAL PROPERTY",
      spListItem: [
        {
          text: (
            <>
              You acknowledge and agree that all rights in the Website, the App,
              the Software (including, code, interface and website and app
              structure) and all material comprised in the Website and the App
              (including, but not limited to, Intellectual Property Rights,
              text, images, web pages, sound and video, and the look and feel,
              design and compilation thereof) <b>(Content)</b>, excluding any
              content posted or uploaded by You, are owned by LOCUMFIND or the
              supplier of the relevant material. You grant LOCUMFIND an
              unrestricted, worldwide, royalty-free licence to use, reproduce,
              modify and adapt any content and information, (including your
              name, image and likeness) posted or uploaded on the Website or the
              App for the purpose of publishing material on the Website and the
              App and as otherwise may be required to provide the Services, for
              the general promotion of LOCUMFIND, and as permitted by these
              Terms and Conditions of Use. You represent and warrant that any
              content and information posted on the Website or App does not
              infringe any third party’s copyright, patent, trade mark, trade
              secret or other proprietary rights or intellectual property
              rights, rights of publicity, confidentiality or privacy. LOCUMFIND
              reserves the right (in its absolute discretion) to remove, without
              notice, any content or information posted by You to the Website or
              App for any reason.
            </>
          ),
        },
        {
          text: "You acknowledge and agree that You are permitted to use the Content only as set out in these Terms and Conditions of Use or as otherwise expressly authorised in writing by LOCUMFIND, and that You may not otherwise copy, reproduce, transmit, publicly perform, distribute, commercially exploit, adapt, translate, modify, bundle, merge, share or make available to any person, or create derivative works of the Content.",
        },
        {
          text: "Except where expressly stated to the contrary all persons (including their names and images), third party trade marks and content, services and/or locations featured on this Website are in no way associated, linked or affiliated with LOCUMFIND and You should not rely on the existence of such a connection or affiliation. Any trade marks/names featured on this Website are owned by the respective trade mark owners. Where a trade mark or brand name is referred to it is used solely to describe or identify the products and services and is in no way an assertion that such products or services are endorsed by or connected to LOCUMFIND.",
        },
      ],
    },
    {
      subPointMainNumber: "7",
      subPointHead: "WEBSITE AND APP MARKETING",
      spListItem: [
        {
          text: "LOCUMFIND may, from time to time, permit third parties to advertise their goods and services (Advertising) using the Website and/or the App. LOCUMFIND is not responsible for, and accepts no liability in relation to, any Advertising appearing on the Website and/or the App.",
        },
      ],
    },
    {
      subPointMainNumber: "8",
      subPointHead: "LIMITATION OF LIABILITY",
      spListItem: [
        {
          text: "Your use of the Website, the App, the Services and the Software is at Your sole risk.",
        },
        {
          text: "LOCUMFIND does not make any representation or warranty that any information or material provided or displayed on the Website or the App will be reliable, accurate or complete and LOCUMFIND does not accept any responsibility arising in any way from any error or omission in or from information or material on the Website or the App.",
        },
        {
          text: "From time to time, LOCUMFIND may restrict access to some parts or all of the Website or the App. LOCUMFIND will not be liable if for any reason the Website or App is unavailable at any time or for any period.",
        },
        {
          text: "To the extent permitted by law, all conditions, warranties and consumer guarantees (whether express, implied or statutory) relating to Your use of the Website, the App, the Software and the Services are excluded.",
        },
        {
          text: "To the extent that liability for breach of any implied warranty, term, condition or consumer guarantee cannot be excluded by law, liability for breach of that implied warranty, term, condition or consumer guarantee will be limited, at LOCUMFIND’s sole discretion, to any one or more of the following:",
          listItems: [
            "the supplying of the Services giving rise to the liability again; or",
            "the payment of the cost of having those Services supplied again.",
          ],
        },
        {
          text: "In relation to any express warranty, term, condition or consumer guarantee set out in these Terms and Conditions of Use in connection with the Services supplied or offered by LOCUMFIND, LOCUMFIND’s liability to You is limited to any amount paid by You (if any) in respect of those Services.",
        },
        {
          text: "To the extent permitted by law, LOCUMFIND will not be liable (whether in contract, tort or on any other basis in law or in equity) for any personal injury or any incidental, special, indirect or consequential loss or damage whatsoever, including damages for loss of profits, loss of data and business interruption arising out of or related to the use of, or inability to use, the Website, the App, the Software and/or the Services or which otherwise arises in connection with Your dealings with LOCUMFIND.",
        },
        {
          text: "LOCUMFIND will not be liable for any act, error or omission by a Client or Locum, including but not limited to, anything that results from a Client providing incorrect details on a Placement Request, or any incorrect details provided by a Locum to a Client in a Quote.",
        },
      ],
    },
    {
      subPointMainNumber: "9",
      subPointHead: "INDEMNITY",
      spListItem: [
        {
          text: "You indemnify LOCUMFIND and its directors, officers, employees, agents and contractors, and agree to keep each of them fully indemnified, from and against any loss, claim or damages (including, without limitation, any costs (including legal costs) on a full indemnity basis) suffered, incurred or brought against any one or more of them as a result of any breach by You of these Terms and Conditions of Use or arising out of any use by You of The Website, the App, the Software or the Services, including but not limited to any loss, claim or damages arising as a result (direct, indirect or otherwise) of any act, omission, failure or default by You or by anyone for whom You are legally responsible (vicariously or otherwise) in any way connected with Your access to the Website, the App or Your Account for or use of any of the Services.",
        },
      ],
    },
    {
      subPointMainNumber: "10",
      subPointHead: "THIRD PARTY SITES",
      spListItem: [
        {
          text: "You may link to the home page of the Website or the App, provided You do so in a way that is fair and legal and does not damage LOCUMFIND’s reputation or take advantage of it, but You must not establish a link in such a way as to suggest any form of association, approval or endorsement on LOCUMFIND’s part where none exists. You must not establish a link from any website that is not owned by You. This Website must not be framed on any other site, nor may You create a link to any part of the Website other than the home page. We reserve the right to withdraw linking permission without notice.",
        },
        {
          text: "The Website and/or the App may contain links to other websites or resources operated by parties other than   LOCUMFIND. References to any products, services, processes, trade names, trade marks, or other information of third parties does not imply or constitute an endorsement, sponsorship, association with or recommendation by LOCUMFIND. Links to third party sites not operated by LOCUMFIND are provided to You for Your convenience and/or reference only. You acknowledge and agree that LOCUMFIND does not control such sites and LOCUMFIND is not responsible for the content on those sites or the privacy of other practices of such sites. Your use of any linked sites will be subject to the terms of use and service contained within each such site. You further acknowledge and agree that LOCUMFIND shall not be responsible or liable, directly or indirectly, for any damage, loss or cost whatsoever caused or alleged to be caused by or in connection with use of or reliance on any such sites or the contents, goods or services available on or through any such site.",
        },
      ],
    },
    {
      subPointMainNumber: "11",
      subPointHead: "GENERAL",
      spListItem: [
        {
          text: "If any provision of these Terms and Conditions of Use is void, voidable, unenforceable or illegal, that provision will be read down so as to be valid and enforceable or, alternatively, will be severed from these Terms and Conditions of Use. The severance of any provision in accordance with this clause will not affect the validity or effectiveness of the remaining provisions of these Terms and Conditions of Use.",
        },
        {
          text: "No failure to exercise or any delay in exercising any right, power or remedy by a party operates as a waiver. A single or partial exercise of any right, power or remedy does not preclude any other or further exercise of that or any other right, power or remedy. A waiver is not valid or binding on the party granting that waiver unless made in writing.",
        },
        {
          text: "These Terms and Conditions of Use are governed by the laws of Victoria, Australia and the parties submit to the non-exclusive jurisdiction of the courts of that State.",
        },
      ],
    },
  ],
];

const SubPoint = ({ children, className, subPointList = [], ...props }) => {
  const isLargeScreen = useMediaQuery("@media screen and (min-width: 768px)");
  const classes = useStyles();
  return (
    <>
      {subPointList?.map((SP, spIndex) => {
        return (
          <LFStack
            className={`${classes.singleBoxWrapper} ${
              className ? className : ""
            }`}
            key={spIndex}
            {...props}
          >
            {children}
            <LFStack direction="row" className={classes.subPointHeadMain}>
              <LFTypography className={classes.subPointMainNumber}>
                {isLargeScreen && <>{SP.subPointMainNumber}</>}
                {!isLargeScreen && <>{`${SP.subPointMainNumber}.`}</>}
              </LFTypography>
              <LFTypography className={classes.subPointHead}>
                {SP.subPointHead}
              </LFTypography>
            </LFStack>
            <List className={classes.alphabeticList} marginTop="20px">
              {SP?.spListItem?.map((spList, spListIndex) => {
                return (
                  <LFStack key={spListIndex}>
                    <LFStack direction="row">
                      {isLargeScreen && (
                        <LFTypography maxWidth="44px" width="100%">
                          {" "}
                          {SP.subPointMainNumber != 7 &&
                            `${SP.subPointMainNumber}.${spListIndex + 1}`}
                        </LFTypography>
                      )}
                      <LFTypography>
                        {!isLargeScreen && (
                          <LFTypography display="inline" marginRight="10px">
                            {" "}
                            {SP.subPointMainNumber != 7 &&
                              `${SP.subPointMainNumber}.${spListIndex + 1}`}
                          </LFTypography>
                        )}
                        {spList.text}
                      </LFTypography>
                    </LFStack>
                    {spList?.listItems && (
                      <PetaPoint listItems={spList?.listItems} />
                    )}
                  </LFStack>
                );
              })}
            </List>
          </LFStack>
        );
      })}
    </>
  );
};

const PetaPoint = ({ className, listItems, ...props }) => {
  const classes = useStyles();
  const isLargeScreen = useMediaQuery("@media screen and (min-width: 768px)");
  return (
    <List
      className={` ${classes.petaMenuMain} ${className ? className : ""}`}
      {...props}
    >
      {listItems?.map((item, index) => (
        <ListItem key={index}>
          {isLargeScreen && (
            <LFTypography maxWidth="44px" width="100%">
              ({String.fromCharCode(97 + index)})
            </LFTypography>
          )}
          <LFTypography>
            {!isLargeScreen && (
              <LFTypography display="inline" marginRight="5px">
                ({String.fromCharCode(97 + index)})
              </LFTypography>
            )}
            {item}
          </LFTypography>
        </ListItem>
      ))}
    </List>
  );
};

const TermsAndConditions = () => {
  const { pathname } = useLocation();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  const TCuse = [
    {
      bText: "Account",
      text: "has the meaning given to that term in clause 1.1;",
    },
    {
      bText: "Advertising",
      text: "has the meaning given to that term in clause 7 ;",
    },
    {
      bText: "Content",
      text: "has the meaning given to that term in clause 6.1 ;",
    },
    {
      bText: "Client",
      text: "means any person who registers an Account as a Client and makes a Placement Request;",
    },
    {
      bText: "Fees",
      text: "means the fees set out in a Quote;",
    },
    {
      bText: "Intellectual Property Rights",
      text: "means copyright, patents, trade marks, designs and other statutory rights and all common law or equitable rights protecting business or personal reputation;",
    },
    {
      bText: "Locum",
      text: "means any person who is an employee, agent or contractor of LocumFind and whose services are provided by LocumFind to the Client;",
    },
    {
      bText: "Placement Request",
      text: "means a request by the Client for Work;",
    },
    {
      bText: "Quote",
      text: "means the document completed by a Locum and issued by LocumFind via the Website or App which will set out the Fees for performing the Work and any additional terms or stipulations for the Work set out in a Placement Request;",
    },
    {
      bText: "Work",
      text: "means the type of work specified in a Placement Request and Quote;",
    },
    {
      bText: "You",
      text: "means a Client or a Locum; and",
    },
    {
      bText: "Your Account Information",
      text: "has the meaning given to that term in clause 1.7 .",
    },
  ];
  const AppAllow = [
    "Client to request LOCUMFIND to supply a specified number of Locums, each of whom are suitable and qualified for performing the Work, by completing an online quote form;",
    "LOCUMFIND to advertise a Placement Request to Locums on the Website and/or App;",
    "a Locum to respond to a Placement Request advertised by LOCUMFIND with a Quote; ",
    "LOCUMFIND to issue the Quote to the Client;",
    "a Client to accept the Quote and pay the Fee; and",
    "LOCUMFIND to assign the Locum whose Quote was accepted to the Client to perform the Work in accordance with the Placement Request,",
  ];

  return (
    <>
      <LFBox className={classes.textContainer}>
        <LFTypography variant="h2" color="primary" className={classes.heading}>
          Terms and Conditions of use
        </LFTypography>
        <LFStack>
          <LFStack>
            <LFTypography>
              These Terms and Conditions of Use relate to the website{" "}
              <Link to={"https://www.locumfind.com.au/"} target="_blank">
                www.locumfind.com.au
              </Link>{" "}
              <b>(Website)</b> and the iOS and Android smartphone application
              “LocumFind” <b>(App)</b>. TheWebsite and the App are owned,
              operated and maintained by LOCUMFIND PTY LTD (ACN 669 232 795){" "}
              <b>(LOCUMFIND)</b>. The Website and App is designed to allow
              pharmacists, dispensary technicians, optometrists and pharmacy
              assistants to undertake Work as Locums, and allow medical
              practices and pharmacies to make Placement Requests for Work, in
              return for a Fee.
            </LFTypography>
            <LFTypography className={classes.mtText}>
              The Website and App allows:
            </LFTypography>
            <PetaPoint listItems={AppAllow}></PetaPoint>
            <LFTypography className={classes.alphabeticListItem}>
              (together, the <b>Services</b>).
            </LFTypography>
          </LFStack>
          <LFStack className={classes.secondPara}>
            <LFTypography>
              Your use of the Website, the App, the software used by LOCUMFIND
              on the Website and/or on the App (Software), and any information,
              materials and services provided on the Website and/or the App is
              subject to the terms and conditions set out in these Terms and
              Conditions of Use. You acknowledge and agree that LOCUMFIND may
              update, vary or amend these Terms and Conditions of Use from time
              to time and the most up-to-date version of these Terms and
              Conditions of Use is available at{" "}
              <Link to={"https://www.locumfind.com.au/terms"} target="_blank">
                www.locumfind.com.au/terms
              </Link>{" "}
              Updates, variations and amendments will be effective immediately
              once they are posted on this Website. You agree to be bound by
              these Terms and Conditions of Use as updated, varied or amended
              from time to time.
            </LFTypography>
            <LFTypography className={classes.mtText}>
              By accessing, registering for and/or using the Website, the App,
              or using the Services, You agree to be bound by these Terms and
              Conditions of Use.
            </LFTypography>
          </LFStack>
          <LFStack className={classes.singleBoxWrapper}>
            <List className={classes.alphabeticList}>
              {TCuse.map((data, tcUseIndex) => {
                return (
                  <LFTypography key={tcUseIndex}>
                    <b style={{ marginRight: 4 }}>{data.bText}</b>
                    {data.text}
                  </LFTypography>
                );
              })}
            </List>
          </LFStack>
          <LFStack>
            {subPoint.map((data1, subPointIndex) => {
              return (
                <SubPoint key={subPointIndex} subPointList={data1}></SubPoint>
              );
            })}
          </LFStack>
        </LFStack>
      </LFBox>
      <MobileCopyright />
    </>
  );
};

export default TermsAndConditions;
