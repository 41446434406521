import { Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  Switch: {
    "&.MuiSwitch-root": {
      padding: "0",
      height: 24,
      width: 40,
      borderRadius: 45,
      background: "#fff",
      "& .MuiButtonBase-root": {
        padding: 2,
        "&.Mui-checked": {
          left: -4,
          "& .MuiSwitch-thumb": {
            color: theme.palette.white.main,
          },
          "&+.MuiSwitch-track": {
            opacity: 1,
          },
        },
      },
    },
  },
}));

const LFSwitch = ({ className, ...props }) => {
  const classes = useStyles();
  return (
    <>
      <Switch
        focusVisibleClassName=".Mui-focusVisible"
        disableRipple
        {...props}
        className={`${classes.Switch} ${className}`}
      />
    </>
  );
};

export default LFSwitch;
