import React from "react";
import LFDatePicker from "../../shared/LFDatePicker";
import LFGrid from "../../shared/LFGrid";
import LFDatePickerField from "../../shared/LFDatePickerField";
import { dateStringToDate } from "../../utils/javascript";

const RecurringShift = ({
  startDate,
  endDate,
  onChange,
  onChangeDateValue,
  onDateChangeError,
}) => {
  return (
    <LFGrid container columnSpacing={3}>
      <LFGrid item md={3} xs={12}>
        <LFGrid container columnSpacing={3}>
          <LFGrid
            item
            md={12}
            xxsm={6}
            xs={12}
            mb={2}
            display="flex"
            direction={{ md: "column" }}
            gap={3}
            sx={{ marginTop: { md: "25px" } }}
          >
            <LFDatePickerField
              dateValue={startDate ? dateStringToDate(startDate) : null}
              name={"startDate"}
              onChange={onChangeDateValue}
              onError={onDateChangeError}
              label={"From"}
              minDate={new Date()}
              showCalendar={false}
              disableIconButton={true}
            />
            <LFDatePickerField
              dateValue={endDate ? dateStringToDate(endDate) : null}
              name={"endDate"}
              onChange={onChangeDateValue}
              onError={onDateChangeError}
              label={"To"}
              minDate={startDate}
              showCalendar={false}
              disableIconButton={true}
            />
          </LFGrid>
        </LFGrid>
      </LFGrid>
      <LFGrid item md={7} xs={12}>
        <LFDatePicker
          startDate={startDate}
          endDate={endDate}
          isRangePicker={true}
          onChange={onChange}
        />
      </LFGrid>
    </LFGrid>
  );
};

export default RecurringShift;
