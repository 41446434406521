import { useDispatch, useSelector } from "react-redux";
import { equal, keys, values } from "../utils/javascript";
import { SET_AUTHENTICATED, SET_FORM_ERROR_DATA } from "../redux/constant";
import ApiContainer from "./api.container";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  LS_KEY_TOKEN,
  LS_KEY_USER_ID,
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../utils/constant";
import { useNavigate } from "react-router-dom";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../utils/localStorage";
import { useEffect, useState } from "react";
import { getCurrentTimezone } from "../utils/moment";

const SignInContainer = ({
  formData,
  formError,
  validate,
  formPath,
  fromRedeemVoucher = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    if (
      getLocalStorageItem(LS_KEY_AUTHENTICATED) &&
      !getLocalStorageItem(LS_KEY_HALF_REGISTER) &&
      !fromRedeemVoucher
    ) {
      navigate(locationPath?.myCalendar);
    } else {
      setShowPage(true);
    }
  }, []);

  const { performRequest } = ApiContainer();

  const apiCall = async () => {
    const response = await performRequest({
      endPoint: apiEndpoints.signIn,
      method: apiMethods.get,
      queryParams: {
        email: formData?.email,
        password: formData?.password,
        timezone: getCurrentTimezone(),
      },
      showFailedMessage: true,
      showSuccessMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });

    if (!equal(response?.statusCode, statusCodes?.OK)) return;

    setLocalStorageItem(LS_KEY_TOKEN, response?.data?.token);
    setLocalStorageItem(LS_KEY_AUTHENTICATED, true);
    setLocalStorageItem(LS_KEY_USER_ID, response?.data?.user_id);
    dispatch({ type: SET_AUTHENTICATED, payload: true });

    if (equal(response?.data?.account_level, "password_choosen")) {
      setLocalStorageItem(LS_KEY_HALF_REGISTER, true);
      return navigate(locationPath?.registerBusiness, {
        state: { registerBusiness: true },
      });
    }
    if (fromRedeemVoucher) return;

    return navigate(locationPath?.myCalendar);
  };

  const handleSignInSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });

    if (values(validationErrors).some((error) => error)) return;

    return apiCall();
  };
  return { showPage, loadingStatus, handleSignInSubmit };
};

export default SignInContainer;
