import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, MenuItem } from "@mui/material";

const MessageMenu = ({ onDeleteMessage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDeleteClick = () => {
    onDeleteMessage();
    handleMenuClose();
  };

  return (
    <>
      <MoreVertIcon
        sx={{ cursor: "pointer" }}
        onClick={handleMenuClick}
        className="menuIcon"
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default MessageMenu;
