export const businessDetailAttribute = [
  {
    name: "name",
    label: "Business Name",
    type: "searchBusiness",
    isRequired: true,
    error: ["Business Name is required"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "telephone",
    label: "Telephone",
    type: "telephone",
    isRequired: true,
    pattern: "telephone",
    error: [
      "Telephone is required",
      "Please enter a valid 10-digit phone number with an acceptable area code (02, 03, 04, 07, or 08)",
    ],
    fullWidth: true,
    xs: 12,
    autoComplete: "off",
  },
  {
    name: "location",
    label: "Address",
    type: "address",
    isRequired: true,
    error: ["Address is required"],
    fullWidth: true,
    xs: 12,
  },
];

export const businessDetailFormPath = {
  parent: "businessDetail",
  initial: "load-business-detail",
  state: "businessDetailState",
};
