import LFStack from "../../shared/LFStack";
import LFSwitch from "../../shared/LFSwitch";
import { makeStyles } from "@mui/styles";
import LFFormLabel from "../../shared/LFFormLabel";
import { Divider, Typography } from "@mui/material";
import LFBox from "../../shared/LFBox";
import { keys, values } from "../../utils/javascript";
import LFChip from "../../shared/LFChip";
import { Fragment } from "react";
const chipAll = "All";

const useStyles = makeStyles((theme) => ({
  smallHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      marginBottom: 12,
    },
  },
  divider: {
    "&.MuiDivider-root": {
      margin: [[20, 0]],
      borderColor: theme.palette.gray.dark,
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      marginBottom: 20,
    },
  },
  switchBox: {
    "&.MuiStack-root": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& label": {
      fontSize: 16,
      color: theme.palette.primary.main,
    },
  },
  range: {
    fontSize: 14,
    color: theme.palette.secondary.dark,
  },
  darkLabel: {
    "&.MuiFormLabel-root": {
      color: theme.palette.dark.main,
    },
  },
  removeLastDivider: {
    "& .MuiDivider-root": {
      marginBottom: 12,
      "&:last-child": {
        display: "none",
      },
    },
  },
  subHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
      margin: [[8, 0]],
      color: theme.palette.dark.main,
    },
  },
  headingLabel: {
    "&.MuiTypography-root": {
      fontSize: 12,
      color: theme.palette.secondary.dark,
      display: "block",
      marginBottom: 8,
    },
  },
  chipContainer: {
    "&.MuiStack-root": {
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 8,
      "& .MuiChip-root": {
        fontSize: 12,
        height: "auto",
        maxHeight: "20px",
        background: "transparent",
        border: `1px solid ${theme.palette.gray.dark}`,
        "& span": {
          padding: [[2, 8]],
        },
      },
    },
  },
}));

const SkillsSlides = (props) => {
  const classes = useStyles();

  const name = props?.name;
  const skills = props?.skills;
  const switchState = props?.switchState;
  const switchToggle = props?.switchToggle;
  const chipToggle = props?.chipToggle;
  const skillsArray = props?.skillsArray;

  let skillsIdArray = [];
  values(skills)?.forEach((subRole) =>
    subRole?.forEach((cur) => skillsIdArray.push(cur.id)),
  );
  return (
    <Fragment key={name}>
      <LFStack className={classes.switchBox}>
        <LFFormLabel>{name}</LFFormLabel>

        <LFSwitch
          name={name}
          onChange={(e) => switchToggle({ e, skillsIdArray })}
          checked={switchState ?? false}
        />
      </LFStack>
      {switchState && (
        <>
          <LFBox>
            {keys(skills)?.map((skillName) => {
              const allChipIds = skills[skillName]?.map((cur) => cur.id);
              return (
                <LFBox className={classes.removeLastDivider} key={skillName}>
                  <Typography variant="h6" className={classes.subHeading}>
                    {skillName}
                  </Typography>
                  <Typography variant="span" className={classes.headingLabel}>
                    Select All that Apply
                  </Typography>
                  <LFStack className={classes.chipContainer}>
                    <LFChip
                      label={chipAll}
                      name={chipAll}
                      selected={
                        allChipIds?.every((element) =>
                          skillsArray?.includes(element),
                        ) ?? false
                      }
                      onClick={(e) => chipToggle({ e, allChipIds })}
                    />

                    {skills[skillName]?.map((subSkill) => {
                      return (
                        <LFChip
                          key={subSkill?.id}
                          label={subSkill?.name}
                          name={subSkill?.name}
                          id={subSkill?.id}
                          selected={
                            skillsArray?.includes(subSkill?.id) ?? false
                          }
                          onClick={(e) => chipToggle({ e, id: subSkill?.id })}
                        />
                      );
                    })}
                  </LFStack>
                  <Divider className={classes.divider} />
                </LFBox>
              );
            })}
          </LFBox>
        </>
      )}
      <Divider className={classes.divider} />
    </Fragment>
  );
};

export default SkillsSlides;
