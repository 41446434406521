export const locationPath = {
  landing: "/",
  welcome: "/welcome",
  welcomeBusiness: "/welcome/business",
  welcomeLocum: "/welcome/locum",
  signIn: "/signIn",
  register: "/register",
  emailVerify: "/email-verify",
  emailSuccess: "/email-success",
  registerPassword: "/register-password",
  registerBusiness: "/register-business",
  accountSuccess: "/account-success",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  myCalendar: "/calendar",
  profile: "/account/profile",
  searchLocum: "/search-locum",
  businessDetail: "/account/business-detail",
  account: "/account",
  settings: "/account/settings",
  settingNotification: "/setting/notification",
  settingSecurity: "/setting/security",
  settingsAccount: "/settings/account",
  calendarSync: "/settings/calendarSync",
  paymentMethod: "/account/payment/method",
  paymentInvoices: "/account/payment/invoices",
  paymentStatistics: "/account/payment/statistics",
  paymentTransactions: "/account/payment/transactions",
  changesSuccess: "/changes/success",
  accountPayment: "/account/payment",
  aboutUs: "/about",
  helpCenter: "/account/help-center",
  privacy: "/privacy",
  terms: "/terms",
  support: "/support",
  supportType: "/support/:type",
  contact: "/contact",
  newShift: "/new-shift",
  singleShift: "single",
  recurringShift: "recurring",
  urgentShift: "/urgent-shift",
  setUrgentShift: "/set-urgent-shift/:shiftId",
  roleAndTemplate: "/role-and-template",
  newShiftAddDetails: "/new-shift/add-details",
  locumDetails: "/locumDetails/:id",
  addShiftSuccess: "/add-shift/success",
  addTemplate: "/add-template",
  addTemplateSuccess: "/add-template/success",
  viewShift: "/view-shift/:shiftId",
  editShiftSuccess: "/edit-shift/success",
  cancelShift: "/cancel-shift/:shiftId",
  applicants: "/applicants/:shiftId",
  cancelShiftSuccess: "/cancel-shift-success",
  confirmApplication: "/confirm-application/:id",
  notifications: "/notifications",
  messages: "/messages",
  finaliseShift: "/view-shift/:shiftId/finalise-shift",
  clientAgreement: "/client-agreement",
  employeeAgreement: "/employee-agreement",
  deleteTemplate: "/delete-template",
  redeemVoucher: "/redeem",
};

export const apiEndpoints = {
  register: "auth/signup/business",
  registerPassword: "auth/set-password",
  emailVerify: "auth/verify-email-address",
  registerBusiness: "businesses",
  signIn: "auth/sign-in/business",
  forgotPassword: "auth/reset-password",
  resetPassword: "auth/reset-password-confirm",
  verifyToken: "auth/verify-token",
  userProfile: "users/profile",
  editProfile: "users/profile-edit",
  allBusiness: "businesses",
  editBusiness: "businesses",
  changePassword: "users/change-password",
  deleteAccount: "users/delete-account",
  resendOTP: "auth/resend-otp",
  accountStatus: "users/verify-statuses",
  roles: "roles",
  templates: "business/templates",
  skills: "skills",
  businessShifts: "business/shifts",
  searchLocums: "/business/locums/search",
  allShifts: "businesses/shifts",
  viewLocum: "/business/locums/",
  dailyShifts: "businesses/daily-shifts",
  notificationPermissions: "notification_permissions",
  notifications: "notifications",
  conversations: "conversations",
  addPaymentMethod: "business/payments/setup_payment_method",
  transactions: "businesses/:id/transactions",
  transactionsDownload: "businesses/:id/generate-transactions-excel",
  invoices: "businesses/:id/invoices",
  finalizePayment: "business/shifts/:id/finalize_payment",
  finalizeShift: "business/shifts/:id/finalize_shift",
  completePayment: "business/shifts/:id/complete_payment",
  statistics: "businesses/:id/statistics",
  retrievePaymentMethod: "business/payments/retrieve_payment_method",
  deleteTemplate: "business/templates",
  defaultBusinesses: "business/default_business_logos",
  publicHolidays: "public_holidays",
  allNotificationsRead: "notifications/mark-notifications-as-read",
  contactUsForm: "business/contact_inquiries",
  acceptLocumInvoice: "/business/shifts/:shiftId/invoice",
  calendarSync: "auth/google_oauth2",
  redeemVoucher: "business/vouchers/redeem",
};

export const apiMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  patch: "PATCH",
  delete: "DELETE",
};

export const statusCodes = {
  OK: 200,
  NON_AUTHORATIVE: 203,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  FORBIDDEN: 403,
};

export const appStoreURL =
  "https://apps.apple.com/us/app/locumfind/id6466252742";
export const playStoreURL =
  "https://play.google.com/store/apps/details?id=com.locumfind.mobile";

export const LS_KEY_USER = "user";
export const LS_KEY_TOKEN = "token";
export const LS_KEY_REGISTER_ID = "registerId";
export const LS_KEY_AUTHENTICATED = "isAuthenticated";
export const LS_KEY_HALF_REGISTER = "halfRegistrater";
export const LS_KEY_SHOW_MENU = "showMenu";
export const LS_KEY_NEWSHIFT_DATA = "newShiftData";
export const LS_KEY_TEMPLATES_DATA = "shiftTemplatesData";
export const LS_KEY_BUSINESS_TYPE = "businessType";
export const LS_KEY_BUSINESS_ID = "selectedBusinessId";
export const LS_KEY_USER_ID = "userId";

export const ZERO = 0;
export const DISPENSESOFTWARE = "Dispense Software";
export const SERVICES = "Services";
export const EXPERIENCE = "Experience";
export const SOFTWARE = "Software";
export const PRETESTING = "Pretesting";
export const shiftStatuses = {
  unfilled: "unfilled",
  pending: "pending",
  confirmed: "confirmed",
  paid: "paid",
  unsettled: "unsettled",
  modified: "modified",
  cancelled: "cancelled",
  unpaid: "unpaid",
};

export const pendingLevels = {
  confirmedEdit: "edited_after_confirmed",
  unpaidEdit: "edited_after_unpaid",
};

export const socialMedia = {
  linkedin: "http://linkedin.com/company/locumfind",
  twitter: "http://twitter.com/locumfindpty",
  instagram: "http://instagram.com/locumfindpty/",
  facebook: "http://facebook.com/locumfindpty/",
};

export const businessRoles = {
  pharmacy: "pharmacy",
  optometrist: "optometrist",
};

export const socketConstants = {
  channel: {
    conversations: "ConversationChannel",
    notification: "NotificationChannel",
  },
  type: {
    conversations: "conversations",
    notification: "notification",
  },
};

export const mainLayoutId = "mainLayoutId";

export const restrictedStates = [
  "Northern Territory",
  "Tasmania",
  "Australian Capital Territory",
];

export const australiaTimezones = {
  AWST: "Australia/Perth",
  ACST: "Australia/Adelaide",
  AEST: "Australia/Sydney",
  ACDT: "Australia/Adelaide",
  AEDT: "Australia/Sydney",
};

export const calendarSyncMessages = {
  success: {
    message:
      "Your Google Calendar has been successfully enabled. Now you can view your upcoming confirmed shifts in the calendar.",
    type: "success",
  },
  "already synchronized": {
    message:
      "The email has already been synchronized with another LocumFind account.",
    type: "info",
  },
};
