import { useNavigate } from "react-router-dom";
import { locationPath } from "../../utils/constant";
import { useState } from "react";
import { useDispatch } from "react-redux";
import logoutAction from "../../redux/action/logoutAction";

const HeaderContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menuToggle, setMenuToggle] = useState(false);

  const goToSignInPage = () => {
    navigate(locationPath.signIn);
  };

  const goToWelcomePage = () => {
    navigate(locationPath.welcomeBusiness);
  };

  const handleToggleMenu = () => {
    setMenuToggle(!menuToggle);
  };

  const handleSignOutClick = () => {
    dispatch(logoutAction(() => navigate(locationPath?.signIn)));
  };

  const NavButtons = [
    {
      label: "SIGN IN",
      onClick: goToSignInPage,
      variant: "outlined",
      color: "white",
    },
    {
      label: "REGISTER",
      onClick: goToWelcomePage,
      variant: "contained",
      color: "white",
    },
  ];
  const MobileNavLink = [
    {
      label: "About",
      link: locationPath?.aboutUs,
    },
    {
      label: "Register/Sign in",
      link: locationPath?.welcomeBusiness,
    },
    {
      label: "Support",
      link: locationPath?.support,
    },
    {
      label: "Contact",
      link: locationPath?.contact,
    },
    {
      label: "Terms",
      link: locationPath?.terms,
    },
    {
      label: "Privacy",
      link: locationPath?.privacy,
    },
  ];
  return {
    NavButtons,
    MobileNavLink,
    handleToggleMenu,
    menuToggle,
    handleSignOutClick,
  };
};

export default HeaderContainer;
