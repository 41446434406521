import moment from "moment";
import { ZERO } from "./constant";

export const equal = (val1, val2) => val1 === val2;

export const getObject = (array, key) => {
  return array.find((obj) => equal(obj?.name, key)) || {};
};

export const ternary = (bool, truthy, falsy) => (bool ? truthy : falsy);

export const length = (arr) => arr?.length;

export const head = (arr) => arr?.[ZERO];

export const last = (arr) => arr?.[length(arr) - 1];

export const lowerCase = (str) => str?.toLowerCase();

export const isArray = (obj) => Array?.isArray(obj);

export const keys = (obj = {}) => Object?.keys(obj);

export const values = (obj = {}) => Object?.values(obj);

export const isEmptyObject = (obj = {}) => keys(obj)?.length === ZERO;

export const isUndefined = (val) => val === undefined;

export const removeTimeStamp = (date) =>
  head(date?.toString()?.split("+")) ?? undefined;

export const capitlizeWord = (str) =>
  str?.slice(0, 1)?.toUpperCase() + str?.slice(1)?.toLowerCase();

export const dateStringToDate = (dateString) => {
  const dateObject = new Date(dateString);

  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObject.getFullYear();

  return `${day}/${month}/${year}`;
};

export const objectsAreSame = (obj1, obj2) => {
  if (keys(obj1)?.length !== keys(obj2).length) {
    return false; // Objects have different numbers of properties
  }

  return keys(obj1)?.every((key) => {
    if (
      typeof obj1?.[key] === "object" &&
      typeof obj2?.[key] === "object" &&
      obj1?.[key] !== null &&
      obj2?.[key] !== null
    ) {
      // If the property is an object, recursively compare its properties
      return objectsAreSame(obj1?.[key], obj2?.[key]);
    } else {
      return obj1?.[key] === obj2?.[key]; // Values are different
    }
  });
};

export function giveDifferenceInObjects(obj1, obj2) {
  const changes = {};

  keys(obj1)?.forEach((key) => {
    if (
      typeof obj1[key] === "object" &&
      obj1[key] !== null &&
      !objectsAreSame(obj1[key], obj2[key])
    ) {
      changes[key] = obj2[key];
    } else if (obj1[key] !== obj2[key]) {
      changes[key] = obj2[key];
    }
  });

  return changes;
}

export const hardCloneObj = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const convertToMinutes = (timeObject) => {
  const { value, ampm } = timeObject;
  const [hours, minutes] = value.split(":").map(Number);

  let militaryHours = hours;

  if (ampm?.toLowerCase() === "pm" && hours < 12) {
    militaryHours += 12;
  } else if (ampm?.toLowerCase() === "am" && hours === 12) {
    militaryHours = 0;
  }

  return militaryHours * 60 + minutes;
};

export const calculateTimeDifference = (time1, time2) => {
  const minutes1 = convertToMinutes(time1);
  const minutes2 = convertToMinutes(time2);

  let timeDiff = minutes2 - minutes1;

  if (timeDiff < 0) {
    timeDiff += 24 * 60;
  }

  const hoursDiff = Math?.floor(timeDiff / 60);
  const minutesDiff = timeDiff % 60;

  const formattedHours = hoursDiff?.toString();
  let formattedMinutes = minutesDiff?.toString()?.padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const displayCount = (count) => {
  return ternary(count > 99, "99+", count);
};

export const reduceSpaces = (inputString) => {
  // Use a regular expression to replace consecutive white spaces with a single space
  return inputString?.trim()?.replace(/\s+/g, " ");
};

export const copyrightText = () => {
  const yearString = equal(moment().year(), 2024)
    ? "2024 "
    : `2024-${moment().year()} `;
  return <>&copy; {yearString} LocumFind Pty Ltd </>;
};

export const roundToTwoDecimalPlaces = (number) => {
  const finalNum = Number(number);
  if (finalNum % 1 !== 0) return (Math.ceil(number * 100) / 100).toFixed(2);
  return Math.round(finalNum * 100) / 100;
};

export const calculateWorkingHours = (
  startTime,
  endTime,
  breakValueMins = 0,
) => {
  const totalTime = calculateTimeDifference(startTime, endTime);
  const [hours, mins] = totalTime.split(":");
  const totalMins = Number(hours) * 60 + Number(mins);

  const finalDiff = totalMins - breakValueMins;

  const hoursDiff = Math?.floor(finalDiff / 60);
  const minutesDiff = finalDiff % 60;

  const formattedHours = hoursDiff?.toString();
  const formattedMinutes = minutesDiff?.toString()?.padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const checkLessThan3Hours = (startTime, endTime, breakTime = 0) => {
  const finalWorkingHours = calculateWorkingHours(
    startTime,
    endTime,
    breakTime,
  );
  return Number(finalWorkingHours.split(":")?.[0]) < 3;
};

export const roundToOneDecimal = (number) => {
  // Multiply by 10, round to nearest integer, then divide by 10:
  return Math.round(number * 10) / 10;
};

export const formatName = (fullName) => {
  const nameParts = fullName.split(" ");
  if (nameParts.length < 2) return fullName;
  const firstName = nameParts[0];
  const lastNameInitial = nameParts[1].charAt(0);
  return `${firstName} ${lastNameInitial}.`;
};
