import { useNavigate } from "react-router-dom";
import { locationPath } from "../../utils/constant";

const BusinessUserContainer = () => {
  const navigate = useNavigate();
  const goToRegistrationPage = () => {
    navigate(locationPath?.register);
  };

  return { goToRegistrationPage };
};

export default BusinessUserContainer;
