import ProtectedRoute from "../auth/ProtectedRoute";
import AccountLayout from "../presentation/layout/AccountLayout";
import MainLayout from "../presentation/layout/MainLayout";
import { locationPath } from "../utils/constant";
import ChangesSuccess from "../presentation/account/ChangesSuccess";
import AppLayout from "../presentation/layout/AppLayout";
import MyCalendar from "../presentation/calendar";
import NewShift from "../presentation/newShift";
import UrgentShift from "../presentation/newShift/urgent";
import RoleAndTemplateSelection from "../presentation/newShift/roleAndTemplate";
import AddShiftDetails from "../presentation/newShift/addShiftDetail";
import NewShiftWrapper from "../presentation/newShift/NewShiftWrapper";
import SearchLocum from "../presentation/searchLocum";
import LocumDetails from "../presentation/locumDetails";
import AddShiftSuccess from "../presentation/newShift/addShiftDetail/AddShiftSuccess";
import AddTemplate from "../presentation/template/AddTemplate";
import AddTemplateSuccess from "../presentation/template/AddTemplateSuccess";
import ViewShift from "../presentation/viewShift";
import EditShiftSuccess from "../presentation/viewShift/EditShiftSuccess";
import ListApplicants from "../presentation/applicants";
import CancelShift from "../presentation/cancelShift";
import CancelShiftSuccess from "../presentation/cancelShift/ShiftCanceledDone";
import ConfirmApplication from "../presentation/confirmApplicant";
import Notifications from "../presentation/notifications";
import Messages from "../presentation/Messages";
import SettleUnpaidShift from "../presentation/viewShift/SettleUnpaidShift";
import Profile from "../presentation/account/profile";
import BusinessDetail from "../presentation/account/businessDetail";
import Settings from "../presentation/account/settings";
import Payments from "../presentation/account/payments";
import HelpCenterMenu from "../presentation/account/HelpCenterMenu";
import PaymentMethod from "../presentation/account/payments/PaymentMethod";
import Invoices from "../presentation/account/payments/invoices";
import Statistics from "../presentation/account/payments/statistics";
import Transactions from "../presentation/account/payments/transactions";
import DeleteTemplateSuccess from "../presentation/newShift/roleAndTemplate/DeleteTemplateSuccess";

const protectedRoutes = [
  {
    element: (
      <ProtectedRoute>
        <MainLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: locationPath?.account,
        element: <AccountLayout />,
        children: [
          {
            path: locationPath?.profile,
            element: <Profile />,
          },
          {
            path: locationPath?.businessDetail,
            element: <BusinessDetail />,
          },
          {
            path: locationPath?.settings,
            element: <Settings />,
          },
          {
            path: locationPath?.accountPayment,
            element: <Payments />,
            children: [
              {
                path: locationPath?.paymentMethod,
                element: <PaymentMethod />,
              },
              {
                path: locationPath?.paymentInvoices,
                element: <Invoices />,
              },
              {
                path: locationPath?.paymentStatistics,
                element: <Statistics />,
              },
              {
                path: locationPath?.paymentTransactions,
                element: <Transactions />,
              },
            ],
          },
          {
            path: locationPath?.helpCenter,
            element: <HelpCenterMenu />,
          },
        ],
      },
      {
        path: locationPath?.changesSuccess,
        element: <ChangesSuccess />,
      },
      {
        path: locationPath?.messages,
        element: <Messages />,
      },
      {
        path: locationPath?.myCalendar,
        element: <MyCalendar />,
      },
      {
        path: locationPath?.setUrgentShift,
        element: (
          <AppLayout
            pageHead={"Urgent Shift"}
            showBackButton={true}
            backPath={locationPath?.notifications}
          />
        ),
        children: [{ index: true, element: <UrgentShift /> }],
      },
      {
        path: locationPath?.viewShift,
        element: <AppLayout pageHead={""} showBackButton={false} />,
        children: [{ index: true, element: <ViewShift /> }],
      },
      {
        path: locationPath?.cancelShift,
        element: <AppLayout pageHead={""} showBackButton={true} />,
        children: [{ index: true, element: <CancelShift /> }],
      },
      {
        path: locationPath?.cancelShiftSuccess,
        element: <AppLayout pageHead={""} showBackButton={false} />,
        children: [{ index: true, element: <CancelShiftSuccess /> }],
      },
      {
        path: locationPath?.editShiftSuccess,
        element: <AppLayout pageHead={"Shift Details"} showBackButton={true} />,
        children: [{ index: true, element: <EditShiftSuccess /> }],
      },
      {
        path: locationPath?.applicants,
        element: <AppLayout pageHead={"Applicants"} showBackButton={true} />,
        children: [{ index: true, element: <ListApplicants /> }],
      },
      {
        path: locationPath?.finaliseShift,
        element: <AppLayout pageHead={""} showBackButton={true} />,
        children: [{ index: true, element: <SettleUnpaidShift /> }],
      },
      {
        path: locationPath?.confirmApplication,
        element: <ConfirmApplication />,
      },
      {
        element: <NewShiftWrapper />,
        children: [
          {
            path: locationPath?.newShift,
            element: (
              <AppLayout
                pageHead={"New Shift"}
                showBackButton={true}
                backPath={locationPath?.myCalendar}
              />
            ),
            children: [{ index: true, element: <NewShift /> }],
          },
          {
            path: locationPath?.urgentShift,
            element: <AppLayout pageHead={"New Shift"} showBackButton={true} />,
            children: [{ index: true, element: <UrgentShift /> }],
          },
          {
            path: locationPath?.roleAndTemplate,
            element: <AppLayout pageHead={"New Shift"} showBackButton={true} />,
            children: [{ index: true, element: <RoleAndTemplateSelection /> }],
          },
          {
            path: locationPath?.deleteTemplate,
            element: <AppLayout pageHead={""} showBackButton={true} />,
            children: [{ index: true, element: <DeleteTemplateSuccess /> }],
          },
          {
            path: locationPath?.newShiftAddDetails,
            element: <AppLayout pageHead={""} showBackButton={false} />,
            children: [{ index: true, element: <AddShiftDetails /> }],
          },
          {
            path: locationPath?.addShiftSuccess,
            element: (
              <AppLayout
                pageHead={"New Shift"}
                showBackButton={true}
                backPath={locationPath?.myCalendar}
              />
            ),
            children: [{ index: true, element: <AddShiftSuccess /> }],
          },
          {
            path: locationPath?.addTemplate,
            element: (
              <AppLayout pageHead={"Save Template"} showBackButton={true} />
            ),
            children: [{ index: true, element: <AddTemplate /> }],
          },
          {
            path: locationPath?.addTemplateSuccess,
            element: (
              <AppLayout pageHead={"Save Template"} showBackButton={true} />
            ),
            children: [{ index: true, element: <AddTemplateSuccess /> }],
          },
        ],
      },
      {
        path: locationPath?.searchLocum,
        element: <AppLayout pageHead={""} showBackButton={false} />,
        children: [{ index: true, element: <SearchLocum /> }],
      },
      {
        path: locationPath?.locumDetails,
        element: <AppLayout pageHead={""} showBackButton={false} />,
        children: [{ index: true, element: <LocumDetails /> }],
      },
      {
        path: locationPath?.notifications,
        element: <Notifications />,
      },
    ],
  },
];

export default protectedRoutes;
