import React from "react";
import { Tab, Tabs } from "@mui/material";
import LFBox from "../../shared/LFBox";
import { makeStyles } from "@mui/styles";
import theme from "../../themes/theme";

const useStyles = makeStyles(() => ({
  settingTab: {
    "& .MuiTabs-root": {
      minHeight: "unset",
    },
    "& .MuiTabs-flexContainer": {
      overflow: "auto",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        maxWidth: 520,
      },
    },
    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
      fontSize: 20,
      lineHeight: "26px",
      fontWeight: 400,
      padding: 0,
      margin: "0 30px 0 0",
      minHeight: "unset",
      "&:last-child": {
        margin: 0,
      },
      [theme.breakpoints.down("lg")]: {
        margin: "0 10px 0 0",
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 16,
        lineHeight: "22px",
      },
      "&.Mui-selected": {
        textDecoration: "underline",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
}));

const TabNavbar = ({ tabBarLink, activeTab, setActiveTab, className }) => {
  const classes = useStyles();
  const handleTabChange = (event, newValue) => {
    return setActiveTab(newValue);
  };
  return (
    <>
      {tabBarLink?.length ? (
        <LFBox
          mb={{ xxsm: 3, xs: 0 }}
          className={`${classes.settingTab} ${className ? className : ""}`}
        >
          <Tabs value={activeTab} onChange={handleTabChange}>
            {tabBarLink.map(({ label, value }, index) => (
              <Tab key={index} label={label} value={value} />
            ))}
          </Tabs>
        </LFBox>
      ) : null}
    </>
  );
};

export default TabNavbar;
