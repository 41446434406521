import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import ApiContainer from "../api.container";
import {
  equal,
  keys,
  reduceSpaces,
  ternary,
  values,
} from "../../utils/javascript";
import { SET_APP_DATA, SET_FORM_ERROR_DATA } from "../../redux/constant";
import {
  LS_KEY_NEWSHIFT_DATA,
  LS_KEY_TEMPLATES_DATA,
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { convertTimeTo24HourFormat } from "../../utils/moment";
import { newShiftFormPath } from "../../description/newShift/newShift.description";
import formDataAction from "../../redux/action/formDataAction";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";
import { useState } from "react";

const AddTemplateContainer = ({ formData, formError, formPath, validate }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  const [askOverride, setAskOverRide] = useState(false);

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const shiftData = state?.shiftData;
  const shiftPosted = state?.shiftPosted;
  const templatesList = JSON?.parse(getLocalStorageItem(LS_KEY_TEMPLATES_DATA));

  const { performRequest } = ApiContainer();
  const apiCall = async ({ isOverlap }) => {
    const {
      time,
      dispenseSoftware,
      pointOfSale,
      software,
      preTesting,
      ...formValues
    } = shiftData;
    const { startTime, endTime } = time || {};
    const start = `${startTime?.value} ${startTime?.ampm}`;
    const end = `${endTime?.value} ${endTime?.ampm}`;
    const start_time = convertTimeTo24HourFormat(start);
    const end_time = convertTimeTo24HourFormat(end);
    const is_overlap = isOverlap;
    const payload = {
      template: {
        ...formData,
        ...formValues,
        break: Number(formValues?.break),
        start_time,
        end_time,
        skill_ids: [dispenseSoftware, pointOfSale, software, preTesting].filter(
          (val) =>
            ternary(equal(val, null) || equal(val, undefined), false, true),
        ),
      },
      is_overlap,
    };
    const { statusCode, data } = await performRequest({
      endPoint: apiEndpoints.templates,
      method: apiMethods.post,
      data: payload,
      tokenKey: "Authorization",
      showFailedMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) {
      return;
    }

    if (!shiftPosted) {
      dispatch(
        formDataAction(
          { template_id: data?.template?.attributes?.id },
          newShiftFormPath?.parent,
          true,
        ),
      );

      dispatch({
        type: SET_APP_DATA,
        payload: {
          [newShiftFormPath?.parent]: {
            templateName: formData?.name,
            showAlert: true,
          },
        },
      });

      const preValues = getLocalStorageItem(LS_KEY_NEWSHIFT_DATA);

      setLocalStorageItem(
        LS_KEY_NEWSHIFT_DATA,
        JSON.stringify({
          ...(preValues && JSON.parse(preValues)),
          template_id: data?.template?.attributes?.id,
        }),
      );
    }

    const finalTemplates = templatesList?.filter(
      (curTemp) => curTemp?.id !== data?.template?.id,
    );

    finalTemplates?.push(data?.template);

    setLocalStorageItem(LS_KEY_TEMPLATES_DATA, JSON.stringify(finalTemplates));
    return navigate(locationPath?.addTemplateSuccess, {
      state: { showTemplateSuccess: true, shiftPosted },
    });
  };

  const handleTemplateSubmit = (event, isOverlap) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData)?.forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });

    const isTemplateAlredyThere = templatesList?.some((template) =>
      equal(
        reduceSpaces(template?.attributes?.name),
        reduceSpaces(formData?.name),
      ),
    );

    if (isTemplateAlredyThere && !isOverlap) {
      return setAskOverRide(true);
    }

    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });
    if (values(validationErrors).some((error) => error)) return;

    return apiCall({ isOverlap });
  };

  const handleOnCloseScreen = () => {
    setAskOverRide(false);
  };

  const dialogActions = [
    {
      label: "Yes",
      onClick: (event) => {
        handleOnCloseScreen();
        handleTemplateSubmit(event, true);
      },
      variant: "outlined",
    },
    {
      label: "No",
      onClick: handleOnCloseScreen,
      variant: "contained",
    },
  ];
  return {
    loadingStatus,
    handleTemplateSubmit,
    askOverride,
    dialogActions,
  };
};

export default AddTemplateContainer;
