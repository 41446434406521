import { showToast } from "./toastService";
import { restrictedStates } from "./constant";
import { equal, ternary } from "./javascript";
import {
  emailValidation,
  notEmpty,
  passwordValidation,
  telephoneValidation,
} from "./regex";

const dropdownValidation = (val) => {
  return ternary(equal(val, "select"), false, notEmpty(val));
};

const confirmPasswordValidation = (val, password) => {
  return equal(val, password);
};

const validation = (pattern, value, password) => {
  switch (pattern) {
    case "notEmpty":
      return notEmpty(value);

    case "email":
      return emailValidation(value);

    case "password":
      return passwordValidation(value);

    case "notEmptyDropdown":
      return dropdownValidation(value);

    case "telephone":
      return telephoneValidation(value);

    case "confirmPassword":
      return confirmPasswordValidation(value, password);

    default:
      return true;
  }
};

export const validateAddress = (address) => {
  if (!(address?.latitude && address?.longitude)) {
    showToast("Please select a valid address from the options.");
    return false;
  }

  if (address?.state && restrictedStates?.includes(address?.state)) {
    showToast(
      "Apologies, our services are not available in Northern Territory, Tasmania, and Australian Capital Territory states at this time",
    );
    return false;
  }
  return true;
};

export default validation;
