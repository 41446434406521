import { apiEndpoints, apiMethods } from "../../utils/constant";
import { ZERO } from "../../utils/constant";
import { equal } from "../../utils/javascript";
import {
  SET_GLOBAL_ROLES,
  CHANGE_SKILLS_LOADING,
  SET_SKILLS_DATA,
} from "../constant";

const getRolesAction =
  ({ performRequest, needSkills = false }) =>
  async (dispatch) => {
    dispatch({ type: CHANGE_SKILLS_LOADING, payload: true });
    const response = await performRequest({
      endPoint: apiEndpoints?.roles,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    const roles = response?.data?.roles;

    dispatch({
      type: SET_GLOBAL_ROLES,
      payload: roles,
    });

    if (needSkills || roles.length !== ZERO) {
      const skillsApiCall = async (rolesArr) => {
        const calls = rolesArr?.map((role) => {
          const name = role?.attributes?.name;
          const id = role?.attributes?.id;
          return loadRoleSkills({ id, name, performRequest });
        });
        try {
          const roleSkillsArray = await Promise.all(calls);
          return roleSkillsArray;
        } catch (error) {
          console.log(error);
        }
      };
      const skillsData = await skillsApiCall(roles);
      const pharmacy = {};
      const optometrist = {};

      skillsData?.forEach((item) => {
        if (equal(item.id, 4)) {
          optometrist[item?.name] = item?.skills;
        } else {
          pharmacy[item?.name] = item?.skills;
        }
      });

      dispatch({
        type: SET_SKILLS_DATA,
        payload: { pharmacy, optometrist },
      });
    }

    dispatch({ type: CHANGE_SKILLS_LOADING, payload: false });
  };

export default getRolesAction;

function transformSkillsArrayToObject(skillsArray) {
  const resultObject = {};
  skillsArray?.forEach((skill) => {
    const { id, attributes } = skill;
    const { name } = attributes;
    const type = attributes?.type;

    if (!resultObject[type]) {
      resultObject[type] = [];
    }

    resultObject[type].push({ id, name });
  });
  return resultObject;
}

const loadRoleSkills = async ({ id, name, performRequest }) => {
  const response = await performRequest({
    endPoint: apiEndpoints?.skills,
    method: apiMethods?.get,
    tokenKey: "Authorization",
    showFailedMessage: true,
    queryParams: { role_id: id },
  });
  const skills = transformSkillsArrayToObject(response?.data?.skills);

  return { name, skills, id };
};
