import React from "react";
import LFGrid from "./LFGrid";

const LFForm = ({
  children,
  onSubmit,
  spacing = 2.5,
  className,
  columnSpacing,
  rowSpacing,
}) => (
  <form onSubmit={onSubmit} className={className}>
    <LFGrid
      container
      spacing={spacing}
      columnSpacing={columnSpacing}
      rowSpacing={rowSpacing}
      className={className}
    >
      {children}
    </LFGrid>
  </form>
);

export default LFForm;
