import { locationPath } from "../../utils/constant";
import { equal, isArray, ternary } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const addNotificationAction =
  (notification, formPath, location) => (dispatch, getState) => {
    const notifcationsData = getState()?.app?.data?.[formPath?.parent];
    const unread_notifications_count =
      getState()?.app?.data?.[formPath?.newcounts];

    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: [
          notification.attributes,
          ...ternary(isArray(notifcationsData), notifcationsData, []),
        ],
        [formPath?.newcounts]: ternary(
          equal(location.pathname, locationPath.notifications),
          0,
          unread_notifications_count + 1,
        ),
      },
    });
  };

export default addNotificationAction;
