import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import setShiftValuesAction from "../../redux/action/setShiftValuesAction";
import ApiContainer from "../api.container";
import { useEffect } from "react";
import { locationPath } from "../../utils/constant";
import { SET_SELECTED_BUSINESS_ID } from "../../redux/constant";

const ListApplicantConatainer = ({ formPath }) => {
  const dispatch = useDispatch();
  const { shiftId } = useParams();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const shiftData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );
  const shiftFormData = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );

  const applicantsData = shiftData?.applicants;
  const businessData = shiftData?.business;
  const roleName = shiftData?.roleName;
  const shiftStatus = shiftData?.status;

  const { performRequest } = ApiContainer();

  useEffect(() => {
    dispatch(setShiftValuesAction({ performRequest, shiftId, formPath }));
  }, []);

  const locumCardAttributes = {
    buttons: [
      {
        label: "ACCEPT APPLICATION",
        classes: "",
        link: `${locationPath?.confirmApplication}?shiftId=${shiftId}`,
        linkState: {
          confirmApllication: true,
          shiftStartDate: shiftFormData?.start_date,
        },
        variant: "outlined",
      },
      {
        label: "SEND MESSAGE",
        classes: "",
        link: locationPath?.messages,
        onClick: () =>
          dispatch({
            type: SET_SELECTED_BUSINESS_ID,
            payload: businessData?.id,
          }),
      },
      {
        label: "VIEW PROFILE",
        classes: "",
        link: locationPath?.locumDetails,
      },
    ],
    showAboutSection: false,
  };
  const businessDetails = {
    id: businessData?.id,
    logo_url: businessData?.logo_url,
    name: businessData?.name,
    address: businessData?.location?.address,
  };

  const shiftDateObj = {
    startDate: shiftFormData?.start_date,
    endDate: shiftFormData?.end_date,
  };

  return {
    loadingStatus,
    applicantsData,
    locumCardAttributes,
    businessDetails,
    roleName,
    shiftStatus,
    shiftDateObj,
  };
};

export default ListApplicantConatainer;
