import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import LFStack from "../../shared/LFStack";
import {
  calculateTimeDifference,
  calculateWorkingHours,
  equal,
  keys,
  removeTimeStamp,
  ternary,
  values,
} from "../../utils/javascript";
import { ReactComponent as EditIcon } from "../../assets/svg/editIcon.svg";
import { makeStyles } from "@mui/styles";
import { notEmpty } from "../../utils/regex";
import { shiftStatuses } from "../../utils/constant";
import ShiftDetailForm from "./ShiftDetailForm";
import classNames from "classnames";
import { useMediaQuery } from "@mui/material";
import moment from "moment/moment";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    borderRadius: 10,
    backgroundColor: theme.palette.gray.main,
    padding: [[4, 32]],
    textAlign: "center",
    justifyContent: "space-between",
    gap: 20,
    height: "100%",
    [theme.breakpoints.down("xsm")]: {
      padding: 0,
      backgroundColor: "transparent",
    },
  },
  subRow: {
    "&.MuiStack-root": {
      position: "relative",
      paddingRight: 24,
      "&>svg": {
        minWidth: 24,
        position: "absolute",
        right: 0,
        top: "50%",
        transform: "translateY(-50%)",
        transition: "all .4s ease-in-out",
      },
      textAlign: "left",
      padding: [[14, 0]],
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      alignItems: "center",
      [theme.breakpoints.down("xsm")]: {
        padding: [[8, 0]],
      },
      "&:last-child": {
        border: 0,
        "&>.MuiStack-root": {
          flexDirection: "column",
          alignItems: "start",
        },
        "& p": {
          width: "100%",
        },
        "& .MuiStack-root": {
          width: "100%",
        },
      },
    },
  },
  label: {
    "&.MuiTypography-root": {
      width: "55%",
      marginRight: 10,
      fontSize: 14,
      flex: "0 0 auto",
      [theme.breakpoints.down("xsm")]: {
        color: theme.palette.secondary.dark,
        width: "100%",
      },
    },
  },
  secondRow: {
    "&.MuiStack-root": {
      alignItems: "center",
      flex: 1,
      [theme.breakpoints.down("xsm")]: {
        alignItems: "start",
        flexDirection: "column",
      },
    },
    "& p": {
      lineHeight: "18px",
      [theme.breakpoints.down("xsm")]: {
        color: theme.palette.dark.main,
      },
    },
  },
  popUpForm: {
    position: "absolute",
    padding: [[24, 32]],
    top: -4,
    maxWidth: 300,
    borderRadius: 10,
    backgroundColor: theme.palette.gray.main,
    width: "100%",
    boxSizing: "border-box",
    cursor: "auto",
    transition: "all .4s ease-in-out",
    visibility: "hidden",
    zIndex: -1,
    right: 0,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  boxOpen: {
    right: -356,
    visibility: "visible",
  },
  activeForm: {
    "&.MuiStack-root": {
      "&>svg": {
        transform: "translateY(-50%) rotate(180deg)",
      },
    },
  },
  lastRow: {
    top: "unset",
    bottom: 0,
  },
  disable: {
    "& .MuiTypography-root, & svg": {
      opacity: 0.5,
    },
  },
}));

const ShiftDetailList = ({
  currentForm,
  setCurrentForm,
  formData,
  detailListAttributes,
  shiftStatus,
  roleId,
  formPath,
  viewDetails,
  businessId,
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width: 991px)");
  const handleFieldClick = (event, name) => {
    setCurrentForm(ternary(equal(currentForm, name), null, name));
  };
  const isEditable = (name, shiftStatus) => {
    const eventMoment = moment(removeTimeStamp(formData?.end_date));
    const currentMoment = moment();

    if (
      equal(shiftStatus, shiftStatuses.paid) ||
      (equal(shiftStatus, shiftStatuses.unpaid) && formData?.payment_failed)
    )
      return false;

    if (
      (currentMoment?.isAfter(eventMoment) ||
        equal(shiftStatus, shiftStatuses.unpaid)) &&
      !(equal(name, "time") || equal(name, "break")) &&
      viewDetails
    )
      return false;

    return true;
  };
  const lastfieldsCount = 5;

  return (
    <LFBox className={classes.mainBox}>
      {values(detailListAttributes)?.map(
        ({ label, desc, name, options }, index) => {
          const liftPopover =
            index > keys(detailListAttributes)?.length - lastfieldsCount;
          const isDisabled = ternary(
            currentForm && !equal(currentForm, name),
            true,
            false,
          );
          return (
            <LFStack
              key={label}
              direction="row"
              style={{
                ...(isEditable(name, shiftStatus) && { cursor: "pointer" }),
              }}
              className={classNames(classes.subRow, {
                [classes.activeForm]: equal(currentForm, name),
                [classes.disable]: isDisabled,
              })}
            >
              <LFStack
                direction="row"
                className={classes.secondRow}
                {...(isEditable(name, shiftStatus) && {
                  onClick: (event) => handleFieldClick(event, name),
                })}
              >
                <LFTypography className={classes.label}>
                  {label}
                  {equal(name, "time") &&
                    formData?.time_zone_abbreviation &&
                    ` (${formData?.time_zone_abbreviation})`}
                </LFTypography>
                <LFTypography sx={{ color: "secondary.dark" }}>
                  {prettifyValueToString(
                    formData?.[name],
                    name,
                    options,
                    formData,
                  ) ?? desc}
                </LFTypography>
              </LFStack>
              {isEditable(name, shiftStatus) && (
                <EditIcon
                  cursor={"pointer"}
                  onClick={(event) => handleFieldClick(event, name)}
                />
              )}
              {!matches && (
                <LFBox
                  className={classNames(classes.popUpForm, {
                    [classes.lastRow]: liftPopover,
                    [classes.boxOpen]: equal(currentForm, name),
                  })}
                >
                  <ShiftDetailForm
                    formName={name}
                    formPath={formPath}
                    setCurrentForm={setCurrentForm}
                    defaultValue={formData?.[name]}
                    detailListAttributes={detailListAttributes}
                    formData={formData}
                    roleId={roleId}
                    key={currentForm === name && currentForm}
                    businessId={businessId}
                  />
                </LFBox>
              )}
            </LFStack>
          );
        },
      )}
    </LFBox>
  );
};

export default ShiftDetailList;

const prettifyValueToString = (value, name, options, formData) => {
  if (equal(value, null) || equal(value, undefined)) {
    return null;
  }
  if (options) {
    return options?.find((obj) => equal(obj?.value, value))?.label;
  }
  switch (name) {
    case "time":
      return formatTimeObject({ time: value, breakValue: formData?.break });

    case "break":
      return `${value} mins`;

    case "rate": {
      const totalCharge = getTotalRate({
        value,
        time: formData?.time,
        timeBreak: formData?.break,
      });
      const finalString = totalCharge
        ? `$${value}/hr (Total $${Math.round(totalCharge)})`
        : `$${value}/hr`;
      return ternary(value, `${finalString}`, null);
    }
    default:
      return ternary(notEmpty(value), value, null);
  }
};

const formatTimeObject = ({ time = {}, breakValue = 0 }) => {
  const { startTime, endTime } = time;
  if (!startTime || !endTime) return null;

  const start = `${removeZeroFromTimeString(startTime?.value)}${
    startTime?.ampm
  }`;
  const end = `${removeZeroFromTimeString(endTime?.value)}${endTime?.ampm}`;

  return `${start} - ${end}  (${calculateWorkingHours(
    startTime,
    endTime,
    breakValue,
  )}hrs)`;
};

const convertToTotalMinutes = (timeString) => {
  const [hours, minutes] = timeString.split(":").map(Number);

  const totalMinutes = hours * 60 + minutes;
  return totalMinutes;
};

const getTotalRate = ({ value, time, timeBreak = "00:00" }) => {
  if (!!value && !!time) {
    const { startTime, endTime } = time;
    const timeString = calculateTimeDifference(startTime, endTime);

    const totalMinutes = convertToTotalMinutes(timeString);
    const totalBreakMinutes = timeBreak;

    const earnings = ((totalMinutes - totalBreakMinutes) / 60) * value;

    return earnings;
  }
  return null;
};

const removeZeroFromTimeString = (timeString) => {
  const timeArray = timeString.split(":");
  timeArray[0] = Number(timeArray[0]);
  return timeArray.join(":")?.replace(":00", "");
};
