import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import LFButton from "../../shared/LFButton";
import { Link } from "react-router-dom";
import { locationPath } from "../../utils/constant";
import BusinessUserContainer from "../../container/welcome/businessUser.container";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  register: {
    color: theme.palette.white.main,
    fontWeight: 700,
    display: "table",
    margin: "0 auto",
  },
}));

const BusinesUser = () => {
  const classes = useStyles();
  const { goToRegistrationPage } = BusinessUserContainer();
  return (
    <LFBox
      textAlign="center"
      mt={{ msm: 10.5, xs: 5 }}
      minHeight={{ xs: 279, xxsm: 409 }}
    >
      <LFTypography
        variant="h2"
        component="h2"
        color="white.main"
        mb={2}
        fontWeight={400}
        fontSize={{ xxsm: 60, xs: 30 }}
      >
        Let’s get started
      </LFTypography>
      <LFTypography color="white.main" mb={{ md: 7, xs: 4 }} fontSize={18}>
        Registering takes just 5 minutes
      </LFTypography>
      <LFButton
        variant="contained"
        color="white"
        sx={{ minWidth: 220, mb: 3, width: { xxs: "auto", xs: "100%" } }}
        onClick={goToRegistrationPage}
      >
        REGISTER
      </LFButton>
      <LFTypography color="white.main" mb={1}>
        Already have an account?
      </LFTypography>
      <Link to={locationPath.signIn} className={classes.register}>
        Sign in
      </Link>
    </LFBox>
  );
};

export default BusinesUser;
