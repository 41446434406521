import React, { useState } from "react";
import BusinessFrameOuter from "../../assets/svg/landingPageImg.svg";
import LandingFrame from "../../assets/png/LandingFrame.png";
import WebPreviewFrame from "../../assets/svg/WebPreviewFrame.svg";
import mobilePreviewFrame from "../../assets/svg/mobilePreviewFrame.svg";

import LandingContainer from "../../container/landing.container";
import LFBox from "../../shared/LFBox";
import LFButton from "../../shared/LFButton";
import LFContainer from "../../shared/LFContainer";
import LFGrid from "../../shared/LFGrid";
import LFTypography from "../../shared/LFTypograpy";
import LFTabs from "../../shared/LFTab";
import { makeStyles } from "@mui/styles";
import LFChip from "../../shared/LFChip";
import { useMediaQuery } from "@mui/material";
import LFSlider from "../../shared/LFSlider";
import { SwiperSlide } from "swiper/react";
import MobileCopyright from "../reusable/MobileCopyright";
import { topLocumsData } from "../../description/landing/landing.description";
import theme from "../../themes/theme";

const useStyles = makeStyles((theme) => ({
  teamBox: {
    backgroundColor: theme.palette.gray.main,
    borderRadius: 10,
    padding: "0 24px 24px 24px",
    marginTop: 60,
    boxSizing: "border-box",
    height: "calc(100% - 60px)",
    "& img": {
      borderRadius: "100%",
      width: 120,
      height: 120,
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: {
        width: 96,
        height: 96,
      },
    },
  },
  teamBoxInfo: {
    marginTop: 8,
    textAlign: "center",
  },
  teamBoxCategory: {
    borderTop: `1px solid ${theme.palette.gray.dark}`,
    marginTop: 15,
    paddingTop: 15,
    "& .MuiChip-root": {
      marginRight: 5,
      marginBottom: 5,
      backgroundColor: theme.palette.gray.dark,
      color: theme.palette.white.main,
      height: "auto",
      padding: "3px 8px",
      fontSize: 12,
      "& .MuiChip-label": {
        padding: 0,
      },
    },
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const [tab, setTab] = useState("pharmacists");
  const { goToWelcomeBusinessPage, goToWelcomeLocumPage } = LandingContainer();
  const application = [
    {
      label: "PHARMACISTS",
      value: "pharmacists",
    },
    {
      label: "DISPENSARY TECHNICIANS",
      value: "dispensaryTechnicians",
    },
    {
      label: "PHARMACY ASSISTANTS",
      value: "pharmacyAssistants",
    },
    {
      label: "OPTOMETRISTS",
      value: "optometrists",
    },
  ];

  const topLocums = topLocumsData?.[tab];
  const TeamBox = ({ locumData }) => {
    const { profile, role, name, rate, skills } = locumData;
    return (
      <>
        <LFBox className={classes.teamBox}>
          {
            <LFBox
              mt={-7.5}
              display="inline-block"
              width="100%"
              textAlign="center"
            >
              {<img src={profile} alt="" />}
            </LFBox>
          }
          <LFBox className={classes.teamBoxInfo}>
            <LFTypography
              variant="h5"
              component="h5"
              color="primary"
              fontSize={{ md: 24, xxsm: 20, xs: 18 }}
            >
              {name}
            </LFTypography>
            <LFTypography
              variant="body1"
              component="p"
              color="secondary.dark"
              fontSize={{ xxsm: 18, xs: 14 }}
            >
              {role}
            </LFTypography>
            <LFTypography
              variant="body1"
              component="p"
              fontSize={{ xxsm: 18, xs: 14 }}
            >
              ${rate}/hr
            </LFTypography>
          </LFBox>
          <LFBox className={classes.teamBoxCategory}>
            {skills?.map((data, i) => {
              return <LFChip key={i} label={data} selected={true} />;
            })}
          </LFBox>
        </LFBox>
      </>
    );
  };

  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <LFBox
        backgroundColor="primary.main"
        py={5}
        minHeight={{ xxsm: "calc(100vh -  113px)", xs: "unset" }}
        display="flex"
        alignItems="center"
      >
        <LFContainer>
          <LFGrid container spacing={3} alignItems="center">
            <LFGrid item md={6} xs={12}>
              <LFBox textAlign="center">
                <img src={BusinessFrameOuter} alt="Pharmacy illustration" />
              </LFBox>
            </LFGrid>
            <LFGrid item md={6} xs={12}>
              <LFBox
                textAlign={{ md: "left", xs: "center" }}
                maxWidth={{ md: "auto", xs: 550 }}
                margin={{ md: 0, xxsm: "72px auto 0", xs: "20px auto 0" }}
              >
                <LFTypography
                  variant="h3"
                  component="h3"
                  color="secondary"
                  fontSize={{ xsm: 44, xs: 24 }}
                  fontWeight={500}
                  mb={2}
                >
                  Well connected
                </LFTypography>
                <LFTypography
                  variant="h5"
                  component="h5"
                  color="white.main"
                  fontSize={{ xsm: 24, xs: 14 }}
                  mb={3}
                >
                  LocumFind is your local talent partner that connects
                  Businesses and Locums.
                </LFTypography>
                <LFButton
                  variant="contained"
                  color="white"
                  onClick={goToWelcomeLocumPage}
                  sx={{
                    minWidth: 200,
                    width: { xts: "fit-content", xs: "100%" },
                  }}
                >
                  GET STARTED
                </LFButton>
              </LFBox>
            </LFGrid>
          </LFGrid>
        </LFContainer>
      </LFBox>
      <LFBox py={{ md: 5, xs: 7.5 }} mb={{ xsm: 0, xs: -25 }}>
        <LFContainer>
          <LFGrid
            container
            spacing={3}
            alignItems="center"
            flexDirection="row-reverse"
          >
            <LFGrid item xsm={6} xs={12}>
              <LFBox textAlign={{ xsm: "left", xs: "center" }}>
                <LFTypography
                  variant="h3"
                  component="h3"
                  color="secondary"
                  fontSize={{ xsm: 36, xs: 24 }}
                  fontWeight={500}
                  mb={2}
                >
                  For Locums
                </LFTypography>
                <LFTypography
                  variant="h5"
                  component="h5"
                  color="primary.main"
                  mb={3}
                  fontSize={{ xsm: 20, xs: 14 }}
                >
                  Find your next shift, get paid on time every time and keep
                  track of all your finances in one easy to use app.
                </LFTypography>
                <LFButton
                  variant="contained"
                  color="primary"
                  onClick={goToWelcomeLocumPage}
                  sx={{ minWidth: 200, marginBottom: { xts: 0, xs: 3 } }}
                >
                  GET STARTED
                </LFButton>
              </LFBox>
            </LFGrid>
            <LFGrid item xsm={6} xs={12}>
              <LFBox textAlign="center">
                <img src={LandingFrame} alt="iPhone_X_locumFind" />
              </LFBox>
            </LFGrid>
          </LFGrid>
        </LFContainer>
      </LFBox>
      <LFBox
        backgroundColor="primary.main"
        pt={{ md: "117px", xxsm: 7.5, xs: 6 }}
        position="relative"
      >
        <LFContainer>
          <LFGrid container columnSpacing={{ md: 5, xs: 3 }}>
            <LFGrid item md={5} xs={12}>
              <LFBox
                textAlign={{ md: "left", xs: "center" }}
                maxWidth={{ md: "auto", xs: 550 }}
                margin={{ md: 0, xxsm: "0 auto 72px", xs: "0 auto 48px" }}
                pt={{ md: 5.25, xs: 0 }}
              >
                <LFTypography
                  variant="h3"
                  component="h3"
                  color="secondary"
                  fontSize={{ xsm: 40, xs: 20 }}
                  fontWeight={500}
                  mb={2}
                >
                  For Businesses
                </LFTypography>
                <LFTypography
                  variant="h5"
                  component="h5"
                  color="white.main"
                  fontSize={{ xsm: 20, xs: 14 }}
                  mb={3}
                >
                  Post shifts for Locums to apply for and keep everything
                  organised in one easy to manage dashboard.
                </LFTypography>
                <LFButton
                  variant="contained"
                  color="white"
                  onClick={goToWelcomeBusinessPage}
                  sx={{
                    minWidth: 200,
                    width: { xts: "fit-content", xs: "100%" },
                  }}
                >
                  GET STARTED
                </LFButton>
              </LFBox>
            </LFGrid>
            <LFGrid item md={7} xs={12}>
              <LFBox sx={{ "& img": { width: "100%" } }}>
                <img
                  src={matches ? mobilePreviewFrame : WebPreviewFrame}
                  alt="Web Preview"
                />
              </LFBox>
            </LFGrid>
          </LFGrid>
          <LFBox
            borderTop="1px solid white"
            pt={{ xxsm: 5, xs: 3 }}
            pb={{ xxsm: 5 }}
            mt={{ xxsm: 5, xs: 3 }}
          >
            <LFBox textAlign="center" sx={{ maxWidth: 465, margin: "0 auto" }}>
              <LFTypography
                variant="h6"
                component="h6"
                color="white.main"
                fontSize={{ xxsm: 20, xs: 14 }}
                sx={{
                  maxWidth: { xts: "100%", xs: 260 },
                  margin: "0 auto 24px auto",
                }}
                lineHeight="normal"
              >
                Find experienced Locums who meet your pharmacy needs.
              </LFTypography>
              <LFTypography
                color="white.main"
                variant="h4"
                component="h4"
                fontSize={{ lg: 36, xxsm: 28, xs: 24 }}
              >
                Top Locums in your area
              </LFTypography>
            </LFBox>
            <LFBox
              backgroundColor={{ xxsm: "white.main" }}
              borderRadius={2.5}
              p={{ xxsm: "31.4px 0" }}
              mt={4}
            >
              <LFTabs
                list={application}
                value={tab}
                onChange={(e, newValue) => setTab(newValue)}
                variant="scrollable"
                sx={{
                  "& .MuiTabs-flexContainer": {
                    display: "block",
                    textAlign: "center",
                    "& button": {
                      [theme.breakpoints.between("xxs", "md")]: {
                        padding: "11px 12px",
                        fontSize: 12,
                      },
                    },
                  },
                }}
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
              />
              <LFBox
                mt={{ xxsm: 6, xs: 3 }}
                mx={{ xxsm: 0, xs: -2 }}
                backgroundColor={{ xxsm: "transparent", xs: "white.main" }}
                py={{ xxsm: 0, xs: 2.5 }}
                sx={{
                  "& .swiper-wrapper": {
                    p: { xts: 0, xs: "20px 00px 20px 20px" },
                  },
                  "& .swiper-slide": { height: "auto" },
                }}
              >
                <LFSlider
                  spaceBetween={20}
                  breakpoints={{
                    0: {
                      slidesPerView: 1.5,
                    },
                    480: {
                      slidesPerView: 3.4,
                    },
                    625: {
                      slidesPerView: 3.25,
                    },
                    1099: {
                      slidesPerView: 3.15,
                    },
                  }}
                  key={tab}
                >
                  {[...topLocums, ...topLocums]?.map((locumData, i) => {
                    return (
                      <SwiperSlide key={`${locumData.name}${i}`}>
                        <TeamBox locumData={locumData} />
                      </SwiperSlide>
                    );
                  })}
                </LFSlider>
              </LFBox>
            </LFBox>
          </LFBox>
        </LFContainer>
      </LFBox>
      <MobileCopyright fontColor={"primary.main"} />
    </>
  );
};

export default LandingPage;
