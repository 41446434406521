import { useSelector } from "react-redux";

const ConfirmOverlapContainer = ({
  handleConfirmClick,
  handleCancelClick,
  formPath,
}) => {
  const isLoading = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const buttonAttributes = [
    {
      label: "CONFIRM",
      onClick: handleConfirmClick,
      variant: "contained",
      loadingStatus: isLoading,
    },
    {
      label: "cancel",
      onClick: handleCancelClick,
      variant: "",
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        fontSize: 16,
        mt: 2,
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];
  return { buttonAttributes };
};

export default ConfirmOverlapContainer;
