import supportLocum1 from "../../assets/svg/supportLocum/supportLocum1.svg";
import supportLocum2 from "../../assets/svg/supportLocum/supportLocum2.svg";
import supportLocum3 from "../../assets/svg/supportLocum/supportLocum3.svg";
import supportLocum4 from "../../assets/svg/supportLocum/supportLocum4.svg";
import supportLocum5 from "../../assets/svg/supportLocum/supportLocum5.svg";
import supportLocum6 from "../../assets/svg/supportLocum/supportLocum6.svg";
import supportLocum6s from "../../assets/svg/supportLocum/supportLocum6s.svg";
import supportLocum7 from "../../assets/svg/supportLocum/supportLocum7.svg";
import supportLocum7s from "../../assets/svg/supportLocum/supportLocum7s.svg";
import supportLocum8 from "../../assets/svg/supportLocum/supportLocum8.svg";
import supportLocum9 from "../../assets/svg/supportLocum/supportLocum9.svg";
import supportLocum10 from "../../assets/svg/supportLocum/supportLocum10.svg";
import supportLocum11 from "../../assets/svg/supportLocum/supportLocum11.svg";
import supportLocum12 from "../../assets/svg/supportLocum/supportLocum12.svg";
import supportLocum13 from "../../assets/svg/supportLocum/supportLocum13.svg";
import supportLocum14 from "../../assets/svg/supportLocum/supportLocum14.svg";
import supportLocum15 from "../../assets/svg/supportLocum/supportLocum15.svg";
import supportLocum16 from "../../assets/svg/supportLocum/supportLocum16.svg";
import supportLocum17 from "../../assets/svg/supportLocum/supportLocum17.svg";
import supportLocum18 from "../../assets/svg/supportLocum/supportLocum18.svg";
import supportLocum19 from "../../assets/svg/supportLocum/supportLocum19.svg";
import supportLocum20 from "../../assets/svg/supportLocum/supportLocum20.svg";
import supportLocum20s from "../../assets/svg/supportLocum/supportLocum20s.svg";
import { Link } from "react-router-dom";
import { locationPath } from "../../utils/constant";

export const registerAccount = {
  title: "Register an account",
  id: "supportLocumRegisterAccount",
  contentArray: [
    {
      type: "paragraph",
      content: (
        <>
          To start using LocumFind as a locum, go to our{" "}
          <Link to={locationPath?.welcomeLocum} target="_blank">
            register
          </Link>{" "}
          page and select either download on the app store or get it on google
          play to download the app.
        </>
      ),
    },

    {
      type: "paragraph",
      content:
        "LocumFind for locum users is exclusively managed through the app.",
    },
    {
      type: "paragraph",
      content:
        "Once you download the app tap “Get Started” and then “Register”",
    },

    {
      type: "imageGrp",
      content: [supportLocum1, supportLocum2],
    },
    {
      type: "paragraph",
      content:
        "Enter your First Name, Surname and Email Address and if you agree toour Terms of Use and Locum Agreement, check the checkbox and tap “Next”",
    },
    {
      type: "image",
      content: supportLocum3,
    },
    {
      type: "paragraph",
      content:
        "A 5-digit code will be sent to your email from donotreply@locumfind.com.au. Check your junk box and enter the code emailed to you to verify your email address.",
    },
    {
      type: "image",
      content: supportLocum4,
    },
    {
      type: "paragraph",
      content:
        "Enter your password and confirm, and if you’re a pharmacist or optometrist enter your AHPRA registration number. Your registration is now complete.",
    },
  ],
};
export const profile = {
  title: "Profile",
  id: "supportLocumProfile",
  contentArray: [
    {
      type: "paragraph",
      content:
        "You can initially skip completing your profile to explore the platform. But to search and apply for shifts you will need to return to complete your Profile, Bank Details, Tax and Superannuation.",
    },
    {
      type: "paragraph",
      content:
        "A strong profile can improve the likelihood of your job applications being accepted.",
    },

    {
      type: "sideImgTextGroup",
      content: {
        image: supportLocum5,
        sideText: [
          "Add your profile picture. Choose a clear and professional headshot that accurately represents you in a professional setting. ",
          "Enabling “Make my profile visible” allows businesses to search for your profile and message you outside of shifts you’ve applied for. If you do not want businesses seeing your profile outside your shift applications, toggle this off.",
          "The “Preferred Name” field allows users to change their displayed first name. This field is optional. If nothing is entered, your First Name will be used.",
          "Address is needed to search for shifts. LocumFind uses your address location to find locum shifts near you.",
          "The “About” section acts as a resume, allowing users to highlight their skills, qualifications, and experience.",
          "Highlight your qualifications by selecting the “Skills” you are proficient in.",
          "“CV” provides an option for locums to attach their resume.",
        ],
      },
    },
  ],
};
export const bankDetails = {
  title: "Bank details",
  id: "supportLocumBankDetails",
  contentArray: [
    {
      type: "paragraph",
      content:
        "To receive payment for your work, LocumFind uses Stripe to process payments.",
    },
    {
      type: "paragraph",
      content:
        "Under Payments -> Bank Details, tap “Next” to be taken to Stripe’s payment form.",
    },
    {
      type: "paragraph",
      content:
        "Fill out the requested information in this form. Information requested is to ensure the legitimacy and security of these transactions and all data and privacy is protected.",
    },
    {
      type: "paragraph",
      content:
        "Once Complete, your Bank Details page will show you have successfully completed the Stripe form.",
    },
  ],
};
export const superannuationDetails = {
  title: "Superannuation details",
  id: "supportLocumSuperannuationDetails",
  contentArray: [
    {
      type: "paragraph",
      content:
        "As LocumFind acts as your employer, we are required to pay you Superannuation. There are tooltips provided in each section to help you complete this process.",
    },
    {
      type: "numberList",
      title: null,
      content: {
        title: null,
        list: [
          "Select your type of super fund. If you do not have a Superannuation Fund, select LocumFind’s super fund.",
          "Fill out all the details in this form.",
          "Once complete tap “Save changes” at the bottom of the screen.",
        ],
      },
    },
  ],
};
export const taxDetails = {
  title: "Tax details",
  id: "supportLocumTaxDetails",
  contentArray: [
    {
      type: "paragraph",
      content:
        "LocumFind will withold tax when you work a shift. Complete each section, using the tooltips provided if you need more help. Tap “Save Changes” when you are finished. ",
    },
    {
      type: "paragraph",
      content: "Note: Only Australian residents can use LocumFind",
    },
  ],
};

export const listView = {
  title: "List view",
  id: "supportLocumListView",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Once you’ve completed your profile, you can start searching for shifts in the “Search” tab. List view displays all shifts that match your filter settings.",
    },
    {
      type: "image",
      content: supportLocum6,
      contents: supportLocum6s,
      mobileFullSize: true,
    },
  ],
};

export const mapView = {
  title: "Map view",
  id: "supportLocumMapView",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Map view displays all shifts that match your filter settings. You can scroll and zoom in and out to view shifts in an area.",
    },
    {
      type: "image",
      content: supportLocum7,
      contents: supportLocum7s,
      mobileFullSize: true,
    },
  ],
};
export const filters = {
  title: "Filters",
  id: "supportLocumFilters",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Tapping the Filter icon in the top right corner of the search screen will bring up filters. You can apply this to your search to customize which shifts get displayed.",
    },
    {
      type: "image",
      content: supportLocum8,
      mobileFullSize: true,
    },
  ],
};
export const applyingtoShifts = {
  title: "Applying to shifts",
  id: "supportLocumApplyingtoShifts",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Selecting a shift displays important information such as the location, start and finish time and more. You can also message the business about the shift.",
    },
    {
      type: "image",
      content: supportLocum9,
    },
    {
      type: "paragraph",
      content:
        "If you decide you want to work the shift, tap the “Apply” button”. Your application will then be sent to the business, allowing them to review your request.",
    },
    {
      type: "image",
      content: supportLocum10,
      mobileFullSize: true,
    },
  ],
};

export const pendingApplication = {
  title: "Pending application",
  id: "supportLocumPendingApplication",
  contentArray: [
    {
      type: "paragraph",
      content:
        "On your My Calendar tab, you can see your shift applications. The shift is marked as Pending until the business decides to accept or decline your application. If the application is declined, you will be notified in the app and the shift will be removed. ",
    },
    {
      type: "imageGrp",
      content: [supportLocum11, supportLocum12],
    },
  ],
};
export const confirmedApplication = {
  title: "Confirmed application",
  id: "supportLocumConfirmedApplication",
  contentArray: [
    {
      type: "paragraph",
      content: (
        <>
          If the business accepts your application, you will get a notification
          and the shift status will change to confirmed.
          <br />
          You can message the business to discuss your upcoming shift.
        </>
      ),
    },
    {
      type: "imageGrp",
      content: [supportLocum13, supportLocum14],
    },
  ],
};
export const unpaidShift = {
  title: "Unpaid shift",
  id: "supportLocumUnpaidShift",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Once you have completed a shift, the shift status will change to unpaid in your calendar.",
    },
    {
      type: "image",
      content: supportLocum15,
    },
  ],
};
export const finalisingYourShift = {
  title: "Finalising your shift",
  id: "supportLocumFinalisingYourShift",
  contentArray: [
    {
      type: "paragraph",
      content:
        "The business will have 3 days to finalise your shift. In the event your hours worked are different from the original hours posted for the shift, the business can make an amendment.",
    },
    {
      type: "paragraph",
      content:
        "If this occurs, the shift status will change to pending status, and you will have 3 days to confirm the changes.",
    },
    {
      type: "paragraph",
      content:
        "Once this is complete, the shift will be finalised and payment will be processed in the next pay run.",
    },
  ],
};
export const paidShift = {
  title: "Paid shift",
  id: "supportLocumPaidshift",
  contentArray: [
    {
      type: "paragraph",
      content:
        "Once a shift is finalised, and payment is processed and received into your account, the shift status will change to paid. ",
    },
    {
      type: "image",
      content: supportLocum16,
    },
  ],
};
export const payslips = {
  title: "Payslips",
  id: "supportLocumPayslips",
  contentArray: [
    {
      type: "paragraph",
      content:
        "You can view your unpaid and paid payslips in your Profile -> Payments -> Payslips. ",
    },
    {
      type: "paragraph",
      content:
        "LocumFind pays locums weekly on Tuesdays. In the event that a shift is finalised after a Sunday, pay will be paid the following week. Your payslip includes your tax and superannuation breakdown.",
    },
    {
      type: "imageGrp",
      content: [supportLocum17, supportLocum18],
    },
  ],
};
export const cancellation = {
  title: "Cancellation",
  id: "supportLocumCancellation",
  contentArray: [
    {
      type: "paragraph",
      content:
        "At LocumFind, our goal is to ensure a consistent and dependable platform for both locums and businesses.",
    },
    {
      type: "paragraph",
      content:
        "In the event you are unable to attend a confirmed shift, you may cancel by tapping “Cancel Shift” at the bottom of the shift details.",
    },
    {
      type: "paragraph",
      content:
        "We encourage locums to communicate and provide businesses with ample notice wherever possible and to only cancel when absolutely necessary.",
    },
    {
      type: "paragraph",
      content:
        "If the business cancels a confirmed shift within 48 hours of the start time, they will be charged a cancellation fee, and the locum will be reimbursed 3 hours of pay at the agreed upon rate.",
    },
  ],
};
export const statistics = {
  title: "Statistics",
  id: "supportLocumStatistics",
  contentArray: [
    {
      type: "paragraph",
      content:
        "You can view your earnings in Payments -> Statistics. Track your weekly, monthly and yearly income.",
    },
    {
      type: "image",
      content: supportLocum19,
    },
  ],
};
export const notificationsAndSecurity = {
  title: "Notifications and security",
  id: "supportLocumNotificationsAndSecurity",
  contentArray: [
    {
      type: "paragraph",
      content: "Change your notification and security settings.",
    },
    {
      type: "image",
      content: supportLocum20,
      contents: supportLocum20s,
      mobileFullSize: true,
    },
  ],
};
export const helpCentre = {
  title: "Help centre",
  id: "supportLocumHelpCentre",
  contentArray: [
    {
      type: "paragraph",
      content:
        "You can find useful information here including our Privacy Policy, Terms of Use, Contact us page and more.",
    },
  ],
};
