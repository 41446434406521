import React from "react";
import Slider from "@mui/material/Slider";

const LFSlipper = (props) => {
  const { value, onChange, min, max, ...restProps } = props;

  return (
    <div>
      <Slider
        value={value}
        onChange={onChange}
        valueLabelDisplay="auto"
        min={min}
        max={max}
        {...restProps}
      />
    </div>
  );
};

export default LFSlipper;
