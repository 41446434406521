import React, { useEffect } from "react";
import LFBox from "../../shared/LFBox";
import PromptTemplet from "../reusable/PromptTemplet";
import { useLocation, useNavigate } from "react-router-dom";
import { locationPath } from "../../utils/constant";
import { makeStyles } from "@mui/styles";
import { equal, ternary } from "../../utils/javascript";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 138px)",
    display: "flex",
    padding: [[0, 16]],
  },
}));

const ChangesSuccess = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location?.state?.redirect) {
      navigate(-1, { replace: true });
    }
  }, []);

  const handleDoneClick = () => {
    return navigate(location?.state?.redirect);
  };

  const buttonAttributes = [
    {
      label: "Done",
      onClick: handleDoneClick,
      variant: "contained",
    },
  ];

  return (
    <>
      <LFBox className={classes.promptWrapper}>
        <PromptTemplet
          mainText={ternary(
            equal(location?.state?.redirect, locationPath?.businessDetail) &&
              !location.state?.isEdited,
            "Business added successfully",
            "Changes saved",
          )}
          buttonAttributes={buttonAttributes}
          iconSrc={true}
        />
      </LFBox>
    </>
  );
};

export default ChangesSuccess;
