import React, { useEffect } from "react";
import LFBox from "../../../shared/LFBox";
import LFTypography from "../../../shared/LFTypograpy";
import LFButton from "../../../shared/LFButton";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { locationPath } from "../../../utils/constant";
import { SuccessIcon } from "../../../shared/icons";

const EmailSuccess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPath } = useOutletContext();

  useEffect(() => {
    setPath(locationPath?.emailVerify);
    if (!location?.state?.showEmailSuccess) {
      navigate(locationPath?.signIn, { replace: true });
    }
  }, []);

  const handleNextClick = () => {
    navigate(locationPath.registerPassword);
  };
  return (
    <LFBox maxWidth={320} textAlign="center">
      <LFBox>
        <SuccessIcon />
      </LFBox>
      <LFTypography color="primary.main" mb={10} mt={1}>
        Email successfully verified
      </LFTypography>
      <LFButton fullWidth onClick={handleNextClick} variant={"outlined"}>
        NEXT
      </LFButton>
    </LFBox>
  );
};

export default EmailSuccess;
