import React from "react";
import LFTypography from "../../../shared/LFTypograpy";
import LFButton from "../../../shared/LFButton";
import LFGrid from "../../../shared/LFGrid";
import UrgentShiftContainer from "../../../container/newShift/urgentShift.container";
import { newShiftFormPath } from "../../../description/newShift/newShift.description";
import { makeStyles } from "@mui/styles";
import LFStack from "../../../shared/LFStack";
import ActionButtons from "../ActionButtons";
import classNames from "classnames";
import { ExclamationIcon } from "../../../shared/icons";
import { useLocation } from "react-router-dom";
import { locationPath, statusCodes } from "../../../utils/constant";
import { viewShiftFormPath } from "../../../description/viewShift/viewShift.description";
import { equal } from "../../../utils/javascript";
import NotFoundComponent from "../../reusable/NotFoundComponent";
import Loader from "../../reusable/Loader";

const useStyles = makeStyles((theme) => ({
  UrgentShift: {
    "& svg": {
      marginBottom: 24,
    },
  },
  heading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      fontWeight: 400,
      color: theme.palette.primary.main,
      maxWidth: 370,
      margin: "0px auto 10px",
    },
  },
  paragraph: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
      maxWidth: 370,
      margin: "0px auto 10px",
      "& span": {
        fontSize: 16,
        color: theme.palette.primary.main,
      },
    },
  },
  actionButton: {
    margin: "0 0 32px",
    "& .MuiButtonBase-root": {
      maxWidth: 280,
      width: "100%",
      padding: [[11, 30]],
      lineHeight: 1,
      marginTop: 8,
      [theme.breakpoints.down("xsm")]: {
        maxWidth: "unset",
      },
    },
  },
  buttonGroup: {
    "&.MuiStack-root": {
      flexDirection: "row",
      margin: "40px 0",
      gap: 24,
      justifyContent: "center",
      "& .MuiButtonBase-root": {
        maxWidth: 280,
        width: "100%",
        padding: [[11, 10]],
        lineHeight: "18px",
        fontSize: 14,
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.gray.main,
        border: 0,
        "&:hover": {
          backgroundColor: theme.palette.secondary.lightGreen,
        },
      },
    },
  },
  selected: {
    "&.MuiButtonBase-root.MuiButton-root": {
      backgroundColor: theme.palette.secondary.lightGreen,
    },
  },
}));

const UrgentShift = () => {
  const classes = useStyles();
  const location = useLocation();

  const {
    typeButtonAttributes,
    isDisabled,
    handleNextClick,
    handleSaveClick,
    handleCancelSave,
    isFormDataChanged,
    resStatus,
    loadingStatus,
  } = UrgentShiftContainer({
    formPath:
      location.pathname === locationPath?.setUrgentShift
        ? viewShiftFormPath
        : newShiftFormPath,
    location: location,
  });
  if (loadingStatus && !equal(loadingStatus, undefined)) return <Loader />;
  return (
    <>
      {equal(resStatus?.code, statusCodes?.FORBIDDEN) ||
      equal(resStatus?.code, statusCodes?.NOT_FOUND) ? (
        <NotFoundComponent
          message={
            equal(resStatus?.code, statusCodes?.FORBIDDEN)
              ? resStatus?.message
              : "Shift not found"
          }
        />
      ) : (
        <LFStack flex="1">
          <LFGrid
            container
            spacing={0}
            textAlign="center"
            justifyContent="center"
            my="auto"
          >
            <LFGrid item md={7} sm={9} xs={12} className={classes.UrgentShift}>
              <ExclamationIcon />
              <LFTypography
                color="primary"
                variant="h6"
                className={classes.heading}
              >
                Would you like to mark this shift as ‘URGENT’?
              </LFTypography>
              <LFTypography
                color="secondary.dark"
                className={classes.paragraph}
              >
                Shifts that are required to be filled within the next 48 hours
                may be marked as ‘URGENT’ and will be given visible priority in
                search results.
              </LFTypography>
              <LFTypography
                color="secondary.dark"
                className={classes.paragraph}
              >
                The service fee for URGENT shifts increases from{" "}
                <span>$50</span> to <span>$60</span>. By proceeding you agree to
                this fee increase.
              </LFTypography>
              <LFStack mt={5} className={classes.buttonGroup}>
                {typeButtonAttributes?.map(
                  ({ label, isActive, disabled, ...attr }, index) => (
                    <LFButton
                      {...attr}
                      disabled={disabled}
                      key={index}
                      className={classNames({ [classes.selected]: isActive })}
                    >
                      {label}
                    </LFButton>
                  ),
                )}
              </LFStack>
            </LFGrid>
          </LFGrid>

          {equal(location.pathname, locationPath?.setUrgentShift) ? (
            <LFStack
              alignItems="center"
              width="100%"
              className={classes.actionButton}
            >
              <LFButton
                type={"button"}
                onClick={handleSaveClick}
                variant={"outlined"}
                disabled={!isFormDataChanged}
                sx={{ minWidth: 272 }}
              >
                SAVE
              </LFButton>
              <LFButton
                sx={{
                  color: "secondary.dark",
                  textTransform: "capitalize",
                  fontSize: 16,
                  fontWeight: 400,
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disableFocusRipple
                disableRipple
                onClick={handleCancelSave}
              >
                Cancel
              </LFButton>
            </LFStack>
          ) : (
            <ActionButtons
              handleNextClick={handleNextClick}
              isDisabled={isDisabled}
            />
          )}
        </LFStack>
      )}
    </>
  );
};

export default UrgentShift;
