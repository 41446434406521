const commonFields = {
  availability: {
    name: "availability",
    label: "Available days",
    type: "checkbox",
    options: [
      { name: "monday", label: "Mon", id: 1 },
      { name: "tuesday", label: "Tue", id: 2 },
      { name: "wednesday", label: "Wed", id: 3 },
      { name: "thursday", label: "Thur", id: 4 },
      { name: "friday", label: "Fri", id: 5 },
      { name: "saturday", label: "Sat", id: 6 },
      { name: "sunday", label: "Sun", id: 0 },
    ],
  },
  date: { name: "date", label: "Select date range", type: "switch" },
  distance: {
    name: "distance",
    label: "Distance",
    type: "range",
  },
  rate: {
    name: "rate",
    label: "Hourly rate",
    type: "range",
  },
};

export const searchLocumFormPath = {
  parent: "searchLocum",
  state: "searchedLocumsResultsState",
};

export const searchLocumAttributes = {
  optometrist: {
    ...commonFields,
    rate: {
      ...commonFields.rate,
      min: 50,
      max: 120,
    },
    distance: {
      ...commonFields.distance,
      min: 0,
      max: 301,
    },
  },
  pharmacy: {
    ...commonFields,
    rate: {
      ...commonFields.rate,
      min: 20,
      max: 120,
    },
    distance: {
      ...commonFields.distance,
      min: 0,
      max: 301,
    },
  },
};

export const defaultValues = {
  searchBussinessId: null,
  optometrist: {
    form: {
      availability: [0, 1, 2, 3, 4, 5, 6],
      start_date: "",
      end_date: "",
      start_rate: 50,
      end_rate: 120,
      min_distance: 0,
      max_distance: 100,
      skill_ids: [],
      role_ids: [],
    },
    switches: {
      distance: false,
      date: false,
    },
  },
  pharmacy: {
    searchBussinessId: null,
    form: {
      availability: [0, 1, 2, 3, 4, 5, 6],
      start_date: "",
      end_date: "",
      start_rate: 20,
      end_rate: 120,
      min_distance: 0,
      max_distance: 100,
      skill_ids: [],
      role_ids: [],
    },
    switches: {
      date: false,
      distance: false,
    },
  },
};

export const DAYS_VALUES = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0,
};
