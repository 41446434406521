import React from "react";
import LFTabs from "../../shared/LFTab";
import NewShiftContainer from "../../container/newShift/newShift.container";
import {
  newShiftFormPath,
  tabNavbar,
} from "../../description/newShift/newShift.description";
import { equal } from "../../utils/javascript";
import { locationPath } from "../../utils/constant";
import SingleShift from "./SingleShift";
import RecurringShift from "./RecurringShift";
import LFGrid from "../../shared/LFGrid";
import { LFStyled } from "../../shared/LFStyled";
import ActionButtons from "./ActionButtons";
import LFStack from "../../shared/LFStack";

const ShiftTab = LFStyled(LFTabs)(({ theme }) => ({
  "&.MuiTabs-root": {
    marginBottom: 25,
    maxWidth: 568,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    "& .MuiButtonBase-root": {
      fontSize: 18,
      color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 14,
      },
      "&:not(:last-child)": {
        marginRight: 30,
      },
      "&.Mui-selected": {
        color: "#000",
      },
    },
  },
}));

const NewShift = () => {
  const {
    newShiftForm,
    currentType,
    handleTabChange,
    handleDateChange,
    handleNextClick,
    isDisabled,
    onChangeDateValue,
    onDateChangeError,
  } = NewShiftContainer({ formPath: newShiftFormPath });

  return (
    <LFStack flex="1" mt={2}>
      <LFGrid container spacing={(0, 3)}>
        <LFGrid
          item
          md={3}
          xs={12}
          sx={{ display: { md: "block", xs: "none" } }}
        />
        <LFGrid item md={7} xs={12}>
          <ShiftTab
            value={currentType}
            onChange={handleTabChange}
            indicatorColor="secondary"
            list={tabNavbar}
            indicatorTab
            tabBordered
            centered
          />
        </LFGrid>
      </LFGrid>
      {equal(currentType, locationPath?.singleShift) ? (
        <SingleShift
          startDate={newShiftForm?.start_date}
          onChange={handleDateChange}
          onChangeDateValue={onChangeDateValue}
          onDateChangeError={onDateChangeError}
        />
      ) : (
        <RecurringShift
          startDate={newShiftForm?.start_date}
          endDate={newShiftForm?.end_date}
          onChange={handleDateChange}
          onChangeDateValue={onChangeDateValue}
          onDateChangeError={onDateChangeError}
        />
      )}
      <LFGrid container spacing={3} mt={-1}>
        <LFGrid
          item
          md={3}
          xs={12}
          sx={{ display: { md: "block", xs: "none" } }}
        />
        <LFGrid item md={7} xs={12}>
          <ActionButtons
            handleNextClick={handleNextClick}
            isDisabled={isDisabled}
            sx={{ maxWidth: { md: 568 } }}
          />
        </LFGrid>
      </LFGrid>
    </LFStack>
  );
};

export default NewShift;
