import { messagesFormPath } from "../../description/messages/messages.description";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal } from "../../utils/javascript";

const createConversationAction =
  ({ performRequest, locumId, setSelectedTab, setSelectedUser, businessId }) =>
  async () => {
    const { data, statusCode } = await performRequest({
      endPoint: apiEndpoints?.conversations,
      method: apiMethods?.post,
      data: {
        conversation: {
          locum_id: locumId,
          business_id: businessId,
        },
      },
      tokenKey: "Authorization",
      showFailedMessage: true,
      needLoader: true,
      parent: messagesFormPath?.createConversation,
    });
    if (!equal(statusCode, statusCodes?.OK)) return;
    const {
      conversation: { id, locum },
    } = { ...data };
    setSelectedTab({
      id,
      userId: locum?.id,
    });
    setSelectedUser(locum);
  };

export default createConversationAction;
