import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import LFButton from "../../shared/LFButton";
import theme from "../../themes/theme";
import LFStack from "../../shared/LFStack";
import LFGrid from "../../shared/LFGrid";
import ApiContainer from "../../container/api.container";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal } from "../../utils/javascript";
import { useSelector } from "react-redux";
import { useState } from "react";
import Loader from "../reusable/Loader";

const StripeForm = ({ cancelForm, setCardNumber }) => {
  const stripe = useStripe();
  const elements = useElements();
  const saveLoader = useSelector(
    (state) => state.app?.loader?.["addPaymentMethod"],
  );
  const [isStipeLoading, setIsStipeLoading] = useState(true);
  const { performRequest } = ApiContainer();

  const attachPaymentMethod = async (paymentId, cardNumber) => {
    const { statusCode } = await performRequest({
      endPoint: apiEndpoints?.addPaymentMethod,
      method: apiMethods?.post,
      tokenKey: "Authorization",
      data: { payment_method: paymentId },
      showFailedMessage: true,
      needLoader: true,
      parent: "addPaymentMethod",
    });
    if (!equal(statusCode, statusCodes?.OK)) return;
    setCardNumber(cardNumber);
    cancelForm();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    elements.submit();
    if (stripe) {
      const result = await stripe.createPaymentMethod({
        elements,
      });
      if (result?.error) return;
      attachPaymentMethod(
        result?.paymentMethod?.id,
        result?.paymentMethod?.card?.last4,
      );
    }
  };
  const options = {
    layout: {
      type: "accordion",
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: true,
    },
  };
  return (
    <form>
      <PaymentElement
        options={options}
        onReady={() => setIsStipeLoading(false)}
      />

      {isStipeLoading ? (
        <Loader height={"max-content"} sx={{ padding: "70px 0" }} />
      ) : (
        <LFGrid item xs={12}>
          <LFStack
            mt={3}
            spacing={1}
            direction={{ sm: "row", xs: "column-reverse" }}
            maxWidth={{ sm: "auto", xs: 342 }}
            mx={{ sm: 0, xs: "auto" }}
          >
            <LFButton
              type={"button"}
              variant={"outlined"}
              onClick={cancelForm}
              sx={{
                fontSize: 14,
                minHeight: 32,
                fontWeight: 400,
                border: { xs: "none", sm: "1px solid" },
                color: {
                  xs: theme.palette.secondary.dark,
                  sm: theme.palette.primary.main,
                },
              }}
            >
              Cancel
            </LFButton>
            <LFButton
              type={"button"}
              variant={"contained"}
              onClick={handleSubmit}
              sx={{ fontSize: 14, minHeight: 32 }}
              isLoading={saveLoader}
            >
              SAVE
            </LFButton>
          </LFStack>
        </LFGrid>
      )}
    </form>
  );
};

export default StripeForm;
