import { ReactComponent as StartIcon } from "../../assets/svg/start.svg";
import { ReactComponent as CompleateProfile } from "../../assets/svg/completProfile.svg";
import { ReactComponent as SearchShift } from "../../assets/svg/searchShift.svg";
import { ReactComponent as BriefcaseIconLite } from "../../assets/svg/briefcaseIconLite.svg";
import { ReactComponent as WalletIcon } from "../../assets/svg/walletIcon.svg";
import { ReactComponent as SettingIconLite } from "../../assets/svg/settingIconLite.svg";
import { ReactComponent as CalPlusIcon } from "../../assets/svg/calPlusIcon.svg";
import { ReactComponent as CalCheckIcon } from "../../assets/svg/calCheckIcon.svg";
import { ReactComponent as CreditCardIcon } from "../../assets/svg/creditCardIcon.svg";

export const sectionButtons = {
  locum: [
    {
      id: 1,
      icon: <StartIcon />,
      title: "Getting Started",
      desc: "How It Works, Registering",
    },
    {
      id: 2,
      icon: <CompleateProfile />,
      title: "Complete Profile",
      desc: "Experience, Tax & Super Details",
    },
    {
      id: 3,
      icon: <SearchShift />,
      title: "Search For Shifts",
      desc: "Search, List View, Map View, Filters",
    },
    {
      id: 4,
      icon: <BriefcaseIconLite />,
      title: "Start Working",
      desc: "Apply To Shifts, Confirm Shifts ",
    },
    {
      id: 5,
      icon: <WalletIcon />,
      title: "Payment",
      desc: "Finalising Hours, Payslips ",
    },
    {
      id: 6,
      icon: <SettingIconLite />,
      title: "Settings",
      desc: "Notifications, Security",
    },
  ],
  business: [
    {
      id: 7,
      icon: <StartIcon />,
      title: "Getting Started",
      desc: "How It Works, Registering",
    },
    {
      id: 8,
      icon: <CompleateProfile />,
      title: "Complete Profile",
      desc: "Add Payment Method, Add Business",
    },
    {
      id: 9,
      icon: <CalPlusIcon />,
      title: "Post Shifts",
      desc: "How To Post Shifts",
    },
    {
      id: 10,
      icon: <CalCheckIcon />,
      title: "Managing Shifts",
      desc: "Review, Edit Shifts, Browse Locums",
    },
    {
      id: 11,
      icon: <CreditCardIcon />,
      title: "Payment",
      desc: "Finalising Shifts, Invoices, Fees",
    },
    {
      id: 12,
      icon: <SettingIconLite />,
      title: "Settings",
      desc: "Notifications, Security",
    },
  ],
};
