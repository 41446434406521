import LFBox from "../../shared/LFBox";
import Chats from "./Chats";
import ChatContacts from "./ChatContacts";
import LFGrid from "../../shared/LFGrid";
import LFContainer from "../../shared/LFContainer";
import { Typography, useMediaQuery } from "@mui/material";
import BackButton from "../reusable/BackButton";
import MessagesContainer from "../../container/messages/messages.container";
import { messagesFormPath } from "../../description/messages/messages.description";
import Loader from "../reusable/Loader";
import { locationPath } from "../../utils/constant";
import { makeStyles } from "@mui/styles";
import LFButton from "../../shared/LFButton";
import { useNavigate } from "react-router-dom";
import { equal, length } from "../../utils/javascript";
import LFStack from "../../shared/LFStack";

const useStyles = makeStyles((theme) => ({
  noConversationBox: {
    textAlign: "center",
    padding: "30px 10px",
    "& h6": {
      fontSize: 16,
      color: "#9ba0b5",
    },
  },
  chatModule: {
    height: "calc(100vh - 174px)",
    minHeight: 520,
    [theme.breakpoints.between("xxsm", "md")]: {
      height: "calc(100svh - 174px)",
    },
    [theme.breakpoints.down("xxsm")]: {
      height: "calc(100vh - 129px)",
    },
  },
}));
const Messages = () => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width: 767px)");

  const {
    initialLoader,
    businessConversation,
    selectedTab,
    showChat,
    chatSocket,
    messagesData,
    selectedUser,
    businessId,
    state,
    setShowChat,
    setMessagesData,
    setSelectedTab,
    handleBackClick,
    locumData,
  } = MessagesContainer({
    formPath: messagesFormPath,
  });
  const navigate = useNavigate();
  if (!businessConversation || !businessId || initialLoader) return <Loader />;

  const messagedAreEmpty =
    (!locumData || !equal(state?.businessId, businessId)) &&
    equal(length(businessConversation?.data), 0);

  return (
    <LFContainer sx={{ margin: { xxsm: "30px auto", xs: "16px auto" } }}>
      <LFGrid container spacing={{ xxsm: 3, xs: 0 }}>
        {!(matches && showChat) && (
          <LFGrid item md={4} xxsm={5} xs={12}>
            <LFStack className={classes.chatModule}>
              <ChatContacts
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                businessConversation={businessConversation}
                businessId={businessId}
                setShowChat={setShowChat}
              />
            </LFStack>
          </LFGrid>
        )}
        {(!matches || showChat) && (
          <LFGrid item md={8} xxsm={7} xs={12}>
            <>
              {matches && (
                <BackButton path={true} onClick={handleBackClick} pt={0} />
              )}
              {messagedAreEmpty ? (
                <LFBox
                  textAlign="center"
                  className={classes.noConversationBox}
                  mt={12}
                >
                  <Typography variant="h5" mb={2} color={"secondary.dark"}>
                    No conversations yet...
                  </Typography>
                  <LFButton
                    variant="outlined"
                    onClick={() =>
                      navigate(locationPath?.searchLocum, {
                        state: { backButton: true },
                      })
                    }
                  >
                    BROWSE LOCUMS
                  </LFButton>
                </LFBox>
              ) : (
                <LFStack className={classes.chatModule}>
                  <Chats
                    selectedTab={selectedTab}
                    messagesData={messagesData}
                    setMessagesData={setMessagesData}
                    chatSocket={chatSocket}
                    formPath={messagesFormPath}
                    selectedUser={selectedUser}
                    matches={matches}
                  />
                </LFStack>
              )}
            </>
          </LFGrid>
        )}
      </LFGrid>
    </LFContainer>
  );
};

export default Messages;
