import React from "react";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import LFBox from "../../shared/LFBox";
import { Link } from "react-router-dom";
import { locationPath } from "../../utils/constant";

const HowItWorks = ({ selectedType }) => {
  const { descParas, points } = { ...howItWorks[selectedType] };
  return (
    <LFBox>
      <LFStack sx={{ gap: { xs: "12px", xsm: "18px" } }}>
        {descParas?.map((para) => (
          <LFTypography key={para}>{para}</LFTypography>
        ))}

        <LFStack spacing={{ xs: 2, xsm: 3 }} sx={{ paddingLeft: "10px" }}>
          {points?.map(({ id, point, desc }) => {
            return (
              <LFBox key={id}>
                <LFTypography>
                  <b>
                    {id}. {point}:
                  </b>{" "}
                  {desc}
                </LFTypography>
              </LFBox>
            );
          })}
        </LFStack>
      </LFStack>
    </LFBox>
  );
};

export default HowItWorks;

const howItWorks = {
  business: {
    descParas: [
      "LocumFind serves as an efficient platform for pharmacy and optometry businesses seeking temporary staffing solutions. Businesses can post locum shifts on their dashboard, while locum professionals search and apply for shifts through our app",
      "LocumFind offers opportunities to fill shifts for Pharmacists, Dispensary Technicians, Pharmacy Assistants, and Optometrists, providing innovative solutions for staffing needs in your business.",
      "Here's how it works for businesses:",
    ],
    points: [
      {
        id: 1,
        point: "Register your account",
        desc: (
          <>
            Begin by{" "}
            <Link to={locationPath?.welcomeBusiness} target="_blank">
              registering
            </Link>{" "}
            an account and adding your business to your profile.
          </>
        ),
      },
      {
        id: 2,
        point: "Post and Manage Shifts",
        desc: (
          <>
            Post shifts from your “My Calendar” page. Edit the details to
            provide locums important information about your shift.
          </>
        ),
      },
      {
        id: 3,
        point: "Review and Confirm Applications",
        desc: (
          <>
            Get notified when locums apply and review their applications. Select
            the right candidate for your business.
          </>
        ),
      },
      {
        id: 4,
        point: "Finalize Shift",
        desc: (
          <>
            Review the locum’s completed shift. Ammend any changes and approve
            their finalized hours.
          </>
        ),
      },
      {
        id: 5,
        point: "Payment",
        desc: (
          <>
            After the shift is finalized, payment is taken by LocumFind and
            transferred to the locum.
          </>
        ),
      },
    ],
  },
  locum: {
    descParas: [
      "LocumFind serves as a seamless platform connecting locum professionals with pharmacy and optometry businesses in need of temporary staffing. Businesses post locum shifts on their dashboard, while locums can conveniently search and apply for shifts using our app.",
      "Payments are handled through LocumFind, removing the requirement for locums to have an ABN.",
      "LocumFind is available for Pharmacists, Dispensary Technicians, Pharmacy Assistants and Optometrists, providing new, flexible and exciting job opportunities for more locums.",
      "Here's how it works for Locums:",
    ],
    points: [
      {
        id: 1,
        point: "Register your account",
        desc: (
          <>
            Download our app and{" "}
            <Link to={locationPath?.welcomeLocum} target="_blank">
              register
            </Link>{" "}
            your account.
          </>
        ),
      },
      {
        id: 2,
        point: "Complete your Profile ",
        desc: (
          <>
            Start by creating a comprehensive profile showcasing your skills,
            qualifications, experience, and availability.
          </>
        ),
      },
      {
        id: 3,
        point: "Search and Apply",
        desc: (
          <>
            Browse through locum job postings via list or map view. Filter based
            on your preferences such as location, rate, and schedule. Apply to
            the shifts that fit your preferences.
          </>
        ),
      },
      {
        id: 4,
        point: "Complete the Shift",
        desc: <>Arrive at your shift on time and work the agreed upon hours.</>,
      },
      {
        id: 5,
        point: "Get Paid Reliably",
        desc: (
          <>
            Payment is secured from the business before you work your shift
            ensuring you get paid on time. Payment is processed weekly.
          </>
        ),
      },
    ],
  },
};
