import LFSwitch from "../../../shared/LFSwitch";
import LFTypography from "../../../shared/LFTypograpy";
import LFStack from "../../../shared/LFStack";
import LFGrid from "../../../shared/LFGrid";
import { capitlizeWord, keys, ternary } from "../../../utils/javascript";
import {
  labelObject,
  notificationSettingsFromPath,
  notificationFieldsSequence,
} from "../../../description/account/settings/NotificationSettings.description";
import { makeStyles } from "@mui/styles";
import theme from "../../../themes/theme";
import NotificationsSettingsContainer from "../../../container/account/settings/notificationsSettings.container";
import Loader from "../../reusable/Loader";

const useStyles = makeStyles((theme) => ({
  tabName: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "31px",
      fontWeight: "500",
      paddingBottom: 16,
      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
  mdTHeading: {
    "&.MuiTypography-root": {
      paddingTop: 16,
      fontSize: 20,
      lineHeight: "26px",
    },
  },
  title: {
    "&.MuiTypography-root": {
      paddingTop: 12,
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  subTitle: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  switch: {
    "&.MuiSwitch-root": {
      marginLeft: "auto",
    },
  },
}));

const NotificationsSettings = () => {
  const classes = useStyles();

  const { NotificationsLoading, notificationData, hendleSwitchChange } =
    NotificationsSettingsContainer({
      formPath: notificationSettingsFromPath,
      fieldsOrder: notificationFieldsSequence,
    });

  return (
    <>
      {NotificationsLoading && !notificationData ? (
        <Loader />
      ) : (
        <>
          {notificationData?.map((curField) => {
            let { web, email, n_type, id } = { ...curField };
            return (
              <LFGrid
                sx={{
                  borderBottom: `1px solid ${theme.palette.gray.dark}`,
                  width: "100%",
                  maxWidth: { xxsm: "371px !important" },
                }}
                key={id}
              >
                <LFTypography
                  variant="h5"
                  color="dark.primary"
                  className={classes.title}
                >
                  {capitlizeWord(labelObject?.[n_type])}
                </LFTypography>
                <LFGrid
                  container
                  columnSpacing={{ sm: 10, xs: 3, xts: 6 }}
                  py={1.5}
                >
                  {keys({ web, email })?.map((curSwitchKey) => {
                    return (
                      <LFGrid item xs={6} key={curSwitchKey}>
                        <LFStack
                          direction="row"
                          maxWidth={ternary(
                            curSwitchKey === "web",
                            "150px",
                            "115px",
                          )}
                          marginLeft={ternary(
                            curSwitchKey === "email",
                            "auto",
                            "none",
                          )}
                        >
                          <LFTypography
                            className={classes.subTitle}
                            color="secondary.dark"
                          >
                            {capitlizeWord(labelObject[curSwitchKey])}
                          </LFTypography>
                          <LFSwitch
                            // disabled={NotificationsLoading}
                            className={classes.switch}
                            name={curSwitchKey}
                            checked={curField?.[curSwitchKey]}
                            onChange={(e) =>
                              hendleSwitchChange({
                                e,
                                id,
                              })
                            }
                          />
                        </LFStack>
                      </LFGrid>
                    );
                  })}
                </LFGrid>
              </LFGrid>
            );
          })}
        </>
      )}
    </>
  );
};

export default NotificationsSettings;
