import React from "react";
import LFStack from "../../shared/LFStack";
import LFButton from "../../shared/LFButton";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { SET_APP_DATA, SET_FORM_DATA } from "../../redux/constant";
import { newShiftFormPath } from "../../description/newShift/newShift.description";
import { useNavigate } from "react-router";
import {
  LS_KEY_NEWSHIFT_DATA,
  LS_KEY_TEMPLATES_DATA,
  locationPath,
} from "../../utils/constant";
import { removeLocalStorageItem } from "../../utils/localStorage";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    margin: "0 0 32px",
    "& .MuiButtonBase-root": {
      maxWidth: 280,
      width: "100%",
      padding: [[11, 30]],
      lineHeight: 1,
      marginTop: 8,
      [theme.breakpoints.down("xsm")]: {
        maxWidth: "unset",
      },
    },
  },
}));

const ActionButtons = ({ handleNextClick, isDisabled, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleCancelClick = () => {
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [newShiftFormPath?.state]: {},
      },
    });
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [newShiftFormPath?.parent]: {},
      },
    });
    removeLocalStorageItem(LS_KEY_NEWSHIFT_DATA);
    removeLocalStorageItem(LS_KEY_TEMPLATES_DATA);
    navigate(locationPath?.myCalendar);
  };
  return (
    <LFStack
      {...rest}
      alignItems="center"
      width="100%"
      className={classes.actionButton}
    >
      <LFButton
        type={"button"}
        onClick={handleNextClick}
        variant={"outlined"}
        disabled={isDisabled}
        sx={{ minWidth: 272 }}
      >
        NEXT
      </LFButton>
      <LFButton
        sx={{
          color: "secondary.dark",
          textTransform: "capitalize",
          fontSize: 16,
          fontWeight: 400,
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        disableFocusRipple
        disableRipple
        onClick={handleCancelClick}
      >
        Cancel
      </LFButton>
    </LFStack>
  );
};

export default ActionButtons;
