import React from "react";
import LFBox from "../../../shared/LFBox";
import LFTypography from "../../../shared/LFTypograpy";
import EditModeHelper from "../../../helper/EditModeHelper";
import {
  businessDetailAttribute,
  businessDetailFormPath,
} from "../../../description/account/businessDetail.description";
import EditForm from "../../../helper/EditForm";
import BusinessDetailContainer from "../../../container/account/businessDetail.container";
import LFDropDown from "../../../shared/LFDropDown";
import LFButton from "../../../shared/LFButton";
import LFGrid from "../../../shared/LFGrid";
import LFStack from "../../../shared/LFStack";
import Loader from "../../reusable/Loader";
import { length } from "../../../utils/javascript";
import theme from "../../../themes/theme";
import LogoAvatar from "../../reusable/LogoAvatar";

const BusinessDetail = () => {
  const {
    cloneAttributes,
    isEditable,
    setIsEditable,
    editingFieldName,
    setEditingFieldName,
  } = EditModeHelper({
    attributes: businessDetailAttribute,
  });

  const {
    initailLoadingStatus,
    selectdBusinessDetail,
    handleDetailsEditSubmit,
    selectBusinessAttribute,
    getDefaultValues,
    handleAddBusinessClick,
    selectedBusinessName,
  } = BusinessDetailContainer({
    formPath: businessDetailFormPath,
    setIsEditable,
  });

  if (!selectdBusinessDetail?.attributes) {
    if (initailLoadingStatus) return <Loader />;
    return null;
  }
  return (
    <>
      <LFBox>
        <LFStack
          direction="row"
          justifyContent="space-between"
          sx={{
            borderBottom: {
              xxsm: "none",
              xs: `1px solid ${theme.palette.secondary.dark}`,
            },
            pb: 1.5,
            display: { md: "none", xs: "flex" },
            mb: { md: 3, xs: 2 },
          }}
        >
          <LFTypography
            variant="h5"
            component="h5"
            color="primary"
            fontWeight="500"
          >
            Businesses
          </LFTypography>
          <LFButton
            sx={{
              fontSize: { xxsm: 14, xs: 12 },
              padding: "7px 12px",
              maxWidth: { xts: "250px", xs: "115px" },
              width: "100%",
              minWidth: "unset",
            }}
            variant="contained"
            onClick={handleAddBusinessClick}
          >
            ADD BUSINESS
          </LFButton>
        </LFStack>
        {selectdBusinessDetail?.attributes ? (
          <LFGrid container spacing={3}>
            <LFGrid item md={8} xs={12} position="relative">
              <LFTypography
                variant="h6"
                component="h6"
                fontWeight="400"
                mb={{ xxsm: 3, xs: 2 }}
                color="primary"
              >
                Business Details
              </LFTypography>
              <LFGrid container spacing={2}>
                <LFGrid item xs={12} mb={{ md: 2, xs: 1 }}>
                  {length(selectBusinessAttribute?.options) > 1 && (
                    <LFDropDown
                      variant="outlined"
                      sx={{ maxWidth: { xsm: 342, sm: "100%" } }}
                      labelProps={{
                        "&.MuiFormLabel-root": {
                          transform: "scale(1)",
                          fontSize: 14,
                          marginLeft: 0,
                          position: "relative",
                        },
                      }}
                      selectSx={{
                        "&.MuiInputBase-root": {
                          marginTop: 1,
                        },
                      }}
                      MenuProps={{
                        sx: {
                          "& .MuiButtonBase-root": {
                            color: "primary.main",
                          },
                        },
                      }}
                      {...selectBusinessAttribute}
                    />
                  )}
                </LFGrid>

                <LFGrid item xs={12}>
                  <EditForm
                    attribute={cloneAttributes}
                    formPath={businessDetailFormPath}
                    defaultValues={getDefaultValues(
                      selectdBusinessDetail?.attributes,
                    )}
                    setIsEditable={setIsEditable}
                    isEditable={isEditable}
                    setEditingFieldName={setEditingFieldName}
                    editingFieldName={editingFieldName}
                    handleSubmit={handleDetailsEditSubmit}
                  />
                </LFGrid>
              </LFGrid>
            </LFGrid>
            <LFGrid item md={4} xs={12} display={{ md: "block", xs: "none" }}>
              <LFStack mt={{ md: -8.75 }}>
                <LFButton
                  sx={{ mb: 3.75, fontSize: "14px", padding: "11px 20px" }}
                  fullWidth
                  variant="contained"
                  onClick={handleAddBusinessClick}
                >
                  ADD BUSINESS
                </LFButton>
                <LFBox
                  sx={{
                    borderRadius: 3,
                    backgroundColor: "gray.main",
                    p: "34px 24px",
                    textAlign: "center",
                    "& img": { width: 64, height: 64 },
                    "& .MuiTypography-root": {
                      wordWrap: "break-word",
                    },
                  }}
                >
                  <LFBox mb={2} lineHeight={0}>
                    <LogoAvatar
                      sx={{ width: 64, height: 64 }}
                      name={selectedBusinessName}
                      logoUrl={selectdBusinessDetail?.attributes?.logo_url}
                    />
                  </LFBox>
                  <LFTypography
                    variant="h6"
                    component="h6"
                    mb={2.5}
                    color="dark.main"
                  >
                    {selectdBusinessDetail?.attributes?.name}
                  </LFTypography>
                  <LFTypography color="secondary.dark" lineHeight="20px">
                    {selectdBusinessDetail?.attributes?.location?.address}
                  </LFTypography>
                </LFBox>
              </LFStack>
            </LFGrid>
          </LFGrid>
        ) : null}
      </LFBox>
    </>
  );
};

export default BusinessDetail;
