import { useLocation, useNavigate } from "react-router-dom";
import {
  LS_KEY_HALF_REGISTER,
  LS_KEY_REGISTER_ID,
  LS_KEY_USER,
  locationPath,
} from "../../utils/constant";
import { ternary } from "../../utils/javascript";
import { useState } from "react";
import { removeLocalStorageItem } from "../../utils/localStorage";
import { useDispatch } from "react-redux";
import logoutAction from "../../redux/action/logoutAction";

const AuthLayoutCountainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [path, setPath] = useState();
  const [hideCancel, setHideCancel] = useState(false);

  const handleCancelClick = () => {
    let redirectPath;
    if (location.pathname?.includes(locationPath?.accountSuccess)) {
      redirectPath = locationPath?.myCalendar;
    } else if (
      location.pathname?.includes(locationPath?.forgotPassword) ||
      location.pathname?.includes(locationPath?.resetPassword)
    ) {
      redirectPath = locationPath?.signIn;
      dispatch(logoutAction());
    } else if (location.state?.showRegiserBusiness) {
      redirectPath = locationPath?.businessDetail;
    } else {
      redirectPath = locationPath?.landing;
      dispatch(logoutAction());
    }

    removeLocalStorageItem(LS_KEY_REGISTER_ID);
    removeLocalStorageItem(LS_KEY_USER);
    removeLocalStorageItem(LS_KEY_HALF_REGISTER);
    navigate(redirectPath);
  };

  const handleBackButtonClick = () => {
    if (location.state?.showRegiserBusiness) {
      return navigate(locationPath?.businessDetail);
    }
    navigate(path);
  };

  const buttonLabel = ternary(
    location.pathname?.includes(locationPath?.accountSuccess),
    "Skip",
    "Cancel",
  );

  return {
    path,
    buttonLabel,
    hideCancel,
    setHideCancel,
    setPath,
    handleCancelClick,
    handleBackButtonClick,
  };
};

export default AuthLayoutCountainer;
