import moment from "moment";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import {
  equal,
  hardCloneObj,
  keys,
  length,
  ternary,
  values,
} from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";
import queryString from "query-string";

const getShiftForMonthAction =
  ({ performRequest, formPath, selectedMounth, businessId }) =>
  async (dispatch, getState) => {
    const filteredShiftData =
      getState()?.app?.data?.[formPath?.filteredShiftData];

    const filteredState = getState()?.app?.data?.[formPath?.state]?.filterState;
    const allRoles = getState()?.globalAttr?.roles;

    const myCalendarState = getState()?.app?.data?.[formPath?.state];
    const selectedDate = myCalendarState?.selectedDate;

    const startDate = moment(selectedMounth, "MMMM YYYY")?.startOf("month");
    const endDate = moment(selectedMounth, "MMMM YYYY")?.endOf("month");

    const startDateStr = startDate.format("DD/MM/YYYY");
    const endDateStr = endDate.format("DD/MM/YYYY");

    const sendingObj = {
      start_date: startDateStr,
      end_date: endDateStr,
    };

    if (filteredState) {
      const filteredRoles = allRoles?.filter(
        (role) => filteredState?.roles?.[role?.attributes?.name],
      );
      const roleIdsArray = filteredRoles?.map((role) => role?.attributes?.id);

      const filteredStatuses = keys(filteredState?.status)?.filter(
        (key) => filteredState?.status?.[key],
      );

      sendingObj.role_ids = ternary(
        length(roleIdsArray),
        [...roleIdsArray],
        ["empty"],
      );
      sendingObj.shift_statuses = ternary(
        length(filteredStatuses),
        [...filteredStatuses],
        ["empty"],
      );
    }
    if (businessId) {
      sendingObj.business_ids = [businessId];
    }

    if (equal(filteredShiftData, undefined))
      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.shiftDataLoading]: !selectedDate },
      });

    const paramsObj = queryString.stringify(sendingObj, {
      arrayFormat: "bracket",
    });

    const { statusCode, data } = await performRequest({
      endPoint: `${apiEndpoints?.allShifts}?${paramsObj}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    dispatch({
      type: SET_APP_DATA,
      payload: { [formPath?.shiftDataLoading]: false },
    });

    if (!equal(statusCode, statusCodes?.OK)) return;

    const finalStatusData = checkUrgentShiftPerDate({
      DateShifts: data?.shifts,
      statusData: data?.date_and_status_wise_shifts_count,
    });

    return dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.shiftData]: data?.shifts,
        [formPath?.statusData]: finalStatusData,
      },
    });
  };

export default getShiftForMonthAction;

const checkUrgentShiftPerDate = ({ DateShifts, statusData }) => {
  const finalStatusData = hardCloneObj({ ...statusData });
  keys(finalStatusData)?.forEach((curDate) => {
    const isUrgentValue = values(DateShifts?.[curDate])?.some(
      (curBusinessShifts) => {
        const arrayOfTF = curBusinessShifts?.some((curData) =>
          equal(curData?.attributes?.is_urgent, true),
        );
        return equal(arrayOfTF, true);
      },
    );
    finalStatusData[curDate].is_urgent = ternary(
      isUrgentValue,
      isUrgentValue,
      false,
    );
  });

  return finalStatusData;
};
