export const filterTab = [
  {
    label: "WEEK",
    value: "week",
  },
  {
    label: "MONTH",
    value: "month",
  },
  {
    label: "YEAR  ",
    value: "year",
  },
];
