import React from "react";
import LFGrid from "../../shared/LFGrid";
import ShiftOverview from "./ShiftOverview";
import ShiftDetailList from "./ShiftDetailList";
import LFBox from "../../shared/LFBox";
import ShiftDetailForm from "./ShiftDetailForm";
import LFButton from "../../shared/LFButton";
import ShiftAttributeHelper from "../../helper/ShiftAttributeHelper";
import Loader from "../reusable/Loader";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import userImage from "../../assets/png/user.jpeg";
import { ReactComponent as InfoIcon } from "../../assets/svg/info-icon.svg";
import {
  equal,
  formatName,
  keys,
  length,
  ternary,
  values,
} from "../../utils/javascript";
import { Link } from "react-router-dom";
import {
  locationPath,
  pendingLevels,
  shiftStatuses,
} from "../../utils/constant";
import BackButton from "../reusable/BackButton";
import ShiftDetailsContainer from "../../container/viewShift/shiftDetails.container";
import { isGreaterThan72Hours } from "../../utils/moment";

const useStyles = makeStyles((theme) => ({
  postButtons: {
    "&.MuiButtonBase-root": {
      maxWidth: 342,
      width: "100%",
      padding: [[11, 10]],
      marginTop: 24,
      fontSize: 14,
    },
  },
  mainBox: {
    maxWidth: 230,
    minWidth: 230,
    borderRadius: 10,
    backgroundColor: theme.palette.gray.main,
    padding: [[24, 32]],
    height: "max-content",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "unset",
    },
  },
  ApplicantsBox: {
    "&.MuiBox-root": {
      textAlign: "center",
      width: "100%",
      maxWidth: "unset",
      boxSizing: "border-box",
      padding: 22,
      minHeight: 388,
      marginBottom: 20,
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        minWidth: "100%",
        height: "100%",
        marginBottom: 0,
      },
      [theme.breakpoints.down("xsm")]: {
        minHeight: "fit-content",
      },
      "& h6": {
        fontWeight: 400,
        fontSize: 24,
        marginBottom: 34,
        color: theme.palette.primary.main,
      },
    },
  },
  imageBox: {
    height: 96,
    width: 96,
    margin: "0 auto 16px",
    display: "flex",
    justifyContent: "center",
    "& img": {
      border: `2px solid ${theme.palette.white.main}`,
      borderRadius: "50%",
      height: "100%",
      width: "100%",
      zIndex: 2,
      "&:nth-child(2)": {
        marginLeft: -80,
        zIndex: 1,
      },
      "&:nth-child(3)": {
        marginLeft: -80,
        zIndex: 0,
      },
    },
  },
  buttonGroup: {
    alignItems: "center",
    "& button": {
      fontSize: 14,
      lineHeight: "18px",
      padding: "10px 20px",
      minWidth: 174,
      width: "fit-content",
    },
  },
  counter: {
    position: "absolute",
    top: -12,
    right: -13,
    zIndex: 1,
    height: 27,
    width: 27,
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.white.main,
    backgroundColor: theme.palette.dark.danger,
  },
  heading: {
    "&.MuiTypography-root": {
      color: theme.palette.primary.main,
      marginBottom: 10,
      fontSize: 16,
    },
  },
  para: {
    "&.MuiTypography-root": {
      color: theme.palette.secondary.dark,
      fontSize: 14,
    },
  },
  confirmedBox: {
    "&.MuiBox-root": {
      textAlign: "center",
      boxSizing: "border-box",
      maxWidth: "unset",
      width: "100%",
      marginTop: "auto",
      [theme.breakpoints.down("md")]: {
        marginTop: 24,
        maxWidth: "100%",
        order: 4,
      },
      "&>svg": {
        height: 28,
        width: 28,
        "& path": {
          stroke: theme.palette.primary.main,
        },
      },
    },
  },
  newShiftContainer: {
    "&.MuiStack-root": {
      paddingTop: 24,
      gap: 24,
      flexDirection: "row",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        padding: [[20, 0]],
      },
      [theme.breakpoints.down("xxsm")]: {
        gap: 0,
      },
    },
  },
  firstCard: {
    "&.MuiBox-root": {
      minWidth: "31%",
      width: "31%",
      [theme.breakpoints.down("md")]: {
        minWidth: "unset",
        flex: "0 0 50%",
        order: 1,
      },
      [theme.breakpoints.down("xsm")]: {
        flex: "0 0 100%",
      },
    },
  },
  secondCard: {
    "&.MuiBox-root": {
      minWidth: "calc(42% - 24px)",
      width: "calc(42% - 24px)",
      zIndex: 1,
      transition: "all .4s ease-in-out",
      [theme.breakpoints.down("md")]: {
        minWidth: "unset",
        width: "100%",
        marginTop: 24,
        order: 3,
      },
    },
  },
  responsiveBox: {
    "&.MuiStack-root": {
      minWidth: "calc(27% - 24px)",
      width: "calc(27% - 24px)",
      [theme.breakpoints.down("md")]: {
        width: "calc(50% - 24px)",
        minWidth: "unsset",
        marginLeft: 24,
        order: 2,
      },
      [theme.breakpoints.down("xsm")]: {
        width: "100%",
        margin: 0,
      },
      "& $ApplicantsBox": {
        [theme.breakpoints.down("xsm")]: {
          borderRadius: "0 0 10px 10px",
          paddingTop: 0,
          "& h6": {
            fontSize: 16,
            marginBottom: 16,
          },
          "& $imageBox": {
            height: 48,
            width: 48,
          },
          "& p": {
            fontSize: 14,
          },
          "& button": {
            width: "100%",
          },
        },
      },
    },
  },
  shiftForm: {
    maxWidth: 342,
    boxSizing: "border-box",
    margin: "20px auto 0 auto",
    width: "100%",
  },
}));

//Here components are seems written 2 times but it is for responsive design

const ShiftDetails = ({
  actionButtonsAttr,
  shiftDateObj,
  shiftStatus,
  applicantsData,
  roleId,
  businessId,
  formData,
  formPath,
  loadingStatus,
  setHideHading,
  setShowCalendar,
  shiftDetailsRefreshAction,
}) => {
  const classes = useStyles();
  const {
    matches,
    currentForm,
    initailLoadingStatus,
    roleName,
    detailListAttributes,
    selectedBusiness,
    setCurrentForm,
  } = ShiftAttributeHelper({
    roleId,
    businessId,
    setHideHading,
  });

  const {
    locumData,
    prepaymentLoading,
    handlePrePaymentClick,
    handleBrowseLocumClick,
    handleViewApplicantClick,
    handleSendMessageClick,
  } = ShiftDetailsContainer({
    applicantsData,
    businessId,
    shiftStatus,
    shiftDetailsRefreshAction,
  });

  if (initailLoadingStatus || loadingStatus) {
    return (
      <>
        <LFStack sx={{ height: "calc(100vh - 240px)" }} alignItems="center">
          <Loader />
        </LFStack>
      </>
    );
  }

  if (!formData || !length(keys(formData))) return null;

  return (
    <>
      {matches && currentForm ? (
        <>
          <BackButton path={true} onClick={() => setCurrentForm(null)} />
          <LFTypography
            variant="h6"
            component="h6"
            color="primary"
            fontSize="24px"
          >
            {
              values(detailListAttributes)?.find((obj) =>
                equal(obj?.name, currentForm),
              )?.label
            }
          </LFTypography>
          <LFBox className={`${classes.mainBox} ${classes.shiftForm}`}>
            <ShiftDetailForm
              formName={currentForm}
              formPath={formPath}
              setCurrentForm={setCurrentForm}
              defaultValue={formData?.[currentForm]}
              detailListAttributes={detailListAttributes}
              formData={formData}
              roleId={roleId}
              businessId={businessId}
            />
          </LFBox>
        </>
      ) : (
        <LFStack direction="row" className={classes.newShiftContainer}>
          <LFStack
            direction="row"
            spacing={{ md: 3, xs: 0 }}
            sx={{ flexWrap: { md: "unset", xs: "wrap" } }}
            overflow="auto"
            mr={{ xl: "-60px", lg: "-48px", xs: "unset" }}
            pr={{ xl: "60px", lg: "48px", xs: "unset" }}
          >
            <LFBox className={classes.firstCard}>
              <ShiftOverview
                businessDetail={selectedBusiness}
                shiftDateObj={shiftDateObj}
                roleName={roleName}
                buttonAttrributes={actionButtonsAttr}
                shiftStatus={shiftStatus}
                viewDetails={true}
                setShowCalendar={setShowCalendar}
                currentForm={currentForm}
              />
            </LFBox>
            <LFBox
              className={classes.secondCard}
              sx={{ marginRight: currentForm ? "324px !important" : 0 }}
            >
              <ShiftDetailList
                formData={formData}
                currentForm={currentForm}
                setCurrentForm={setCurrentForm}
                detailListAttributes={detailListAttributes}
                shiftStatus={shiftStatus}
                roleId={roleId}
                formPath={formPath}
                viewDetails={true}
                businessId={businessId}
              />
            </LFBox>
            <LFStack className={classes.responsiveBox}>
              {length(applicantsData) ? (
                locumData?.id ? (
                  <LFBox
                    className={`${classes.mainBox} ${classes.ApplicantsBox}`}
                  >
                    <LFTypography variant="h6">
                      {ternary(
                        equal(shiftStatus, shiftStatuses.pending),
                        "Unconfirmed Locum",
                        "Confirmed Locum",
                      )}
                    </LFTypography>
                    <LFBox className={classes.imageBox}>
                      <img
                        src={locumData?.avatar_url || userImage}
                        style={{ objectFit: "cover" }}
                        alt=""
                      />
                    </LFBox>
                    <LFTypography>
                      {formatName(
                        `${locumData?.first_name} ${locumData?.surname}`,
                      )}
                    </LFTypography>
                    <LFGrid item xs={12}>
                      <LFStack
                        className={classes.buttonGroup}
                        mt={"20px"}
                        spacing={2}
                      >
                        <Link
                          style={{ textDecoration: "none" }}
                          to={locationPath?.messages}
                          state={{ locumData, businessId }}
                        >
                          <LFButton
                            variant={"contained"}
                            onClick={handleSendMessageClick}
                          >
                            SEND MESSAGE
                          </LFButton>
                        </Link>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`${locationPath?.locumDetails.replace(
                            ":id",
                            locumData?.id,
                          )}`}
                        >
                          <LFButton variant="outlined">
                            {"VIEW PROFILE"}
                          </LFButton>
                        </Link>
                      </LFStack>
                    </LFGrid>
                  </LFBox>
                ) : (
                  <LFBox
                    className={`${classes.mainBox} ${classes.ApplicantsBox}`}
                  >
                    <LFTypography variant="h6">Applicants</LFTypography>
                    <LFBox className={classes.imageBox}>
                      {applicantsData?.slice(0, 3)?.map((data, index) => (
                        <img
                          key={index}
                          src={data?.attributes?.avatar_url || userImage}
                          alt=""
                        />
                      ))}
                    </LFBox>
                    <LFGrid item xs={12}>
                      <LFStack
                        className={classes.buttonGroup}
                        spacing={2}
                        mt={"47px"}
                        alignItems="center"
                      >
                        <LFButton
                          variant="contained"
                          onClick={handleViewApplicantClick}
                        >
                          VIEW APPLICANTS
                          <LFTypography
                            variant="span"
                            className={classes.counter}
                          >
                            {length(applicantsData)}
                          </LFTypography>
                        </LFButton>
                        <LFButton
                          variant="outlined"
                          onClick={handleBrowseLocumClick}
                        >
                          BROWSE LOCUMS
                        </LFButton>
                      </LFStack>
                    </LFGrid>
                  </LFBox>
                )
              ) : (
                <LFBox
                  className={`${classes.mainBox} ${classes.ApplicantsBox}`}
                >
                  <LFTypography variant="h6">Applicants</LFTypography>
                  <LFTypography mb={3} mt={{ xsm: 8.5, xs: 5 }}>
                    Currently no applicants
                  </LFTypography>
                  <LFGrid item xs={12}>
                    <LFStack className={classes.buttonGroup} spacing={2}>
                      <LFButton
                        variant="outlined"
                        onClick={handleBrowseLocumClick}
                      >
                        BROWSE LOCUMS
                      </LFButton>
                    </LFStack>
                  </LFGrid>
                </LFBox>
              )}
              {equal(shiftStatus?.toLowerCase(), shiftStatuses?.pending) &&
                (equal(formData?.prepayment_failed, true) ? (
                  <PrepaymentInfoCard
                    prepaymentFailed={true}
                    handlePrePaymentClick={handlePrePaymentClick}
                    loadingStatus={prepaymentLoading}
                    sx={{ display: { md: "block", xs: "none" } }}
                  />
                ) : (
                  <ChangeInfoCard
                    sx={{ display: { md: "block", xs: "none" } }}
                    isUnpaidEdit={equal(
                      formData?.pending_level,
                      pendingLevels.unpaidEdit,
                    )}
                  />
                ))}
              {equal(shiftStatus?.toLowerCase(), shiftStatuses?.confirmed) &&
                isGreaterThan72Hours(shiftDateObj?.startDate) && (
                  <PrepaymentInfoCard
                    loadingStatus={prepaymentLoading}
                    sx={{ display: { md: "block", xs: "none" } }}
                  />
                )}
            </LFStack>
            {equal(shiftStatus?.toLowerCase(), shiftStatuses?.pending) &&
              (equal(formData?.prepayment_failed, true) ? (
                <PrepaymentInfoCard
                  prepaymentFailed={true}
                  handlePrePaymentClick={handlePrePaymentClick}
                  loadingStatus={prepaymentLoading}
                  sx={{ display: { md: "none", xs: "block" } }}
                />
              ) : (
                <ChangeInfoCard
                  sx={{ display: { md: "none", xs: "block" } }}
                  isUnpaidEdit={equal(
                    formData?.pending_level,
                    pendingLevels.unpaidEdit,
                  )}
                />
              ))}
            {equal(shiftStatus?.toLowerCase(), shiftStatuses?.confirmed) &&
              isGreaterThan72Hours(shiftDateObj?.startDate) && (
                <PrepaymentInfoCard
                  loadingStatus={prepaymentLoading}
                  sx={{ display: { md: "none", xs: "block" } }}
                />
              )}
          </LFStack>
          <LFBox
            sx={{ display: { md: "none", xs: "flex" } }}
            width="100%"
            alignItems="center"
            flexDirection="column"
          >
            {actionButtonsAttr?.map(({ label, ...attr }, index) => (
              <LFButton key={index} {...attr} className={classes.postButtons}>
                {label}
              </LFButton>
            ))}
          </LFBox>
        </LFStack>
      )}
    </>
  );
};

export default ShiftDetails;

const ChangeInfoCard = ({ sx, isUnpaidEdit }) => {
  const classes = useStyles();
  return (
    <LFBox className={`${classes.mainBox} ${classes.confirmedBox}`} sx={sx}>
      <InfoIcon />
      <LFTypography className={classes.heading}>
        {isUnpaidEdit
          ? "Changes have been made to this completed shift."
          : "Changes have been made to this shift."}
      </LFTypography>
      <LFTypography className={classes.para}>
        {isUnpaidEdit
          ? "Locum must confirm these changes to finalize payment for this shift."
          : "Locum must confirm these changes in order to revert back to ‘CONFIRMED’ status."}
      </LFTypography>
    </LFBox>
  );
};

const PrepaymentInfoCard = ({
  handlePrePaymentClick,
  loadingStatus,
  prepaymentFailed = false,
  sx,
}) => {
  const classes = useStyles();
  return (
    <LFBox className={`${classes.mainBox} ${classes.confirmedBox}`} sx={sx}>
      <InfoIcon />
      <LFTypography className={classes.heading}>
        {ternary(
          prepaymentFailed,
          `Prepayment Required`,
          `Prepayment will be automatically processed 3 days before scheduled
        shift.`,
        )}
      </LFTypography>
      {prepaymentFailed && (
        <>
          <LFTypography className={classes.para} mb={2}>
            To secure your locum and prevent incurring cancellation fees, please
            prepay now
          </LFTypography>
          <LFButton
            variant={"contained"}
            onClick={handlePrePaymentClick}
            isLoading={loadingStatus}
            sx={{
              fontSize: 14,
              minHeight: 40,
              maxWidth: 272,
            }}
          >
            FINALISE PAYMENT
          </LFButton>
        </>
      )}
    </LFBox>
  );
};
