import { equal } from "../../utils/javascript";

export const boolenOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const boolenWithNumberOptions = [
  { label: "Yes", value: "yes" },
  { label: "Preferred", value: "preferred" },
  { label: "No", value: "no" },
];

export const openCloseOptions = [
  { label: "Open", value: "open" },
  { label: "Close", value: "close" },
  { label: "Both", value: "both" },
  { label: "Neither", value: "neither" },
];

export const payOptions = [
  { label: "N/A", value: null },
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const appointmentLengthOptions = [
  { label: "20min", value: 20 },
  { label: "30min", value: 30 },
];

export const dispenseTechNumberOptions = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2+", value: 2 },
];

export const pharmcist = {
  dispenseSoftware: {
    name: "dispenseSoftware",
    label: "Dispense Software",
    desc: "Select option",
  },
  avrgScript: {
    name: "average_scripts",
    label: "Average Scripts",
    desc: "Edit number",
  },
  solePharmicist: {
    name: "sole_pharmacist",
    label: "Sole Pharmacist",
    desc: "Select option",
  },
  dispenseTechNumber: {
    name: "tech_no",
    label: "No. of Dispense Techs",
    desc: "Select option",
  },
  pharmacotherapy: {
    name: "pharmacotherapy",
    label: "Pharmacotherapy",
    desc: "Select option",
    options: boolenOptions,
  },
  vacinatorRequired: {
    name: "immunizer_required",
    label: "Vaccinator Required",
    desc: "Select option",
    options: boolenWithNumberOptions,
  },
  doseAdministrationAids: {
    name: "administration_aids",
    label: "Dose Administration Aids",
    desc: "Select option",
  },
};

export const dispensaryTechnician = {
  dispenseSoftware: {
    name: "dispenseSoftware",
    label: "Dispense Software",
    desc: "Select option",
  },
  avrgScript: {
    name: "average_scripts",
    label: "Average Scripts",
    desc: "Edit number",
  },
  doseAdministrationAids: {
    name: "doseAdministrationAids",
    label: "Dose Administration Aids",
    desc: "Select option",
  },
};

export const pharmcyAssistant = {
  pointOfSale: {
    name: "pointOfSale",
    label: "Point of Sale",
    desc: "Select option",
  },
  takingScript: {
    name: "taking_in_scripts",
    label: "Taking in Scripts",
    desc: "Select option",
  },
  processingSales: {
    name: "processing_sales",
    label: "Processing Sales",
    desc: "Select option",
  },
  markingOff: {
    name: "marking_off",
    label: "Marking off/Putting Away Order",
    desc: "Select option",
  },
};

export const optomerist = {
  software: {
    name: "software",
    label: "Software",
    desc: "Select option",
  },
  preTesting: {
    name: "preTesting",
    label: "Pre-testing",
    desc: "Select option",
  },
  appointmentLength: {
    name: "appointment_length",
    label: "Appointment Length",
    desc: "Select option",
  },
};

export const getDetailListAttributes = ({
  roleId,
  dispenseSoftwareOptions,
  softwareOptions,
  preTestingOptions,
}) => {
  return {
    time: {
      name: "time",
      label: "Time",
      desc: "Edit time",
      isRequred: true,
    },
    rate: {
      name: "rate",
      label: "Rate (including super)",
      desc: "Edit rate",
      isRequred: true,
    },
    break: {
      name: "break",
      label: "Break",
      desc: "Edit break time",
      isRequred: true,
    },
    ...(equal(roleId, 1) && {
      dispenseSoftware: {
        name: "dispenseSoftware",
        label: "Dispense Software",
        desc: "Select option",
        options: dispenseSoftwareOptions,
        isRequred: true,
      },
      avrgScript: {
        name: "average_scripts",
        label: "Average Scripts",
        desc: "Edit number",
        isRequred: true,
      },
      solePharmicist: {
        name: "sole_pharmacist",
        label: "Sole Pharmacist",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
      dispenseTechNumber: {
        name: "tech_no",
        label: "No. of Dispense Techs",
        desc: "Select option",
        options: dispenseTechNumberOptions,
        isRequred: true,
      },
      pharmacotherapy: {
        name: "pharmacotherapy",
        label: "Pharmacotherapy",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
      vacinatorRequired: {
        name: "immunizer_required",
        label: "Vaccinator Required",
        desc: "Select option",
        options: boolenWithNumberOptions,
        isRequred: true,
      },
      doseAdministrationAids: {
        name: "administration_aids",
        label: "Dose Administration Aids",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
    }),
    ...(equal(roleId, 2) && {
      dispenseSoftware: {
        name: "dispenseSoftware",
        label: "Dispense Software",
        desc: "Select option",
        options: dispenseSoftwareOptions,
        isRequred: true,
      },
      avrgScript: {
        name: "average_scripts",
        label: "Average Scripts",
        desc: "Edit number",
        isRequred: true,
      },
      doseAdministrationAids: {
        name: "administration_aids",
        label: "Dose Administration Aids",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
    }),
    ...(equal(roleId, 3) && {
      pointOfSale: {
        name: "pointOfSale",
        label: "Point of Sale",
        desc: "Select option",
        options: dispenseSoftwareOptions,
        isRequred: true,
      },
      takingScript: {
        name: "taking_in_scripts",
        label: "Taking in Scripts",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
      processingSales: {
        name: "processing_sales",
        label: "Processing Sales",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
      markingOff: {
        name: "marking_off",
        label: "Marking off/Putting Away Order",
        desc: "Select option",
        options: boolenOptions,
        isRequred: true,
      },
    }),
    ...(equal(roleId, 4) && {
      software: {
        name: "software",
        label: "Software",
        desc: "Select option",
        options: softwareOptions,
        isRequred: true,
      },
      preTesting: {
        name: "preTesting",
        label: "Pre-testing",
        desc: "Select option",
        options: preTestingOptions,
        isRequred: true,
      },
      appointmentLength: {
        name: "appointment_length",
        label: "Appointment Length",
        desc: "Select option",
        options: appointmentLengthOptions,
        isRequred: true,
      },
    }),
    openOrClose: {
      name: "open_close",
      label: "Open or Close",
      desc: "Select option",
      options: openCloseOptions,
      isRequred: true,
    },
    payTravel: {
      name: "pay_travel",
      label: "Pay for Travel",
      desc: "N/A",
      options: payOptions,
      isRequred: false,
    },
    payAccommondation: {
      name: "pay_accommodation",
      label: "Pay for Accommodation",
      desc: "N/A",
      options: payOptions,
      isRequred: false,
    },
    payFlights: {
      name: "pay_flights",
      label: "Pay for Flights",
      desc: "N/A",
      options: payOptions,
      isRequred: false,
    },
    extraNotes: {
      name: "notes",
      label: "Extra notes",
      desc: "Extra notes go to here",
      isRequred: false,
    },
  };
};

export const rolesMinRate = {
  1: 40,
  2: 20,
  3: 14,
  4: 40,
};
//here key is roleId and value is rate for role
