import { useLocation, useNavigate } from "react-router-dom";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  locationPath,
} from "../../utils/constant";
import { useEffect } from "react";
import { getLocalStorageItem } from "../../utils/localStorage";

const PublicLayoutContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentURL = location.pathname?.split("/")?.[2];
  const tabNavbar = [
    { label: "I'M A BUSINESS", value: locationPath.welcomeBusiness },
    { label: "I'M A LOCUM", value: locationPath.welcomeLocum },
  ];

  useEffect(() => {
    if (
      getLocalStorageItem(LS_KEY_AUTHENTICATED) &&
      !getLocalStorageItem(LS_KEY_HALF_REGISTER)
    ) {
      navigate(locationPath?.myCalendar);
    }
  }, [location?.pathname]);

  const handleTabChange = (e, value) => {
    navigate(value);
  };
  return {
    currentURL,
    tabNavbar,
    handleTabChange,
  };
};

export default PublicLayoutContainer;
