import { useState } from "react";
import { ReactComponent as EditIcon } from "../assets/svg/editIcon.svg";

const EditModeHelper = ({ attributes }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [editingFieldName, setEditingFieldName] = useState(null);

  const cloneAttributes = attributes?.map((attr) => ({
    ...attr,
    sx: {
      ...(editingFieldName &&
        editingFieldName !== attr?.name && { display: "none", paddingTop: 0 }),
    },
    endAdornment: <EditIcon cursor="pointer" />,
    adormentClick: () => {
      if (attr?.name !== "email" && !isEditable) {
        setIsEditable(true);
        setEditingFieldName(attr?.name);
      }
    },
  }));

  return {
    cloneAttributes,
    isEditable,
    setIsEditable,
    setEditingFieldName,
    editingFieldName,
  };
};

export default EditModeHelper;
