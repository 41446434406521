export const selectRolesAttribute = {
  name: "role_id",
  type: "select",
  label: "Role",
};

export const selectTemplateAttribute = {
  name: "template_id",
  type: "select",
  label: "Template",
};
