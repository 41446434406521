export const SET_FORM_VALUES = "SET_FORM_VALUES";

export const CLEAR_FORM_DATA = "CLEAR_FORM_DATA";

export const LOGOUT = "LOGOUT";

export const SET_APP_DATA = "SET_APP_DATA";

export const SET_FORM_DATA = "SET_FORM_DATA";

export const SET_FORM_ERROR_DATA = "SET_FORM_ERROR_DATA";

export const LOADING_CHANGE = "LOADING_CHANGE";

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";

export const SET_GLOBAL_ROLES = "SET_GLOBAL_ROLES";

export const SET_BUSINESS_DETAILS = "SET_BUSINESS_DETAILS";

export const SET_SELECTED_BUSINESS_ID = "SET_SELECTED_BUSINESS_ID";

export const CHANGE_SKILLS_LOADING = "CHANGE_SKILLS_LOADING";

export const SET_SKILLS_DATA = "SET_SKILLS_DATA";

export const SET_SOCKET_INSTANCE = "SET_SOCKET_INSTANCE";

export const SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE";

export const SET_DEFAULT_BUSINESS = "SET_DEFAULT_BUSINESS";

export const SET_PUBLIC_HOLIDAYS = "SET_PUBLIC_HOLIDAYS";
