import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal, head } from "../../utils/javascript";
import { SET_DEFAULT_BUSINESS } from "../constant";

const getDefaultBusinesses =
  ({ performRequest, selectedbusinessType }) =>
  async (dispatch, getState) => {
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.defaultBusinesses}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });

    if (!equal(statusCode, statusCodes?.OK)) return;

    const businessType = selectedbusinessType
      ? selectedbusinessType
      : getState()?.globalAttr?.businessType;

    const businessLogos = data?.type_based_logos?.reduce((acc, curr) => {
      if (equal(curr?.business_type, businessType)) {
        acc?.push(curr?.logos?.map((logo) => logo?.attributes));
      }
      return acc;
    }, []);

    dispatch({
      type: SET_DEFAULT_BUSINESS,
      payload: head(businessLogos),
    });
  };

export default getDefaultBusinesses;
