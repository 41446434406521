import { Typography } from "@mui/material";
import LFBox from "../../shared/LFBox";
import LFStack from "../../shared/LFStack";
import classNames from "classnames";
import { themeTime } from "../../utils/moment";
import NotificationBoxContainer from "../../container/notifications/notificationBox.container";

const NotificationsBox = ({
  data,
  notifcationsData,
  formPath,
  classNameBox,
  classNameNew,
  onClick,
}) => {
  const {
    id,
    title,
    description,
    read,
    notifiable_type,
    notifiable_id,
    created_at,
    n_type,
  } = { ...data };

  const { onclickNotification } = NotificationBoxContainer({
    formPath,
    onClick,
    notifcationsData,
  });

  const showtime = themeTime(created_at);

  return (
    <LFBox
      key={id}
      style={{ cursor: "pointer" }}
      className={classNames(classNameBox, {
        [classNameNew]: !read,
      })}
      onClick={() =>
        onclickNotification({
          notifiable_type,
          notifiable_id,
          read,
          n_type,
          notificationid: id,
        })
      }
    >
      <LFStack direction="row" justifyContent="space-between" mb={0.5}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="span">{showtime}</Typography>
      </LFStack>
      <Typography>{description}</Typography>
    </LFBox>
  );
};

export default NotificationsBox;
