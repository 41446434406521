import moment from "moment";
import momentTz from "moment-timezone";
import { removeTimeStamp, ternary } from "./javascript";

export const isGreaterThan72Hours = (givenDate) => {
  const finalGivenDate = removeTimeStamp(givenDate) ?? givenDate;
  const currentDateTime = moment();
  const givenDateTime = moment(finalGivenDate);

  const differenceInHours = givenDateTime.diff(currentDateTime, "hours");

  return differenceInHours > 72;
};
export const isLessThan48Hours = (givenDate) => {
  const finalGivenDate = removeTimeStamp(givenDate) ?? givenDate;
  const currentDateTime = moment();
  const givenDateTime = moment(finalGivenDate);

  const differenceInHours = givenDateTime.diff(currentDateTime, "hours");

  return differenceInHours < 42;
};
export const getWeekDayDateAndMonth = (date) => {
  const finalDate = removeTimeStamp(date) ?? date;
  return moment(finalDate).format("dddd DD MMMM");
};
export const getyearMonthDateFormat = (date) => {
  const finalDate = removeTimeStamp(date) ?? date;
  return moment(finalDate).format("YYYY-MM-DD");
};

export const combineDateAndTime = (isoDate, time) => {
  const localDate = moment.utc(isoDate).local().format("DD/MM/YYYY");
  const time24hourFormat = convertTimeTo24HourFormat(time);
  return `${localDate} ${time24hourFormat}`;
};

export const getTotalMinutes = (time) => {
  if (!time) return null;
  const [hours, minutes] = time.split(":");
  const duration = moment.duration({ hours, minutes });
  return duration.asMinutes();
};

export const convertTimeTo24HourFormat = (timeWithPeriod) => {
  return moment(timeWithPeriod, ["h:mm A"]).format("HH:mm");
};

export const convertTimeTo12HourFormat = (time24hour) => {
  return moment(time24hour, "HH:mm").format("h:mm A");
};

export const get12hrsFormatTime = (date) => {
  const finalDate = removeTimeStamp(date) ?? date;
  return moment(finalDate).format("hh:mm A");
};

export const convertToTimeFormat = (minutes = 0) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const timeString = moment()
    .hours(hours)
    .minutes(remainingMinutes)
    .format("HH:mm");
  return timeString;
};

export const convetTimeToStrInHours = (inputTime, isMinutesonly = false) => {
  const parsedTime = isMinutesonly
    ? moment().startOf("day").add(inputTime, "minutes")
    : moment(inputTime, "HH:mm");
  const hours = parsedTime.hours();
  const formattedTime = ternary(
    hours,
    `${hours} hr ${parsedTime.minutes()} min`,
    `${parsedTime.minutes()} min`,
  );
  return formattedTime;
};

export const calculateDaysDifference = (date1, date2) => {
  const startDate = moment(date1);
  const endDate = moment(date2);

  return endDate.diff(startDate, "days") + 1;
};

export const compareTimes = (time1, time2) => {
  const format = "hh:mm A";
  const momentTime1 = moment(time1, format);
  const momentTime2 = moment(time2, format);
  return momentTime1.isAfter(momentTime2);
};

export const getCurrentTimezone = () => {
  return momentTz.tz.guess();
};

export const themeTime = (inputTime) => {
  let showtime;
  const currentDate = moment();
  const localTime = moment(inputTime).local();
  showtime = moment(localTime).format("Do MMM YYYY");

  const currentDateDay = moment().startOf("day");
  const inputDate = moment(inputTime).startOf("day");

  showtime = moment(localTime).format("D MMM YYYY");

  if (currentDateDay.diff(moment(inputDate), "days") <= 7)
    showtime = moment(inputTime).format("dddd");

  if (currentDate.diff(moment(inputDate), "days") == 1) showtime = "Yesterday";

  if (moment(localTime).isSame(currentDate, "day"))
    showtime = moment(localTime, "h:mm a").format("h:mm A");

  return showtime;
};

export const convertTimeToMinutes = (time) => {
  const format = "HH:mm"; // Use 'HH:mm' format for 24-hour time
  const momentTime = moment(time, format);

  const minutes = momentTime.hours() * 60 + momentTime.minutes();
  return minutes;
};

export const getDateInSeparate = (date) => {
  return moment(removeTimeStamp(date))
    ?.format("h:mmA")
    ?.replace(":00", "")
    ?.toLocaleLowerCase();
};
