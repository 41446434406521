import React from "react";
import SkillsSlides from "./SkillsSlides";
import { keys } from "../../utils/javascript";
import { makeStyles } from "@mui/styles";
import RolesSectionContainer from "../../container/searchLocum/RolesSection.container";
import LFStack from "../../shared/LFStack";
import LFFormLabel from "../../shared/LFFormLabel";
import LFSwitch from "../../shared/LFSwitch";
import { pharmacy } from "../../container/searchLocum/constants";
import { Divider } from "@mui/material";
import LFTypography from "../../shared/LFTypograpy";
import Loader from "../reusable/Loader";
import LFBox from "../../shared/LFBox";
const All = "All";

const useStyles = makeStyles((theme) => ({
  divider: {
    "&.MuiDivider-root": {
      margin: [[20, 0]],
      borderColor: theme.palette.gray.dark,
    },
  },
  switchBox: {
    "&.MuiStack-root": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "& label": {
      fontSize: 16,
      color: theme.palette.primary.main,
    },
  },
}));

const RolesSection = ({ searchLocumFormPath, defaultValues }) => {
  const classes = useStyles();

  const {
    rolesSwitches,
    switchToggle,
    skillsArray,
    chipToggle,
    allSwitchToggle,
    skillsLoading,
    allSkillsArray,
    businessType,
    rolesObj,
  } = RolesSectionContainer({
    formPath: searchLocumFormPath,
    defaultValues,
  });

  const allSwitch = (
    <LFBox key="all">
      <LFStack className={classes.switchBox}>
        <LFFormLabel>{All}</LFFormLabel>

        <LFSwitch
          name={All}
          onChange={(e) => allSwitchToggle({ e })}
          checked={
            allSkillsArray?.every((element) =>
              skillsArray?.includes(element),
            ) ?? false
          }
        />
      </LFStack>
      <Divider className={classes.divider} />
    </LFBox>
  );

  return (
    <LFBox>
      {skillsLoading ? (
        <Loader />
      ) : (
        <LFBox>
          <LFTypography
            variant="h6"
            component="h6"
            className={classes.smallHeading}
          >
            {businessType === pharmacy ? "Roles" : "Role"}
          </LFTypography>
          <br />
          {businessType === pharmacy && allSwitch}
          {keys(rolesObj)?.map((rolekey) => {
            let switchState = false;
            if (rolesSwitches) switchState = rolesSwitches[rolekey];
            return (
              <div key={rolekey}>
                <SkillsSlides
                  switchState={switchState}
                  name={rolekey}
                  skills={rolesObj[rolekey]}
                  switchToggle={switchToggle}
                  skillsArray={skillsArray}
                  chipToggle={chipToggle}
                />
              </div>
            );
          })}
        </LFBox>
      )}
    </LFBox>
  );
};

export default RolesSection;
