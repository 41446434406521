import React from "react";
import LFBox from "../../shared/LFBox";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import LocumUserContainer from "../../container/welcome/locumUser.container";
import { Link } from "react-router-dom";

const LocumUser = () => {
  const { mobileStore } = LocumUserContainer();

  return (
    <LFBox
      textAlign="center"
      mt={{ msm: 10.5, xs: 5 }}
      minHeight={{ xs: 279, xxsm: 409 }}
    >
      <LFTypography
        variant="h2"
        component="h2"
        color="white.main"
        mb={2}
        fontWeight={400}
        fontSize={{ xxsm: 60, xs: 30 }}
      >
        Download the App
      </LFTypography>
      <LFTypography
        color="white.main"
        mb={7}
        variant="h6"
        component="h6"
        fontSize={18}
        maxWidth={290}
        lineHeight="normal"
        margin="0 auto"
      >
        LocumFind for Locums is available on both iOS and Android
      </LFTypography>
      <LFStack
        direction="row"
        justifyContent="center"
        spacing={{ md: 4, xs: 2 }}
        mt={7.25}
      >
        {mobileStore.map(({ image, url }, index) => (
          <LFBox key={index}>
            <Link to={url} target="_blank">
              <img src={image} alt="mobile store" />
            </Link>
          </LFBox>
        ))}
      </LFStack>
    </LFBox>
  );
};

export default LocumUser;
