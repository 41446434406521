import { useEffect, useRef, useState } from "react";
import ApiContainer from "../api.container";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  socketConstants,
  statusCodes,
} from "../../utils/constant";
import { equal, isArray, last, ternary } from "../../utils/javascript";
import { useNavigate } from "react-router-dom";
import { notEmpty } from "../../utils/regex";
import { useDispatch, useSelector } from "react-redux";
import readConversationAction from "../../redux/action/readConversationAction";

const ChatsContainer = ({
  selectedTab,
  formPath,
  chatSocket,
  messagesData,
  setMessagesData,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialLoader = useSelector(
    (state) => state.app?.loader?.[formPath?.child],
  );
  const businessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const businessDetail = useSelector(
    (state) => state?.globalAttr?.businessDetail,
  );
  const businessName = businessDetail?.find((business) =>
    equal(business?.id, businessId),
  )?.name;

  const [inputText, setInputText] = useState("");
  const [maxPage, setMaxPage] = useState(1);
  const textAreaRef = useRef(null);

  const { performRequest } = ApiContainer();

  const selectedTabMessages = messagesData[selectedTab?.id];

  const getMessagesById = async (id, page) => {
    const selectedTabMessages = messagesData[selectedTab?.id];
    let messageId;
    if (isArray(selectedTabMessages)) {
      const lastMessage = last(selectedTabMessages);
      messageId = lastMessage?.attributes?.id;
    }
    const perPage = 50;
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints.conversations}/${id}`,
      method: apiMethods.get,
      queryParams: { page, message_id: messageId, per_page: perPage },
      tokenKey: "Authorization",
      showFailedMessage: true,
      needLoader: true,
      parent: formPath?.child,
    });
    if (!equal(statusCode, statusCodes?.OK)) return;
    const {
      conversation: { id: conversationId, messages },
      pagy_links: { pages },
    } = { ...data };

    setMaxPage(pages);
    setMessagesData({
      ...messagesData,
      [conversationId]: [...(messagesData[conversationId] || []), ...messages],
    });
  };

  useEffect(() => {
    setInputText("");
    if (selectedTab?.id && !selectedTabMessages) {
      getMessagesById(selectedTab?.id, 1, null);
    }
    return () => {
      if (selectedTab?.id) {
        dispatch(
          readConversationAction({
            performRequest,
            id: selectedTab?.id,
          }),
        );
      }
    };
  }, [selectedTab?.id]);

  const adjustTextareaHeight = () => {
    const textarea = textAreaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleTextChange = (event) => {
    setInputText(event?.target?.value);
  };
  const onDeleteMessage = (id) => {
    chatSocket.send(
      JSON.stringify({
        command: "message",
        data: JSON.stringify({
          message_id: id,
          action: "destroy",
        }),
        identifier: JSON.stringify({
          channel: socketConstants.channel.conversations,
        }),
      }),
    );
  };
  const handleSendMessage = () => {
    if (notEmpty(inputText)) {
      const chatContainer = document.getElementById(scrollableChatsDiv);
      if (chatContainer) {
        chatContainer.scrollTop = 0;
      }
      chatSocket.send(
        JSON.stringify({
          command: "message",
          data: JSON.stringify({
            body: inputText,
            conversation_id: selectedTab?.id,
            messagable_type: "Business",
            messagable_id: businessId,
          }),
          identifier: JSON.stringify({
            channel: socketConstants.channel.conversations,
          }),
        }),
      );
      setInputText("");
      const textarea = textAreaRef.current;
      textarea.style.height = "auto";
    }
  };
  const handleKeyDown = (e) => {
    if (equal(e.keyCode, 13) && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const goToLocumDetail = (id) => {
    return navigate(locationPath.locumDetails.replace(":id", id), {
      state: { locumId: id, businessId },
    });
  };

  const loadMoreMessages = () => {
    return getMessagesById(selectedTab?.id, 1);
  };
  const hasNextPage = ternary(equal(maxPage, 1), false, true);
  const scrollableChatsDiv = "scrollableChatsDiv";

  return {
    inputText,
    initialLoader,
    selectedTabMessages,
    hasNextPage,
    scrollableChatsDiv,
    businessId,
    businessName,
    textAreaRef,
    goToLocumDetail,
    handleSendMessage,
    onDeleteMessage,
    handleTextChange,
    handleKeyDown,
    loadMoreMessages,
    adjustTextareaHeight,
  };
};

export default ChatsContainer;
