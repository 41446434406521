import { SET_FORM_DATA } from "../constant";

const formDataAction =
  (payload, path, withPrevValues = false) =>
  (dispatch, getState) => {
    let previousValues = {};
    if (withPrevValues) {
      previousValues = getState()?.form?.formValues?.[path];
    }
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [path]: { ...previousValues, ...payload },
      },
    });
  };

export default formDataAction;
