import LFBox from "../../shared/LFBox";
import LFButton from "../../shared/LFButton";
import LFTypography from "../../shared/LFTypograpy";
import userImage from "../../assets/png/user.jpeg";
import LFStack from "../../shared/LFStack";
import { ReactComponent as SendIcon } from "../../assets/svg/sendIcon.svg";
import ChatsContainer from "../../container/messages/chats.container";
import Loader from "../reusable/Loader";
import { equal, formatName, length } from "../../utils/javascript";
import classNames from "classnames";
import { get12hrsFormatTime, themeTime } from "../../utils/moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import ShiftLinkCard from "./ShiftLinkCard";
import MessageMenu from "./MessageMenu";
import { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  chatHeader: {
    "&.MuiStack-root": {
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      paddingBottom: 15,
      [theme.breakpoints.down("xxsm")]: {
        flexDirection: "column",
      },
    },
    "& img": {
      height: 68,
      width: 68,
      borderRadius: 50,
      marginRight: 24,
      [theme.breakpoints.down("xxsm")]: {
        margin: [[0, 0, 8, 0]],
        height: 48,
        width: 48,
      },
    },
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
    "& button": {
      marginLeft: "auto",
      fontSize: 14,
      padding: [[11, 35]],
      [theme.breakpoints.down("xxsm")]: {
        display: "none",
      },
    },
  },
  chatBox: ({ isScrollbarVisible }) => ({
    padding: [[30, 5, 0, 0]],
    overflow: "auto",
    boxSizing: "border-box",
    flex: 1,
    [theme.breakpoints.down("md")]: {
      padding: [[16, 0, 0]],
    },
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: isScrollbarVisible ? "#8a8a8a" : "transparent",
      borderRadius: "5px",
      "&:hover": {
        background: "#8a8a8a",
      },
    },
  }),
  chatDay: {
    "&.MuiTypography-root": {
      textAlign: "center",
      marginBottom: 12,
      fontSize: 15,
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 10,
      },
    },
  },
  messageBox: {
    marginBottom: 5,
    paddingTop: 18,
    "& span": {
      fontSize: 10,
      marginTop: 4,
      color: theme.palette.secondary.dark,
    },
  },
  message: {
    "&.MuiTypography-root": {
      padding: 8,
      background: theme.palette.gray.main,
      borderRadius: [[12, 12, 12, 0]],
      width: "max-content",
      maxWidth: 370,
      whiteSpace: "pre-line",
      boxSizing: "border-box",
      wordWrap: "break-word",
      [theme.breakpoints.down("xxsm")]: {
        maxWidth: "75%",
      },
    },
  },
  senderMessage: {
    alignItems: "end",
    position: "relative",
    "&>p": {
      "&.MuiTypography-root": {
        background: theme.palette.secondary.lightGreen,
        borderRadius: [[12, 12, 0, 12]],
        marginLeft: "auto",
      },
    },
    "& .menuIcon": {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: -2,
      opacity: 0,
      "&.MuiSvgIcon-root": {
        width: 13,
      },
    },
    "&:hover": {
      "& .menuIcon": {
        opacity: 1,
        zIndex: 2,
      },
    },
  },
  messageInputbox: {
    position: "relative",
    borderTop: `1px solid ${theme.palette.gray.dark}`,
    "& textarea": {
      boxSizing: "border-box",
      border: 0,
      padding: [[12, 50, 12, 16]],
      fontSize: 16,
      color: theme.palette.dark.main,
      resize: "none",
      margin: 0,
      fontFamily: theme.typography.fontFamily,
      maxHeight: 120,
      "&:focus-visible": {
        outline: "none",
      },
      "&:focus": {
        outline: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 3,
      },
    },
    "& button": {
      position: "absolute",
      right: 16,
      padding: 0,
      height: 24,
      width: 24,
      bottom: 10,
      minWidth: "unset",
      "&:hover": {
        background: "transparent",
      },
    },
  },
}));
const Chats = ({
  selectedTab,
  chatSocket,
  formPath,
  messagesData,
  setMessagesData,
  selectedUser,
  matches,
}) => {
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const handleScroll = () => {
    setIsScrollbarVisible(true);
    clearTimeout(scrollTimeoutRef.current);
    scrollTimeoutRef.current = setTimeout(
      () => setIsScrollbarVisible(false),
      500,
    );
  };
  const classes = useStyles({ isScrollbarVisible });
  const {
    inputText,
    initialLoader,
    selectedTabMessages,
    hasNextPage,
    scrollableChatsDiv,
    businessId,
    businessName,
    textAreaRef,
    goToLocumDetail,
    handleSendMessage,
    onDeleteMessage,
    handleTextChange,
    handleKeyDown,
    loadMoreMessages,
    adjustTextareaHeight,
  } = ChatsContainer({
    selectedTab,
    chatSocket,
    formPath,
    messagesData,
    setMessagesData,
  });

  const handleProfileClick = () => {
    return goToLocumDetail(selectedUser?.id);
  };

  if ((initialLoader && !length(selectedTabMessages)) || !selectedUser?.id)
    return <Loader />;

  return (
    <LFStack
      height={{
        xxsm: "calc(100vh - 174px)",
        md: "calc(100svh - 174px)",
        xs: "calc(100vh - 129px)",
      }}
      minHeight={"520px"}
      key={selectedTab?.id}
    >
      <LFBox>
        <LFStack direction="row" className={classes.chatHeader}>
          <img
            src={selectedUser?.avatar_url || userImage}
            alt="profile photo"
            {...(matches && {
              style: { cursor: "pointer" },
              onClick: handleProfileClick,
            })}
          />
          <LFTypography
            {...(matches && {
              sx: { cursor: "pointer" },
              onClick: handleProfileClick,
            })}
          >
            {formatName(selectedUser?.name)}
          </LFTypography>
          <LFButton variant="outlined" onClick={handleProfileClick}>
            {"VIEW PROFILE"}
          </LFButton>
        </LFStack>
      </LFBox>
      <LFStack
        className={classes.chatBox}
        direction="column-reverse"
        id={scrollableChatsDiv}
        onScroll={() => handleScroll()}
      >
        {selectedTabMessages && (
          <InfiniteScroll
            dataLength={length(selectedTabMessages)}
            inverse={true}
            hasMore={hasNextPage}
            next={loadMoreMessages}
            style={{ display: "flex", flexDirection: "column-reverse" }}
            loader={
              <Loader
                overflow={"hidden"}
                height={"max-content"}
                sx={{ padding: "10px 0" }}
              />
            }
            scrollableTarget={scrollableChatsDiv}
          >
            {selectedTabMessages &&
              [...selectedTabMessages]?.map(({ attributes }, index) => {
                const curMessageDate = moment(attributes?.created_at)
                  .local()
                  .format("Do MMM YYYY");
                const prevMessageDate = moment(
                  selectedTabMessages?.[index + 1]?.attributes?.created_at,
                )
                  .local()
                  .format("Do MMM YYYY");
                const todaysDate = moment().local().format("Do MMM YYYY");

                let showingTime = "";
                if (!equal(curMessageDate, prevMessageDate)) {
                  showingTime = themeTime(attributes?.created_at);
                  if (equal(curMessageDate, todaysDate)) showingTime = "Today";
                }
                const isSenderMsg =
                  equal(attributes?.messagable_id, businessId) &&
                  equal(attributes?.messagable_type, "Business");

                return (
                  <LFStack key={attributes?.id}>
                    {showingTime && (
                      <LFTypography className={classes.chatDay}>
                        {showingTime}
                      </LFTypography>
                    )}
                    <LFStack
                      className={classNames(classes.messageBox, {
                        [classes.senderMessage]: isSenderMsg,
                      })}
                      key={attributes?.id}
                    >
                      {isSenderMsg && (
                        <MessageMenu
                          onDeleteMessage={() =>
                            onDeleteMessage(attributes?.id)
                          }
                        />
                      )}
                      <LFTypography className={classes.message}>
                        {attributes?.body}
                      </LFTypography>
                      {attributes?.body?.includes(
                        `${process.env.REACT_APP_WEB_URL}/view-shift?shiftId=`,
                      ) && (
                        <ShiftLinkCard
                          message={attributes?.body}
                          isSenderMsg={isSenderMsg}
                          businessName={businessName}
                          selectedUser={selectedUser}
                          businessId={businessId}
                        />
                      )}
                      <LFTypography variant="span">
                        {removeZeroFromTimeString(
                          get12hrsFormatTime(attributes?.created_at),
                        )}
                      </LFTypography>
                    </LFStack>
                  </LFStack>
                );
              })}
          </InfiniteScroll>
        )}
      </LFStack>
      <LFStack className={classes.messageInputbox}>
        <textarea
          ref={textAreaRef}
          type="text"
          placeholder="Type your message..."
          rows={1}
          value={inputText}
          onChange={handleTextChange}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
        />
        <LFButton onClick={handleSendMessage}>
          <SendIcon />
        </LFButton>
      </LFStack>
    </LFStack>
  );
};

export default Chats;

const removeZeroFromTimeString = (timeString) => {
  const timeArray = timeString.split(":");
  timeArray[0] = Number(timeArray[0]);
  return timeArray.join(":")?.toLowerCase();
};
