import LFStack from "../../../shared/LFStack";
import { useState } from "react";
import LFDropDown from "../../../shared/LFDropDown";
import {
  calculateTimeDifference,
  checkLessThan3Hours,
  equal,
  ternary,
  values,
} from "../../../utils/javascript";
import FromActionButtons from "./FromActionButtons";
import LFTimeField from "../../../shared/LFTimeField";
import {
  convertTimeTo24HourFormat,
  convertTimeToMinutes,
  getTotalMinutes,
} from "../../../utils/moment";
import { FormHelperText } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import { showToast } from "../../../utils/toastService";
import { ZERO, pendingLevels, shiftStatuses } from "../../../utils/constant";
import { validateOvertime } from "../../../utils/dateOvertimeValidation";

const TimeFormTemplate = ({
  setCurrentForm,
  defaultValue,
  onSave,
  formPath,
  formData,
  roleId,
  businessId,
}) => {
  const shiftForm = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const viewShiftData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );
  const globalAttr = useSelector((state) => state?.globalAttr);

  const [time, setTime] = useState(
    defaultValue ?? {
      startTime: {
        value: "09:00",
        ampm: "am",
      },
      endTime: {
        value: "05:00",
        ampm: "pm",
      },
    },
  );

  const timeFormAttributes = {
    startTime: {
      textField: {
        name: "startTime",
        label: "Start time",
      },
      select: { name: "startTime" },
    },
    endTime: {
      textField: {
        name: "endTime",
        label: "End time",
      },
      select: { name: "endTime" },
    },
  };

  const timeDifference = calculateTimeDifference(
    time?.startTime,
    time?.endTime,
  );
  const allowedShiftDiffMinutes = 60;
  const isError = getTotalMinutes(timeDifference) < allowedShiftDiffMinutes;
  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const timeValue = ternary(value?._isValid, value?.format("HH:mm"), "12:00");
    return setTime({ ...time, [name]: { ...time[name], value: timeValue } });
  };
  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    return setTime({ ...time, [name]: { ...time[name], ampm: value } });
  };

  const handleSaveClick = () => {
    const startDate =
      shiftForm?.start_date &&
      moment(shiftForm?.start_date).format("DD/MM/YYYY");
    const currentDate = moment().format("DD/MM/YYYY");

    const { startTime, endTime } = { ...time };

    if (equal(startDate, currentDate)) {
      const currentTime = moment().format("HH:mm");
      const endTime = convertTimeTo24HourFormat(
        `${time?.endTime?.value} ${time?.endTime?.ampm}`,
      );
      const currentMoment = moment(currentTime, "HH:mm");
      const givenMoment = moment(endTime, "HH:mm");

      const isUnpaid = equal(viewShiftData?.status, shiftStatuses?.unpaid);
      const isPendingUnpaidEdit =
        equal(viewShiftData?.status, shiftStatuses?.pending) &&
        equal(shiftForm?.pending_level, pendingLevels?.unpaidEdit);

      const checkAfterCurrentTime = viewShiftData?.status
        ? !(isUnpaid || isPendingUnpaidEdit) &&
          currentMoment.isAfter(givenMoment)
        : currentMoment.isAfter(givenMoment);

      if (checkAfterCurrentTime) {
        return showToast(
          "The shift's end time must be later than the current time. Please choose a valid end time.",
        );
      }
    }
    if (checkLessThan3Hours(startTime, endTime, formData?.break)) {
      return showToast(
        "Minimum shift duration must be at least 3 hours exlcuding break time.",
      );
    }

    const diffrence = calculateTimeDifference(startTime, endTime);
    const breakDiffrence =
      convertTimeToMinutes(diffrence) -
      Number(ternary(formData?.break, formData?.break, 0));
    if (formData?.break) {
      if (breakDiffrence < ZERO)
        return showToast("The Shift time should be more than break");
    }

    validateOvertime({
      breakDiffrenceMins: breakDiffrence,
      roleId,
      date: shiftForm?.start_date,
      showTeal: true,
      businessId,
      globalAttr,
    });

    onSave(time);
  };

  return (
    <>
      <LFStack spacing={3}>
        {values(timeFormAttributes)?.map(({ textField, select }, index) => (
          <LFStack key={index} direction="row" alignItems="flex-end" gap={3}>
            <LFTimeField
              {...textField}
              format={"hh:mm"}
              onChange={handleTimeChange}
              value={time?.[textField?.name]?.value}
              error={isError}
            />
            <LFDropDown
              {...select}
              onChange={handleDropdownChange}
              value={time?.[select?.name]?.ampm}
              options={[
                { label: "AM", value: "am" },
                { label: "PM", value: "pm" },
              ]}
              preSelected={"AM"}
              timePeriod={true}
              sx={{ minWidth: 58, flex: 1 }}
            />
          </LFStack>
        ))}
        {isError && (
          <FormHelperText error>
            {"Please choose at least 1 hour."}
          </FormHelperText>
        )}
      </LFStack>
      <FromActionButtons
        setCurrentForm={setCurrentForm}
        handleSaveClick={handleSaveClick}
        isDisabled={isError}
      />
    </>
  );
};

export default TimeFormTemplate;
