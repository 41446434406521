import React from "react";
import ProfileContainer from "../../../container/account/profile.container";
import EditModeHelper from "../../../helper/EditModeHelper";
import {
  ProfileFormPath,
  profileAttributes,
} from "../../../description/account/profile.description";
import LFTypography from "../../../shared/LFTypograpy";
import EditForm from "../../../helper/EditForm";
import Loader from "../../reusable/Loader";
import LFGrid from "../../../shared/LFGrid";
import theme from "../../../themes/theme";

const Profile = () => {
  const {
    cloneAttributes,
    isEditable,
    setIsEditable,
    editingFieldName,
    setEditingFieldName,
  } = EditModeHelper({
    attributes: profileAttributes,
  });
  const { userData, initailLoadingStatus, handleProfileEditSubmit } =
    ProfileContainer({
      formPath: ProfileFormPath,
      setIsEditable,
    });

  if (!userData) {
    if (initailLoadingStatus) return <Loader />;
    return null;
  }

  const { first_name, surname, email, telephone } = userData;

  return (
    <LFGrid container spacing={3}>
      <LFGrid item xs={12} md={8} position="relative">
        <LFTypography
          variant="h5"
          component="h5"
          mb={1.5}
          color="primary"
          fontWeight="500"
          sx={{
            borderBottom: {
              xxsm: "none",
              xs: `1px solid ${theme.palette.secondary.dark}`,
            },
            pb: 1.5,
            display: { md: "none", xs: "block" },
          }}
        >
          Profile
        </LFTypography>
        <LFTypography
          variant="h6"
          component="h6"
          mb={{ xxsm: 3, xs: 2 }}
          color="primary"
          fontWeight="400"
        >
          Personal Details
        </LFTypography>
        <LFGrid item xs={12}>
          <EditForm
            attribute={cloneAttributes}
            defaultValues={{ first_name, surname, email, telephone }}
            formPath={ProfileFormPath}
            setIsEditable={setIsEditable}
            isEditable={isEditable}
            setEditingFieldName={setEditingFieldName}
            editingFieldName={editingFieldName}
            handleSubmit={handleProfileEditSubmit}
          />
        </LFGrid>
      </LFGrid>
    </LFGrid>
  );
};

export default Profile;
