import React, { useEffect, useState } from "react";
import PromptTemplet from "../../reusable/PromptTemplet";
import { useLocation, useNavigate } from "react-router-dom";
import { locationPath } from "../../../utils/constant";
import { makeStyles } from "@mui/styles";
import LFBox from "../../../shared/LFBox";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));

const AddShiftSuccess = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [templatePrompt, setTemplatePropmt] = useState(false);

  const shiftData = state?.shiftData;
  const templateNumber = state?.templateNumber;

  useEffect(() => {
    if (!state?.showSuccess) {
      navigate(-1, { replace: true });
    }
  }, []);

  const handleDoneClick = () => {
    if (shiftData?.template_id) return navigate(locationPath?.myCalendar);
    return setTemplatePropmt(true);
  };

  const handleConfirmClick = () => {
    return navigate(locationPath?.addTemplate, {
      state: { shiftData, shiftPosted: true, templateNumber },
    });
  };

  const handleCancelClick = () => {
    return navigate(locationPath?.myCalendar);
  };

  const buttonAttributes = [
    {
      label: "Done",
      onClick: handleDoneClick,
      variant: "contained",
      sx: { minWidth: { xs: "90%", xts: "342px", sm: "unset" } },
    },
  ];
  const PromptButtonAttributes = [
    {
      label: "SAVE TEMPLATE",
      onClick: handleConfirmClick,
      variant: "contained",
      sx: { minWidth: "max-content" },
    },
    {
      label: "cancel",
      onClick: handleCancelClick,
      variant: "",
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        fontSize: 16,
        mt: 2,
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];

  return (
    <LFBox className={classes.promptWrapper}>
      {templatePrompt ? (
        <PromptTemplet
          mainText={"Would you like to save template?"}
          buttonAttributes={PromptButtonAttributes}
          bgGray={true}
        />
      ) : (
        <PromptTemplet
          mainText={"Shift has been posted."}
          buttonAttributes={buttonAttributes}
          iconSrc={true}
          bgGray={true}
        />
      )}
    </LFBox>
  );
};

export default AddShiftSuccess;
