import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import getConversationsAction from "../../redux/action/getConversationsAction";
import ApiContainer from "../api.container";

const ChatContactsContainer = ({
  setSelectedTab,
  formPath,
  businessId,
  setShowChat,
}) => {
  const dispatch = useDispatch();
  const contactLoader = useSelector(
    (state) => state.app?.loader?.[formPath?.parent],
  );
  const contactData = useSelector(
    (state) => state.app?.data?.[formPath?.parent],
  );
  const maxPage = contactData?.[businessId]?.[formPath?.maxPage];

  const scrollableDivRef = useRef(null);

  const { performRequest } = ApiContainer();

  useEffect(() => {
    const handleScroll = () => {
      const scrollableDiv = scrollableDivRef?.current;
      if (
        scrollableDiv?.scrollTop + scrollableDiv?.clientHeight >=
        scrollableDiv?.scrollHeight
      ) {
        if (maxPage > 1) {
          dispatch(
            getConversationsAction({
              formPath,
              performRequest,
              businessId,
            }),
          );
        }
      }
    };

    const scrollableDiv = scrollableDivRef.current;
    scrollableDiv?.addEventListener("scroll", handleScroll);

    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [maxPage]);

  const handleChatClick = (id, userId) => {
    setShowChat(true);
    setSelectedTab({ id, userId });
  };

  return { scrollableDivRef, contactLoader, handleChatClick };
};

export default ChatContactsContainer;
