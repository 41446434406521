import { equal, values } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const deleteMessageAction = (message, formPath) => (dispatch, getState) => {
  const contactData = getState().app?.data?.[formPath?.parent];
  let businessId;
  values(contactData)?.some(({ data }) =>
    data?.some(({ attributes }) => {
      const isEqual = equal(attributes?.id, message?.conversation_id);
      if (isEqual) {
        businessId = attributes?.business?.id;
      }
      return isEqual;
    }),
  );
  const businessConversations = contactData?.[businessId]?.data;
  if (businessConversations) {
    const newContactData = businessConversations?.map((data) => {
      if (equal(data?.attributes?.last_message?.id, message?.message_id)) {
        data.attributes.last_message = message?.conversation_last_message;
      }
      return data;
    });

    return dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: {
          ...contactData,
          [businessId]: {
            ...contactData?.[businessId],
            data: newContactData,
          },
        },
      },
    });
  }
};

export default deleteMessageAction;
