import { useDispatch, useSelector } from "react-redux";
import ApiContainer from "../container/api.container";
import { equal, keys, ternary, values } from "../utils/javascript";
import { useEffect, useMemo, useState } from "react";
import getRolesAction from "../redux/action/getRolesAction";
import { getDetailListAttributes } from "../description/shift/shiftDetailList.description";
import {
  DISPENSESOFTWARE,
  PRETESTING,
  SOFTWARE,
  ZERO,
} from "../utils/constant";
import { useMediaQuery } from "@mui/material";

const ShiftAttributeHelper = ({
  roleId,
  businessId,
  formData,
  setHideHading,
}) => {
  const matches = useMediaQuery("(max-width: 991px)");
  const dispatch = useDispatch();
  const { performRequest } = ApiContainer();
  const [currentForm, setCurrentForm] = useState(null);
  const globalAttr = useSelector((state) => state?.globalAttr);

  const initailLoadingStatus = useSelector(
    (state) => state?.app?.loader?.["businessDetailsLoader"],
  );
  const businessDetail = globalAttr?.businessDetail;
  const selectedBusiness = businessDetail?.find((business) =>
    equal(business?.id, businessId),
  );
  const roles = globalAttr?.roles;
  const roleName = roles?.find((role) => equal(role?.attributes?.id, roleId))
    ?.attributes?.name;
  const allrolesAndSkills = globalAttr?.allRoleAndSkills;
  const roleTypesWithSkills =
    allrolesAndSkills?.[selectedBusiness?.type]?.[
      ternary(equal(roleName, "Pharmacy Assistant"), "Pharmacist", roleName)
    ];

  useEffect(() => {
    if (equal(roles?.length, ZERO))
      dispatch(getRolesAction({ performRequest, needSkills: true }));

    if (setHideHading && matches) {
      if (currentForm) {
        setHideHading(true);
      } else {
        setHideHading(false);
      }
    }
  }, [currentForm]);

  const extractSkillFromRolesArray = (key) => {
    return roleTypesWithSkills?.[key]?.map((obj) => ({
      label: obj?.name,
      value: +obj?.id,
    }));
  };

  const dispenseSoftwareOptions = extractSkillFromRolesArray(DISPENSESOFTWARE);
  const preTestingOptions = extractSkillFromRolesArray(PRETESTING);
  const softwareOptions = extractSkillFromRolesArray(SOFTWARE);

  const detailListAttributes = useMemo(
    () =>
      getDetailListAttributes({
        dispenseSoftwareOptions,
        softwareOptions,
        preTestingOptions,
        roleId,
      }),
    [roleId, businessId, allrolesAndSkills],
  );

  const validateShiftDetails = () => {
    let isError = false;
    const extraNotesName = detailListAttributes?.extraNotes?.name;
    const finalListAttributes = {};
    const finalformData = {};

    keys(detailListAttributes)?.forEach((key) => {
      if (detailListAttributes?.[key]?.isRequred === true)
        finalListAttributes[key] = detailListAttributes[key];
    });

    keys(formData)?.forEach((key) => {
      if (!equal(key, extraNotesName)) finalformData[key] = formData[key];
    });

    if (!validateAttributes(finalListAttributes, finalformData)) {
      return (isError = true);
    }

    return isError;
  };

  const isSubmitDisabled = validateShiftDetails();

  return {
    matches,
    currentForm,
    setCurrentForm,
    initailLoadingStatus,
    roleName,
    detailListAttributes,
    selectedBusiness,
    isSubmitDisabled,
  };
};

export default ShiftAttributeHelper;

const validateAttributes = (attributes, form) => {
  let isValid = true;

  values(attributes)?.forEach((attributeValues) => {
    const attributeName = attributeValues?.name;

    if (!keys(form)?.includes(attributeName)) {
      isValid = false;
    } else {
      const attributeValue = form[attributeName];
      if (
        equal(attributeValue, "") ||
        equal(attributeValue, null) ||
        equal(attributeValue, undefined)
      ) {
        isValid = false;
      }
    }
  });

  return isValid;
};
