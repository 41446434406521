import React from "react";
import LFBox from "../../shared/LFBox";
import LFForm from "../../shared/LFForm";
import LFFormFields from "../../shared/LFFormFields";
import LFButton from "../../shared/LFButton";
import {
  resetPasswordAttribute,
  defaultValues,
  resetPasswordFromPath,
} from "../../description/resetPassword.description";
import FormContainer from "../../container/form.container";
import ResetPasswordContainer from "../../container/resetPassword.container";
import LFGrid from "../../shared/LFGrid";

const ResetPassword = () => {
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: resetPasswordAttribute,
      formPath: resetPasswordFromPath,
      defaultValues,
    });

  const { isValid, loadingStatus, handleResetPasswordSubmit } =
    ResetPasswordContainer({
      formData,
      formError,
      formPath: resetPasswordFromPath,
      validate,
    });

  return (
    <>
      {isValid ? (
        <LFBox width="100%">
          <LFForm>
            <LFFormFields
              attributes={resetPasswordAttribute}
              formData={formData}
              formError={formError}
              handleChange={handleChange}
            />
            <LFGrid item xs={12}>
              <LFBox textAlign="center" width="100%">
                <LFButton
                  type={"submit"}
                  onClick={handleResetPasswordSubmit}
                  variant={"outlined"}
                  isLoading={loadingStatus}
                  disabled={isFormError}
                  sx={{
                    minWidth: 280,
                    mt: { md: 15, sm: 10, xs: 5 },
                    mx: "auto",
                  }}
                >
                  NEXT
                </LFButton>
              </LFBox>
            </LFGrid>
          </LFForm>
        </LFBox>
      ) : null}
    </>
  );
};

export default ResetPassword;
