import { lowerCase } from "./javascript";

export const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(lowerCase(email));
};

export const notEmpty = (val) => {
  const regex = /\S/;
  return regex.test(val);
};

export const passwordValidation = (val) => {
  const regex =
    /(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}/;
  return regex.test(val);
};

export const telephoneValidation = (val) => {
  const regex = /^(02|03|04|07|08)\d{8}$/;
  return regex.test(val);
};

export const numberValidation = (val) => {
  const regex = /^[0-9]+$/;
  return regex.test(val);
};
