import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimeField } from "@mui/x-date-pickers/TimeField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import moment from "moment";

const LFTimeField = ({
  name,
  label,
  onChange,
  format,
  value = "00:00",
  ...rest
}) => {
  const [hour, minute] = value.split(":").map(Number);
  const date = moment(new Date()).set({ hour: hour || 0, minute: minute || 0 });

  const handleOnChange = (newValue) => {
    onChange({
      target: {
        name: name,
        value: newValue,
      },
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <TimeField
        name={name}
        variant="standard"
        label={label}
        onChange={handleOnChange}
        format={format}
        value={date}
        {...rest}
      />
    </LocalizationProvider>
  );
};

export default LFTimeField;
