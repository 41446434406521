import React from "react";
import LFDropDown from "../../../shared/LFDropDown";
import RoleAndTemplateSelectionContainer from "../../../container/newShift/roleAndTemplate.container";
import { newShiftFormPath } from "../../../description/newShift/newShift.description";
import Loader from "../../reusable/Loader";
import { length } from "../../../utils/javascript";
import LFButton from "../../../shared/LFButton";
import LFGrid from "../../../shared/LFGrid";
import {
  selectRolesAttribute,
  selectTemplateAttribute,
} from "../../../description/newShift/roleAndTemplateSelection.description";
import ActionButtons from "../ActionButtons";
import LFStack from "../../../shared/LFStack";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    "&.MuiStack-root": {
      flexDirection: "row",
      margin: "40px 0",
      gap: 24,
      justifyContent: "center",
      "& .MuiButtonBase-root": {
        maxWidth: 280,
        width: "100%",
        padding: [[11, 10]],
        lineHeight: "18px",
        fontSize: 14,
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.gray.main,
        border: 0,
        "&:hover": {
          backgroundColor: theme.palette.secondary.lightGreen,
        },
      },
    },
  },
  selected: {
    "&.MuiButtonBase-root.MuiButton-root": {
      backgroundColor: theme.palette.secondary.lightGreen,
    },
  },
}));

const RoleAndTemplateSelection = () => {
  const classes = useStyles();
  const {
    loadingStatus,
    showSelectBusiness,
    selectBusinessAttribute,
    optionButtonAttributes,
    rolesOption,
    selectedOption,
    roleId,
    templateId,
    templateOptions,
    handleSelectChange,
    handleNextClick,
  } = RoleAndTemplateSelectionContainer({
    formPath: newShiftFormPath,
  });

  if (!length(selectBusinessAttribute?.options)) {
    if (loadingStatus) return <Loader />;
    return null;
  }
  if (!selectBusinessAttribute?.value) return <Loader />;

  return (
    <LFStack flex="1">
      <LFGrid container spacing={3} my="auto" justifyContent="center">
        <LFGrid item md={6} xsm={9} xs={12} sx={{ minHeight: 240 }}>
          {showSelectBusiness && <LFDropDown {...selectBusinessAttribute} />}
          <LFStack className={classes.buttonGroup}>
            {optionButtonAttributes?.map(
              ({ label, isActive, ...attr }, index) => (
                <LFButton
                  {...attr}
                  key={index}
                  className={classNames({ [classes.selected]: isActive })}
                >
                  {label}
                </LFButton>
              ),
            )}
          </LFStack>
          {selectedOption?.isRoles && (
            <LFDropDown
              {...selectRolesAttribute}
              value={roleId}
              onChange={handleSelectChange}
              options={[...rolesOption]}
            />
          )}
          {selectedOption?.isTemplate && (
            <LFDropDown
              {...selectTemplateAttribute}
              value={templateId}
              onChange={handleSelectChange}
              options={[...templateOptions]}
              PaperMaxHeight={"31%"}
            />
          )}
        </LFGrid>
      </LFGrid>
      <ActionButtons
        handleNextClick={handleNextClick}
        isDisabled={!roleId && !templateId}
      />
    </LFStack>
  );
};

export default RoleAndTemplateSelection;
