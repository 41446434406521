export const invoiceFormPath = {
  parent: "payment-invoice",
  loader: "payment-invoices-loader",
  startDate: "startDate",
  endDate: "endDate",
};
export const defaultValues = {
  startDate: {
    date: "",
    error: false,
  },
  endDate: {
    date: "",
    error: false,
  },
};
