import { useNavigate } from "react-router-dom";
import { LS_KEY_NEWSHIFT_DATA, locationPath } from "../../utils/constant";
import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_DATA } from "../../redux/constant";
import { equal, isUndefined, objectsAreSame } from "../../utils/javascript";
import { setLocalStorageItem } from "../../utils/localStorage";
import { useEffect, useState } from "react";
import setShiftValuesAction from "../../redux/action/setShiftValuesAction";
import ApiContainer from "../api.container";
import addShiftAction from "../../redux/action/addShiftAction";

const UrgentShiftContainer = ({ formPath, location }) => {
  const { performRequest } = ApiContainer();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resStatus, setStatusCode] = useState({ code: null, message: "" });

  const shiftForm = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  const viewShiftFormClone =
    useSelector((state) => state?.form?.formValues?.[formPath?.parentClone]) ??
    false;

  let isFormDataChanged = false;
  if (shiftForm && viewShiftFormClone)
    isFormDataChanged = !objectsAreSame(shiftForm, viewShiftFormClone);

  const { fromNotification, shiftId } = { ...location.state };

  useEffect(() => {
    if (
      equal(location?.pathname, locationPath?.setUrgentShift) &&
      fromNotification
    ) {
      dispatch(
        setShiftValuesAction({
          performRequest,
          shiftId,
          formPath,
          setStatusCode,
        }),
      );
    }
  }, [shiftId]);

  const handleUrgentTypeChange = (value) => {
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...shiftForm,
          is_urgent: value,
        },
      },
    });
  };

  const handleNextClick = () => {
    setLocalStorageItem(LS_KEY_NEWSHIFT_DATA, JSON.stringify({ ...shiftForm }));
    navigate(locationPath?.roleAndTemplate);
  };

  const handleSaveClick = () => {
    if (!isFormDataChanged) return handleCancelSave();
    else {
      const redirect = () =>
        navigate(locationPath?.editShiftSuccess, {
          state: { shiftId },
          replace: true,
        });
      dispatch(
        addShiftAction({
          performRequest,
          formPath,
          redirect,
          shiftId,
        }),
      );
    }
  };
  const handleCancelSave = () => {
    navigate(locationPath?.notifications, { replace: true });
  };
  const isUrgentShift = shiftForm?.is_urgent;

  const typeButtonAttributes = [
    {
      label: "STANDARD",
      variant: "outlined",
      onClick: () => handleUrgentTypeChange(false),
      isActive: isUndefined(isUrgentShift) ? false : !isUrgentShift,
      disabled: fromNotification,
    },
    {
      label: "URGENT",
      variant: "outlined",
      onClick: () => handleUrgentTypeChange(true),
      isActive: equal(isUrgentShift, true),
    },
  ];

  const isDisabled = !equal(typeof isUrgentShift, "boolean");

  return {
    typeButtonAttributes,
    isDisabled,
    handleNextClick,
    handleSaveClick,
    handleCancelSave,
    isFormDataChanged,
    resStatus,
    loadingStatus,
  };
};

export default UrgentShiftContainer;
