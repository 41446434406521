import React from "react";
import LFAccordian from "../../../shared/LFAccordian";
import LFStack from "../../../shared/LFStack";

import HowItWorks from "../HowItWorks";
import LocumAccordianCard from "./LocumAccordianCard";
import {
  applyingtoShifts,
  bankDetails,
  cancellation,
  confirmedApplication,
  filters,
  finalisingYourShift,
  helpCentre,
  listView,
  mapView,
  notificationsAndSecurity,
  paidShift,
  payslips,
  pendingApplication,
  profile,
  registerAccount,
  statistics,
  superannuationDetails,
  taxDetails,
  unpaidShift,
} from "../../../description/support/LocumSupport.description";

const sectionData = {
  2: [profile, bankDetails, superannuationDetails, taxDetails],
  3: [listView, mapView, filters],
  4: [applyingtoShifts, pendingApplication, confirmedApplication],
  5: [
    unpaidShift,
    finalisingYourShift,
    paidShift,
    payslips,
    cancellation,
    statistics,
  ],
  6: [notificationsAndSecurity, helpCentre],
};

const LocumSupport = ({ selectedType, selectedSection }) => {
  const renderSection = () => {
    switch (selectedSection) {
      case 1:
        return (
          <>
            <LFAccordian title={"How it Works"} id="supportLocumHowItWorks">
              <HowItWorks selectedType={selectedType} />
            </LFAccordian>
            <LocumAccordianCard
              cardData={registerAccount}
              id={registerAccount?.id}
              key={registerAccount?.title}
            />
          </>
        );
      case 2:
      case 3:
      case 4:
      case 5:
      case 6: {
        const data = sectionData[selectedSection] || [];
        return data?.map((cardData) => (
          <LocumAccordianCard
            cardData={cardData}
            id={cardData?.id}
            key={cardData?.title}
          />
        ));
      }
    }
  };
  return (
    <LFStack
      gap={{ xs: 3, xsm: 5 }}
      sx={{ paddingBottom: { xs: 2, xts: 4, xsm: 6 } }}
    >
      {renderSection()}
    </LFStack>
  );
};

export default LocumSupport;
