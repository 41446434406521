import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import ShiftInfoCard from "./ShiftInfoCard";
import { useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  busiName: {
    "&.MuiTypography-root": {
      fontSize: 18,
      [theme.breakpoints.down("md")]: {
        marginBottom: 8,
      },
      [theme.breakpoints.down("xsm")]: {
        fontSize: 12,
        marginBottom: 4,
      },
    },
  },
  businessAccordion: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      "& .MuiAccordionSummary-content": {
        margin: 0,
      },
      "& .MuiButtonBase-root": {
        padding: "0 0 15px",
        minHeight: "unset",
        "& svg": {
          height: 24,
          width: 24,
          color: theme.palette.dark.main,
        },
        "& p": {
          color: theme.palette.dark.main,
          fontSize: 18,
        },
      },
      "& .MuiAccordionDetails-root": {
        padding: 0,
      },
    },
  },
}));

const BusinessShiftBox = ({
  businessName,
  selectedBusinessId,
  businessShifts,
}) => {
  const classes = useStyles();
  const matches = useMediaQuery("(max-width: 991px)");
  const withAccordian = (
    <Accordion
      defaultExpanded
      disableGutters={true}
      className={classes.businessAccordion}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <LFTypography color="primary.dark" fontWeight="500">
          {businessName}
        </LFTypography>
      </AccordionSummary>
      <AccordionDetails>
        <LFBox>
          {businessShifts?.map((curShift) => {
            return <ShiftInfoCard key={curShift?.id} shiftDetails={curShift} />;
          })}
        </LFBox>
      </AccordionDetails>
    </Accordion>
  );
  const withoutAccordion = (
    <>
      <LFTypography
        color="primary.dark"
        fontWeight="500"
        className={classes.busiName}
      >
        {!selectedBusinessId && businessName}
      </LFTypography>
      <LFBox sx={{ display: { md: "unset", xsm: "flex", xs: "unset" } }}>
        {businessShifts?.map((curShift) => {
          return <ShiftInfoCard key={curShift?.id} shiftDetails={curShift} />;
        })}
      </LFBox>
    </>
  );
  return (
    <LFBox key={businessName} width="100%">
      {!matches && !selectedBusinessId ? withAccordian : withoutAccordion}
    </LFBox>
  );
};

export default BusinessShiftBox;
