import {
  DISPENSESOFTWARE,
  LS_KEY_NEWSHIFT_DATA,
  PRETESTING,
  SOFTWARE,
  apiEndpoints,
  apiMethods,
  statusCodes,
} from "../../utils/constant";
import { equal, ternary } from "../../utils/javascript";
import { getLocalStorageItem } from "../../utils/localStorage";
import { convertTimeTo12HourFormat } from "../../utils/moment";
import { SET_APP_DATA, SET_FORM_DATA } from "../constant";

const setShiftFromTemplateAction =
  ({ performRequest, templateId, formPath }) =>
  async (dispatch, getState) => {
    const preFormData = getLocalStorageItem(LS_KEY_NEWSHIFT_DATA);
    const preAppData = getState()?.app?.data?.[formPath?.parent];
    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.templates}/${templateId}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
      needLoader: true,
      parent: formPath?.parent,
    });
    if (!equal(statusCode, statusCodes?.OK)) return;

    const {
      skills,
      role_name,
      end_time,
      start_time,
      from_date,
      to_date,
      role_id,
      name,
      ...formData
    } = data.template.attributes || {};

    formData.break = Number(formData?.break);
    const start12Time = convertTimeTo12HourFormat(start_time)?.trim();
    const end12Time = convertTimeTo12HourFormat(end_time)?.trim();
    const startTime = ternary(
      equal(start12Time, "Invalid date"),
      ["12:00", "AM"],
      start12Time?.split(" "),
    );
    const endTime = ternary(
      equal(end12Time, "Invalid date"),
      ["12:00", "PM"],
      end12Time?.split(" "),
    );
    formData.time = {
      startTime: {
        value: startTime?.[0],
        ampm: startTime?.[1]?.toLowerCase(),
      },
      endTime: {
        value: endTime?.[0],
        ampm: endTime?.[1]?.toLowerCase(),
      },
    };
    formData.start_date = from_date;
    formData.end_date = to_date;
    formData.dispenseSoftware = skills?.find((obj) =>
      equal(obj?.type, DISPENSESOFTWARE),
    )?.id;
    formData.pointOfSale = ternary(
      equal(role_name, "Pharmacist"),
      null,
      skills?.find((obj) => equal(obj?.type, DISPENSESOFTWARE))?.id,
    );
    formData.software = skills?.find((obj) => equal(obj?.type, SOFTWARE))?.id;
    formData.preTesting = skills?.find((obj) =>
      equal(obj?.type, PRETESTING),
    )?.id;

    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...formData,
          ...(preFormData && JSON.parse(preFormData)),
          template_id: templateId,
          role_id,
        },
      },
    });
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: {
          ...preAppData,
          templateName: name,
        },
      },
    });
  };

export default setShiftFromTemplateAction;
