import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { locationPath, shiftStatuses } from "../../utils/constant";
import classNames from "classnames";
import PromptTemplet from "../reusable/PromptTemplet";
import LFBox from "../../shared/LFBox";
import { equal, ternary } from "../../utils/javascript";

const EditShiftSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const shiftId = location?.state?.shiftId;
  const shiftStatus = location?.state?.shiftStatus;
  const changeAllRecurringShifts =
    location?.state?.changeAllRecurringShifts ?? false;

  useEffect(() => {
    if (!shiftId) {
      navigate(-1, { replace: true });
    }
  }, []);

  const handleDoneClick = () => {
    return navigate(locationPath.myCalendar);
  };

  const subText = ternary(
    equal(shiftStatus, shiftStatuses?.unfilled),
    null,
    equal(shiftStatus, shiftStatuses?.confirmed)
      ? ""
      : "Changes to shift details will need to be confirmed by the applicant. Status will be changed to ‘PENDING’ in the meantime.",
  );

  const buttonAttributes = [
    {
      label: "OK",
      onClick: handleDoneClick,
      variant: "contained",
    },
  ];

  return (
    <LFBox className={classNames.promptWrapper}>
      <PromptTemplet
        mainText={
          !changeAllRecurringShifts
            ? "Shift has been updated."
            : "All recurring shifts have been updated."
        }
        subText={subText}
        buttonAttributes={buttonAttributes}
        iconSrc={true}
        bgGray={true}
      />
    </LFBox>
  );
};

export default EditShiftSuccess;
