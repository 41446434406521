import { Link } from "react-router-dom";

export const definitionPoints1 = [
  <>
    The following definitions will apply for the purpose of this Agreement,
    unless the context otherwise requires:
  </>,
  <>
    <b>Account</b> has the meaning given to that term in the Terms and
    Conditions of Use;
  </>,
  <>
    <b>Agreement</b> means this LocumFind Agreement (Client);
  </>,
  <>
    <b>App</b> means the iOS and Android smartphone application “LocumFind”;
  </>,
  <>
    <b>Client Hourly Rate</b> means the hourly rate set out in a Placement
    Request;
  </>,
  <>
    <b>Effective Date</b> means the date on which the Client registers an
    Account;
  </>,
  <>
    <b>Fees</b> means the fees set out in a Quote and includes the Placement Fee
    and/or Urgent Placement Fee (as applicable);
  </>,
  <>
    <b>Hourly Rate</b> means the hourly rate set out in a Quote:
  </>,
  <>
    <b>Intellectual Property Rights</b> includes all copyright and related
    rights, and all rights in relation to inventions including patents,
    registered and unregistered trade marks (including service marks), trade
    secrets, registered designs, confidential information and circuit layouts,
    and all other rights resulting from intellectual activity in the industrial,
    scientific, literary or artistic fields and includes the right to register
    and/or apply for any such rights;
  </>,
  <>
    <b>Minimum Award Rate</b> means:
  </>,
];
export const definitionPoints2 = [
  <>
    <b>Related</b> Body Corporate has the meaning given to this term under the
    Corporations Act 2001 (Cth);
  </>,
  <>
    <b>Role</b> means the relevant roles as specified in Item 1 of the Schedule;
  </>,
  <>
    <b>Placement</b> Fee means the fee payable by the Client to LOCUMFIND each
    time it places a locum;
  </>,
  <>
    <b>Placement Request</b> means a request by the Client for Services;
  </>,
  <>
    <b>Platform</b> means LocumFind platform accessible view the Website and the
    App;
  </>,
  <>
    <b>Quote</b> refers to the Quote for the Services in accordance with clause
    3 ;
  </>,
  <>
    <b>Services</b> means the supply of Locums to carry out the Work;
  </>,
  <>
    <b>Site</b> means any place at which the Client requests the provision of
    Services;
  </>,
  <>
    <b>Territory</b> means Victoria, New South Wales, South Australia,
    Queensland and Western Australia;
  </>,
  <>
    <b>Term</b> has the meaning given to that clause in 16.1 ;
  </>,
  <>
    <b>Terms and Conditions of Use</b> means the separate terms and conditions
    relating to the use of the Website and the App;
  </>,
  <>
    <b>Urgent Placement Fee</b> means the fee payable by the Client to LOCUMFIND
    each time it provides a Locum with less than 48 hours’ notice prior to the
    requested start date or time set out in a Placement Request;
  </>,
  <>
    <b>Website</b> means the website{" "}
    <Link to={"https://www.locumfind.com.au/"} target="_blank">
      www.locumfind.com.au
    </Link>
  </>,
  <>
    <b>Work</b> means the type of work specified in a Placement Request; and
  </>,
  <>
    <b>Locum</b> means any person who is an employee, agent or contractor of
    LocumFind and whose services are provided by LocumFind to the Client.
  </>,
];

export const interpretationPoints = [
  {
    key: "a",
    point:
      "headings are for convenience only and do not affect interpretation;",
  },
  {
    key: "b",
    point: " the singular includes the plural and conversely;",
  },
  {
    key: "c",
    point:
      "the meaning of general words is not limited by specific examples introduced by “including”, or “for example”, or similar expressions;",
  },
  {
    key: "d",
    point:
      "if a word or phrase is defined, its other grammatical forms have a corresponding meaning;",
  },
  {
    key: "e",
    point:
      "a reference to a person, corporation, trust, partnership, unincorporated body or any other entity includes any of them;",
  },
  {
    key: "f",
    point:
      "a reference to a ”party” means a party to this Agreement and  a reference to “parties” means both of the parties to this  Agreement;",
  },
  {
    key: "g",
    point:
      "a reference to a clause, Schedule or Item is a reference to a clause of, Schedule to or Item of this Agreement;",
  },
  {
    key: "h",
    point:
      "a reference to an agreement or document (including, a reference to this Agreement) is to the agreement or documentas amended, varied, supplemented, innovated or replaced,except to the extent prohibited by this Agreement or that other agreement or document;",
  },
  {
    key: "i",
    point:
      "a reference to legislation or to a provision of legislation includes a modification or re-enactment of it, a legislative provision substituted for it and a regulation or statutory sinstrument issued under it; and",
  },
  {
    key: "j",
    point:
      " unless otherwise indicated, a reference to a monetary amount in this Agreement, is a reference to that amount in Australian dollars.",
  },
];

export const placemenRequestsPoints = [
  {
    key: "2.1",
    point: (
      <>
        From time to time, the Client may request that LOCUMFIND supply a Locum,
        who is suitable and qualified for performing the Work, by completing an
        online form, available through the Platform (<b>Placement Request</b>).
      </>
    ),
  },
  {
    key: "2.2",
    point: (
      <>
        It is the responsibility of the Client to stipulate in the Placement
        Request:
      </>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: "the Role to be performed by the Locum;",
      },
      {
        subkey: "b",
        subPoint:
          " any necessary training, certifications, tools or equipment required of the Locum;",
      },
      {
        subkey: "c",
        subPoint: "the nature of the Work;",
      },
      {
        subkey: "d",
        subPoint:
          "the location of the Site (which must be located within the Territory);",
      },
      {
        subkey: "e",
        subPoint: (
          <>
            whether travel expenses will be covered by the Client and to what
            amount <b>(Travel Expenses)</b>
          </>
        ),
      },
      {
        subkey: "f",
        subPoint: "the expected start date or time; and",
      },
      {
        subkey: "g",
        subPoint: "the expected finish date or time.",
      },
    ],
  },
  {
    key: "2.3",
    point: (
      <>
        The Placement Request will also stipulate the Client Hourly Rate in
        respect of the Work, which must not be less than the Minimum Award Rate,
        if any, for the Role.
      </>
    ),
  },
  {
    key: "2.4",
    point: (
      <>
        In response to a Placement Request, LOCUMFIND may provide a Quote in
        accordance with clause 3 for the Services which must then be accepted by
        the Client on reasonable notice before the intended start of the Work.
        Upon acceptance of the Quote on this basis, LOCUMFIND is bound to
        deliver the Services and the Client is obligated to make payment of Fees
        as per the Quote and this Agreement.
      </>
    ),
  },

  {
    key: "2.5",
    point: (
      <>The Client agrees and acknowledges that for each Placement Request:</>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            the Client may only request one Locum (however, a Client may make
            more than one Placement Request at any one time);
          </>
        ),
      },

      {
        subkey: "b",
        subPoint: (
          <>
            LOCUMFIND will only provide Locums subject to receiving at least 48
            hours’ notice prior to the requested start date or time except where
            the Client agrees to pay the Urgent Placement Fee; and
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            LOCUMFIND will charge the Client for a minimum of [3] hours of Fees
            for each Locum (calculated at the applicable Hourly Rate), per
            Placement Request, regardless of the actual length of the shift or
            placement.
          </>
        ),
      },
    ],
  },
];

export const quoteProcessPoints = [
  {
    key: "3.1",
    point:
      "In response to a Placement Request, LOCUMFIND may issue a Quote via the Platform which will set out the Fees and Hourly Rate for the Services and any additional terms or stipulations for the Services.",
  },
  {
    key: "3.2",
    point:
      "The Client acknowledges that each Quote in respect of a Placement Request is only active and valid for acceptance until the expected start date or time as set out in that Placement Request, unless the Client cancels the Placement Request prior to this, in which case the Quote is no longer active and valid from the time of such cancellation.",
  },
  {
    key: "3.3",
    point:
      "If the Client requests or directs any Locum to perform any work attracting overtime and/or penalty rates in relation to the Services, the Client is required to pay overtime and/or penalty rates as per the applicable Award (if any) to LOCUMFIND for that Locum. For the avoidance of doubt such overtime          and/or penalties are to be paid to LOCUMFIND as part of the Fees, and LOCUMFIND will be responsible for the remittance to the Locum of those Fees as required by law.",
  },
];
export const replacements = [
  {
    key: "4.1",
    point: (
      <>
        If the Client is not satisfied by the Services provided by a particular
        Locum, the Client will notify LOCUMFIND as soon as possible via the
        Platform.
      </>
    ),
  },
  {
    key: "4.2",
    point: (
      <>
        LOCUMFIND may replace that Locum with another Locum (
        <b>Replacement Locum</b>) subject to:
      </>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            the availability of a Replacement Locum with the necessary training,
            certifications, tools or equipment required to fill the Role; and
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            the Client’s payment of a minimum of 3 hours of Fees for any Locum
            who has been replaced with the Replacement Locum in accordance with
            this clause 4 . LOCUMFIND will withdraw any further charges
            associated with a Locum who has been replaced once LOCUMFIND gives
            notice to the Client that a Replacement Locum is available.
          </>
        ),
      },
    ],
  },
  {
    key: "4.3",
    point: (
      <>
        If LOCUMFIND is unable to find a Replacement Locum, then LOCUMFIND may
        cancel the Services.
      </>
    ),
  },
];
export const cancelations = [
  {
    key: "5.1",
    point: <>Cancellation by Client</>,
    subpointHead: (
      <>The Client must notify LOCUMFIND of any cancellation of Services.</>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            In the event any Service is cancelled by the Client in the 48 hours
            before the shift starts, the Client acknowledges and agrees that
            LOCUMFIND may retain a minimum of 3 hours of Fees and reimburse to
            the Client the remainder of the Fees charged for the Services on
            accepting the Quote.
          </>
        ),
      },
    ],
  },
  {
    key: "5.2",
    point: <>Cancellation by LOCUMFIND</>,
    subpointHead: (
      <>
        LOCUMFIND may cancel the Services immediately without prior notice if:
      </>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            the Client fails or refuses to pay any amount invoiced to the Client
            as per the payment terms in clause 8;
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: <>the Client breaches any health and safety requirement; </>,
      },
      {
        subkey: "c",
        subPoint: (
          <>
            there is any change of circumstances which reasonably prevents
            LOCUMFIND from providing the agreed Services.
          </>
        ),
      },
    ],
  },
  {
    key: "5.3",
    point: <>Cancellation Fees</>,
    subpointHead: <>In the event any Service is cancelled:</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            by the Client in the 48 hours before the shift starts, the Client
            acknowledges and agrees that LOCUMFIND may retain a minimum of 3
            hours of Fees and reimburse to the Client the remainder of the Fees
            charged for the Services on accepting the Quote;
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            by LOCUMFIND pursuant to clause 5.2 (a) or 5.2(b), the Client
            acknowledges and agrees that LOCUMFIND is entitled to claim payment
            of a minimum of 3 hours of Fees;
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            by LOCUMFIND pursuant to clause 5.2 (c), LOCUMFIND agrees to
            reimburse the Client any Fees charged for the Services on accepting
            the Quote.
          </>
        ),
      },
    ],
  },
];
export const relBetweenParties = [
  {
    key: "6.1",
    point: (
      <>
        The Client engages LOCUMFIND to provide the Services as an independent
        contractor.
      </>
    ),
  },
  {
    key: "6.2",
    point: (
      <>
        {" "}
        Nothing in this Agreement is intended to constitute a relationship of
        employer and employee, principal and agent or partnership between
        LOCUMFIND and the Client or the Locum and the Client. It is the express
        intention of the parties that an employment relationship between
        LOCUMFIND and the Client and the Locum and the Client does not exist.
      </>
    ),
  },
  {
    key: "6.3",
    point: <>Neither Party has any right or authority to:</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: <>bind the other party;</>,
      },
      {
        subkey: "b",
        subPoint: (
          <>
            assume or create any obligations for, or on behalf of, the other
            party; or
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            make any representations or warranties for, or on behalf of, the
            other party.
          </>
        ),
      },
    ],
  },
  {
    key: "6.4",
    point: (
      <>
        No contractual relationship will exist between the Client and the Locum.
        However, all Locums will be under the direct supervision of the Client,
        and not LOCUMFIND, for the duration of the Work.{" "}
      </>
    ),
  },
  {
    key: "6.5",
    point: (
      <>
        The Locums are not entitled to any benefit from the Client that is
        usually attributable to an employee, other than overtime or penalties
        specified in clause 0 above.
      </>
    ),
  },
];
export const locumFindsObligations = [
  {
    key: "7.1",
    point: (
      <>
        LOCUMFIND will be solely responsible, and will solely bear the
        liability, for:
      </>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            the payment of remuneration to the Locums, including salaries and
            wages, superannuation, annual leave, sick leave, long service leave
            and other benefits to which casual employees may be entitled under
            any contract of service or under any rule, statute, common law or
            Award (as defined in relevant legislation);
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            the payment of all taxes and duties in respect of such remuneration
            and benefits;
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            compliance, and the cost of compliance, with all statutory, award or
            other legal or contractual requirements with respect to employees,
            agents, servants and contractors; and
          </>
        ),
      },
      {
        subkey: "d",
        subPoint: (
          <>
            the maintenance, and the cost, of obtaining appropriate workers’
            compensation policies to provide coverage for the Locums.
          </>
        ),
      },
    ],
  },
  {
    key: "7.2",
    point: (
      <>
        For the avoidance of doubt, neither the Client, nor any Related Bodies
        Corporate of the Client, will have any responsibility for:
      </>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: <>the payment of salary or wages to any Locum;</>,
      },
      {
        subkey: "b",
        subPoint: (
          <>
            the payment of any payroll tax, PAYG tax instalments or
            superannuation guarantee charge associated with the employment of
            any Locum;
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            annual leave, personal/carers leave or long service leave which may
            accrue to any Locum.
          </>
        ),
      },
    ],
  },
];

export const feesPoints = [
  {
    key: "8.1",
    point: (
      <>
        In consideration for LOCUMFIND providing the Services, the Client must
        pay LOCUMFIND the Fees stipulated in the Quote.
      </>
    ),
  },
  {
    key: "8.2",
    point: (
      <>
        By registering an Account with LOCUMFIND, the Client agrees that
        LOCUMFIND may charge the Client’s credit card the Fee immediately upon
        the Client accepting a Quote. The Client acknowledges and accepts that
        the Services will not be provided by LOCUMFIND unless and until it has
        paid LOCUMFIND the applicable Fees stipulated in the Quote.
      </>
    ),
  },
  {
    key: "8.3",
    point: (
      <>
        When making a payment of the Fees, financial details are passed through
        a third party payment processor (<b>Payment Processor</b>). LOCUMFIND is
        not responsible for ensuring the strength or effectiveness of the
        encryption used by Payment Processor and accepts no responsibility for
        any loss or damage that may arise as a result of any unauthorised access
        to and/or use of that encrypted information.
      </>
    ),
  },
  {
    key: "8.4",
    point: (
      <>
        In so far as it is relevant to the provision of the processing of Fees
        by the Payment Processor, the terms at{" "}
        <Link
          to={"https://stripe.com/ssa/"}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          https://stripe.com/ssa/
        </Link>{" "}
        are incorporated into this Agreement and will prevail over this
        Agreement to the extent of any inconsistency in relation to the
        processing of fees. If LOCUMFIND changes its Payment Processor, the
        Client may be asked to agree to any further additional terms with those
        providers. If the Client does not agree to them, the Client will be
        given alternative means of payment.
      </>
    ),
  },
];

export const taxPoints = [
  {
    key: "9.1",
    point: <>GST</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            Unless otherwise stated, Fees for any goods or services supplied (or
            offered for supply) under this Agreement are stated exclusive of
            GST. Where GST applies to any supply made to the Client, LOCUMFIND
            will issue the Client a Tax Invoice.
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            All defined terms in this clause 9 have the meaning given to them in
            A New Tax System (Goods and Services Tax) Act 1999 (Cth).
          </>
        ),
      },
    ],
  },
  {
    key: "9.2",
    point: <>Payroll Tax</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            Legislation may provide that, as a client of LOCUMFIND, the Client
            is liable for any payroll tax payable when it uses a Locum.
            LOCUMFIND is authorised to collect and pay payroll tax on behalf of
            the Client if, under any applicable payroll tax legislation, that
            liability falls on the Client. This clause applies even if no
            employment relationship arises between the Client and any Locum.
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            The Fee is inclusive of any payroll tax whether it is paid by the
            Client or LOCUMFIND. If the Client is required to pay payroll tax,
            the Fee will be adjusted accordingly.
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            If the Client wishes LOCUMFIND to collect and remit payroll tax on
            its behalf in accordance with clause 9.2(a) , it will sign an
            authorisation form to be provided by LOCUMFIND.
          </>
        ),
      },
      {
        subkey: "d",
        subPoint: (
          <>
            Payroll tax is not subject to GST. Accordingly should the Client
            elect for LOCUMFIND to collect and remit payroll tax on its behalf,
            the amount of payroll tax will be itemised separately on all
            documentation including the invoices.
          </>
        ),
      },
    ],
  },
];
export const workHealthSafety = [
  {
    key: "10.1",
    point: <>The Client must:</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            make all reasonable attempts to ensure that all Work carried out
            pursuant to this Agreement complies with all work health and safety
            requirements of the state or territory in which the Work is carried
            out;
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            ensure that the Locums are not exposed to risks to their health or
            safety or to hazards arising from the provision of Work;
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            ensure that any equipment or facilities provided for use in the
            provision of Work are safe and without risks to health and safety
            when properly used;
          </>
        ),
      },
      {
        subkey: "d",
        subPoint: (
          <>
            ensure that systems of work and the working environment are safe and
            without risks to health;
          </>
        ),
      },
      {
        subkey: "e",
        subPoint: (
          <>
            provide such information, instruction and supervision as necessary
            to ensure that the Work is provided without hazards or risks to
            health and safety;
          </>
        ),
      },
      {
        subkey: "f",
        subPoint: (
          <>
            ensure Locums receive adequate training to ensure tasks are
            undertaken in a manner that minimises the risk to the Locums’ health
            and safety and the health and safety of others; and
          </>
        ),
      },
      {
        subkey: "g",
        subPoint: (
          <>
            ensure Locums do not undertake any tasks for which they are not
            qualified and/or have not received adequate training.
          </>
        ),
      },
    ],
  },
  {
    key: "10.2",
    point: (
      <>
        LOCUMFIND must ensure that all of the Locums supplied have the skills,
        qualifications and licences for the relevant Roles.
      </>
    ),
  },
  {
    key: "10.3",
    point: (
      <>
        Each Party must notify the other of any injury sustained by any of the
        Locums on the Site, or any injury sustained by any of the Locums during
        the time in which their services were being supplied to the Client.
      </>
    ),
  },
];

export const Confidentiality = [
  {
    key: "11.1",
    point: (
      <>
        LOCUMFIND must maintain the confidentiality of, and not disclose, any
        trade secret, confidential information or commercially sensitive
        information about the Client or any of its Related Bodies Corporate
        obtained while providing Services to the Client, other than when
        required by law or when the information enters into the public domain.
      </>
    ),
  },
  {
    key: "11.2",
    point: (
      <>
        LOCUMFIND must take commercially reasonable steps to ensure that each of
        the Locums maintains the confidentiality of, and does not disclose, any
        trade secret, confidential information or commercially sensitive
        information about the Client or any of its Related Bodies Corporate
        obtained while providing Services to the Client, other than when
        required by law or when the information enters the public domain.
      </>
    ),
  },
  {
    key: "11.3",
    point: <>This clause survives termination of the Agreement.</>,
  },
];

export const intelectualProperty = [
  {
    key: "12.1",
    point: <> This clause survives termination of the Agreement.</>,
  },
];

export const warrantiesPoints = [
  {
    key: "13.1",
    point: <>General</>,
    subpointHead: <> Both parties represent and warrant that:</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            they have the right, power and authority to enter into and perform
            their respective obligations in accordance with this agreement;
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            all corporate and other necessary action has been taken by each of
            them to authorise the entry into and performance of this agreement;
            and
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            this agreement is valid and legally binding on them in accordance
            with the terms of this agreement.
          </>
        ),
      },
    ],
  },
  {
    key: "13.2",
    point: <>LocumFind Warranties</>,
    subpointHead: <> LOCUMFIND represents and warrants that it:</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            has the requisite skills, experience, resources and infrastructure
            to provide and perform the Services;
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            holds and will continue to hold during the Term, all relevant
            authorisations required by law to provide the Services; and
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            will comply with any applicable industrial awards or agreements that
            apply in respect of its employment, or contracting, of the Locums.
          </>
        ),
      },
    ],
  },
];

export const indemnityPoints = [
  {
    key: "a",
    point: (
      <>
        The Client agrees to indemnify LOCUMFIND, its officers, employees and
        agents (Indemnified) against all liability, loss, damage, penalty,
        charge, claim, harm, injury, costs or expenses of any kind whatsoever
        (including consequential loss) (Loss) incurred or suffered directly or
        indirectly from or in connection with:
      </>
    ),
    subPoints: [
      {
        subkey: "i",
        subPoint: <>any breach of this Agreement by the Client;</>,
      },
      {
        subkey: "ii",
        subPoint: (
          <>
            any wilful, unlawful or negligent act or omission of the Client or
            any of its Related Bodies Corporate;
          </>
        ),
      },
      {
        subkey: "iii",
        subPoint: (
          <>
            any injury to or death of a natural person and any loss of or damage
            to a third party’s real or personal property caused or contributed
            to by the Client or any of its Related Bodies Corporate;
          </>
        ),
      },
      {
        subkey: "iv",
        subPoint: (
          <>
            any claim, action, demand or proceeding by a third party against any
            of the Indemnified caused by or contributed to by the Client or any
            of its Related Bodies Corporate; and
          </>
        ),
      },
      {
        subkey: "v",
        subPoint: (
          <>
            any claim, action, demand or proceeding by a third party against any
            of the Indemnified caused by or contributed to by any of the Locums
            whilst performing Work at the direction of the Client.
          </>
        ),
      },
    ],
  },
  {
    key: "b",
    point: (
      <>
        The parties acknowledge and agree that Indemnified may enforce this
        clause 14 , and any claim arising thereunder, directly against the
        Client as a third party beneficiary.
      </>
    ),
  },
];

export const liability = [
  {
    key: "15.1",
    point: (
      <>
        The liability of LOCUMFIND to the Client for breach of contract, or in
        tort, or for any other common law or statutory cause of action arising
        out of the operation of this Agreement will (except to the extent that
        an exclusion or limitation of liability is prohibited by law) be limited
        to the amount paid or payable by the Client under this Agreement.
      </>
    ),
  },
  {
    key: "15.2",
    point: (
      <>
        Notwithstanding the foregoing, LOCUMFIND will have no liability to the
        Client in contract, or in tort, or for any other common law or statutory
        cause of action (to the extent liability may be excluded or limited by
        law) in respect of damages representing loss of opportunity, loss of
        revenue, loss of profit or anticipated profit, loss of contract, loss of
        goodwill, loss arising from business interruption, or any other form of
        loss or damage which is indirect or consequential.
      </>
    ),
  },
];

export const termsAndTerminations = [
  {
    key: "16.1",
    point: (
      <>
        This Agreement commences on the Effective Date and, unless terminated in
        accordance with the terms of this Agreement, continues indefinitely
        (Term).
      </>
    ),
  },
  {
    key: "16.2",
    point: (
      <>
        Either party may terminate this Agreement by providing the other Party
        30 business days’ written notice without prejudice to the accrued rights
        of the parties.
      </>
    ),
  },
  {
    key: "16.3",
    point: (
      <>LOCUMFIND may terminate this Agreement with immediate effect if:</>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: <> the Client is in breach of any term of this agreement;</>,
      },
      {
        subkey: "b",
        subPoint: (
          <>
            it is aware of any fact or matter which in its opinion, acting
            reasonable, would endanger the health and safety of any Locum;
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>it is unable to provide the required Locums for any reason.</>
        ),
      },
    ],
  },
];

export const general = [
  {
    key: "17.1",
    point: <>Notices</>,
    subpointHead: (
      <>
        Any notices must be given by registered ordinary post or by email,
        either to LOCUMFIND’s contact address, or to the Client’s contact
        address as provided at registration. Any notice shall be deemed given:
      </>
    ),
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            if sent by email, 24 hours after email is sent, unless the Client is
            notified that the email address is invalid or the email is
            undeliverable;
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            if sent by pre-paid post, three business days after the date of
            posting.
          </>
        ),
      },
    ],
  },
  {
    key: "17.2",
    point: <>Entire Agreement</>,
    subpointHead: (
      <>
        This Agreement constitutes the entire agreement and understanding
        between the parties with respect to its subject matter and supersede all
        prior or contemporaneous oral or written agreements, undertakings, or
        representations between the parties concerning such subject matter.
      </>
    ),
  },
  {
    key: "17.3",
    point: <>Severance</>,
    subpointHead: (
      <>
        Any provision of this Agreement which is or becomes prohibited or
        unenforceable in any jurisdiction shall, as to such jurisdiction, be
        ineffective and severed to the extent thereof without invalidating any
        other provision of this Agreement, and any such prohibition or
        unenforceability shall not invalidate such provision in any other
        jurisdiction.
      </>
    ),
  },
  {
    key: "17.4",
    point: <>Waiver</>,
    subpointHead: (
      <>
        No failure to exercise or any delay in exercising any right, power or
        remedy by a party operates as a waiver. A single or partial exercise of
        any right, power or remedy does not preclude any other or further
        exercise of that or any other right, power or remedy. A waiver is not
        valid or binding on the party granting that waiver unless made in
        writing
      </>
    ),
  },
  {
    key: "17.5",
    point: <>Variations</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            LOCUMFIND may modify, vary or amend this Agreement from time to
            time. LOCUMFIND will send notification of such modifications to the
            client’s Account or advise the Client the next time the Client logs
            in to the Platform.
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            When the Client actively agrees to amended terms or uses the
            Platform in any manner, the amended terms will be effective
            immediately. In all other cases, the amended terms will
            automatically be effective 30 days after the date LOCUMFUND notifies
            the Client of the amendments.
          </>
        ),
      },
      {
        subkey: "c",
        subPoint: (
          <>
            If the Client does not agree with any changes to this Agreement, the
            Client must either terminate its Account, or must notify LOCUMFIND
            who will terminate the client’s Account, and stop using the
            Platform.
          </>
        ),
      },
    ],
  },
  {
    key: "17.6",
    point: <>Assignment</>,
    subPoints: [
      {
        subkey: "a",
        subPoint: (
          <>
            The Client agrees that its rights and obligations under this
            Agreement are personal to it and may not be transferred, delegated,
            assigned or otherwise dealt with (by subcontract or otherwise)
            directly or indirectly without the prior written consent of
            LOCUMFIND.
          </>
        ),
      },
      {
        subkey: "b",
        subPoint: (
          <>
            This Agreement may be assigned or novated by LOCUMFIND to a third
            party without your consent. In the event of an assignment or
            novation the Client will remain bound by this Agreement.
          </>
        ),
      },
    ],
  },
  {
    key: "17.7",
    point: <>Governing law</>,
    subpointHead: (
      <>
        This Agreement is governed by the laws of the State of Victoria,
        Australia and the parties submit to the non-exclusive jurisdiction of
        the courts of that State.
      </>
    ),
  },
];
