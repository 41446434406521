import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal, length } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const notifications_per_page = 25;

const getNotificationsAction =
  ({ formPath, performRequest, pageNo }) =>
  async (dispatch, getState) => {
    const currentNotifcationsData = getState()?.app?.data?.[formPath?.parent];

    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.notifications}?per_page=${notifications_per_page}&page=${pageNo}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
      parent: equal(length(currentNotifcationsData, 0))
        ? formPath?.parent
        : formPath?.paginationLoader,
      needLoader: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) return;
    const { notifications, pagy_links, unread_notifications_count } = {
      ...data,
    };

    const notificationsData = [...notifications.data];
    const reducedNotificationsData = notificationsData?.map(
      (notification) => notification.attributes,
    );

    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: currentNotifcationsData
          ? [...currentNotifcationsData, ...reducedNotificationsData]
          : [...reducedNotificationsData],
        [formPath?.maxPages]: pagy_links.pages,
        [formPath?.newcounts]: unread_notifications_count,
        [formPath?.currentPage]: pageNo,
      },
    });
  };

export default getNotificationsAction;
