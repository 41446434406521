import { useDispatch, useSelector } from "react-redux";
import { SET_FORM_ERROR_DATA } from "../redux/constant";
import { keys, values } from "../utils/javascript";
import ApiContainer from "./api.container";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  apiEndpoints,
  apiMethods,
  locationPath,
} from "../utils/constant";
import { useEffect } from "react";
import { getLocalStorageItem } from "../utils/localStorage";
import { useNavigate } from "react-router-dom";

const ForgotPasswordContainer = ({
  formData,
  formError,
  validate,
  formPath,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );

  useEffect(() => {
    if (
      getLocalStorageItem(LS_KEY_AUTHENTICATED) &&
      !getLocalStorageItem(LS_KEY_HALF_REGISTER)
    ) {
      navigate(locationPath?.myCalendar);
    }
  }, []);

  const { performRequest } = ApiContainer();

  const apiCall = async () => {
    await performRequest({
      endPoint: apiEndpoints?.forgotPassword,
      method: apiMethods?.post,
      data: { email: formData?.email },
      showFailedMessage: true,
      showSuccessMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });
  };

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });

    if (values(validationErrors).some((error) => error)) return;

    return apiCall();
  };
  return { loadingStatus, handleForgotPasswordSubmit };
};

export default ForgotPasswordContainer;
