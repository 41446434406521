import React from "react";
import LFBox from "../../shared/LFBox";
import { Outlet } from "react-router-dom";
import LFTypography from "../../shared/LFTypograpy";
import LFContainer from "../../shared/LFContainer";
import LFChip from "../../shared/LFChip";
import LFGrid from "../../shared/LFGrid";
import { formPath, sideBarLinks } from "../../description/layout/accountLayout";
import { makeStyles } from "@mui/styles";
import LFButton from "../../shared/LFButton";
import { locationPath } from "../../utils/constant";
import BackButton from "../reusable/BackButton";
import { ReactComponent as LogoutIcon } from "../../assets/svg/logoutIcon.svg";
import { ReactComponent as HelpCentre } from "../../assets/svg/helpCentreIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/editIcon.svg";
import LFStack from "../../shared/LFStack";
import AccountLayoutContainer from "../../container/layout/accountLayout.container";
import theme from "../../themes/theme";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  accountMenu: {
    backgroundColor: theme.palette.gray.main,
    padding: 14,
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      backgroundColor: "transparent",
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      textTransform: "capitalize",
      textDecoration: "none",
      color: theme.palette.primary.main,
      padding: "18px 12px",
      borderRadius: 10,
      width: "100%",
      textAlign: "left",
      [theme.breakpoints.down("md")]: {
        borderRadius: 0,
        padding: "24px 0 24px 4px",
        borderBottom: `1px solid ${theme.palette.gray.dark}`,
      },
      [theme.breakpoints.down("xxsm")]: {
        padding: "17px 0 17px 4px",
      },
      "&.active": {
        [theme.breakpoints.up("md")]: {
          backgroundColor: theme.palette.white.main,
        },
      },
      "& svg": {
        marginRight: 10,
        [theme.breakpoints.down("md")]: {
          marginRight: 0,
          height: 24,
          width: 24,
        },
      },
      "&>span": {
        [theme.breakpoints.down("md")]: {
          marginLeft: "16px",
          marginRight: "auto",
          fontSize: 24,
        },
        [theme.breakpoints.down("xxsm")]: {
          marginLeft: "8px",
          fontSize: 20,
        },
      },
    },
  },
  editIcon: {
    marginLeft: 16,
  },
}));

const AccountLayout = () => {
  const classes = useStyles();
  const {
    showMenu,
    pathname,
    matches,
    mobileMatches,
    incompleteStatus,
    handleBackButtonClick,
    handleLinkClick,
    handleSignOutClick,
  } = AccountLayoutContainer({ formPath });
  return (
    <LFStack
      pt={{ md: 6, xxsm: 3, xs: 1.5 }}
      pb={{ md: 2, xxsm: 3, xs: 1.5 }}
      sx={{ justifyContent: "space-between", boxSizing: "border-box" }}
      flex={1}
    >
      <LFContainer>
        {!matches || showMenu ? (
          <LFTypography
            variant="h5"
            component="h5"
            color="primary"
            fontWeight="500"
            pb={{ md: 2.5, xxsm: 3.5, xs: 2 }}
            borderBottom={{
              xxsm: "none",
              xs: `1px solid ${theme.palette.secondary.dark}`,
            }}
          >
            Account
          </LFTypography>
        ) : (
          <BackButton
            py="0"
            mb={2}
            path={true}
            onClick={handleBackButtonClick}
            sx={{ "& .MuiButtonBase-root": { fontSize: 12 } }}
          />
        )}
        <LFGrid container columnSpacing={4}>
          {(!matches || showMenu) && (
            <LFGrid item lg={3} md={4} xs={12}>
              <nav className={classes.accountMenu}>
                {sideBarLinks?.map(({ label, icon, link }, index) => (
                  <LFButton
                    key={index}
                    disableRipple
                    onClick={() => handleLinkClick(link)}
                    className={pathname?.includes(link) ? "active" : ""}
                  >
                    {icon}
                    <LFTypography component="span" variant="body1">
                      {label}
                    </LFTypography>
                    {incompleteStatus[label?.toLowerCase()] && (
                      <LFChip
                        style={{ backgroundColor: "#717171" }}
                        sx={{
                          color: "white.main",
                          fontSize: 10,
                          height: "auto",
                          padding: "2px 5px",
                          float: "right",
                          maxWidth: 84,
                          width: "100%",
                          ml: "auto",
                          "& span": {
                            padding: 0,
                          },
                        }}
                        label="INCOMPLETE"
                      />
                    )}
                    {matches && <EditIcon className={classes.editIcon} />}
                  </LFButton>
                ))}
                {mobileMatches && (
                  <LFButton
                    disableRipple
                    onClick={() => handleLinkClick(locationPath?.helpCenter)}
                  >
                    <HelpCentre />
                    <LFTypography component="span" variant="body1">
                      Help Centre
                    </LFTypography>
                    <EditIcon className={classes.editIcon} />
                  </LFButton>
                )}
              </nav>
            </LFGrid>
          )}
          {(!matches || !showMenu) && (
            <LFGrid item lg={9} md={8} xs={12}>
              <LFBox
                height="100%"
                display="flex"
                flexDirection="column"
                minHeight={{ sm: "auto", xs: "calc(100vh - 208px)" }}
              >
                <Outlet />
              </LFBox>
            </LFGrid>
          )}
        </LFGrid>
      </LFContainer>
      <LFStack>
        {(matches || mobileMatches) && showMenu && (
          <LFButton
            disableRipple
            onClick={handleSignOutClick}
            sx={{ width: "fit-content", margin: "0 auto" }}
          >
            <LogoutIcon />
            <LFTypography
              component="span"
              variant="body1"
              sx={{
                textTransform: "none",
                paddingLeft: "8px",
                fontSize: 18,
              }}
            >
              Sign out
            </LFTypography>
          </LFButton>
        )}
        <MobileCopyright />
      </LFStack>
    </LFStack>
  );
};

export default AccountLayout;
