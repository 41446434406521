import LFTypography from "../../shared/LFTypograpy";
import LFBox from "../../shared/LFBox";
import LFCheckbox from "../../shared/LFCheckbox";
import { values } from "../../utils/javascript";
import { makeStyles } from "@mui/styles";
import LFGrid from "../../shared/LFGrid";
import { ZERO } from "../../utils/constant";

const useStyles = makeStyles((theme) => ({
  filterMenu: {
    display: "grid",
    paddingTop: "5px",
    paddingRight: "19px",
    gridTemplateColumns: "1fr 1fr",
    [theme.breakpoints.down("md")]: {
      paddingTop: "none",
      paddingRight: "none",
      gridTemplateColumns: "1fr",
    },
    "& span": {
      fontSize: 12,
      color: theme.palette.secondary.dark,
      whiteSpace: "nowrap",
      lineHeight: "14px",
      [theme.breakpoints.down("lg")]: {
        whiteSpace: "normal",
      },
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
    },
  },
  filterTitle: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: theme.palette.secondary.dark,
    },
  },
  filterCheckBox: {
    "&.MuiButtonBase-root.MuiCheckbox-root": {
      padding: 0,
      margin: [[2, 0]],
      marginRight: 12,
    },
  },
}));

const FilterMenu = ({ roles, status, filterHandler, filterValues }) => {
  const classes = useStyles();
  return (
    <>
      {values(roles)?.length > ZERO && (
        <LFGrid container direction="row" rowSpacing={{ xsm: 0, xs: 2 }}>
          <LFGrid item lg={7.5} xsm={6.7} xs={12}>
            <LFTypography className={classes.filterTitle}>Roles</LFTypography>
            <LFBox className={classes.filterMenu}>
              {values(roles)?.map(({ name, label }) => (
                <LFCheckbox
                  label={label}
                  key={name}
                  name={name}
                  onChange={(e) => filterHandler(e, "roles")}
                  checked={filterValues?.roles?.[name] ?? true}
                  className={classes.filterCheckBox}
                />
              ))}
            </LFBox>
          </LFGrid>
          <LFGrid item lg={4.5} xsm={5.3} xs={12}>
            <LFTypography className={classes.filterTitle}>Status</LFTypography>
            <LFBox direction="row" className={classes.filterMenu}>
              {values(status)?.map(({ name, label }) => (
                <LFCheckbox
                  label={label}
                  key={name}
                  name={name}
                  onChange={(e) => filterHandler(e, "status")}
                  checked={filterValues?.status?.[name] ?? true}
                  className={classes.filterCheckBox}
                />
              ))}
            </LFBox>
          </LFGrid>
        </LFGrid>
      )}
    </>
  );
};

export default FilterMenu;
