import { Chip } from "@mui/material";

const LFChip = ({ onClick, label, name, selected, id, ...props }) => {
  return (
    <Chip
      name={name}
      id={id}
      label={label}
      onClick={onClick}
      style={
        selected
          ? { backgroundColor: "#B2B2B2", color: "white" }
          : { backgroundColor: "transparent", color: "#717171" }
      }
      {...props}
    />
  );
};

export default LFChip;
