import React from "react";
import OTPInput from "react-otp-input";
import { equal } from "../utils/javascript";
import { useMediaQuery } from "@mui/material";

const LFOtpInput = ({
  otp,
  handleChange,
  inputStyle,
  containerStyle,
  numInputs,
}) => {
  const mobileMatches = useMediaQuery("(max-width:900px)");
  const handleKeyPress = (e) => {
    const key = e.key;
    if (
      !/^\d*$/.test(key) &&
      !equal(key, "Backspace") &&
      !equal(key, "Delete") &&
      !((e.ctrlKey || e.metaKey) && (equal(key, "v") || equal(key, "V")))
    ) {
      e.preventDefault();
    }
  };

  return (
    <OTPInput
      value={otp}
      {...(mobileMatches && { inputType: "number" })}
      onChange={handleChange}
      numInputs={numInputs}
      renderInput={(props) => (
        <input {...props} onKeyDownCapture={handleKeyPress} />
      )}
      inputStyle={inputStyle}
      containerStyle={containerStyle}
    />
  );
};

export default LFOtpInput;
