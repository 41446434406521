import axios from "axios";
import { useDispatch } from "react-redux";
import { equal, isArray, ternary, values } from "../utils/javascript";
import { getLocalStorageItem } from "../utils/localStorage";
import { LOADING_CHANGE } from "../redux/constant";
import { LS_KEY_TOKEN, locationPath, statusCodes } from "../utils/constant";
import { showToast } from "../utils/toastService";
import logoutAction from "../redux/action/logoutAction";
import { useNavigate } from "react-router-dom";
import { ZERO } from "../utils/constant";

const ApiContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const performRequest = async ({
    endPoint,
    method,
    data,
    queryParams = {},
    needLoader = false,
    parent = "",
    tokenKey = "token",
    showSuccessMessage = false,
    showFailedMessage = false,
    successMessage,
    failedMessage,
  }) => {
    try {
      const accessToken = getLocalStorageItem(LS_KEY_TOKEN);
      const headers = {
        "Content-Type": "application/json",
        ...(accessToken &&
          (equal(tokenKey, "Authorization")
            ? { [tokenKey]: `Bearer ${accessToken}` }
            : { [tokenKey]: accessToken })),
      };

      if (needLoader) {
        dispatch({ type: LOADING_CHANGE, payload: { [parent]: true } });
      }

      const response = await axios({
        method,
        url: `${process.env.REACT_APP_API_URL}/api/v1/${endPoint}`,
        data,
        headers,
        params: queryParams,
      });

      if (needLoader) {
        dispatch({ type: LOADING_CHANGE, payload: { [parent]: false } });
      }

      let sucssessMsg;

      if (isArray(response?.data?.message)) {
        sucssessMsg = response?.data?.message[ZERO];
      } else if (response?.data?.message) {
        sucssessMsg = response?.data?.message;
      } else {
        sucssessMsg = "Success";
      }

      if (equal(response?.data?.status_code, statusCodes?.NON_AUTHORATIVE)) {
        showFailedMessage && showToast(failedMessage || sucssessMsg, "error");
      } else {
        showSuccessMessage &&
          showToast(successMessage || sucssessMsg, "success");
      }

      return {
        data: response?.data?.data || response?.data,
        status: response?.status,
        headers: response?.headers,
        statusCode: response?.data?.status_code,
        message: sucssessMsg,
      };
    } catch (error) {
      let errorMessage;
      if (
        isArray(error?.response?.data?.message) &&
        error?.response?.data?.message?.[ZERO]
      ) {
        if (equal(typeof error?.response?.data?.message?.[ZERO], "object")) {
          errorMessage = values(error?.response?.data?.message?.[ZERO])?.[ZERO];
        } else {
          errorMessage = error?.response?.data?.message?.[ZERO];
        }
      } else if (error?.response?.data?.message) {
        errorMessage = error?.response?.data?.message;
      } else {
        errorMessage =
          "Oops! Something went wrong on our end. Please try again later.";
      }

      if (needLoader) {
        dispatch({ type: LOADING_CHANGE, payload: { [parent]: false } });
      }

      if (
        showFailedMessage &&
        !equal(error?.response?.status, statusCodes.UNAUTHORIZED)
      ) {
        showToast(
          failedMessage ||
            ternary(isArray(errorMessage), errorMessage?.[ZERO], errorMessage),
          "error",
        );
      }

      if (
        equal(error?.response?.data?.status_code, statusCodes?.UNAUTHORIZED)
      ) {
        dispatch(logoutAction(() => navigate(locationPath?.signIn)));
      }

      return {
        status: false,
        error: data?.message,
        error_code: data?.status_code,
        statusCode: error?.response?.status,
        errorMsg: errorMessage,
        errorObj: error?.response?.data?.message,
        data,
        errorData: error?.response?.data,
      };
    }
  };
  return { performRequest };
};

export default ApiContainer;
