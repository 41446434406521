import LFStack from "../../../shared/LFStack";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LFBox from "../../../shared/LFBox";
import theme from "../../../themes/theme";
import BusinessSelector from "../../reusable/BusinessSelector";
import { equal } from "../../../utils/javascript";
import { filterTab } from "../../../description/payments/statistics.description";
import Skeleton from "@mui/material/Skeleton";
import StatisticsContainer from "../../../container/payments/statistics.container";
import { Fragment, useRef } from "react";
import LFTypography from "../../../shared/LFTypograpy";

const useStyles = makeStyles((theme) => ({
  InvoiceCard: {
    background: theme.palette.gray.main,
    marginBottom: 20,
    borderRadius: 10,
    padding: [[12, 12, 20]],
    "&:last-child": {
      margin: 0,
    },
  },
  heading: {
    "&.MuiTypography-root": {
      marginBottom: 20,
      fontSize: 18,
      color: theme.palette.primary.main,
      fontWeight: 500,
    },
  },
  date: {
    flex: 1,
    fontSize: 12,
    color: theme.palette.primary.main,
    fontWeight: 500,
    padding: 11,
    textAlign: "center",
    lineHeight: "18px",
    textTransform: "uppercase",
    marginBottom: 20,
    borderRadius: 50,
    backgroundColor: theme.palette.white.main,
  },
  details: {
    "&.MuiStack-root": {
      flexDirection: "row",
      padding: [[8, 0]],
      justifyContent: "space-between",
      borderBottom: `1px solid #DDDDDD`,
      [theme.breakpoints.down("xts")]: {
        fontSize: 14,
      },
      "& p": {
        fontSize: 14,
      },
      "& span": {
        color: theme.palette.secondary.dark,
        fontSize: 14,
      },
    },
  },
  cardScroll: {
    maxHeight: "calc(100vh - 392px)",
    overflow: "auto",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      maxHeight: "unset",
    },
    "&.scrolling": {
      "&::-webkit-scrollbar-thumb": {
        background: "#8a8a8a",
      },
    },
    "&::-webkit-scrollbar": {
      width: 4,
      background: "transparent",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: "5px",
      "&:hover": {
        background: "#8a8a8a",
      },
    },
  },
}));

const Statistics = () => {
  const classes = useStyles();
  const containerRef = useRef(null);

  const { activeTab, apiData, data, handleTabChange } = StatisticsContainer();
  const handleScroll = () => {
    containerRef.current.classList.add("scrolling");
    clearTimeout(containerRef.current.timeout);
    containerRef.current.timeout = setTimeout(() => {
      containerRef.current.classList.remove("scrolling");
    }, 400);
  };

  return (
    <>
      <Typography
        variant="h6"
        mb={{ md: 3, xs: 1 }}
        sx={{
          display: { xxsm: "none" },
          fontWeight: 400,
          color: theme.palette.primary.main,
        }}
      >
        Statistics
      </Typography>
      <LFStack mb={{ md: 4, xs: 3 }} spacing={3} pt={1}>
        <BusinessSelector
          sx={{ maxWidth: { md: "unset", xxsm: "50%", xs: "unset" } }}
        />
      </LFStack>
      <LFBox
        className={classes.cardScroll}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {equal(apiData, null) ? (
          <LFTypography
            variant="h6"
            mt={2}
            color={"secondary.dark"}
            textAlign="center"
          >
            Oops! Something went wrong...
          </LFTypography>
        ) : (
          data?.map(({ heading, showTab, details, isLoading }, index) => (
            <LFStack
              justifyContent="space-between"
              className={classes.InvoiceCard}
              key={index}
            >
              <LFBox
                width="100%"
                sx={{ borderBottom: `1px solid ${theme.palette.gray.dark}` }}
                mb={2}
              >
                <Typography className={classes.heading}>{heading}</Typography>
                {showTab && (
                  <LFStack direction="row" gap={1.5}>
                    {filterTab.map(({ label, value }, index) => (
                      <LFBox
                        className={classes.date}
                        key={index}
                        onClick={() => handleTabChange(value)}
                        sx={{
                          cursor: "pointer",
                          ...(equal(activeTab, value) && {
                            background: theme.palette.primary.light,
                          }),
                        }}
                      >
                        {label}
                      </LFBox>
                    ))}
                  </LFStack>
                )}
              </LFBox>
              {details?.map(({ label, value }, i) =>
                isLoading ? (
                  <Fragment key={i}>
                    <Skeleton sx={{ minHeight: 38 }} />
                  </Fragment>
                ) : (
                  <LFStack className={classes.details} key={i}>
                    <>
                      <Typography>{label}</Typography>
                      <Typography variant="span">{value}</Typography>
                    </>
                  </LFStack>
                ),
              )}
            </LFStack>
          ))
        )}
      </LFBox>
    </>
  );
};

export default Statistics;
