import React from "react";
import LFBox from "../../../shared/LFBox";
import EditModeHelper from "../../../helper/EditModeHelper";
import EditForm from "../../../helper/EditForm";
import {
  changePasswordAttribute,
  changePasswordFormPath,
  defaultValues,
  dummyFormData,
  readOnlyAttributes,
} from "../../../description/account/settings/security.description";
import LFFormFields from "../../../shared/LFFormFields";
import LFForm from "../../../shared/LFForm";
import SecurityContainer from "../../../container/account/settings/security.container";

const Security = () => {
  const { isEditable, setIsEditable, cloneAttributes } = EditModeHelper({
    attributes: readOnlyAttributes,
  });

  const { handleChangePasswordSubmit } = SecurityContainer({
    formPath: changePasswordFormPath,
  });

  return (
    <LFBox sx={{ width: { md: "380px" } }}>
      {isEditable ? (
        <EditForm
          attribute={changePasswordAttribute}
          defaultValues={defaultValues}
          formPath={changePasswordFormPath}
          setIsEditable={setIsEditable}
          handleSubmit={handleChangePasswordSubmit}
        />
      ) : (
        <LFForm>
          <LFFormFields
            formData={dummyFormData}
            attributes={cloneAttributes}
            readOnly
            editable
          />
        </LFForm>
      )}
    </LFBox>
  );
};

export default Security;
