import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import ApiContainer from "../api.container";
import { equal, roundToTwoDecimalPlaces } from "../../utils/javascript";

const StatisticsContainer = () => {
  const { performRequest } = ApiContainer();

  const selectedBusinessId = useSelector(
    (state) => state?.globalAttr?.selectedBusinessId,
  );
  const [activeTab, setActiveTab] = useState("month");
  const [apiData, setApiData] = useState({});
  const [dataLoading, setDataLoading] = useState({
    current: false,
    allTime: false,
  });

  const apiCall = async () => {
    setDataLoading({
      current: true,
      allTime:
        !apiData?.time_period_statistics ||
        selectedBusinessIdRef.current !== selectedBusinessId,
    });

    const { data, statusCode } = await performRequest({
      endPoint: `${apiEndpoints?.statistics?.replace(
        ":id",
        selectedBusinessId,
      )}`,
      method: apiMethods.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    setDataLoading({ current: false, allTime: false });

    if (!equal(statusCode, statusCodes?.OK)) return setApiData(null);
    setApiData(data);
  };

  const selectedBusinessIdRef = useRef(selectedBusinessId);

  useEffect(() => {
    if (selectedBusinessId) {
      apiCall();
    }
    selectedBusinessIdRef.current = selectedBusinessId;
  }, [selectedBusinessId]);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  const { time_period_statistics, statistics } = apiData;

  const data = [
    {
      heading: "Current",
      showTab: true,
      isLoading: dataLoading?.current,
      details: [
        {
          label: `This ${activeTab} expenses`,
          value: `$${roundToTwoDecimalPlaces(
            time_period_statistics?.[activeTab]?.shifts_expenses,
          )}`,
        },
        {
          label: `This ${activeTab} shifts filled`,
          value: `${time_period_statistics?.[activeTab]?.filled_shifts_count}`,
        },
        {
          label: `Last ${activeTab} expenses`,
          value: `$${roundToTwoDecimalPlaces(
            time_period_statistics?.[activeTab]?.last_time_period_expenses,
          )}`,
        },
      ],
    },
    {
      heading: "All time",
      isLoading: dataLoading?.allTime,
      details: [
        {
          label: "Total expenses",
          value: `$${roundToTwoDecimalPlaces(statistics?.shifts_expenses)}`,
        },
        {
          label: "Total shifts filled",
          value: `${statistics?.filled_shifts_count}`,
        },
      ],
    },
  ];

  return {
    activeTab,
    apiData,
    data,
    handleTabChange,
  };
};

export default StatisticsContainer;
