import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal, ternary } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const readConversationAction =
  ({ performRequest, id, formPath, businessId }) =>
  async (dispatch, getState) => {
    const contactData = getState().app?.data?.[formPath?.parent];
    const businessConversations = contactData?.[businessId]?.data;
    const unreadConversationCount =
      getState().app?.data?.[formPath?.unreadCount];
    const contactIndex = businessConversations?.findIndex((contact) =>
      equal(contact?.attributes?.id, id),
    );
    const { statusCode, data } = await performRequest({
      endPoint: `${apiEndpoints?.conversations}/${id}`,
      method: apiMethods?.patch,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });

    if (!equal(statusCode, statusCodes?.OK)) return;
    if (!isNaN(contactIndex) && !equal(contactIndex, -1)) {
      const newContactData = [...businessConversations];
      newContactData[contactIndex] = data?.conversation;
      dispatch({
        type: SET_APP_DATA,
        payload: {
          [formPath?.parent]: {
            ...contactData,
            [businessId]: {
              ...contactData?.[businessId],
              data: newContactData,
            },
          },
          [formPath?.unreadCount]: ternary(
            unreadConversationCount,
            unreadConversationCount - 1,
            0,
          ),
        },
      });
    }
  };

export default readConversationAction;
