export const registerAttribute = [
  {
    name: "firstname",
    label: "First Name",
    type: "text",
    isRequired: true,
    error: ["First Name is required"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "surname",
    label: "Surname",
    type: "text",
    isRequired: true,
    error: ["Surname is required"],
    fullWidth: true,
    xs: 12,
  },
  {
    name: "email",
    label: "Email Address",
    type: "text",
    pattern: "email",
    isRequired: true,
    error: ["Email is required", "Please enter a valid email"],
    fullWidth: true,
    xs: 12,
  },
];

export const defaultValues = {
  firstname: "",
  surname: "",
  email: "",
};

export const registerFormPath = {
  parent: "register",
  state: "registerState",
};
