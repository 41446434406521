import React, { useState } from "react";
import TabNavbar from "../TabNavbar";
import { settingsTabBarLinks } from "../../../description/account/settings/settings.description";
import { locationPath } from "../../../utils/constant";
import Security from "./Security";
import LFGrid from "../../../shared/LFGrid";
import AccountSetting from "./AccountSetting";
import { useMediaQuery } from "@mui/material";
import LFBox from "../../../shared/LFBox";
import LFTypography from "../../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import NotificationsSettings from "./NotificationsSettings";

const useStyles = makeStyles((theme) => ({
  tabName: {
    "&.MuiTypography-root": {
      fontSize: 24,
      lineHeight: "31px",
      fontWeight: "500",
      paddingBottom: 16,
      borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
  },
  mdTHeading: {
    "&.MuiTypography-root": {
      paddingTop: 16,
      fontSize: 20,
      lineHeight: "26px",
    },
  },
  title: {
    "&.MuiTypography-root": {
      paddingTop: 12,
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  subTitle: {
    "&.MuiTypography-root": {
      fontSize: 18,
      lineHeight: "23px",
    },
  },
  switch: {
    "&.MuiSwitch-root": {
      marginLeft: 21,
    },
  },
  securityTab: {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-between",
      maxWidth: 380,
    },
  },
}));

const Settings = () => {
  const classes = useStyles();
  const mobileMatches = useMediaQuery("(max-width: 767px)");
  const [activeTab, setActiveTab] = useState(locationPath?.settingNotification);

  const renderChildren = () => {
    if (mobileMatches) {
      return (
        <LFBox>
          <LFTypography
            variant="h3"
            color="primary"
            className={classes.tabName}
          >
            Settings
          </LFTypography>
          <LFTypography
            variant="h3"
            color="primary"
            className={classes.mdTHeading}
            sx={{ marginBottom: 1.5 }}
          >
            Notification
          </LFTypography>
          <NotificationsSettings />
          <LFTypography
            variant="h3"
            color="primary"
            className={classes.mdTHeading}
            sx={{ marginBottom: 1.5 }}
          >
            Security
          </LFTypography>
          <Security />
          <LFTypography
            variant="h3"
            color="primary"
            className={classes.mdTHeading}
            sx={{ marginBottom: 2 }}
          >
            Account
          </LFTypography>
          <AccountSetting />
        </LFBox>
      );
    }

    switch (activeTab) {
      case locationPath?.settingNotification:
        return (
          <LFBox>
            <NotificationsSettings />
          </LFBox>
        );
      case locationPath?.settingSecurity:
        return <Security />;

      case locationPath?.settingsAccount:
        return <AccountSetting />;

      default:
        return null;
    }
  };

  return (
    <LFGrid container spacing={3}>
      <LFGrid item lg={6} md={7} xs={12}>
        <LFTypography
          variant="h6"
          mb={3.5}
          color="primary"
          sx={{
            display: { md: "none", xxsm: "block", xs: "none" },
            fontWeight: 500,
            fontSize: 24,
          }}
        >
          Settings
        </LFTypography>
        {!mobileMatches && (
          <TabNavbar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabBarLink={settingsTabBarLinks}
            className={classes.securityTab}
          />
        )}
        {renderChildren()}
      </LFGrid>
    </LFGrid>
  );
};

export default Settings;
