import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xxs: 375,
      xts: 480,
      xsm: 575,
      msm: 600,
      xxsm: 768,
      sm: 769,
      bsm: 820,
      md: 992,
      lg: 1280,
      xl: 1440,
      xxl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#44269A",
      light: "#b69cfb",
    },
    secondary: {
      main: "#33DFA1",
      light: "#E6FAF3",
      dark: "#717171",
      lightGreen: "#82FFCD",
    },
    white: {
      main: "#ffffff",
      contrastText: "#44269A",
    },
    dark: {
      main: "#000000",
      danger: "#FF0000",
      success: "#1FD01F",
      pending: "#EBBB0C",
      confirmed: "#48A7FF",
    },
    gray: {
      main: "#F2F2F2",
      dark: "#B2B2B2",
      light: "#DDDDDD",
    },
    danger: {
      main: "#ff0000",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: [
      "'Poppins', sans-serif",
      "'Work Sans', sans-serif",
      "'DM Sans', sans-serif",
    ],
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          "@media (min-width: 1280px)": {
            maxWidth: 1230,
          },
        },
      },
    },
  },
});

export default theme;
