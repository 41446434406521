import React, { useState } from "react";
import FromActionButtons from "./FromActionButtons";
import { calculateTimeDifference } from "../../../utils/javascript";
import LFStack from "../../../shared/LFStack";
import { convertTimeToMinutes } from "../../../utils/moment";
import { ZERO } from "../../../utils/constant";
import { showToast } from "../../../utils/toastService";
import LFTextField from "../../../shared/LFTextField";

const BreakForm = ({ setCurrentForm, defaultValue, formData, onSave }) => {
  const [time, setTime] = useState(defaultValue ?? 0);

  const handleTimeChange = (e) => {
    const inputValue = e?.target?.value?.replace(/\D/g, "");
    return setTime(inputValue ? Number(inputValue) : 0);
  };

  const handleSaveClick = () => {
    if (formData?.time) {
      const { startTime, endTime } = { ...formData.time };
      const diffrence = calculateTimeDifference(startTime, endTime);
      const breakDiffrence = convertTimeToMinutes(diffrence) - Number(time);

      if (breakDiffrence < ZERO)
        return showToast("The Break time should be less than shift time");
    }

    onSave(time);
  };

  return (
    <>
      <LFStack>
        <LFStack direction="row" alignItems="end" gap={3}>
          <LFTextField
            name="minutes"
            label={"Minutes"}
            onChange={handleTimeChange}
            value={time}
            fullWidth
            inputProps={{
              maxLength: 3,
            }}
          />
        </LFStack>
      </LFStack>

      <FromActionButtons
        setCurrentForm={setCurrentForm}
        handleSaveClick={handleSaveClick}
      />
    </>
  );
};

export default BreakForm;
