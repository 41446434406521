import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const allNotificationsSeen = (performRequest, formPath) => async (dispatch) => {
  const { statusCode } = await performRequest({
    endPoint: apiEndpoints?.allNotificationsRead,
    method: apiMethods?.patch,
    tokenKey: "Authorization",
    showFailedMessage: true,
  });
  if (equal(statusCode, statusCodes?.OK)) {
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.newcounts]: 0,
      },
    });
  }
};
export default allNotificationsSeen;
