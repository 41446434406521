import AppStoreImag from "../../assets/png/appStore.png";
import PlayStoreImag from "../../assets/png/playStore.png";
import { appStoreURL, playStoreURL } from "../../utils/constant";

const LocumUserContainer = () => {
  const mobileStore = [
    {
      image: AppStoreImag,
      url: appStoreURL,
    },
    {
      image: PlayStoreImag,
      url: playStoreURL,
    },
  ];

  return { mobileStore };
};

export default LocumUserContainer;
