import { locationPath } from "../../../utils/constant";

export const settingsTabBarLinks = [
  {
    label: "Notifications",
    value: locationPath?.settingNotification,
  },
  {
    label: "Security",
    value: locationPath?.settingSecurity,
  },
  {
    label: "Account",
    value: locationPath?.settingsAccount,
  },
];
