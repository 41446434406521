import React, { useEffect, useState } from "react";
import LFBox from "../../shared/LFBox";
import PromptTemplet from "../reusable/PromptTemplet";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import moment from "moment";
import ApiContainer from "../../container/api.container";
import { equal, ternary } from "../../utils/javascript";
import Loader from "../reusable/Loader";

const useStyles = makeStyles(() => ({
  promptWrapper: {
    minHeight: "calc(100vh - 270px)",
    display: "flex",
  },
}));
const SettleUnpaidShift = () => {
  const { performRequest } = ApiContainer();
  const classes = useStyles();

  const [showDone, setShowDone] = useState(false);
  const [iseLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { shiftId } = useParams();

  const viewShiftForm = location?.state?.viewShiftForm;
  const name = location?.state?.name;

  useEffect(() => {
    if (!viewShiftForm) {
      navigate(`${locationPath?.viewShift.replace(":shiftId", shiftId)}`, {
        replace: true,
      });
    }
  }, []);

  const endPoint = ternary(
    viewShiftForm?.payment_failed,
    apiEndpoints?.completePayment?.replace(":id", shiftId),
    apiEndpoints?.finalizeShift?.replace(":id", shiftId),
  );

  const confirmFinalizeSHift = async () => {
    setIsLoading(true);
    const response = await performRequest({
      endPoint: endPoint,
      method: apiMethods?.put,
      tokenKey: "Authorization",
      showFailedMessage: true,
    });
    const { statusCode } = response;
    if (equal(statusCode, statusCodes.NOT_FOUND)) return navigate(-1);
    if (equal(statusCode, statusCodes.OK)) setShowDone(true);
    setIsLoading(false);
  };

  const confirmButtonAttributes = [
    {
      label: "CONFIRM",
      onClick: () => {
        confirmFinalizeSHift();
      },
      variant: "contained",
    },
    {
      label: "cancel",
      onClick: () => navigate(-1),
      variant: "",
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        fontSize: 16,
        mt: 2,
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];

  const DoneButtonAttributes = [
    {
      label: "DONE",
      onClick: () => navigate(-1),
      variant: "contained",
    },
  ];

  const { time, start_date } = { ...viewShiftForm };
  const { startTime, endTime } = { ...time };
  const askMessage = `Confirm ${name} worked ${startTime?.value}${
    startTime?.ampm
  }-${endTime?.value}${endTime?.ampm} ${moment(start_date).format("dddd")} ?`;

  return (
    <LFBox className={classes.promptWrapper}>
      {iseLoading ? (
        <Loader />
      ) : (
        <PromptTemplet
          mainText={showDone ? "Shift has been finalized" : askMessage}
          subText={""}
          buttonAttributes={
            showDone ? DoneButtonAttributes : confirmButtonAttributes
          }
          iconSrc={true}
          alertType={showDone ? false : true}
          bgGray={false}
        />
      )}
    </LFBox>
  );
};

export default SettleUnpaidShift;
