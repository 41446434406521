import { apiEndpoints, apiMethods, statusCodes } from "../../utils/constant";
import { equal, last } from "../../utils/javascript";
import { SET_APP_DATA } from "../constant";

const getConversationsAction =
  ({ performRequest, formPath, businessId }) =>
  async (dispatch, getState) => {
    const per_page = 25;
    const contactData = getState().app?.data?.[formPath?.parent];
    const businessConversations = contactData?.[businessId];
    const lastConversationId = last(businessConversations?.data)?.id;

    const { data, statusCode } = await performRequest({
      endPoint: apiEndpoints?.conversations,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      queryParams: {
        per_page,
        business_id: businessId,
        last_conversation_id: lastConversationId,
      },
      showFailedMessage: true,
      needLoader: true,
      parent: formPath?.parent,
    });
    if (!equal(statusCode, statusCodes.OK)) return;
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: {
          ...contactData,
          [businessId]: {
            ...businessConversations?.data,
            data: [
              ...(businessConversations?.data || []),
              ...data.conversations,
            ],
            [formPath?.maxPage]: data?.pagy_links?.pages,
          },
        },
        [formPath?.unreadCount]: data?.unread_conversations_count,
      },
    });
  };

export default getConversationsAction;
