import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import LFStack from "../../shared/LFStack";
import { Link } from "react-router-dom";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  heading: {
    "&.MuiTypography-root": {
      paddingTop: 64,
      paddingBottom: 48,
      fontSize: 36,
      lineHeight: "41px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        paddingTop: 32,
        paddingBottom: 40,
        fontSize: 28,
        lineHeight: "32px",
      },
      [theme.breakpoints.down("xts")]: {
        fontSize: 20,
        lineHeight: "20px",
        fontWeight: 400,
        paddingTop: 36,
        paddingBottom: 24,
      },
    },
  },

  textContainer: {
    maxWidth: 800,
    width: "100%",
    margin: [[0, "auto"]],
    padding: [[0, 14]],
    paddingBottom: 160,
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 100,
      padding: [[0, 13]],
    },
    [theme.breakpoints.down("xts")]: {
      paddingBottom: 75,
    },
    "& .MuiTypography-body1": {
      color: theme.palette.secondary.dark,
      fontSize: 12,
      lineHeight: "150%",
    },
  },
  mdHeading: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      textTransform: "uppercase",
    },
  },
  mainPointHead: {
    "&.MuiTypography-root": {
      textDecoration: "underline",
      textTransform: "capitalize",
      fontWeight: 700,
    },
  },
  subPointHead: {
    "&.MuiTypography-root": {
      fontWeight: 700,
    },
  },
  petamenuMain: {
    "&.MuiStack-root": {
      marginLeft: 20,
      gap: [[15, 0]],
    },
  },
}));
const EmploymentAgreement = () => {
  const classes = useStyles();
  return (
    <>
      <LFBox className={classes.textContainer}>
        <LFTypography variant="h2" color="primary" className={classes.heading}>
          Locum Casual Employment Agreement
        </LFTypography>
        <LFStack spacing={"15px"}>
          <LFTypography>
            LOCUMFIND PTY LTD (ACN 669 232 795) (<b>LOCUMFIND</b>) is pleased to
            offer you (<b>Locum, You</b>) casual employment in accordance with
            the terms and conditions outlined in this Agreement.
          </LFTypography>
          <LFTypography>
            By registering an Account as a Locum with LOCUMFIND, you agree to
            accept LOCUMFIND’s offer of casual employment and be bound by the
            terms and conditions set out in this Agreement. You acknowledge and
            agree that LOCUMFIND may update, vary or amend this Locum Agreement
            from time to time in accordance with its terms and the most
            up-to-date version of the Locum Agreement is available at{" "}
            <Link
              to={"https://www.locumfind.com.au/employee-agreement"}
              target="_blank"
            >
              www.locumfind.com.au/employee-agreement
            </Link>{" "}
          </LFTypography>
          <LFTypography>
            Your employment will also be governed by the National Employment
            Standards (NES), which are summarised in the Fair Work Information
            Statement and the Fair Work Casual Employment Information Statement
            <b>attached</b> at Schedule 2 and the modern award, if any, set out
            in <b>Item 3</b> of Schedule 1 (<b>Award</b>). For the avoidance of
            doubt, the NES and the Award, if any, do not form part of this
            Agreement.
          </LFTypography>
          <LFTypography className={classes.mdHeading}>AGREEMENT:</LFTypography>
          <LFTypography className={classes.mainPointHead}>
            1. DEFINITIONS AND INTERPRETATION
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            1.1 Definitions
          </LFTypography>
          <LFTypography>
            The following definitions will apply for the purpose of this
            Agreement, unless the context otherwise requires:
          </LFTypography>
          <LFTypography></LFTypography>
          <LFTypography>
            <b>Account</b> has the meaning given to that term in the Terms and
            Conditions of Use;
          </LFTypography>
          <LFTypography>
            <b>Agreed Hourly Rate</b> has the meaning given to that term in
            clause 9.3 ;
          </LFTypography>
          <LFTypography>
            <b>Agreement</b> means this Locum Casual Employment Agreement;
          </LFTypography>
          <LFTypography>
            <b>App</b> means the iOS and Android smartphone application
            “LocumFind”;
          </LFTypography>
          <LFTypography>
            <b>Client</b> means a person or entity that has engaged LOCUMFIND to
            supply locums;
          </LFTypography>
          <LFTypography>
            <b>Client Hourly Rate</b> means the hourly rate set by the Client in
            a Placement Request (which must not be less than the Minimum Award
            Rate);
          </LFTypography>
          <LFTypography>
            <b>Client Request</b> means a request by the Client for a Placement;
          </LFTypography>
          <LFTypography>
            <b>Effective Date</b> means the date on which the Locum registers an
            Account;
          </LFTypography>
          <LFTypography>
            <b>Fees</b> means the fees set out in a Quote;
          </LFTypography>
          <LFTypography>
            <b>Minimum Award Rate</b> means:
          </LFTypography>
          <LFTypography>
            (a) the minimum hourly rate for your Role as set out in the Award,
            if any, in Item 3 of Schedule 1; plus
          </LFTypography>
          <LFTypography>
            (b) any overtime, loadings, penalty rates, allowances and other
            payments due for the proposed work hours in a Placement Request in
            accordance with the Award, if any, set out in Item 3 in respect of
            your Role.
          </LFTypography>
          <LFTypography>
            <b>Related Body Corporate</b> has the meaning given to this term
            under the Corporations Act 2001 (Cth);
          </LFTypography>
          <LFTypography>
            <b>Role</b> means the relevant roles as specified in the Schedule;
          </LFTypography>
          <LFTypography>
            <b>Placement Request</b> means a request by the Client for a
            Placement;
          </LFTypography>
          <LFTypography>
            <b>Platform</b> means LocumFind platform accessible view the Website
            and the App;
          </LFTypography>
          <LFTypography>
            <b>Quote</b> refers to the Quote for the Services in accordance with
            clause 10 ;
          </LFTypography>
          <LFTypography>
            <b>Services</b> has the meaning given to that term in clause 9.2 ;
          </LFTypography>
          <LFTypography>
            <b>Site</b> means any place at which the Client requests the
            provision of Services;
          </LFTypography>
          <LFTypography>
            <b>Territory</b> means Victoria, New South Wales, South Australia,
            Queensland and Western Australia.
          </LFTypography>
          <LFTypography>
            <b>Terms and Conditions of Use</b> means the separate terms and
            conditions relating to the use of the Website and the App;
          </LFTypography>
          <LFTypography>
            <b>Website</b> means the website{" "}
            <Link to={"https://www.locumfind.com.au/"} target="_blank">
              www.locumfind.com.au
            </Link>
          </LFTypography>
          <LFTypography>
            <b>Work</b> means the type of work specified in a Client Request;
            and
          </LFTypography>
          <LFTypography>
            <b>Your Hourly Rate</b> means your hourly rate set out in a Quote.
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            1.2 Interpretation
          </LFTypography>
          <LFTypography>
            The following rules shall in this Agreement unless the context
            requires otherwise:
          </LFTypography>
          <LFTypography>
            (a) headings are for convenience only and do not affect
            interpretation;
          </LFTypography>
          <LFTypography>
            (b) the singular includes the plural and conversely;
          </LFTypography>
          <LFTypography>
            (c) the meaning of general words is not limited by specific examples
            introduced by “including”, or “for example”, or similar expressions;
          </LFTypography>
          <LFTypography>
            (d) if a word or phrase is defined, its other grammatical forms have
            a corresponding meaning;
          </LFTypography>
          <LFTypography>
            (e) a reference to a person, corporation, trust, partnership,
            unincorporated body or any other entity includes any of them;
          </LFTypography>
          <LFTypography>
            (f) a reference to a “party” means a party to this Agreement and a
            reference to “parties” means both of the parties to this Agreement;
          </LFTypography>
          <LFTypography>
            (g) a reference to a clause, Schedule or Item is a reference to a
            clause of, Schedule to or Item of this Agreement;
          </LFTypography>
          <LFTypography>
            (h) a reference to an agreement or document (including, a reference
            to this Agreement) is to the agreement or document as amended,
            varied, supplemented, innovated or replaced, except to the extent
            prohibited by this Agreement or that other agreement or document;
          </LFTypography>
          <LFTypography>
            (i) a reference to legislation or to a provision of legislation
            includes a modification or re-enactment of it, a legislative
            provision substituted for it and a regulation or statutory
            instrument issued under it; and
          </LFTypography>
          <LFTypography>
            (j) unless otherwise indicated, a reference to a monetary amount in
            this Agreement, is a reference to that amount in Australian dollars.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            2. COMMENCEMENT DATE
          </LFTypography>
          <LFTypography>
            Your employment will commence on the date you register an Account.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            3. POSITION
          </LFTypography>
          <LFTypography>
            3.1 You will be employed in the Role set out in <b>Item 1</b> of
            Schedule 1. The Award classification of your position, if any, is
            set out in
            <b>Item 2,</b> of Schedule 1.
          </LFTypography>
          <LFTypography>
            3.2 You will be required to work at the locations set out in a
            Placement Request or elsewhere as reasonably directed by LOCUMFIND.
            You will not be required to (and must not) work outside the
            Territory.
          </LFTypography>
          <LFTypography>
            3.3 You agree to use your best endeavours to perform the duties and
            responsibilities contained in the position description referred to
            at Schedule 2 and otherwise as assigned orally or in writing by
            LOCUMFIND from time to time.
          </LFTypography>
          <LFTypography>
            3.4 You agree to comply with all lawful and reasonable directions
            given orally or in writing by LOCUMFIND from time to time.
          </LFTypography>
          <LFTypography>
            3.5 LOCUMFIND may vary your position title, duties and
            responsibilities, reporting line or location of employment from time
            to time in accordance with legislative changes and business
            requirements. If such variations are made, this Agreement will
            continue to apply unless terminated or replaced in writing.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            4. REMUNERATION
          </LFTypography>
          <LFTypography>
            4.1 Your gross remuneration is set out in <b>Item 3</b> of Schedule
            1.
          </LFTypography>
          <LFTypography>
            1.1 You remuneration will be paid at the frequency set out in{" "}
            <b>Item 5</b> of Schedule 1 by electronic funds transfer into your
            nominated bank account, unless otherwise specified by LOCUMFIND. It
            is your responsibility to provide the correct bank details to
            LOCUMFIND and to advise of any changes to those details.
          </LFTypography>
          <LFTypography>
            4.2 Your gross remuneration includes all payments that LOCUMFIND is
            legally obliged to provide to you and is offset against any payments
            to which you are, or may become, legally entitled (including, but
            not limited to, minimum wages, allowances, penalty rates, premiums,
            overtime, loadings and payments of a like nature) under any
            legislation, Award or award, unless LOCUMFIND specifies otherwise.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            5. DEDUCTIONS
          </LFTypography>
          <LFTypography>
            5.1 LOCUMFIND will be entitled to deduct from your remuneration
            (other than for any accrued leave entitlements), during your
            employment or upon the termination of your employment, the
            following:
          </LFTypography>
          <LFTypography>(a) any overpayment in monies;</LFTypography>
          <LFTypography>
            (b) any amount that LOCUMFIND is legally obliged to deduct;
          </LFTypography>
          <LFTypography>
            (c) any amount in respect of which you have provided prior written
            authority;
          </LFTypography>
          <LFTypography>
            (d) any amount for loss or damage to us or our property that you
            have caused;
          </LFTypography>
          <LFTypography>
            (e) such other amounts as may be owed by you on any account to
            LOCUMFIND or a related body corporate;
          </LFTypography>
          <LFTypography>
            5.2 The exercise of LOCUMFIND’s rights under clause 5.1 will not be
            a waiver of our rights to recover the balance of any such amount
            listed in that clause.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            6. HOURS
          </LFTypography>
          <LFTypography>
            6.1 As a casual employee, you will be engaged by the hour in
            accordance with a Placement Request.
          </LFTypography>
          <LFTypography>6.2 Your hours are not to exceed:</LFTypography>
          <LFTypography>
            (a) where your Role is an optometrist, more than 10 hours within any
            24 hour period, except where the work in that 24 hour period is
            performed solely for one Client;
          </LFTypography>
          <LFTypography>
            (b) where you Role is covered by the Pharmacy Industry Award 2020,
            more than 12 hours within any 24 hour period, except where the work
            in that 24 hour period is performed solely for one Client); and.
          </LFTypography>
          <LFTypography>
            (c) more than 38 hours per week or 76 hours per fortnight, except
            where the work in that 24 hour period or 76 hour period (as
            applicable) is performed solely for one Client.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            7. TRAVEL
          </LFTypography>
          <LFTypography>
            7.1 You agree to undertake such travel as may reasonably be required
            to deliver the Services to a Client in accordance with a Placement
            Request.
          </LFTypography>
          <LFTypography>
            7.2 If the Client has indicated in a Placement Request that it will
            cover travel expenses, then you will be entitled to reimbursement of
            Your documented travel expenses up to the amount stipulated in the
            Placement Request. LOCUMFIND is not required to reimburse You for
            any other travel-related costs or expenses.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            8. PLACEMENT REQUESTS
          </LFTypography>
          <LFTypography>
            8.1 From time to time, a Client may request that LOCUMFIND supply a
            Locum by submitting a request through the Platform (
            <b>Placement Request</b>).
          </LFTypography>
          <LFTypography>8.2 The Placement Request will stipulate:</LFTypography>
          <LFTypography>
            (a) the Role to be performed by the Locum;
          </LFTypography>
          <LFTypography>
            (b) any necessary training, certifications, tools or equipment
            required of the Locum;
          </LFTypography>
          <LFTypography>(c) the nature of the Work;</LFTypography>
          <LFTypography>
            (d) the location of the Site (which must be located within the
            Territory);
          </LFTypography>
          <LFTypography>
            (e) whether travel expenses will be covered and to what amount;;
          </LFTypography>
          <LFTypography>(f) the expected start date or time; and</LFTypography>
          <LFTypography>(g) the expected finish date or time. </LFTypography>
          <LFTypography>
            (together, the <b>Services</b>).
          </LFTypography>
          <LFTypography>
            8.3 The Placement Request will also stipulate the Client Hourly Rate
            in respect of the Services.
          </LFTypography>
          <LFTypography>
            8.4 LOCUMFIND will notify You of a Placement Request via its
            Platform (or other method as may be used by LOCUMFUND from time to
            time).
          </LFTypography>
          <LFTypography>
            8.5 You may then have the opportunity to provide a Quote to
            LOCUMFIND in respect of the Services set out in the Placement
            Request in accordance with clause 9. LOCUMFIND may then pass on your
            Quote to the Client for acceptance.
          </LFTypography>
          <LFTypography>
            8.6 A Client may decide to accept the Quote. Upon acceptance of the
            Quote, You will be required to deliver the Services to the Client in
            accordance with the Placement Request, the Quote and the terms of
            this Agreement.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            9. QUOTES
          </LFTypography>
          <LFTypography>
            9.1 In response to a Placement Request advertised by LOCUMFIND, You
            may issue a Quote to LOCUMFIND via the Platform.
          </LFTypography>
          <LFTypography>
            9.2 The Quote must stipulate Your Hourly Rate for providing the
            Services, which must not be less than the Minimum Award Rate (if
            applicable).
          </LFTypography>
          <LFTypography>
            9.3 If the Client accepts the Quote, then Your Hourly Rate is the
            Agreed Hourly Rate.
          </LFTypography>
          <LFTypography>9.4 You acknowledge and agree that:</LFTypography>
          <LFTypography>
            (a) You may only submit a Quote in respect of a Placement Request
            where You hold all the necessary qualifications, registrations,
            certifications, licences, tools and equipment required to perform
            the Role as set out in that Placement Request;
          </LFTypography>
          <LFTypography>
            (b) You may only submit a Quote in respect of a Placement Request
            where the hours of the Work in that Placement Request are such that,
            in the event the Quote is accepted, you will not:
          </LFTypography>
          <LFStack className={classes.petamenuMain}>
            <LFTypography>
              (i) work for more than 10 hours within any 24 hour period if your
              Role is an Optometrist or work for more than 12 hours within any
              24 hour period if your Role is covered by the Pharmacy Industry
              Award 2020 (except where the work in that 24 hour period will be
              performed solely for one Client);
            </LFTypography>
            <LFTypography>
              (ii) work for more than 38 hours within any 7 day period (except
              where the work in that 7 day period will be performed solely for
              one Client);
            </LFTypography>
          </LFStack>
          <LFTypography>
            taking into account any additional hours that you have worked, will
            work, or may work under any other Placement Request(s) or active
            Quote;
          </LFTypography>
          <LFTypography>
            (c) You may only submit a Quote and perform Work in respect of a
            Placement Request in the Territory;
          </LFTypography>
          <LFTypography>
            (d) You are solely responsible for making Your own assessment or
            calculation of Your Hourly Rate for providing the Services set out
            in a Placement Request and for making Your own determination of Your
            suitability and availability to provide the Services as requested in
            the Placement Request;
          </LFTypography>
          <LFTypography>
            (e) Your Quote will only be made available to the Client for
            acceptance by the Client until the earlier of:
          </LFTypography>
          <LFStack className={classes.petamenuMain}>
            <LFTypography>
              (i) the expected start date or time as set out in the Placement
              Request;
            </LFTypography>
            <LFTypography>
              (ii) the Client’s acceptance of another Quote provided by
              LOCUMFIND;
            </LFTypography>
          </LFStack>
          <LFTypography>
            the Client’s cancellation of the Placement Request.
          </LFTypography>
          <LFTypography>
            (f) LOCUMFIND makes no representation, warranty or guarantee that
            your Quote will be accepted by the Client;
          </LFTypography>
          <LFTypography>
            (g) LOCUMFIND reserves the right (in its absolute discretion) to
            withdraw, cancel or remove any Quote you submit in response to a
            Placement Request at any time prior to the delivery of the Services,
            including after the Client has accepted the Quote, for any reason;
          </LFTypography>
          <LFTypography>
            (h) LOCUMFIND reserves the right (in its absolute discretion) to
            withdraw, cancel or remove the Locum from supplying the Services to
            a Client at any time, for any reason; and
          </LFTypography>
          <LFTypography>
            (i) after the Services are completed pursuant to a Placement
            Request, the Client is encouraged to review and provide feedback of
            the Services on the Platform.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            10. LEAVE
          </LFTypography>
          <LFTypography>
            10.1 As a casual employee, you will be entitled to unpaid carer’s
            leave, compassionate leave and community service leave in accordance
            with the NES and any Award set out in <b>Item 2</b> of Schedule 1.
            You may also be entitled to parental leave in accordance with the
            NES and long service leave in accordance with the relevant
            industrial instrument or State legislation.
          </LFTypography>
          <LFTypography>
            10.2 You may be required to work on a public holiday unless you have
            reasonable grounds to refuse in accordance with the NES.
          </LFTypography>
          <LFTypography>
            10.3 Subject to the requirements of any Award set out in{" "}
            <b>Item 2</b> of Schedule 1, LOCUMFIND may temporarily close down
            some or all of our operations, or operate with essential staff only,
            at certain times such as the Christmas and New Year period.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            11. POLICIES AND PROCEDURES
          </LFTypography>
          <LFTypography>
            11.1 You are required to read, understand and comply with
            LOCUMFIND’s policies and procedures which may be introduced or
            amended from time to time in accordance with the legislative changes
            and business requirements.
          </LFTypography>
          <LFTypography>
            11.2 You acknowledge and agree that LOCUMFIND’s policies and
            procedures:
          </LFTypography>
          <LFTypography>
            (a) are not incorporated into this Agreement and, to the extent that
            they appear to impose any obligations on LOCUMFIND, they are
            intended to be guidelines only; and
          </LFTypography>
          <LFTypography>
            (b) operate as lawful and reasonable directions.
          </LFTypography>
          <LFTypography>
            11.3 Serious breaches of LOCUMFIND’s policies and procedures may
            result in termination of employment.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            12. HEALTH AND SAFETY
          </LFTypography>
          <LFTypography>12.1 You must:</LFTypography>
          <LFTypography>
            (a) take reasonable care for your own health and safety at work and
            the health and safety of other persons who may be adversely affected
            by your acts or omissions in the workplace;
          </LFTypography>
          <LFTypography>
            (b) comply with any reasonable instruction that is given by
            LOCUMFIND in relation to health and safety;
          </LFTypography>
          <LFTypography>
            (c) immediately report to the person responsible for health and
            safety at work any risk or hazard that you observe in the workplace;
            and
          </LFTypography>
          <LFTypography>
            (d) immediately report to the person responsible for health and
            safety at work any illness or injury which you have sustained in the
            course of your employment with LOCUMFIND.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            13. FITNESS FOR WORK AND MEDICAL EXAMINATIONS
          </LFTypography>
          <LFTypography>
            13.1 Your employment is subject to you being fit to perform the
            inherent requirements of your position. If, at any time during your
            employment with LOCUMFIND, you are not fit to perform the inherent
            requirements of your position, it may result in the termination of
            your employment.
          </LFTypography>
          <LFTypography>
            13.2 You warrant that you are fit for work and do not have an injury
            or illness which would impede the performance of your duties or pose
            a risk to the health and safety of yourself, other staff or a member
            of the public. If any circumstances change your fitness for work,
            you must advise LOCUMFIND immediately.
          </LFTypography>
          <LFTypography>
            13.3 LOCUMFIND may direct you to undergo a medical examination or
            investigation by a qualified medical practitioner or other health
            care professional appointed by LOCUMFIND at any time if, in our
            reasonable opinion, there is a valid reason (for example, to
            determine whether you are fit for work). You agree to attend and
            cooperate fully in such medical examination or investigation as
            required by LOCUMFIND.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            14. ENTITLEMENT TO WORK IN AUSTRALIA
          </LFTypography>
          <LFTypography>
            Your employment is subject to you being legally entitled to engage
            in paid work in Australia. If at any time during your employment
            with LOCUMFIND you cease to meet this requirement, or any conditions
            that are imposed on your right to work in Australia, your employment
            may be terminated immediately. Although LOCUMFIND has the right to
            check your visa status at any time, you have an ongoing
            responsibility to inform LOCUMFIND immediately in the event of any
            change in your visa status, visa conditions or personal
            circumstances that may affect your working rights in Australia.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            15. CRIMINAL OFFENCES
          </LFTypography>
          <LFTypography>
            15.1 You agree to immediately notify LOCUMFIND if:
          </LFTypography>
          <LFTypography>
            (a) you are charged with, or convicted of, any summary or indictable
            offence during your employment with LOCUMFIND;
          </LFTypography>
          <LFTypography>
            (b) you have been charged with a summary or indictable offence which
            is likely to be pending when you commence your employment with
            LOCUMFIND; or
          </LFTypography>
          <LFTypography>
            (c) you have a prior conviction (other than a conviction which is
            spent or lapsed at law) where the offence is relevant to the work
            that you will perform during your employment with LOCUMFIND.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            16. WARRANTIES
          </LFTypography>
          <LFTypography>16.1 You warrant that:</LFTypography>
          <LFTypography>
            (a) You are skilled, trained, qualified and competent to work as a
            pharmacists, pharmacy assistant, dispensary technician or
            optometrist (as applicable to your Role);
          </LFTypography>
          <LFTypography>
            (b) You possess and will maintain the qualifications necessary to
            fulfil your role;
          </LFTypography>
          <LFTypography>
            (c) if you are a pharmacist or optometrist, You are registered with
            the Australian Health Practitioner Regulation Agency and will
            continue to hold this registration during your employment;
          </LFTypography>
          <LFTypography>
            (d) All information you have provided to LOCUMFIND about your work
            experience and qualifications is correct;
          </LFTypography>
          <LFTypography>
            (e) Any photographs of yourself that You provide to LOCUMFIND for
            identity verification purposes (including for use in display
            pictures on Your Account) are current, show a true likeness, and are
            not otherwise misleading;
          </LFTypography>
          <LFTypography>
            (f) You currently comply and during your employment you will
            continue to comply with all Australian laws (including any Act of
            the Commonwealth, State or Territory in which you work and
            regulations and any other instruments made under such Acts,
            including public health orders and directions that may be in place
            from time to time), associated with your employment and/or required
            to perform your work, including laws, regulations, public health
            orders and directions in relation to being vaccinated against
            certain illnesses and if required, providing evidence you have been
            vaccinated against those illnesses and if applicable, complying with
            requirements for exemptions and exceptions contained in those laws,
            regulations or public health orders and directions;
          </LFTypography>
          <LFTypography>
            (g) You have a lawful right to work in Australia and perform the
            duties and responsibilities of employment contemplated by this
            Agreement and you will immediately notify LOCUMFIND of any
            circumstance that might prejudice this right at any time during your
            employment by LOCUMFIND;
          </LFTypography>
          <LFTypography>
            (h) To enable LOCUMFIND to verify at any time you have this right,
            you will provide to LOCUMFIND upon request sufficient evidence to
            establish this fact including but not limited to evidence of
            qualifications and working visas or Australian citizenship; and
          </LFTypography>
          <LFTypography>
            (i) You have disclosed to LOCUMFIND the particulars of any restraint
            or restriction in relation to the performance of your duties and
            responsibilities and by entering into this contract or performing
            your duties and responsibilities, you will not breach any obligation
            you have to a third party or expose the employer to any claims from
            a third party.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            17. DUTIES AND RESPONSIBILITIES
          </LFTypography>
          <LFTypography>17.1 During your employment you must:</LFTypography>
          <LFTypography>
            (a) Perform the duties and responsibilities that LOCUMFIND assigns
            to you;
          </LFTypography>
          <LFTypography>
            (b) Perform your duties and responsibilities faithfully, honestly
            and diligently;
          </LFTypography>
          <LFTypography>
            (c) Use your best endeavours to promote and protect the interests of
            the LOCUMFIND;
          </LFTypography>
          <LFTypography>
            (d) Comply with all lawful and reasonable directions given by
            LOCUMFIND and its respective authorised representatives;
          </LFTypography>
          <LFTypography>
            (e) Comply with all of the LOCUMFIND’s policies as varied from time
            to time (although such policies operate independently of this
            Agreement and are not incorporated into this Agreement); and
          </LFTypography>
          <LFTypography>
            (f) Not at any time do anything contrary to the interests of
            LOCUMFIND or do anything which does or may damage LOCUMFIND’s
            reputation, viability or profitability or cause loss or damage to
            LOCUMFIND in any way.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            18. CONFIDENTIALITY
          </LFTypography>
          <LFTypography>18.1 For the purposes of this clause 19 :</LFTypography>
          <LFTypography>
            (a) <b>Confidential Information</b> means:
          </LFTypography>
          <LFStack className={classes.petamenuMain}>
            <LFTypography>
              (i) information specifically designated by LOCUMFIND or any third
              party as being confidential;
            </LFTypography>
            <LFTypography>
              (ii) Intellectual Property of LOCUMFIND or any third party;
            </LFTypography>
            <LFTypography>
              (iii) products and services marketed or used, or to be marketed or
              used, or rejected, by LOCUMFIND or any related body corporate and
              persons or entities dealing with LOCUMFIND;
            </LFTypography>
            <LFTypography>
              (iv) information relating to LOCUMFIND’s general business
              operations and any related body corporate;
            </LFTypography>
            <LFTypography>
              (v) patient lists of clients, contact details and any personal or
              business details relating to them;
            </LFTypography>
            <LFTypography>
              (vi) costing and selling price information, including pricing,
              credit policies, credit procedures, payment policies, payment
              procedures and systems;
            </LFTypography>
            <LFTypography>
              (vii) business and marketing plans and strategies;
            </LFTypography>
            <LFTypography>
              (viii) current activities and future plans relating to all or any
              matters of development including the timing of all such matters;
            </LFTypography>
            <LFTypography>
              (ix) any other information that would otherwise at law be
              considered confidential information of ours or any third party;
            </LFTypography>
          </LFStack>
          <LFTypography>
            whether in verbal, electronic, documentary, visual, machine-readable
            or other form.
          </LFTypography>
          <LFTypography>
            (b) <b>Intellectual Property</b> means:
          </LFTypography>
          <LFStack className={classes.petamenuMain}>
            <LFTypography>
              (i) all developments, discoveries, innovations, inventions, novel
              or technical designs, procedures, and trade secrets;
            </LFTypography>
            <LFTypography>(ii) the entire copyright in all Works;</LFTypography>
            <LFTypography>
              (iii) all designs within the meaning of the Designs Act 2003
              (Cth);
            </LFTypography>
            <LFTypography>
              (iv) all patents and patented applications, processes and products
              within the meaning of the Patents Act 1990 (Cth); and
            </LFTypography>
            <LFTypography>
              (v) any trade name, brand name, common law trademark or trademark
              within the meaning of the Trademarks Act 1995 (Cth);
            </LFTypography>
          </LFStack>
          <LFTypography>
            (c) <b>Moral Rights</b> has the same meaning as the term has in Part
            IX of the Copyright Act 1968 (Cth); and
          </LFTypography>
          <LFTypography>
            (d) <b>Works</b> includes, but is not limited to, all literary,
            dramatic, musical and artistic work within the meaning of the
            Copyright Act 1968 (Cth).
          </LFTypography>
          <LFTypography>18.2 You agree that:</LFTypography>
          <LFTypography>
            (a) you will not, except in the proper performance of your duties
            and responsibilities, as required by law or with our prior written
            consent, use or disclose any Confidential Information;
          </LFTypography>
          <LFTypography>
            (b) you will take all reasonable and necessary precautions to
            maintain the secrecy, and prevent the unauthorised use and
            disclosure, of any Confidential Information;
          </LFTypography>
          <LFTypography>
            (c) if requested by LOCUMFIND, or upon the termination of your
            employment, you will immediately return any Confidential Information
            that is in your possession or under your control to LOCUMFIND or, if
            the Confidential Information is recorded electronically, LOCUMFIND
            may require you to permanently delete or erase such information in a
            manner so that it cannot be retrieved.
          </LFTypography>
          <LFTypography>
            18.3 You acknowledge that LOCUMFIND owns all Intellectual Property
            that you may develop in the course of, or arising out of, your
            employment with LOCUMFIND and, for the avoidance of doubt, you
            immediately assign any such Intellectual Property to LOCUMFIND. You
            agree that you will make prompt and full disclosure to LOCUMFIND of
            the development of any Intellectual Property and you will not make
            use of, or reproduce, any of our Intellectual Property without
            LOCUMFIND’s prior written approval other than in the course of your
            employment.
          </LFTypography>
          <LFTypography>
            18.4 You consent to LOCUMFIND, our employees, agents, licensees and
            assigns, doing any acts or making any omissions that constitute an
            infringement of your Moral Rights in any Works made by you in the
            course of your employment with LOCUMFIND including:
          </LFTypography>
          <LFTypography>
            (a) not naming you as an author of the Work; and
          </LFTypography>
          <LFTypography>
            (b) amending or modifying (whether by changing, adding to or
            deleting/removing) any part of the Work, but only if you are not
            named as the author of the amended or modified Work, whether any
            such acts or omissions occur before or after the termination of your
            employment with LOCUMFIND.
          </LFTypography>
          <LFTypography>
            18.5 The obligations contained within this clause 19 continue after
            the termination of your employment with LOCUMFIND.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            19. SUSPENSION OF EMPLOYMENT
          </LFTypography>
          <LFTypography>
            LOCUMFIND may, at its discretion, suspend you on full pay for the
            period of time that LOCUMFIND may consider reasonably necessary to
            investigate unsatisfactory performance or misconduct by you.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            20. TERMINATION
          </LFTypography>
          <LFTypography>
            20.1 As a casual employee, you are not entitled to notice of
            termination of employment. However, if LOCUMFIND terminates your
            employment, LOCUMFIND will endeavour to provide you with at least
            one hour of notice.
          </LFTypography>
          <LFTypography>
            20.2 You may resign from employment with immediate effect by giving
            LOCUMFIND written notice or by deleting your Account.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            21. GENERAL
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            21.1 Notices
          </LFTypography>
          <LFTypography>
            Any notices must be given by registered ordinary post or by email,
            either to LOCUMFIND’s contact address, or to the Locum’s contact
            address as provided at registration. Any notice shall be deemed
            given:
          </LFTypography>
          <LFTypography>
            (a) if sent by email, 24 hours after email is sent, unless the Locum
            is notified that the email address is invalid or the email is
            undeliverable;
          </LFTypography>
          <LFTypography>
            (b) if sent by pre-paid post, three business days after the date of
            posting.
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            21.2 Entire Agreement
          </LFTypography>
          <LFTypography>
            This Agreement constitutes the entire agreement and understanding
            between the parties with respect to its subject matter and supersede
            all prior or contemporaneous oral or written agreements,
            undertakings, or representations between the parties concerning such
            subject matter.
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            21.3 Severance
          </LFTypography>
          <LFTypography>
            Any provision of this Agreement which is or becomes prohibited or
            unenforceable in any jurisdiction shall, as to such jurisdiction, be
            ineffective and severed to the extent thereof without invalidating
            any other provision of this Agreement, and any such prohibition or
            unenforceability shall not invalidate such provision in any other
            jurisdiction.
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            21.4 Waiver
          </LFTypography>
          <LFTypography>
            No failure to exercise or any delay in exercising any right, power
            or remedy by a party operates as a waiver. A single or partial
            exercise of any right, power or remedy does not preclude any other
            or further exercise of that or any other right, power or remedy. A
            waiver is not valid or binding on the party granting that waiver
            unless made in writing.
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            21.5 Variations
          </LFTypography>
          <LFTypography>
            (a) LOCUMFIND may modify, vary or amend this Agreement from time to
            time. LOCUMFIND will send notification of such modifications to Your
            Account or advise You the next time You log in to the Platform.
          </LFTypography>
          <LFTypography>
            (b) When You actively agree to amended terms or use the Platform in
            any manner, the amended terms will be effective immediately. In all
            other cases, the amended terms will automatically be effective 30
            days after the date LOCUMFUND notifies the Locum of the amendments.
          </LFTypography>
          <LFTypography>
            (c) If You do not agree with any changes to this Agreement, You must
            give notice of your termination of the Agreement in accordance with
            clause 17, and stop using the Platform.
          </LFTypography>
          <LFTypography className={classes.subPointHead}>
            21.6 Governing law
          </LFTypography>
          <LFTypography>
            This Agreement is governed by the laws of the State of Victoria,
            Australia and the parties submit to the non-exclusive jurisdiction
            of the courts of that State.
          </LFTypography>
          <LFTypography className={classes.mainPointHead}>
            SCHEDULE 1
          </LFTypography>
          <LFStack>
            <LFTypography className={classes.subPointHead}>
              Item 1 (Role)
            </LFTypography>
            <LFTypography>Pharmacy Assistant (Level 1) (Casual)</LFTypography>
            <LFTypography>Pharmacy Assistant (Level 2) (Casual)</LFTypography>
            <LFTypography>
              Pharmacy Assistant / Dispensary Assistant (Level 3) (Casual)
            </LFTypography>
            <LFTypography>
              Pharmacy Assistant / Dispensary Assistant (Level 4) (Casual)
            </LFTypography>
            <LFTypography>Pharmacy Student (Casual)</LFTypography>
            <LFTypography>Pharmacy Intern (Casual)</LFTypography>
            <LFTypography>Pharmacist (Casual)</LFTypography>
            <LFTypography>Experienced Pharmacist (Casual)</LFTypography>
            <LFTypography>Pharmacist in Charge (Casual)</LFTypography>
            <LFTypography>Pharmacist Manager (Casual)</LFTypography>
            <LFTypography>Optometrist (Casual)</LFTypography>
          </LFStack>
          <LFStack>
            <LFTypography className={classes.subPointHead}>
              Item 2 (Award)
            </LFTypography>
            <LFTypography>Pharmacy Industry Award 2020</LFTypography>
            <LFTypography>Optometrist : N/A</LFTypography>
          </LFStack>
          <LFStack>
            <LFTypography className={classes.subPointHead}>
              Item 3 (Renumeration)
            </LFTypography>
            <LFTypography>
              <b>Wages</b> in respect of any completed Placement Requests
              calculated as:
            </LFTypography>
            <LFTypography>a) The Minimum Award Rate; plus</LFTypography>
            <LFTypography>
              b) where the Agreed Hourly Rate for a Placement Request exceeds
              the Minimum Award Rate, the difference between the Agreed Hourly
              Rate and the Minimum Award Rate.
            </LFTypography>
          </LFStack>
          <LFStack>
            <LFTypography className={classes.subPointHead}>
              Item 4 (Pay Frequency)
            </LFTypography>
            <LFTypography>Weekly</LFTypography>
          </LFStack>
          <LFStack>
            <LFTypography className={classes.mainPointHead}>
              SCHEDULE 2 - FAIR WORK INFORMATION STATEMENTS
            </LFTypography>
            <LFTypography className={classes.subPointHead} marginTop={2}>
              <Link
                to={
                  "https://www.fairwork.gov.au/sites/default/files/migration/724/Fair-Work-Information-Statement.pdf"
                }
                target="_blank"
              >
                Fair Work Information Statement
              </Link>{" "}
            </LFTypography>
            <LFTypography className={classes.subPointHead} marginTop={2}>
              <Link
                to={
                  "https://www.fairwork.gov.au/sites/default/files/migration/724/casual-employment-information-statement.pdf"
                }
                target="_blank"
              >
                Fair Work Casual Employment Information Statement
              </Link>{" "}
            </LFTypography>
          </LFStack>
        </LFStack>
      </LFBox>
      <MobileCopyright />
    </>
  );
};

export default EmploymentAgreement;
