import React from "react";
import LFAccordian from "../../../shared/LFAccordian";
import LFStack from "../../../shared/LFStack";

import LFTypography from "../../../shared/LFTypograpy";
import LFBox from "../../../shared/LFBox";

const BussinessAccordianCard = ({ cardData, id }) => {
  const { title, contentArray } = cardData;
  return (
    <LFAccordian title={title} id={id}>
      <LFStack
        sx={{
          gap: "20px",
          "& > .MuiBox-root:last-child:has( > img ) ": {
            "& img": {
              paddingBottom: 0,
            },
          },
        }}
      >
        {contentArray?.map(({ type, content }, index) => {
          switch (type) {
            case "paragraph":
              return (
                <LFTypography key={index} sx={{ lineHeight: "20.83px" }}>
                  {content}
                </LFTypography>
              );
            case "bulletList":
              return (
                <LFBox key={index}>
                  <LFTypography>{content?.title}</LFTypography>
                  <ul style={{ margin: 0, paddingLeft: 26 }}>
                    {content?.list?.map((cur) => (
                      <li key={cur}>
                        <LFTypography
                          fontSize={{ xs: "14px", xsm: "16px" }}
                          lineHeight={{ xs: "18.23px", xsm: "20.83px" }}
                        >
                          {cur}
                        </LFTypography>
                      </li>
                    ))}
                  </ul>
                </LFBox>
              );
            case "image":
              return (
                <LFBox
                  key={index}
                  sx={{
                    "& img": {
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                      padding: { xs: "12px 0", xsm: "50px 0" },
                      margin: "0 auto",
                    },
                  }}
                >
                  <img src={content} alt="" />
                </LFBox>
              );
          }
        })}
      </LFStack>
    </LFAccordian>
  );
};

export default BussinessAccordianCard;
