import LFSlipper from "../../../shared/LFSlipper";
import { FormLabel, Typography } from "@mui/material";
import LFBox from "../../../shared/LFBox";
import { useState } from "react";
import FromActionButtons from "./FromActionButtons";
import LFStack from "../../../shared/LFStack";
// import { rolesMinRate } from "../../../description/shift/shiftDetailList.description";
import { useSelector } from "react-redux";
import { equal } from "../../../utils/javascript";

const RateForm = ({ setCurrentForm, defaultValue, label, onSave, roleId }) => {
  const roles = useSelector((state) => state?.globalAttr?.roles);
  const minRate = roles.find((curRole) =>
    equal(curRole?.attributes?.id, roleId),
  )?.attributes?.minimum_shit_rate;

  const [value, setValue] = useState(defaultValue ?? minRate);
  const handleRateChange = (e) => {
    setValue(e?.target?.value);
  };
  const handleSaveClick = () => {
    onSave(value);
  };

  return (
    <>
      <LFStack>
        <FormLabel>{label}</FormLabel>
        <LFBox
          style={{ display: "flex", justifyContent: "space-between" }}
          mt={3}
        >
          <Typography variant="body1">${value}/hr</Typography>
        </LFBox>
        <LFSlipper
          value={value}
          onChange={handleRateChange}
          min={minRate}
          max={150}
        />
      </LFStack>
      <FromActionButtons
        setCurrentForm={setCurrentForm}
        handleSaveClick={handleSaveClick}
      />
    </>
  );
};

export default RateForm;
