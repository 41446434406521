import React from "react";
import LFBox from "../../shared/LFBox";
import Header from "./Header";
import { Outlet, useLocation } from "react-router-dom";
import LFButton from "../../shared/LFButton";
import AuthLayoutCountainer from "../../container/layout/authLayout.container";
import BackButton from "../reusable/BackButton";
import { makeStyles } from "@mui/styles";
import LFContainer from "../../shared/LFContainer";
import LFTypography from "../../shared/LFTypograpy";
import theme from "../../themes/theme";
import classNames from "classnames";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  registerLayout: {
    minHeight: "calc(100vh - 97px)",
    padding: 16,
    maxWidth: 525,
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      minHeight: "calc(100vh - 65px)",
    },
  },
  backBtnShow: {
    minHeight: "calc(100vh - 169px)",
  },
  fullContainer: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "start",
      maxWidth: "100%",
      padding: 0,
    },
  },
}));

const AuthLayout = () => {
  const classes = useStyles();
  const { state } = useLocation();
  const {
    handleCancelClick,
    buttonLabel,
    path,
    hideCancel,
    setPath,
    setHideCancel,
    handleBackButtonClick,
  } = AuthLayoutCountainer();
  return (
    <LFBox>
      <Header />
      <LFContainer>
        <BackButton onClick={handleBackButtonClick} path={path} />
        {state?.showRegiserBusiness && (
          <>
            <LFTypography
              variant="h5"
              component="h5"
              mb={1.5}
              color="primary"
              fontWeight="500"
              sx={{
                borderBottom: {
                  xxsm: "none",
                  xs: `1px solid ${theme.palette.secondary.dark}`,
                },
                pb: 1.5,
              }}
            >
              Add Business
            </LFTypography>
            <LFTypography
              variant="h6"
              component="h6"
              mb={{ xxsm: 3, xs: 2 }}
              color="primary"
              fontWeight="400"
              display={{ md: "none", xs: "block" }}
            >
              Business Details
            </LFTypography>
          </>
        )}
        <LFBox
          className={classNames(classes.registerLayout, {
            [classes.backBtnShow]: path,
            [classes.fullContainer]: state?.showRegiserBusiness,
          })}
        >
          <Outlet context={{ setPath, setHideCancel }} />
          {hideCancel ? null : (
            <LFButton
              variant="link"
              disableRipple
              disableFocusRipple
              sx={{
                color: "secondary.dark",
                textTransform: "capitalize",
                fontWeight: 400,
                fontSize: 16,
                mt: 2,
                minWidth: 280,
                "&:hover": { backgroundColor: "transparent" },
              }}
              onClick={handleCancelClick}
            >
              {buttonLabel}
            </LFButton>
          )}
        </LFBox>
      </LFContainer>
      <MobileCopyright fontColor={"secondary.dark"} />
    </LFBox>
  );
};

export default AuthLayout;
