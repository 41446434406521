import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./StripeForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_API_KEY}`);

const AddPaymentMethod = ({ cancelForm, setCardNumber }) => {
  const options = {
    mode: "setup",
    currency: "aud",
    paymentMethodCreation: "manual",
    paymentMethodTypes: ["card"],
    loader: "never",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeForm cancelForm={cancelForm} setCardNumber={setCardNumber} />
    </Elements>
  );
};

export default AddPaymentMethod;
