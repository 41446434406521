import TimeFormTemplate from "./forms/TimeFormTemplate";
import LFBox from "../../shared/LFBox";
import RateForm from "./forms/RateForm";
import BreakForm from "./forms/BreakForm";
import AvrgScriptForm from "./forms/AvrgScriptForm";
import DropDownForm from "./forms/DropDownForm";
import ExtraNotesForm from "./forms/ExtraNotesForm";
import formDataAction from "../../redux/action/formDataAction";
import { useDispatch } from "react-redux";
import { equal, values } from "../../utils/javascript";

const ShiftDetailForm = ({
  formName,
  setCurrentForm,
  defaultValue,
  formPath,
  detailListAttributes,
  roleId,
  formData,
  businessId,
}) => {
  const dispatch = useDispatch();

  const handleSaveClick = (value) => {
    dispatch(formDataAction({ [formName]: value }, formPath?.parent, true));
    setCurrentForm(null);
  };
  const currentFormAttribute = values(detailListAttributes)?.find((obj) =>
    equal(obj?.name, formName),
  );
  const options = currentFormAttribute?.options;
  const label = currentFormAttribute?.label;

  const formProps = {
    setCurrentForm,
    defaultValue,
    label,
    onSave: handleSaveClick,
  };
  const renderForm = () => {
    switch (formName) {
      case "time":
        return (
          <TimeFormTemplate
            {...formProps}
            formPath={formPath}
            formData={formData}
            roleId={roleId}
            businessId={businessId}
          />
        );

      case "rate":
        return <RateForm {...formProps} roleId={roleId} />;

      case "break":
        return <BreakForm {...formProps} formData={formData} />;

      case "average_scripts":
        return <AvrgScriptForm {...formProps} />;

      case "sole_pharmacist":
      case "tech_no":
      case "dispenseSoftware":
      case "pointOfSale":
      case "software":
      case "preTesting":
      case "immunizer_required":
      case "pharmacotherapy":
      case "administration_aids":
      case "taking_in_scripts":
      case "processing_sales":
      case "marking_off":
      case "appointment_length":
      case "open_close":
        return <DropDownForm {...formProps} options={options} />;

      case "pay_travel":
      case "pay_accommodation":
      case "pay_flights":
        return (
          <DropDownForm
            {...formProps}
            options={options}
            defaultValue={defaultValue}
            isNullEqualNA={true}
          />
        );

      case "notes":
        return <ExtraNotesForm {...formProps} />;

      default:
        return null;
    }
  };
  return <LFBox>{renderForm()}</LFBox>;
};

export default ShiftDetailForm;
