import { useNavigate } from "react-router-dom";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../../utils/constant";
import { equal } from "../../../utils/javascript";
import ApiContainer from "../../api.container";

const SecurityContainer = ({ formPath }) => {
  const navigate = useNavigate();
  const { performRequest } = ApiContainer();

  const handleChangePasswordSubmit = async (formData) => {
    const payload = {
      user: {
        current_password: formData?.current_password,
        password: formData?.password,
      },
    };
    const response = await performRequest({
      endPoint: apiEndpoints?.changePassword,
      method: apiMethods?.put,
      tokenKey: "Authorization",
      data: { ...payload },
      showFailedMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });

    if (!equal(response?.statusCode, statusCodes?.OK)) return;

    return navigate(locationPath?.changesSuccess, {
      state: { redirect: locationPath?.settings },
    });
  };
  return { handleChangePasswordSubmit };
};

export default SecurityContainer;
