import React from "react";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { head, ternary } from "../../utils/javascript";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import LFStack from "../../shared/LFStack";
import ShiftLinkCardContainer from "../../container/messages/shiftLinkCard.container";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles((theme) => ({
  cardMessage: {
    borderRadius: 10,
    marginTop: 5,
    padding: 8,
    background: theme.palette.gray.main,
  },
  outerBox: {
    borderRadius: "0 10px 10px 0",
    padding: 8,
    background: theme.palette.gray.dark,
    maxWidth: 370,
    width: "max-content",
    marginTop: 3,
    [theme.breakpoints.down("xxsm")]: {
      maxWidth: "75%",
    },
    "& h6": {
      fontSize: 14,
      fontWeight: 500,
      marginRight: 10,
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 12,
      },
    },
  },
  senderMsg: {
    borderRadius: "10px 0 0 10px",
    background: theme.palette.secondary.lightGreen,
  },
  rate: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: 500,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("xxsm")]: {
        fontSize: 14,
      },
    },
  },
  time: {
    "&.MuiTypography-root": {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.secondary.dark,
    },
    [theme.breakpoints.down("xxsm")]: {
      fontSize: 12,
    },
  },
}));

const ShiftLinkCard = ({
  message,
  isSenderMsg,
  businessName,
  selectedUser,
  businessId,
}) => {
  const classes = useStyles();

  const regex = new RegExp(
    `${process.env.REACT_APP_WEB_URL}/view-shift\\?shiftId=\\d+`,
    "g",
  );
  const shiftLinks = message.match(regex);
  const firstLink = head(shiftLinks);
  const shiftId = firstLink.match(/\d+/);

  return (
    <LFBox
      className={classNames(classes.outerBox, {
        [classes.senderMsg]: isSenderMsg,
      })}
    >
      <LFTypography>{`${ternary(
        isSenderMsg,
        businessName,
        selectedUser?.name,
      )} shared a shift`}</LFTypography>
      <ShiftCardMessage
        shiftId={head(shiftId)}
        businessId={businessId}
        selectedUserId={selectedUser?.id}
      />
    </LFBox>
  );
};

export default ShiftLinkCard;

const ShiftCardMessage = ({ shiftId, selectedUserId, businessId }) => {
  const classes = useStyles();

  const { shiftData, notFound, gotoShiftDetails } = ShiftLinkCardContainer({
    shiftId,
    selectedUserId,
    businessId,
  });

  return (
    <>
      {notFound ? (
        <></>
      ) : (
        <LFBox
          className={classes.cardMessage}
          onClick={gotoShiftDetails}
          sx={{ cursor: "pointer" }}
        >
          {!shiftData?.rate ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              <LFStack direction="row">
                <LFTypography variant="h6">
                  {shiftData?.businessName}
                </LFTypography>
                <LFTypography className={classes.rate}>
                  {shiftData?.rate}
                </LFTypography>
              </LFStack>
              <LFTypography className={classes.time}>
                {shiftData?.date}
              </LFTypography>
              <LFTypography className={classes.time}>
                {shiftData?.time}
              </LFTypography>{" "}
            </>
          )}
        </LFBox>
      )}
    </>
  );
};
