import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import LogoAvatar from "../presentation/reusable/LogoAvatar";
import { useSelector } from "react-redux";

import LFTextField from "./LFTextField";
import { equal, length } from "../utils/javascript";

const customFilter = (options, state) => {
  if (!length(state?.inputValue)) return [];

  const inputValue = state?.inputValue?.toLowerCase();
  const finaloptions = options?.filter((option) =>
    option?.name?.toLowerCase()?.startsWith(inputValue),
  );
  return finaloptions;
};

const renderOption = (props, option) => {
  return (
    <li key={option?.id} {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={option?.logo_url}
          alt={option}
          style={{ width: 24, height: 24, marginRight: 5, borderRadius: "50%" }}
        />
        {option?.name}
      </div>
    </li>
  );
};

function LFSearchBusiness({ name, onChange, value, adormentClick, ...rest }) {
  const defaultBusinesses =
    useSelector((state) => state?.globalAttr?.defaultBusinesses) ?? [];

  const [foundBusiness, setfoundBusiness] = useState("");

  const findBusinessInfo = (givenValue) => {
    return defaultBusinesses?.find((cur) => {
      if (equal(cur?.name, givenValue)) return true;
      else if (givenValue?.startsWith(cur?.name)) return true;
      return false;
    });
  };

  useEffect(() => {
    const latestUpdate = findBusinessInfo(value);
    setfoundBusiness(latestUpdate);
  }, [defaultBusinesses]);

  const handleInputChange = (newValue) => {
    const findBusiness = findBusinessInfo(newValue);
    setfoundBusiness(findBusiness);
    onChange({
      target: {
        value: newValue,
        name,
        default_logo_id: findBusiness?.id ? findBusiness?.id : null,
      },
    });
  };
  return (
    <>
      <Autocomplete
        name={name}
        value={value}
        getOptionLabel={(option) => option?.name ?? option}
        renderOption={renderOption}
        onChange={(event, newValue) => {
          handleInputChange(newValue?.name);
        }}
        onFocus={adormentClick}
        filterOptions={customFilter}
        freeSolo
        disableClearable
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);
        }}
        options={defaultBusinesses}
        sx={{ width: "100%" }}
        renderInput={(params) => (
          <>
            <LFTextField
              {...{ ...params, ...rest, adormentClick }}
              startLogo={
                <LogoAvatar
                  name={value}
                  {...(foundBusiness?.logo_url && {
                    logoUrl: foundBusiness?.logo_url,
                  })}
                  sx={{
                    width: 24,
                    height: 24,
                    fontSize: 10,
                    marginRight: "5px",
                    ...(!value && { display: "none" }),
                  }}
                />
              }
            />
          </>
        )}
      />
    </>
  );
}

export default LFSearchBusiness;
