import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import setShiftValuesAction from "../../redux/action/setShiftValuesAction";
import ApiContainer from "../api.container";
import { equal, ternary, objectsAreSame, head } from "../../utils/javascript";
import addShiftAction from "../../redux/action/addShiftAction";
import {
  locationPath,
  pendingLevels,
  shiftStatuses,
} from "../../utils/constant";
import { getWeekDayDateAndMonth } from "../../utils/moment";
import { SET_FORM_DATA } from "../../redux/constant";
import moment from "moment";
import { shiftPostValidate } from "../../utils/dateOvertimeValidation";
import { showToast } from "../../utils/toastService";

const ViewShiftContainer = ({ formPath }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { shiftId } = useParams();

  const globalAttr = useSelector((state) => state?.globalAttr);

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const viewShiftForm = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parent],
  );
  const viewShiftFormClone = useSelector(
    (state) => state?.form?.formValues?.[formPath?.parentClone],
  );
  const viewShiftData = useSelector(
    (state) => state?.app?.data?.[formPath?.parent],
  );

  const [showConfimPage, setShowConfirmPage] = useState(false);
  const [hideHeading, setHideHading] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [allRecurringChangePage, setAllRecurringChangePage] = useState({
    save: false,
    cancel: false,
  });

  const [date, setDate] = useState({
    dateVal: null,
    error: false,
  });

  const [isFormDataChanged, setisFormDataChanged] = useState(false);

  const roles = globalAttr?.roles;
  const roleId = roles?.find((role) =>
    equal(role?.attributes?.name, viewShiftData?.roleName),
  )?.attributes?.id;
  const businessId = viewShiftData?.business?.id;
  const shiftStatus = viewShiftData?.status;
  const applicantsData = viewShiftData?.applicants;

  const [resStatus, setStatusCode] = useState({ code: null, message: "" });
  const { performRequest } = ApiContainer();

  useEffect(() => {
    if (viewShiftForm && viewShiftFormClone)
      setisFormDataChanged(!objectsAreSame(viewShiftForm, viewShiftFormClone));
  }, [viewShiftForm]);

  const shiftDetailsRefreshAction = () => {
    dispatch(
      setShiftValuesAction({
        performRequest,
        shiftId,
        formPath,
        setStatusCode,
      }),
    );
  };
  useEffect(() => {
    shiftDetailsRefreshAction();
  }, [shiftId]);

  const handleCancelClick = () => {
    setShowConfirmPage(false);
  };

  const handleDateChange = (value) => {
    setDate({
      dateVal: value,
      error: false,
    });
  };

  const onChangeDateValue = (value) => {
    setDate({ ...date, dateVal: value._d });
  };
  const onDateChangeError = (reason) => {
    let isError = false;
    if (reason) isError = true;
    setDate({ ...date, error: isError });
  };

  const saveDateChange = () => {
    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: {
          ...viewShiftForm,
          start_date: moment(date?.dateVal).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
        },
      },
    });
    setShowCalendar(false);
  };
  const handleCancelDateClick = () => {
    setShowCalendar(false);
    setDate(null);
  };

  const handleSaveClick = (
    overlapValidate,
    changeAllRecurringShifts = false,
  ) => {
    if (!isFormDataChanged) return navigate(locationPath?.myCalendar);
    if (
      !shiftPostValidate(
        viewShiftForm,
        roleId,
        businessId,
        globalAttr,
        viewShiftData,
      )
    )
      return;
    if (
      !showConfimPage &&
      !allRecurringChangePage?.save &&
      equal(viewShiftForm?.type, "recurring") &&
      equal(viewShiftFormClone?.start_date, viewShiftForm?.start_date)
    )
      return setAllRecurringChangePage({
        ...allRecurringChangePage,
        save: true,
      });
    const redirect = (shiftData) => {
      navigate(locationPath?.editShiftSuccess, {
        state: {
          shiftId,
          shiftStatus: shiftData?.status,
          changeAllRecurringShifts,
        },
      });
    };
    dispatch(
      addShiftAction({
        performRequest,
        formPath,
        redirect,
        shiftId,
        setShowConfirmPage,
        overlapValidate,
        changeAllRecurringShifts,
      }),
    );
    setAllRecurringChangePage({ save: false, cancel: false });
  };

  const handleCancelShiftClick = (changeAllRecurringShifts = false) => {
    if (
      !showConfimPage &&
      !allRecurringChangePage?.cancel &&
      equal(viewShiftForm?.type, "recurring") &&
      equal(viewShiftFormClone?.start_date, viewShiftForm?.start_date)
    )
      return setAllRecurringChangePage({
        ...allRecurringChangePage,
        cancel: true,
      });

    navigate(`${locationPath?.cancelShift.replace(":shiftId", shiftId)}`, {
      state: {
        showCancelConfirm: true,
        changeAllRecurringShifts: changeAllRecurringShifts,
        shiftData: viewShiftForm,
        shiftStatus,
      },
    });
  };

  const handleFinaliseShiftClick = () => {
    navigate(`${locationPath?.finaliseShift.replace(":shiftId", shiftId)}`, {
      state: {
        viewShiftForm: viewShiftForm,
        name: head(applicantsData)?.attributes?.first_name,
      },
    });
  };

  const changeAllRecurrencesButttons = [
    {
      label: "YES",
      onClick: () =>
        allRecurringChangePage.cancel
          ? handleCancelShiftClick(true)
          : handleSaveClick(true, true),
      variant: "contained",
    },
    {
      label: "NO",
      onClick: () =>
        allRecurringChangePage.cancel
          ? handleCancelShiftClick(false)
          : handleSaveClick(true, false),
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];

  const actionButtonsAttr = [
    {
      type: "button",
      label: "SAVE",
      onClick: () => handleSaveClick(true),
      variant: "contained",
      sx: {
        minWidth: 174,
        ...(equal(shiftStatus, shiftStatuses.paid) && { visibility: "hidden" }),
      },
    },
    {
      type: "button",
      label: "CANCEL SHIFT",
      onClick: () => handleCancelShiftClick(false),
      variant: "outlined",
      sx: {
        ...((equal(shiftStatus, shiftStatuses.paid) ||
          equal(viewShiftForm?.pending_level, pendingLevels?.unpaidEdit)) && {
          visibility: "hidden",
        }),
      },
    },
  ];

  const unsettledShiftActionButton = [
    {
      type: "button",
      label:
        equal(shiftStatus, shiftStatuses.unpaid) &&
        viewShiftForm?.payment_failed
          ? "FINALISE PAYMENT"
          : "FINALISE SHIFT",
      onClick: handleFinaliseShiftClick,
      variant: "contained",
      sx: {
        minWidth: 174,
      },
      ...(isFormDataChanged && { disabled: true }),
    },
    {
      type: "button",
      label: "AMEND SHIFT",
      onClick: () => {
        if (!isFormDataChanged)
          return showToast(
            "Change time and/or break fields to amend this shift",
          );
        handleSaveClick();
      },
      sx: {
        ...(equal(shiftStatus, shiftStatuses.unpaid) &&
          viewShiftForm?.payment_failed && { visibility: "hidden" }),
      },
      disabled: false,
      variant: "outlined",
    },
  ];

  const handleBackButtonClick = () => {
    if (showCalendar) return setShowCalendar(false);
    const backPath = ternary(
      state?.locumData,
      locationPath?.messages,
      locationPath?.myCalendar,
    );
    navigate(backPath, { state });
  };

  const shiftDateObj = {
    startDate: viewShiftForm?.start_date,
  };

  const shiftDetailStr =
    shiftDateObj?.startDate &&
    `${getWeekDayDateAndMonth(shiftDateObj?.startDate)}`;

  return {
    loadingStatus,
    roleId,
    businessId,
    actionButtonsAttr: ternary(
      equal(shiftStatus, shiftStatuses.unpaid),
      unsettledShiftActionButton,
      actionButtonsAttr,
    ),
    applicantsData,
    viewShiftForm,
    shiftDateObj,
    shiftStatus,
    viewShiftFormClone,
    isFormDataChanged,
    shiftDetailStr,
    showConfimPage,
    date,
    hideHeading,
    showCalendar,
    setShowCalendar,
    setHideHading,
    handleCancelClick,
    handleSaveClick,
    handleBackButtonClick,
    handleDateChange,
    saveDateChange,
    handleCancelDateClick,
    shiftId,
    resStatus,
    onChangeDateValue,
    onDateChangeError,
    allRecurringChangePage,
    changeAllRecurrencesButttons,
    shiftDetailsRefreshAction,
  };
};

export default ViewShiftContainer;
