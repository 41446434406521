export const forgotPasswordAttribute = [
  {
    name: "email",
    type: "text",
    label: "Email Address",
    isRequired: true,
    pattern: "email",
    error: ["Email is required", "Please enter a valid email"],
    xs: 12,
    fullWidth: true,
  },
];

export const defaultValues = {
  email: "",
};

export const forgotPasswordFormPath = {
  parent: "forgot-password",
};
