import React from "react";
import LFTextField from "../../../shared/LFTextField";
import { ReactComponent as EditIcon } from "../../../assets/svg/editIcon.svg";
import theme from "../../../themes/theme";
import LFBox from "../../../shared/LFBox";
import AddPaymentMethod from "../../addPaymentMethod";
import LFTypography from "../../../shared/LFTypograpy";
import PaymentMethodContainer from "../../../container/payments/paymentMethod.container";
import { paymentFormPath } from "../../../description/account/payments/payments.description";
import Loader from "../../reusable/Loader";

const PaymentMethod = () => {
  const {
    cardNumber,
    showPaymentMethod,
    paymentMethodAttribute,
    initailLoadingStatus,
    cancelForm,
    openForm,
    setCardNumber,
  } = PaymentMethodContainer({ formPath: paymentFormPath });

  return (
    <>
      <LFTypography
        variant="h6"
        mb={3}
        sx={{
          display: { xxsm: "none", xs: "block" },
          fontWeight: 400,
          color: theme.palette.primary.main,
        }}
      >
        Payment Method
      </LFTypography>
      <>
        {showPaymentMethod ? (
          <LFBox>
            <AddPaymentMethod
              cancelForm={cancelForm}
              setCardNumber={setCardNumber}
            />
          </LFBox>
        ) : initailLoadingStatus ? (
          <Loader height={"max-content"} sx={{ padding: "70px 0" }} />
        ) : (
          cardNumber && (
            <LFTextField
              {...paymentMethodAttribute}
              value={`**** **** **** ${cardNumber}`}
              endAdornment={<EditIcon cursor="pointer" />}
              onClick={openForm}
              readOnlyField={true}
              sx={{ width: "100%" }}
            />
          )
        )}
      </>
    </>
  );
};

export default PaymentMethod;
