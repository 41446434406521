import moment from "moment";
import {
  LS_KEY_NEWSHIFT_DATA,
  // LS_KEY_TEMPLATES_DATA,
  apiEndpoints,
  apiMethods,
  statusCodes,
} from "../../utils/constant";
import {
  equal,
  giveDifferenceInObjects,
  length,
  ternary,
} from "../../utils/javascript";
import { removeLocalStorageItem } from "../../utils/localStorage";
import { combineDateAndTime, compareTimes } from "../../utils/moment";
import formDataAction from "./formDataAction";
import { SET_APP_DATA } from "../constant";

const addShiftAction =
  ({
    performRequest,
    formPath,
    redirect,
    shiftId = false,
    setShowConfirmPage,
    overlapValidate,
    changeAllRecurringShifts,
  }) =>
  async (dispatch, getState) => {
    const changedFields = giveDifferenceInObjects(
      getState()?.form?.formValues?.[formPath?.parentClone],
      getState()?.form?.formValues?.[formPath?.parent],
    );

    const payloadForm = shiftId
      ? changedFields
      : getState()?.form?.formValues?.[formPath?.parent] || {};

    const start_date =
      getState()?.form?.formValues?.[formPath?.parent]?.start_date;

    const {
      time,
      dispenseSoftware,
      pointOfSale,
      software,
      preTesting,
      recurrences,
      ...formData
    } = { ...payloadForm };
    const { startTime, endTime } = { ...time } || {};
    const start = `${startTime?.value} ${startTime?.ampm}`;
    const end = `${endTime?.value} ${endTime?.ampm}`;
    const isGreaterStartTime = compareTimes(start, end);

    const start_time = combineDateAndTime(start_date, start);
    const end_time = combineDateAndTime(
      ternary(isGreaterStartTime, moment(start_date).add(1, "day"), start_date),
      end,
    );

    const skill_ids = [
      dispenseSoftware,
      pointOfSale,
      software,
      preTesting,
    ]?.filter((val) =>
      ternary(equal(val, null) || equal(val, undefined), false, true),
    );

    let payload = {
      shift: {
        ...formData,
        ...(formData?.break && { break: Number(formData?.break) }),
        ...(time && { start_time }),
        ...(time && { end_time }),
        ...(length(skill_ids) && { skill_ids }),
        overlap_validation: overlapValidate,
      },
      ...(recurrences && { recurrences }),
      ...(shiftId && { include_all_recurrences: changeAllRecurringShifts }),
    };

    const apiEndPoint = ternary(
      shiftId,
      `${apiEndpoints?.businessShifts}/${shiftId}`,
      apiEndpoints?.businessShifts,
    );
    const apiMethod = ternary(shiftId, apiMethods?.put, apiMethods?.post);
    const { statusCode, errorData, data } = await performRequest({
      endPoint: apiEndPoint,
      method: apiMethod,
      data: { ...payload },
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.parent,
      showFailedMessage: true,
    });
    if (!equal(statusCode, statusCodes?.OK)) {
      if (errorData?.data?.overlap_validation && overlapValidate) {
        return setShowConfirmPage && setShowConfirmPage(true);
      }
      return;
    }

    dispatch(formDataAction({}, formPath?.parent, false));
    !shiftId &&
      dispatch({
        type: SET_APP_DATA,
        payload: { [formPath?.parent]: {} },
      });
    removeLocalStorageItem(LS_KEY_NEWSHIFT_DATA);
    redirect && redirect(data?.shift?.attributes);
  };

export default addShiftAction;
