import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLocalStorageItem } from "../../utils/localStorage";
import { LS_KEY_NEWSHIFT_DATA, locationPath } from "../../utils/constant";
import { SET_FORM_DATA } from "../../redux/constant";
import { newShiftFormPath } from "../../description/newShift/newShift.description";

const NewShiftWrapper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const newShiftForm = getLocalStorageItem(LS_KEY_NEWSHIFT_DATA);
    if (newShiftForm) {
      dispatch({
        type: SET_FORM_DATA,
        payload: {
          [newShiftFormPath?.parent]: { ...JSON.parse(newShiftForm) },
        },
      });
    } else {
      navigate(locationPath?.newShift, { replace: true });
    }
  }, []);

  return <Outlet />;
};

export default NewShiftWrapper;
