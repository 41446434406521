import {
  DISPENSESOFTWARE,
  PRETESTING,
  SOFTWARE,
  apiEndpoints,
  apiMethods,
  statusCodes,
} from "../../utils/constant";
import { equal, ternary } from "../../utils/javascript";
import { get12hrsFormatTime } from "../../utils/moment";
import { SET_APP_DATA, SET_FORM_DATA } from "../constant";

const setShiftValuesAction =
  ({ performRequest, shiftId, formPath, setStatusCode }) =>
  async (dispatch) => {
    const { data, statusCode, errorMsg } = await performRequest({
      endPoint: `${apiEndpoints?.businessShifts}/${shiftId}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.parent,
    });

    setStatusCode &&
      setStatusCode({
        code: statusCode,
        message: ternary(errorMsg, errorMsg, ""),
      });

    if (!equal(statusCode, statusCodes?.OK)) return;

    const {
      skills,
      applicants,
      business,
      role_name,
      end_time,
      start_time,
      status,
      ...formData
    } = data.shift.attributes || {};

    formData.break = Number(formData?.break);
    const startTime = get12hrsFormatTime(start_time)?.trim()?.split(" ");
    const endTime = get12hrsFormatTime(end_time)?.trim()?.split(" ");
    formData.time = {
      startTime: {
        value: startTime?.[0],
        ampm: startTime?.[1]?.toLowerCase(),
      },
      endTime: {
        value: endTime?.[0],
        ampm: endTime?.[1]?.toLowerCase(),
      },
    };
    formData.start_date = start_time;
    formData.end_date = start_time;
    formData.dispenseSoftware = skills?.[DISPENSESOFTWARE]?.[0]?.id;
    formData.pointOfSale = ternary(
      equal(role_name, "Pharmacist"),
      null,
      skills?.[DISPENSESOFTWARE]?.[0]?.id,
    );
    formData.software = skills?.[SOFTWARE]?.[0]?.id;
    formData.preTesting = skills?.[PRETESTING]?.[0]?.id;

    dispatch({
      type: SET_FORM_DATA,
      payload: {
        [formPath?.parent]: { ...formData },
        ...(formPath?.parentClone && {
          [formPath?.parentClone]: { ...formData },
        }),
      },
    });
    dispatch({
      type: SET_APP_DATA,
      payload: {
        [formPath?.parent]: {
          applicants,
          business,
          roleName: role_name,
          status,
        },
      },
    });
  };

export default setShiftValuesAction;
