import { LS_KEY_AUTHENTICATED } from "../../utils/constant";
import { getLocalStorageItem } from "../../utils/localStorage";
import { LOADING_CHANGE, SET_APP_DATA, SET_AUTHENTICATED } from "../constant";

const initialState = {
  data: {},
  loader: {},
  isAuthenticated: getLocalStorageItem(LS_KEY_AUTHENTICATED),
};

const App = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_APP_DATA:
      return {
        ...state,
        data: { ...state?.data, ...payload },
      };

    case LOADING_CHANGE:
      return {
        ...state,
        loader: { ...state?.loader, ...payload },
      };

    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };

    default:
      return state;
  }
};

export default App;
