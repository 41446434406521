import React from "react";
import LFBox from "../../shared/LFBox";
import LFButton from "../../shared/LFButton";
import LFForm from "../../shared/LFForm";
import LFFormFields from "../../shared/LFFormFields";
import {
  signInFormPath,
  signInAttributes,
  defaultValues,
} from "../../description/signIn.description";
import LFTypography from "../../shared/LFTypograpy";
import { Link } from "react-router-dom";
import SignInContainer from "../../container/signIn.container";
import FormContainer from "../../container/form.container";
import { locationPath } from "../../utils/constant";
import { makeStyles } from "@mui/styles";
import LFGrid from "../../shared/LFGrid";

const useStyles = makeStyles((theme) => ({
  forgotPass: {
    color: theme.palette.white.main,
    display: "table",
    margin: "20px auto 50px",
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      margin: "20px auto 20px",
    },
  },
  register: {
    color: theme.palette.white.main,
    fontWeight: 700,
    display: "table",
    margin: "0 auto",
  },
  authInput: {
    "& .MuiFormLabel-root, & .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.white.main,
    },
    "& .MuiInputBase-root": {
      "&.MuiInputBase-formControl": {
        "&:before,&:after": {
          borderBottomColor: theme.palette.white.main,
        },
        "&:hover": {
          "&:before": {
            borderBottomColor: theme.palette.white.main,
          },
        },
      },
      "& .MuiInputBase-input": {
        color: theme.palette.white.main,
        caretColor: theme.palette.white.main,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button, &[type="number"]':
          {
            WebkitAppearance: "none",
            MozAppearance: "textfield",
          },
        "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus":
          {
            WebkitBoxShadow: `0 0 0px 40rem ${theme.palette.primary.main} inset`,
            WebkitTextFillColor: theme.palette.white.main,
          },
        "&::-webkit-input-placeholder": {
          color: theme.palette.white.main,
        },
        "&:-ms-input-placeholder": {
          color: theme.palette.white.main,
        },
        "&::placeholder": {
          color: theme.palette.white.main,
        },
      },
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },

  signInButton: {
    "&.MuiButtonBase-root": {
      margin: "0 auto 16px",
      minWidth: 220,
      maxWidth: 342,
      display: "flex",
      width: "auto",
      "&.Mui-disabled": {
        background: theme.palette.white.main,
        color: theme.palette.primary.main,
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const { formData, formError, isFormError, handleChange, validate } =
    FormContainer({
      attribute: signInAttributes,
      formPath: signInFormPath,
      defaultValues,
      isResetValues: true,
    });
  const { showPage, loadingStatus, handleSignInSubmit } = SignInContainer({
    formData,
    formError,
    validate,
    formPath: signInFormPath,
  });

  if (!showPage) return null;

  return (
    <LFBox>
      <LFForm>
        <LFFormFields
          attributes={signInAttributes}
          handleChange={handleChange}
          formData={formData}
          formError={formError}
          classes={{
            root: classes.authInput,
          }}
        />
        <LFGrid xs={12} item>
          <Link
            to={locationPath?.forgotPassword}
            className={classes.forgotPass}
          >
            Forgot password?
          </Link>
          <LFButton
            type="submit"
            color="white"
            variant="contained"
            onClick={handleSignInSubmit}
            isLoading={loadingStatus}
            disabled={isFormError}
            className={classes.signInButton}
          >
            SIGN IN
          </LFButton>
        </LFGrid>
      </LFForm>
      <LFTypography color="white.main" textAlign="center" mb={0.5}>
        Not yet registered?
      </LFTypography>
      <Link to={locationPath.welcomeBusiness} className={classes.register}>
        Register
      </Link>
    </LFBox>
  );
};

export default SignIn;
