import jackBrownImg from "../../assets/svg/topLocums/pharmacists/jackBrown.svg";
import oiviaHarissImg from "../../assets/svg/topLocums/pharmacists/oiviaHariss.svg";
import EllaBakerImg from "../../assets/svg/topLocums/pharmacists/EllaBaker.svg";
import emilyLeeImg from "../../assets/svg/topLocums/pharmacists/emilyLee.svg";
import johnGeorgiouImg from "../../assets/svg/topLocums/pharmacists/johnGeorgiou.svg";
import ashaChenImg from "../../assets/svg/topLocums/pharmacists/ashaChen.svg";
import gracebianchiImg from "../../assets/svg/topLocums/pharmacists/gracebianchi.svg";
import aravPatelImg from "../../assets/svg/topLocums/pharmacists/aravPatel.svg";
import ruviKapoorImg from "../../assets/svg/topLocums/pharmacists/ruviKapoor.svg";

import ameliaCooperImg from "../../assets/svg/topLocums/dispensaryTechnicians/ameliaCooper.svg";
import michelleNgImg from "../../assets/svg/topLocums/dispensaryTechnicians/michelleNg.svg";
import tonyZhangImg from "../../assets/svg/topLocums/dispensaryTechnicians/tonyZhang.svg";
import isabellaMorenoImg from "../../assets/svg/topLocums/dispensaryTechnicians/isabellaMoreno.svg";
import priyaTanakaImg from "../../assets/svg/topLocums/dispensaryTechnicians/priyaTanaka.svg";
import sarahWongImg from "../../assets/svg/topLocums/dispensaryTechnicians/sarahWong.svg";
import rachelLingImg from "../../assets/svg/topLocums/dispensaryTechnicians/rachelLing.svg";
import georgiaGrantImg from "../../assets/svg/topLocums/dispensaryTechnicians/georgiaGrant.svg";
import siennaHughesImg from "../../assets/svg/topLocums/dispensaryTechnicians/siennaHughes.svg";

import anikaLim from "../../assets/svg/topLocums/pharmacyAssistants/anikaLim.svg";
import anneSmith from "../../assets/svg/topLocums/pharmacyAssistants/anneSmith.svg";
import devChandra from "../../assets/svg/topLocums/pharmacyAssistants/devChandra.svg";
import janeSimpson from "../../assets/svg/topLocums/pharmacyAssistants/janeSimpson.svg";
import zoeClarke from "../../assets/svg/topLocums/pharmacyAssistants/zoeClarke.svg";
import melissaStefanos from "../../assets/svg/topLocums/pharmacyAssistants/melissaStefanos.svg";
import zaraMitchell from "../../assets/svg/topLocums/pharmacyAssistants/zaraMitchell.svg";
import yasminIbrahim from "../../assets/svg/topLocums/pharmacyAssistants/yasminIbrahim.svg";
import sofiaRomano from "../../assets/svg/topLocums/pharmacyAssistants/sofiaRomano.svg";

import emmaThompson from "../../assets/svg/topLocums/optometrists/emmaThompson.svg";
import tomChilton from "../../assets/svg/topLocums/optometrists/tomChilton.svg";
import anastasiaKaras from "../../assets/svg/topLocums/optometrists/anastasiaKaras.svg";
import rogerLim from "../../assets/svg/topLocums/optometrists/rogerLim.svg";
import matthewMoretti from "../../assets/svg/topLocums/optometrists/matthewMoretti.svg";
import andreasPappas from "../../assets/svg/topLocums/optometrists/andreasPappas.svg";
import ruviSingh from "../../assets/svg/topLocums/optometrists/ruviSingh.svg";
import lachlanThomas from "../../assets/svg/topLocums/optometrists/lachlanThomas.svg";
import nickWilson from "../../assets/svg/topLocums/optometrists/nickWilson.svg";

const allSkills = {
  websterPacking: "Webster Packing",
  hmrs: "HMR's",
  ordering: "Ordering",
  methadone: "Methadone",
  vaccinations: "Vaccinations",
  medicationCheck: "Medication Check",
  ndss: "NDSS",
  s2s3Training: "S2/S3 Training",
  markingOffOrders: "Marking off Orders",
  puttingSalesThrough: "Putting Sales Through",
  handingOutScripts: "Handing out Scripts",
  counselling: "Counselling",
  TakingInScripts: "Taking in Scripts",
  optomate: "Optomate",
  retinalCamera: "Retinal Camera",
  opsm: "OPSM",
  socrates: "Socrates",
  oct: "OCT",
  sunix: "Sunix",
};

export const topLocumsData = {
  pharmacists: [
    {
      profile: jackBrownImg,
      role: "Pharmacist",
      name: "Jack Brown",
      rate: 75,
      skills: [allSkills?.websterPacking, allSkills?.hmrs, allSkills?.ordering],
    },
    {
      profile: oiviaHarissImg,
      role: "Pharmacist",
      name: "Olivia Harris",
      rate: 68,
      skills: [
        allSkills?.methadone,
        allSkills?.vaccinations,
        allSkills?.websterPacking,
      ],
    },
    {
      profile: EllaBakerImg,
      role: "Pharmacist",
      name: "Ella Baker",
      rate: 70,
      skills: [
        allSkills?.medicationCheck,
        allSkills?.hmrs,
        allSkills?.ordering,
      ],
    },
    {
      profile: emilyLeeImg,
      role: "Pharmacist",
      name: "Emily Lee",
      rate: 75,
      skills: [allSkills?.websterPacking, allSkills?.ordering],
    },
    {
      profile: johnGeorgiouImg,
      role: "Pharmacist",
      name: "John Georgiou",
      rate: 70,
      skills: [allSkills?.websterPacking, allSkills?.hmrs, allSkills?.ordering],
    },
    {
      profile: ashaChenImg,
      role: "Pharmacist",
      name: "Asha Chen",
      rate: 63,
      skills: [allSkills?.vaccinations, allSkills?.hmrs, allSkills?.ordering],
    },
    {
      profile: gracebianchiImg,
      role: "Pharmacist",
      name: "Grace Bianchi",
      rate: 65,
      skills: [allSkills?.websterPacking, allSkills?.ordering],
    },
    {
      profile: aravPatelImg,
      role: "Pharmacist",
      name: "Aarav Patel",
      rate: 75,
      skills: [
        allSkills?.ndss,
        allSkills?.medicationCheck,
        allSkills?.ordering,
      ],
    },
    {
      profile: ruviKapoorImg,
      role: "Pharmacist",
      name: "Ravi Kapoor",
      rate: 78,
      skills: [allSkills?.websterPacking, allSkills?.hmrs, allSkills?.ndss],
    },
  ],
  dispensaryTechnicians: [
    {
      profile: ameliaCooperImg,
      role: "Dispensary Technician",
      name: "Amelia Cooper",
      rate: 34,
      skills: [
        allSkills?.websterPacking,
        allSkills?.ordering,
        allSkills?.s2s3Training,
      ],
    },
    {
      profile: michelleNgImg,
      role: "Dispensary Technician",
      name: "Michelle Ng",
      rate: 38,
      skills: [allSkills?.ordering, allSkills?.s2s3Training],
    },
    {
      profile: tonyZhangImg,
      role: "Dispensary Technician",
      name: "Tony Zhang",
      rate: 40,
      skills: [allSkills?.websterPacking, allSkills?.ordering, allSkills?.ndss],
    },
    {
      profile: isabellaMorenoImg,
      role: "Dispensary Technician",
      name: "Isabella Moreno",
      rate: 35,
      skills: [allSkills?.ordering, allSkills?.markingOffOrders],
    },
    {
      profile: priyaTanakaImg,
      role: "Dispensary Technician",
      name: "Priya Tanaka",
      rate: 36,
      skills: [allSkills?.websterPacking, allSkills?.ordering, allSkills?.ndss],
    },
    {
      profile: sarahWongImg,
      role: "Dispensary Technician",
      name: "Sarah Wong",
      rate: 30,
      skills: [allSkills?.websterPacking, allSkills?.hmrs, allSkills?.ordering],
    },
    {
      profile: rachelLingImg,
      role: "Dispensary Technician",
      name: "Rachel Ling",
      rate: 34,
      skills: [allSkills?.websterPacking, allSkills?.puttingSalesThrough],
    },
    {
      profile: georgiaGrantImg,
      role: "Dispensary Technician",
      name: "Georgia Grant",
      rate: 38,
      skills: [
        allSkills?.markingOffOrders,
        allSkills?.ordering,
        allSkills?.s2s3Training,
      ],
    },
    {
      profile: siennaHughesImg,
      role: "Dispensary Technician",
      name: "Sienna Hughes",
      rate: 35,
      skills: [allSkills?.markingOffOrders, allSkills?.puttingSalesThrough],
    },
  ],
  pharmacyAssistants: [
    {
      profile: anikaLim,
      role: "Pharmacy Assistant",
      name: "Anika Lim",
      rate: 22,
      skills: [
        allSkills?.handingOutScripts,
        allSkills?.ordering,
        allSkills?.s2s3Training,
      ],
    },
    {
      profile: anneSmith,
      role: "Pharmacy Assistant",
      name: "Anne Smith",
      rate: 26,
      skills: [allSkills?.websterPacking, allSkills?.markingOffOrders],
    },
    {
      profile: devChandra,
      role: "Pharmacy Assistant",
      name: "Dev Chandra",
      rate: 25,
      skills: [
        allSkills?.ordering,
        allSkills?.counselling,
        allSkills?.TakingInScripts,
      ],
    },
    {
      profile: janeSimpson,
      role: "Pharmacy Assistant",
      name: "Jane Simpson",
      rate: 28,
      skills: [allSkills?.handingOutScripts, allSkills?.TakingInScripts],
    },
    {
      profile: zoeClarke,
      role: "Pharmacy Assistant",
      name: "Zoe Clarke",
      rate: 30,
      skills: [allSkills?.websterPacking, allSkills?.hmrs, allSkills?.ordering],
    },
    {
      profile: melissaStefanos,
      role: "Pharmacy Assistant",
      name: "Melissa Stefanos",
      rate: 28,
      skills: [allSkills?.websterPacking],
    },
    {
      profile: zaraMitchell,
      role: "Pharmacy Assistant",
      name: "Zara Mitchell",
      rate: 27,
      skills: [allSkills?.s2s3Training, allSkills?.ordering],
    },
    {
      profile: yasminIbrahim,
      role: "Pharmacy Assistant",
      name: "Yasmin Ibrahim",
      rate: 24,
      skills: [
        allSkills?.counselling,
        allSkills?.handingOutScripts,
        allSkills?.ordering,
      ],
    },
    {
      profile: sofiaRomano,
      role: "Pharmacy Assistant",
      name: "Sofia Romano",
      rate: 28,
      skills: [
        allSkills?.markingOffOrders,
        allSkills?.s2s3Training,
        allSkills?.ordering,
      ],
    },
  ],
  optometrists: [
    {
      profile: emmaThompson,
      role: "Optometrist",
      name: "Emma Thompson",
      rate: 80,
      skills: [allSkills?.optomate, allSkills?.retinalCamera],
    },
    {
      profile: tomChilton,
      role: "Optometrist",
      name: "Tom Chilton",
      rate: 74,
      skills: [allSkills?.opsm, allSkills?.socrates, allSkills?.oct],
    },
    {
      profile: anastasiaKaras,
      role: "Optometrist",
      name: "Anastasia Karas",
      rate: 84,
      skills: [allSkills?.sunix, allSkills?.socrates, allSkills?.optomate],
    },
    {
      profile: rogerLim,
      role: "Optometrist",
      name: "Roger Lim",
      rate: 75,
      skills: [allSkills?.optomate, allSkills?.socrates, allSkills?.oct],
    },
    {
      profile: matthewMoretti,
      role: "Optometrist",
      name: "Matthew Moretti",
      rate: 70,
      skills: [
        allSkills?.sunix,
        allSkills?.socrates,
        allSkills?.opsm,
        allSkills?.retinalCamera,
      ],
    },
    {
      profile: andreasPappas,
      role: "Optometrist",
      name: "Andreas Pappas",
      rate: 80,
      skills: [allSkills?.sunix, allSkills?.socrates, allSkills?.retinalCamera],
    },
    {
      profile: ruviSingh,
      role: "Optometrist",
      name: "Ruvi Singh",
      rate: 77,
      skills: [allSkills?.socrates, allSkills?.sunix, allSkills?.opsm],
    },
    {
      profile: lachlanThomas,
      role: "Optometrist",
      name: "Lachlan Thomas",
      rate: 72,
      skills: [allSkills?.sunix, allSkills?.retinalCamera],
    },
    {
      profile: nickWilson,
      role: "Optometrist",
      name: "Nick Wilson",
      rate: 75,
      skills: [allSkills?.opsm, allSkills?.socrates, allSkills?.retinalCamera],
    },
  ],
};
