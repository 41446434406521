import React from "react";
import LFTextField from "./LFTextField";
import { ternary } from "../utils/javascript";
import LFDropDown from "./LFDropDown";
import LFGoogleAddress from "./LFGoogleAddress";
import LFGrid from "./LFGrid";
import LFTelephoneField from "./LFTelephoneField";
import LFSearchBusiness from "./LFSearchBusiness";
import LFCheckboxGroup from "./LFCheckboxGroup";

const LFFormFields = ({
  attributes,
  handleChange,
  formData,
  formError,
  classes,
  readOnly = false,
  editable = false,
  singleFieldEditable = false,
}) => {
  const setFormFields = attributes?.map((attribute, index) => {
    const {
      type,
      name,
      label,
      autoComplete,
      placeholder,
      options,
      preSelected,
      variant,
      fullWidth,
      md,
      sm,
      xs,
      xsm,
      sx,
      endAdornment,
      startAdornment,
      adormentClick,
      readOnlyField,
      showEyeIcon,
      isMobileNumber,
      multiline = false,
    } = attribute;

    switch (type) {
      case "text":
      case "password":
        return (
          <LFGrid
            item
            md={md}
            sm={sm}
            xs={xs}
            xsm={xsm}
            key={index}
            {...(singleFieldEditable && { sx: sx })}
          >
            <LFTextField
              {...{
                type,
                name,
                label,
                onChange: handleChange,
                value: formData?.[name] ?? "",
                error: ternary(formError?.[name], true, false),
                helperText: formError?.[name],
                autoComplete,
                variant,
                fullWidth,
                sx,
                classes,
                endAdornment,
                startAdornment,
                adormentClick,
                readOnly,
                readOnlyField,
                showEyeIcon,
                editable,
                multiline,
              }}
              // {...(multiline && "multiline")}
            />
          </LFGrid>
        );
      case "searchBusiness":
        return (
          <LFGrid
            item
            md={md}
            sm={sm}
            xs={xs}
            xsm={xsm}
            key={index}
            {...(singleFieldEditable && { sx: sx })}
          >
            <LFSearchBusiness
              {...{
                type,
                name,
                label,
                onChange: handleChange,
                value: formData?.[name] ?? "",
                error: ternary(formError?.[name], true, false),
                helperText: formError?.[name],
                autoComplete,
                variant,
                fullWidth,
                sx,
                classes,
                endAdornment,
                startAdornment,
                adormentClick,
                readOnly,
                readOnlyField,
                showEyeIcon,
                editable,
              }}
            />
          </LFGrid>
        );
      case "select":
        return (
          <LFGrid item md={md} sm={sm} xs={xs} xsm={xsm} key={index}>
            <LFDropDown
              {...{
                name,
                label,
                onChange: handleChange,
                value: formData?.[name],
                placeholder,
                options,
                error: formError?.[name],
                preSelected,
              }}
            />
          </LFGrid>
        );

      case "address":
        return (
          <LFGrid
            item
            md={md}
            sm={sm}
            xs={xs}
            xsm={xsm}
            key={index}
            {...(singleFieldEditable && { sx: sx })}
          >
            <LFGoogleAddress
              {...{
                label,
                name,
                value: formData?.[name],
                onChange: handleChange,
                error: ternary(formError?.[name], true, false),
                helperText: formError?.[name],
                fullWidth,
                endAdornment,
                startAdornment,
                adormentClick,
                readOnly,
                readOnlyField,
                showEyeIcon,
                editable,
                sx,
              }}
            />
          </LFGrid>
        );

      case "telephone":
        return (
          <LFGrid
            item
            md={md}
            sm={sm}
            xs={xs}
            xsm={xsm}
            key={index}
            {...(singleFieldEditable && { sx: sx })}
          >
            <LFTelephoneField
              {...{
                type,
                name,
                label,
                onChange: handleChange,
                value: formData?.[name] ?? "",
                error: ternary(formError?.[name], true, false),
                helperText: formError?.[name],
                autoComplete,
                variant,
                fullWidth,
                sx,
                classes,
                endAdornment,
                startAdornment,
                adormentClick,
                readOnly,
                readOnlyField,
                showEyeIcon,
                editable,
                isMobileNumber,
              }}
            />
          </LFGrid>
        );
      case "checkboxGroup":
        return (
          <LFGrid
            item
            md={md}
            sm={sm}
            xs={xs}
            xsm={xsm}
            key={index}
            {...(singleFieldEditable && { sx: sx })}
          >
            <LFCheckboxGroup
              {...{
                type,
                name,
                options,
                label,
                onChange: handleChange,
                value: formData?.[name] ?? "",
                error: ternary(formError?.[name], true, false),
                helperText: formError?.[name],
                autoComplete,
                variant,
                fullWidth,
                sx,
                classes,
                endAdornment,
                startAdornment,
                adormentClick,
                readOnly,
                readOnlyField,
                showEyeIcon,
                editable,
              }}
            />
          </LFGrid>
        );

      default:
        return null;
    }
  });
  return <>{setFormFields}</>;
};

export default LFFormFields;
