import React from "react";
import LFTextField from "./LFTextField";
import FormHelperText from "@mui/material/FormHelperText";
import parsePhoneNumber from "libphonenumber-js";
import InputMask from "react-input-mask";
import { ternary } from "../utils/javascript";

const formatNumber = (value) => {
  if (!value) return "";
  try {
    const phoneNumber = parsePhoneNumber(`+${value?.toString()}`);
    return phoneNumber.formatInternational();
  } catch (error) {
    return value;
  }
};

const LFTelephoneField = ({
  onChange,
  readOnly,
  value,
  name,
  helperText,
  error,
  editable,
  isMobileNumber = false,
  ...rest
}) => {
  const handleOnChange = (e) => {
    const inputValue = e?.target?.value?.replace(/\D/g, "");
    onChange({
      target: {
        value: inputValue,
        name,
      },
    });
  };

  return (
    <>
      {readOnly ? (
        <InputMask
          mask="99 9999 9999"
          maskChar="_"
          {...{
            ...rest,
            type: "text",
            readOnly,
            value: formatNumber(value),
            InputLabelProps: {
              shrink: true,
            },
            editable,
          }}
        >
          {(inputProps) => <LFTextField {...inputProps} />}
        </InputMask>
      ) : (
        <>
          <InputMask
            mask={ternary(isMobileNumber, "9999999999", "99 9999 9999")}
            maskChar=""
            onChange={handleOnChange}
            value={value?.toString()}
          >
            {(inputProps) => (
              <LFTextField
                {...inputProps}
                maxLength={10}
                fullWidth
                country="au"
                {...rest}
                editable
              />
            )}
          </InputMask>

          {error && <FormHelperText error>{helperText}</FormHelperText>}
        </>
      )}
    </>
  );
};

export default LFTelephoneField;
