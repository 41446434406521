import React from "react";
import ListApplicantConatainer from "../../container/applicants/listApplicant.container";
import { viewShiftFormPath } from "../../description/viewShift/viewShift.description";
import LocumCard from "../reusable/LocumCard";
import ShiftOverview from "../shift/ShiftOverview";
import Loader from "../reusable/Loader";
import { length, ternary } from "../../utils/javascript";
import { makeStyles } from "@mui/styles";
import LFBox from "../../shared/LFBox";
import LFGrid from "../../shared/LFGrid";
import LFTypography from "../../shared/LFTypograpy";
import MobileCopyright from "../reusable/MobileCopyright";

const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: "calc(100vh - 233px)",
    overflow: "auto",
    paddingBottom: 30,
    position: "relative",
    [theme.breakpoints.down("xxsm")]: {
      maxHeight: "unset",
    },
  },
  firstCard: {
    "&.MuiBox-root": {
      width: "100%",
      height: "max-content",
      position: "sticky",
      top: 0,
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        marginBottom: 24,
        maxWidth: "unset",
        minWidth: "unset",
      },
    },
  },
}));

const ListApplicants = () => {
  const classes = useStyles();
  const {
    loadingStatus,
    applicantsData,
    locumCardAttributes,
    businessDetails,
    roleName,
    shiftStatus,
    shiftDateObj,
  } = ListApplicantConatainer({
    formPath: viewShiftFormPath,
  });

  if (!length(applicantsData)) {
    if (loadingStatus) return <Loader />;
    return null;
  }

  return (
    <LFBox>
      {!!length(applicantsData) && (
        <LFTypography textAlign="right" color="secondary.dark">
          {`Showing ${length(applicantsData)} ${ternary(
            length(applicantsData) > 1,
            "applicants",
            "applicant",
          )}`}
        </LFTypography>
      )}

      <LFGrid
        container
        columnSpacing={3}
        mt={1.5}
        className={classes.container}
      >
        <LFGrid item md={4} xxsm={12} xs={12}>
          <LFBox className={classes.firstCard}>
            <ShiftOverview
              businessDetail={businessDetails}
              shiftDateObj={shiftDateObj}
              roleName={roleName}
              shiftStatus={shiftStatus}
            />
          </LFBox>
        </LFGrid>
        <LFGrid item md={8} xxsm={12} xs={12} textAlign="center">
          {applicantsData?.map((locum) => (
            <LocumCard
              key={locum?.id}
              locumData={locum?.attributes}
              locumCardAttributes={locumCardAttributes}
              bgColor={true}
              businessId={businessDetails?.id}
              withoutAccordian={true}
              forAppliacantsPage={true}
            />
          ))}
        </LFGrid>
      </LFGrid>
      <MobileCopyright />
    </LFBox>
  );
};

export default ListApplicants;
