export const choosePasswordAttribute = [
  {
    name: "password",
    label: "Choose Password",
    type: "password",
    isRequired: true,
    pattern: "password",
    error: [
      "Password is required",
      "Password must contain at least one uppercase, lowercase, number, special character and be at least 8 charaters.",
    ],
    showEyeIcon: true,
    autoComplete: "on",
    fullWidth: true,
    xs: 12,
  },
];

export const defaultValues = {
  password: "",
};

export const choosePasswordFormPath = {
  parent: "choosePassword",
};
