import React from "react";
import ShiftOverview from "../../shift/ShiftOverview";
import ShiftDetailList from "../../shift/ShiftDetailList";
import ShiftDetailForm from "../../shift/ShiftDetailForm";
import LFGrid from "../../../shared/LFGrid";
import LFButton from "../../../shared/LFButton";
import { makeStyles } from "@mui/styles";
import LFBox from "../../../shared/LFBox";
import AddShiftDetailsContainer from "../../../container/newShift/addShiftDetails.container";
import { newShiftFormPath } from "../../../description/newShift/newShift.description";
import ShiftAttributeHelper from "../../../helper/ShiftAttributeHelper";
import Loader from "../../reusable/Loader";
import BackButton from "../../reusable/BackButton";
import LFTypography from "../../../shared/LFTypograpy";
import LFStack from "../../../shared/LFStack";
import { ReactComponent as InfoIcon } from "../../../assets/svg/info-icon.svg";
import { Alert } from "@mui/material";
import { equal, keys, length, values } from "../../../utils/javascript";
import ConfirmOvelapShift from "../../confirmOverlap";

const useStyles = makeStyles((theme) => ({
  postButtons: {
    "&.MuiButtonBase-root": {
      maxWidth: 342,
      width: "100%",
      padding: [[11, 10]],
      marginTop: 24,
    },
  },
  mainBox: {
    maxWidth: 230,
    minWidth: 230,
    borderRadius: 10,
    backgroundColor: theme.palette.gray.main,
    padding: [[24, 32]],
    height: "max-content",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      minWidth: "unset",
    },
  },
  InfoTag: {
    color: theme.palette.white.main,
    padding: [[8, 12]],
    borderRadius: 50,
    fontSize: 12,
    backgroundColor: theme.palette.dark.danger,
    alignItems: "center",
    fontWeight: 500,
    marginLeft: "auto",
    "& svg": {
      marginRight: 8,
    },
  },
  newShiftContainer: {
    "&.MuiStack-root": {
      padding: "24px 0",
      gap: 24,
      flexDirection: "row",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        padding: [[20, 0]],
      },
      [theme.breakpoints.down("xxsm")]: {
        gap: 0,
      },
    },
  },
  firstCard: {
    "&.MuiBox-root": {
      minWidth: "31%",
      width: "31%",
      [theme.breakpoints.down("md")]: {
        minWidth: "unset",
        flex: "0 0 100%",
        order: 1,
      },
    },
  },
  secondCard: {
    "&.MuiBox-root": {
      minWidth: "calc(42% - 24px)",
      width: "calc(42% - 24px)",
      zIndex: 1,
      [theme.breakpoints.down("md")]: {
        minWidth: "unset",
        width: "100%",
        marginTop: 24,
        order: 3,
      },
    },
  },
  templateName: {
    "&.MuiTypography-root": {
      color: theme.palette.dark.main,
      background: theme.palette.gray.light,
      padding: "2px 8px",
      borderRadius: 10,
      margin: "auto 0 0 auto",
      display: "inline-block",
      fontSize: 12,
      wordBreak: "break-word",
    },
  },
  alertMessage: {
    "&.MuiPaper-root": {
      position: "fixed",
      top: 76,
      left: "51%",
      boxSizing: "border-box",
      transform: "translateX(-50%)",
      width: "100%",
      maxWidth: 450,
      background: theme.palette.secondary.main,
      borderRadius: 10,
      color: "#FFFFFF",
      fontSize: 18,
      lineHeight: 1.2,
      alignItems: "center",
      zIndex: 1,
      "& .MuiAlert-message": {
        padding: 0,
        oveflow: "unset",
      },
    },
  },
  shiftForm: {
    maxWidth: 342,
    boxSizing: "border-box",
    margin: "20px auto 0 auto",
    width: "100%",
  },
  overviewCard: {
    [theme.breakpoints.down("xxsm")]: {
      padding: "20px 12px",
    },
  },
  addShiftHeader: {
    [theme.breakpoints.down("md")]: {
      gap: 24,
      alignItems: "flex-end",
      "& $secondCard, & $firstCard": {
        flex: 1,
        margin: 0,
      },
    },
  },
}));

const AddShiftDetails = () => {
  const classes = useStyles();
  const {
    loadingStatus,
    roleId,
    businessId,
    newShiftForm,
    shiftDateObj,
    templateName,
    templateId,
    showAlert,
    shiftDetailStr,
    showConfimPage,
    handleCancelClick,
    handleBackButtonClick,
    closeAlert,
    handlePostClick,
    handleSaveTemplateClick,
  } = AddShiftDetailsContainer({ formPath: newShiftFormPath });
  const {
    currentForm,
    initailLoadingStatus,
    roleName,
    matches,
    detailListAttributes,
    selectedBusiness,
    setCurrentForm,
    isSubmitDisabled,
  } = ShiftAttributeHelper({
    roleId,
    businessId,
    formData: newShiftForm,
  });

  const actionButtonsAttr = [
    {
      type: "button",
      label: "POST",
      onClick: () => handlePostClick(null, true),
      variant: "contained",
      isLoading: loadingStatus,
      disabled: isSubmitDisabled,
      sx: { minWidth: 174 },
    },
    {
      type: "button",
      label: "SAVE TEMPLATE",
      onClick: handleSaveTemplateClick,
      disabled: isSubmitDisabled,
      variant: "outlined",
    },
  ];

  if (
    !newShiftForm ||
    !length(keys(newShiftForm)) ||
    initailLoadingStatus ||
    loadingStatus
  )
    return <Loader />;

  if (showConfimPage)
    return (
      <ConfirmOvelapShift
        handleCancelClick={handleCancelClick}
        handleConfirmClick={handlePostClick}
        shiftDetailStr={shiftDetailStr}
      />
    );

  if (matches && currentForm) {
    return (
      <>
        <BackButton path={true} onClick={() => setCurrentForm(null)} />
        <LFTypography
          variant="h6"
          component="h6"
          color="primary"
          fontSize="24px"
        >
          {
            values(detailListAttributes)?.find((obj) =>
              equal(obj?.name, currentForm),
            )?.label
          }
        </LFTypography>
        <LFGrid className={`${classes.mainBox} ${classes.shiftForm}`}>
          <ShiftDetailForm
            formName={currentForm}
            formPath={newShiftFormPath}
            setCurrentForm={setCurrentForm}
            defaultValue={newShiftForm?.[currentForm]}
            detailListAttributes={detailListAttributes}
            formData={newShiftForm}
            roleId={roleId}
            businessId={businessId}
          />
        </LFGrid>
      </>
    );
  }

  return (
    <>
      <LFStack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
      >
        <BackButton path={true} onClick={handleBackButtonClick} />
        {newShiftForm?.is_urgent && (
          <LFStack
            direction="row"
            className={classes.InfoTag}
            display={{ xxsm: "none", xs: "flex" }}
          >
            <InfoIcon />
            URGENT
          </LFStack>
        )}
      </LFStack>
      <LFStack
        direction="row"
        spacing={{ md: 3, xs: 0 }}
        width="100%"
        className={classes.addShiftHeader}
      >
        <LFBox className={classes.firstCard} display="flex">
          <LFTypography
            variant="h5"
            component="h5"
            fontWeight="500"
            color="primary"
          >
            {"New Shift"}
          </LFTypography>
          {newShiftForm?.is_urgent && (
            <LFStack
              direction="row"
              className={classes.InfoTag}
              display={{ xxsm: "flex", xs: "none" }}
            >
              <InfoIcon />
              URGENT
            </LFStack>
          )}
        </LFBox>
        <LFBox display="flex" className={classes.secondCard}>
          {templateId && templateName && (
            <LFTypography className={classes.templateName}>
              {templateName}
            </LFTypography>
          )}
        </LFBox>
      </LFStack>
      {showAlert && (
        <Alert
          icon={false}
          className={classes.alertMessage}
          onClose={() => {
            closeAlert(false);
          }}
        >
          <LFTypography fontSize={18}>
            Template saved as{" "}
            <span style={{ fontWeight: 700 }}>{`“${templateName}”`}</span>
          </LFTypography>
        </Alert>
      )}
      <LFStack direction="row" className={classes.newShiftContainer}>
        <LFStack
          direction="row"
          spacing={{ md: 3, xs: 0 }}
          sx={{ flexWrap: { md: "unset", xs: "wrap" } }}
          width="100%"
        >
          <LFBox className={classes.firstCard}>
            <ShiftOverview
              businessDetail={selectedBusiness}
              shiftDateObj={shiftDateObj}
              roleName={roleName}
              buttonAttrributes={actionButtonsAttr}
              currentForm={currentForm}
              className={classes.overviewCard}
            />
          </LFBox>
          <LFBox className={classes.secondCard}>
            <ShiftDetailList
              formData={newShiftForm}
              currentForm={currentForm}
              setCurrentForm={setCurrentForm}
              detailListAttributes={detailListAttributes}
              roleId={roleId}
              formPath={newShiftFormPath}
              businessId={businessId}
            />
          </LFBox>
        </LFStack>
        <LFBox
          sx={{ display: { md: "none", xs: "flex" } }}
          width="100%"
          alignItems="center"
          flexDirection="column"
        >
          {actionButtonsAttr?.map(({ label, ...attr }, index) => (
            <LFButton key={index} {...attr} className={classes.postButtons}>
              {label}
            </LFButton>
          ))}
        </LFBox>
      </LFStack>
    </>
  );
};

export default AddShiftDetails;
