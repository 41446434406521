import { Link, useParams } from "react-router-dom";
import LFGrid from "../../shared/LFGrid";
import LFBox from "../../shared/LFBox";
import LFTypography from "../../shared/LFTypograpy";
import { makeStyles } from "@mui/styles";
import SkillsDetails from "../reusable/SkillsDetails";
import LocumOverview from "../reusable/LocumOverview";
import { Divider } from "@mui/material";
import LFStack from "../../shared/LFStack";
import LFButton from "../../shared/LFButton";
import userImage from "../../assets/png/user.jpeg";
import LocumDetailsContainer from "../../container/locumDetails/locumDetails.container";
import Loader from "../reusable/Loader";
import { DAYS_VALUES } from "../../description/searchLocum/searchLocum.description";
import { isEmptyObject, keys, last } from "../../utils/javascript";
import { ZERO, locationPath } from "../../utils/constant";
import NotFoundComponent from "../reusable/NotFoundComponent";
import BackButton from "../reusable/BackButton";

const useStyles = makeStyles((theme) => ({
  searchButtons: {
    marginTop: 9,
    "& a": {
      marginTop: 16,
      "& button": {
        width: "100%",
        fontSize: 14,
        padding: [[11, 20]],
        border: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
          background: "transparent",
        },
      },
    },
    "& hr": {
      display: "none",
    },
  },
  profileButtons: {
    marginTop: 0,
    "& hr": {
      display: "block",
      margin: [[28, 0]],
      [theme.breakpoints.down("xxsm")]: {
        margin: [[16, 0, 0]],
      },
    },
    "& a": {
      maxWidth: 172,
      width: "100%",
      margin: "16px auto 0",
      [theme.breakpoints.down("xxsm")]: {
        maxWidth: "unset",
      },
      "&:last-child": {
        "& button": {
          background: "transparent",
          color: theme.palette.primary.main,
        },
      },
    },
  },
  grayCard: {
    backgroundColor: theme.palette.gray.main,
    borderRadius: 10,
    height: "100%",
    padding: 24,
    boxSizing: "border-box",
  },
  aboutHeading: {
    "&.MuiTypography-root": {
      fontSize: 20,
      marginBottom: 12,
    },
  },
  aboutContent: {
    "&.MuiTypography-root": {
      fontSize: 14,
      color: theme.palette.secondary.dark,
      wordBreak: "break-word",
    },
  },
}));

const LocumDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { locumData, businessId, handleBackButtonClick } =
    LocumDetailsContainer({ locumId: id });

  const {
    role_name,
    about,
    first_name,
    surname,
    hourly_rate,
    avatar_url,
    availability,
    skills,
    cv_url,
  } = { ...locumData?.data?.attributes };

  const skillsObject = createSkillFormArray(skills);
  const availabilityObject = createAvailabilityFormArray(availability);
  const handleDownloadClick = (event) => {
    event.preventDefault();
    if (!cv_url) return;
    const link = document.createElement("a");
    link.href = cv_url;
    const fileName = last(cv_url?.split("/"));
    link.download = fileName;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <BackButton path={true} onClick={handleBackButtonClick} />
      {locumData.isLoading ? (
        <Loader />
      ) : (
        <>
          {!locumData?.data ? (
            <NotFoundComponent message={"Oops! Locum not found"} />
          ) : (
            <>
              {!isEmptyObject(locumData?.data) && (
                <LFGrid container key={id} spacing={3}>
                  <LFGrid item md={4} xxsm={6} xs={12}>
                    <LFBox
                      className={`${classes.cardSkill} ${classes.grayCard}`}
                    >
                      <LocumOverview
                        locum_name={`${first_name} ${surname}`}
                        hourly_rate={hourly_rate ? hourly_rate : ZERO}
                        avatar_url={avatar_url}
                        role_name={role_name}
                        userImage={userImage}
                      />
                      <LFStack
                        className={`${classes.searchButtons} ${classes.profileButtons}`}
                      >
                        <Divider />
                        <>
                          <Link
                            to={locationPath?.messages}
                            state={{
                              locumData: locumData?.data?.attributes,
                              businessId,
                            }}
                            style={{ textDecoration: "none" }}
                          >
                            <LFButton variant="contained">
                              {"Send Message"}
                            </LFButton>
                          </Link>
                          <Link
                            onClick={handleDownloadClick}
                            style={{
                              cursor: !cv_url && "default",
                              textDecoration: "none",
                            }}
                          >
                            {cv_url && (
                              <LFButton variant="contained">
                                {"Download Cv"}
                              </LFButton>
                            )}
                          </Link>
                        </>
                      </LFStack>
                    </LFBox>
                  </LFGrid>
                  <LFGrid item md={4} xxsm={6} xs={12}>
                    <LFBox
                      className={`${classes.cardSkill} ${classes.grayCard}`}
                    >
                      <SkillsDetails
                        availability={availabilityObject}
                        skillsObject={skillsObject}
                        extraSpacing={true}
                      />
                    </LFBox>
                  </LFGrid>

                  <LFGrid item md={4} xs={12}>
                    <LFBox className={classes.grayCard}>
                      <LFTypography
                        variant="h3"
                        component="h3"
                        color="primary"
                        className={classes.aboutHeading}
                      >
                        About
                      </LFTypography>
                      <LFTypography className={classes.aboutContent}>
                        {about}
                      </LFTypography>
                    </LFBox>
                  </LFGrid>
                </LFGrid>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LocumDetails;

const createSkillFormArray = (dataArray) => {
  const groupedData = {};

  dataArray?.forEach((item) => {
    const { type } = item;

    if (!groupedData[type]) {
      groupedData[type] = [];
    }

    groupedData[type].push(item.name);
  });
  return groupedData;
};

const createAvailabilityFormArray = (availabilityArray) => {
  let finalObj = {};
  keys(DAYS_VALUES)?.forEach((day) => {
    if (availabilityArray?.includes(DAYS_VALUES[day])) {
      return (finalObj[day] = true);
    }
    finalObj[day] = false;
  });
  return finalObj;
};
