import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { LFStyled } from "./LFStyled";

const StyledButton = LFStyled(Button, {
  shouldForwardProp: (prop) => prop !== "dis",
})(({ theme }) => ({
  borderRadius: 30,
  padding: "5px 20px",
  minWidth: 140,
  fontSize: 18,
  letterSpacing: 0.6,
  lineHeight: "normal",
  transition: "all 0.4s ease-in-out",
  "&[disabled]": {
    opacity: 0.5,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.light,
  },
}));

const LFButton = ({
  children,
  onClick,
  variant,
  isLoading,
  disabled,
  loaderSize = 20,
  ...rest
}) => {
  return (
    <StyledButton
      disableElevation
      onClick={onClick}
      variant={variant}
      disabled={disabled || isLoading}
      startIcon={isLoading ? <CircularProgress size={loaderSize} /> : null}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};

export default LFButton;
