export const changePasswordAttribute = [
  {
    name: "current_password",
    type: "password",
    label: "Password",
    isRequired: true,
    error: ["Password is required"],
    autoComplete: "on",
    variant: "standard",
    showEyeIcon: true,
    fullWidth: true,
    md: 12,
    xs: 12,
  },
  {
    name: "password",
    type: "password",
    label: "New password",
    isRequired: true,
    pattern: "password",
    error: [
      "New password is required",
      "Password must contain at least one uppercase, lowercase, number, special character and be at least 8 charaters.",
    ],
    autoComplete: "on",
    variant: "standard",
    showEyeIcon: true,
    fullWidth: true,
    md: 12,
    xs: 12,
  },
  {
    name: "confirm_password",
    type: "password",
    label: "Confirm new password",
    isRequired: true,
    pattern: "confirmPassword",
    error: [
      "Confirm new password is required",
      "Confirm new password must be same as new password",
    ],
    autoComplete: "on",
    variant: "standard",
    showEyeIcon: true,
    fullWidth: true,
    md: 12,
    xs: 12,
  },
];

export const defaultValues = {
  current_password: "",
  password: "",
  confirm_password: "",
};

export const changePasswordFormPath = {
  parent: "changePassword",
};

export const readOnlyAttributes = [
  {
    name: "readOnlyPassword",
    type: "password",
    label: "Password",
    autoComplete: "on",
    variant: "standard",
    fullWidth: true,
    md: 12,
    xs: 12,
  },
];

export const dummyFormData = {
  readOnlyPassword: "12345678",
};
