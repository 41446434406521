import { useEffect, useRef } from "react";
import LFBox from "../../shared/LFBox";

import SearchLocumContainer from "../../container/searchLocum/searchLocum.container";
import SearchLocumFormField from "./SearchLocumFormField";
import LocumCard from "../reusable/LocumCard";
import LFButton from "../../shared/LFButton";
import FindaLocum from "../../assets/svg/findLocum.svg";
import { makeStyles } from "@mui/styles";
import RolesSection from "./RolesSection";

import {
  searchLocumFormPath,
  searchLocumAttributes,
  defaultValues,
} from "../../description/searchLocum/searchLocum.description";
import { keys } from "../../utils/javascript";
import LFGrid from "../../shared/LFGrid";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import { locationPath } from "../../utils/constant";
import BackButton from "../reusable/BackButton";
import SimpleBar from "simplebar";
import "simplebar/dist/simplebar.min.css";

const useStyles = makeStyles((theme) => ({
  lightBox: {
    backgroundColor: theme.palette.white.main,
    padding: [[20, 0]],
    borderRadius: 20,
    position: "sticky",
    top: 0,
    [theme.breakpoints.down("xxsm")]: {
      paddingTop: 0,
    },
  },
  searchDetails: {
    maxHeight: "calc(100vh - 348px)",
    [theme.breakpoints.between("xxsm", "md")]: {
      maxHeight: "calc(100svh - 348px)",
    },
    overflowY: "auto",
    padding: [[0, 20]],
    [theme.breakpoints.down("xxsm")]: {
      maxHeight: "unset",
      padding: 0,
    },
  },
  searchButton: {
    padding: [[20, 20, 0]],
    [theme.breakpoints.down("xxsm")]: {
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      width: "100%",
      padding: [[11, 30]],
      fontSize: 14,
    },
  },
  findLocum: {
    maxHeight: "calc(100vh - 114px)",
    [theme.breakpoints.between("xxsm", "md")]: {
      maxHeight: "calc(100svh - 114px)",
    },
    [theme.breakpoints.down("xxsm")]: {
      maxHeight: "unset",
    },
  },
  searchImage: {
    "&.MuiStack-root": {
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("xxsm")]: {
        display: "none",
      },
      "& img": {
        maxWidth: 535,
        width: "100%",
      },
    },
  },
  searchHeading: {
    "&.MuiStack-root": {
      flexDirection: "row",
      alignItems: "end",
      marginBottom: 0,
      [theme.breakpoints.down("xxsm")]: {
        flexDirection: "column",
        alignItems: "start",
      },
    },
  },
  smallHeading: {
    "&.MuiTypography-root": {
      fontSize: 16,
      fontWeight: "400",
    },
  },
  divider: {
    "&.MuiDivider-root": {
      margin: [[20, 0]],
      borderColor: theme.palette.gray.dark,
    },
  },
}));

const SearchLocum = () => {
  const classes = useStyles();
  const {
    backButton,
    showResult,
    mobileMatches,
    handlSubmit,
    handleBackButtonClick,
    businessType,
    searchedLocumsResultsState,
    selectedBusinessId,
  } = SearchLocumContainer({
    formPath: searchLocumFormPath,
    defaultValues,
  });
  const locumCardAttributes = {
    variant: "",
    buttons: [
      {
        label: "Send Message",
        classes: "",
        link: locationPath?.messages,
        variant: "outlined",
      },
      {
        label: "View Profile",
        classes: "",
        link: locationPath?.locumDetails,
      },
    ],
    showAboutSection: false,
  };
  const formAttributes = searchLocumAttributes[businessType];
  const resultLength = searchedLocumsResultsState?.results?.length;

  const mainDivsearchRef = useRef(null);
  const searchMenuRef = useRef(null);

  useEffect(() => {
    if (mainDivsearchRef?.current) {
      const mainref = mainDivsearchRef?.current;
      new SimpleBar(mainref);
    }
    if (searchMenuRef.current) {
      new SimpleBar(searchMenuRef?.current);
    }
  }, [searchedLocumsResultsState]);

  return (
    <LFStack className={classes.findLocum}>
      <LFGrid item xsm={12} xs={"auto"} display="flex">
        {showResult || backButton ? (
          <BackButton path={true} onClick={handleBackButtonClick} />
        ) : (
          <LFBox height={{ sm: 56, xs: 16 }}></LFBox>
        )}
      </LFGrid>
      <LFStack justifyContent="space-between" className={classes.searchHeading}>
        <LFTypography
          variant="h6"
          component="h6"
          color="primary"
          fontSize="24px"
          mb={0.5}
        >
          {"Find a Locum"}
        </LFTypography>
        {!!resultLength && (!mobileMatches || showResult) && (
          <LFTypography color="secondary.dark">
            {`Showing ${resultLength} ${
              (resultLength > 1, "result", "results")
            }`}
          </LFTypography>
        )}
      </LFStack>
      {mobileMatches && showResult ? (
        <LFBox>
          {!searchedLocumsResultsState?.results || resultLength === 0 ? (
            <LFStack className={classes.searchImage}>
              <img src={FindaLocum} alt="mobile store" />
            </LFStack>
          ) : searchedLocumsResultsState?.isLoading ? (
            <LFStack className={classes.searchImage}>
              <img src={FindaLocum} alt="mobile store" />
            </LFStack>
          ) : (
            <>
              {searchedLocumsResultsState?.results?.map((locum) => (
                <LocumCard
                  key={locum?.id}
                  locumData={locum?.attributes}
                  locumCardAttributes={locumCardAttributes}
                  businessId={selectedBusinessId}
                />
              ))}
            </>
          )}
        </LFBox>
      ) : (
        <>
          <LFGrid container columnSpacing={3} mt={1.5}>
            <LFGrid item md={4} xxsm={5} xs={12}>
              <LFBox className={classes.lightBox}>
                <LFBox className={classes.searchDetails} ref={searchMenuRef}>
                  {keys(formAttributes)?.map((attribute) => {
                    return (
                      <SearchLocumFormField
                        key={attribute}
                        type={attribute}
                        field={formAttributes?.[attribute]}
                        formPath={searchLocumFormPath}
                      />
                    );
                  })}

                  <RolesSection
                    searchLocumFormPath={searchLocumFormPath}
                    defaultValues={defaultValues}
                  />
                </LFBox>
                <LFBox className={classes.searchButton}>
                  <LFButton
                    sx={{ fontSize: 12 }}
                    variant="contained"
                    isLoading={searchedLocumsResultsState?.isLoading}
                    loaderSize={18}
                    onClick={(e) => {
                      handlSubmit(e);
                    }}
                  >
                    Search
                  </LFButton>
                </LFBox>
              </LFBox>
            </LFGrid>
            {!mobileMatches && (
              <LFGrid item md={8} xxsm={7} xs={12} textAlign="center">
                {!searchedLocumsResultsState?.results || resultLength === 0 ? (
                  <LFStack className={classes.searchImage}>
                    <img src={FindaLocum} alt="mobile store" />
                  </LFStack>
                ) : searchedLocumsResultsState?.isLoading ? (
                  <LFStack className={classes.searchImage}>
                    <img src={FindaLocum} alt="mobile store" />
                  </LFStack>
                ) : (
                  <LFBox
                    overflow="auto"
                    sx={{
                      maxHeight: {
                        xs: "calc(100vh - 225px)",
                        xxsm: "calc(100svh - 225px)",
                        md: "calc(100vh - 225px)",
                      },
                    }}
                    ref={mainDivsearchRef}
                  >
                    {searchedLocumsResultsState?.results?.map((locum) => (
                      <LocumCard
                        key={locum?.id}
                        locumData={locum?.attributes}
                        locumCardAttributes={locumCardAttributes}
                        businessId={selectedBusinessId}
                      />
                    ))}
                  </LFBox>
                )}
              </LFGrid>
            )}
          </LFGrid>
        </>
      )}
    </LFStack>
  );
};

export default SearchLocum;
