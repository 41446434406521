export const notificationSettingsFromPath = {
  parent: "notification-Settings",
  loader: "notification-settings-loader",
};
export const labelObject = {
  shifts_change: "shift changes",
  web: "website",
  email: "email",
  message: "messages",
  payment: "payments",
  reminder: "reminders",
};

export const notificationFieldsSequence = [
  "message",
  "payment",
  "reminder",
  "shifts_change",
];
