import * as React from "react";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Range: {
    marginTop: "16px",
    "& .MuiSlider-rail": {
      color: theme.palette.primary.main,
      opacity: 1,
      heigth: "3px",
    },
    "& .MuiSlider-track": {
      border: "none",
      heigth: "3px",
    },
    "& .MuiSlider-thumb": {
      "& .MuiSlider-valueLabel": {
        color: theme.palette.secondary.dark,
        background: "none",
        top: 0,
      },
    },
  },
}));

export default function LFRange(props) {
  const classes = useStyles();
  const {
    value,
    onChange,
    min,
    max,
    className,
    valueLabelFormatFunction,
    ...restProps
  } = props;

  return (
    <>
      <Slider
        getAriaLabel={() => "Temperature range"}
        value={value}
        onChange={onChange}
        valueLabelDisplay="on"
        min={min}
        max={max}
        valueLabelFormat={valueLabelFormatFunction}
        className={`${classes.Range} ${className}`}
        {...restProps}
      />
    </>
  );
}
