import React from "react";
import { Swiper } from "swiper/react";
import "swiper/css";
import { Autoplay } from "swiper/modules";
import { useMediaQuery } from "@mui/material";
import { ternary } from "../utils/javascript";

const LFSlider = ({ children, key, ...rest }) => {
  const mobileMatches = useMediaQuery("(max-width: 479px)");

  return (
    <Swiper
      key={key}
      modules={[Autoplay]}
      loop={true}
      centeredSlides={!mobileMatches}
      slidesPerGroup={ternary(mobileMatches, 1, 3)}
      speed={1000}
      initialSlide={9}
      autoplay={{
        delay: ternary(mobileMatches, 2000, 5000),
        disableOnInteraction: false,
      }}
      {...rest}
    >
      {children}
    </Swiper>
  );
};

export default LFSlider;
