import { reduceBusinessDetails } from "../../description/calendar/myCalendar.description";
import { apiEndpoints, apiMethods } from "../../utils/constant";
import { equal, head } from "../../utils/javascript";
import {
  SET_BUSINESS_DETAILS,
  SET_BUSINESS_TYPE,
  SET_SELECTED_BUSINESS_ID,
} from "../constant";
import getDefaultBusinesses from "./getDefaultBusinesses";

const getBusinessDetailsAction =
  (performRequest) => async (dispatch, getState) => {
    const globalAttr = getState()?.globalAttr;
    const selected = globalAttr?.selectedBusinessId;
    const response = await performRequest({
      endPoint: apiEndpoints?.allBusiness,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      showFailedMessage: true,
      parent: "businessDetailsLoader",
      needLoader: true,
    });
    const reducedDetails = reduceBusinessDetails(response?.data?.business);

    const selectedBusinessId = !selected ? head(reducedDetails)?.id : selected;

    const businessType = !selected
      ? head(reducedDetails)?.type
      : reducedDetails?.find((business) => equal(business?.id, selected))?.type;

    dispatch({
      type: SET_BUSINESS_DETAILS,
      payload: reducedDetails,
    });

    dispatch({
      type: SET_SELECTED_BUSINESS_ID,
      payload: selectedBusinessId,
    });

    dispatch({
      type: SET_BUSINESS_TYPE,
      payload: businessType,
    });

    dispatch(getDefaultBusinesses({ performRequest }));
  };
export default getBusinessDetailsAction;
