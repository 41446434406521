export const SuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <path
      d="M18.75 30L26.25 37.5L41.25 22.5M55 30C55 43.8071 43.8071 55 30 55C16.1929 55 5 43.8071 5 30C5 16.1929 16.1929 5 30 5C43.8071 5 55 16.1929 55 30Z"
      stroke="#44269A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ExclamationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <path
      d="M30 17.8125V33.75M30 40.3125H30.0094M55.3125 30C55.3125 43.9797 43.9797 55.3125 30 55.3125C16.0203 55.3125 4.6875 43.9797 4.6875 30C4.6875 16.0203 16.0203 4.6875 30 4.6875C43.9797 4.6875 55.3125 16.0203 55.3125 30Z"
      stroke="#44269A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
