import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import ApiContainer from "../api.container";
import {
  LS_KEY_AUTHENTICATED,
  LS_KEY_HALF_REGISTER,
  LS_KEY_REGISTER_ID,
  LS_KEY_TOKEN,
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import { equal, keys, ternary, values } from "../../utils/javascript";
import { SET_AUTHENTICATED, SET_FORM_ERROR_DATA } from "../../redux/constant";
import { useEffect } from "react";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "../../utils/localStorage";
import { validateAddress } from "../../utils/validation";
import getDefaultBusinesses from "../../redux/action/getDefaultBusinesses";

const RegisterBusinessContainer = ({
  formData,
  formError,
  formPath,
  validate,
  isResetValues,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { setPath } = useOutletContext();

  const loadingStatus = useSelector(
    (state) => state?.app?.loader?.[formPath?.parent],
  );
  const isAuthenticated = useSelector((state) => state?.app?.isAuthenticated);

  const token = getLocalStorageItem(LS_KEY_TOKEN);
  const registerId = getLocalStorageItem(LS_KEY_REGISTER_ID);
  const halfRegister = getLocalStorageItem(LS_KEY_HALF_REGISTER);

  useEffect(() => {
    if (!isResetValues || !formData?.type)
      dispatch(
        getDefaultBusinesses({
          performRequest,
          selectedbusinessType: formData?.type
            ? formData?.type
            : state?.businessType,
        }),
      );
  }, [formData?.type]);

  useEffect(() => {
    setPath(
      ternary(
        halfRegister,
        null,
        ternary(
          isAuthenticated,
          locationPath?.businessDetail,
          locationPath?.registerPassword,
        ),
      ),
    );

    if (
      !state?.showRegiserBusiness &&
      !state?.registerBusiness &&
      (equal(registerId, null) || !token)
    ) {
      navigate(-1, { replace: true });
    } else if (
      (state?.showRegiserBusiness && !isAuthenticated) ||
      (state?.registerBusiness && !halfRegister)
    ) {
      navigate(locationPath?.signIn, { replace: true });
    }
  }, []);

  const { performRequest } = ApiContainer();

  const apiCall = async () => {
    const payload = {
      business: {
        type: ternary(
          state?.showRegiserBusiness,
          state?.businessType,
          formData?.type,
        ),
        name: formData?.name,
        telephone: formData?.telephone,
        location_attributes: {
          address: formData?.address?.address,
          latitude: formData?.address?.latitude,
          longitude: formData?.address?.longitude,
          postal_code: formData?.address?.postal_code,
          city: formData?.address?.city,
          state: formData?.address?.state,
          address_line_1: formData?.address?.address_line_1,
          address_line_2: formData?.address?.address_line_2,
        },
      },
      default_logo_id: formData?.default_logo_id,
    };
    const response = await performRequest({
      endPoint: apiEndpoints.registerBusiness,
      method: apiMethods.post,
      data: { ...payload },
      tokenKey: "Authorization",
      showFailedMessage: true,
      parent: formPath?.parent,
      needLoader: true,
    });

    if (!equal(response?.statusCode, statusCodes?.OK)) return;

    if (state?.showRegiserBusiness) {
      return navigate(locationPath?.changesSuccess, {
        state: { redirect: locationPath?.businessDetail },
      });
    }

    setLocalStorageItem(LS_KEY_AUTHENTICATED, true);
    removeLocalStorageItem(LS_KEY_HALF_REGISTER);
    removeLocalStorageItem(LS_KEY_REGISTER_ID);
    dispatch({ type: SET_AUTHENTICATED, payload: true });

    return navigate(locationPath?.accountSuccess, {
      state: { showAccountSuccess: true },
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let validationErrors = {};
    keys(formData).forEach((name) => {
      const error = validate(name, formData[name]);
      validationErrors[name] = error;
    });
    dispatch({
      type: SET_FORM_ERROR_DATA,
      payload: {
        [formPath?.parent]: { ...formError, ...validationErrors },
      },
    });
    if (values(validationErrors).some((error) => error)) return;

    if (!validateAddress(formData?.address)) return;

    return apiCall();
  };
  return { loadingStatus, handleSubmit };
};

export default RegisterBusinessContainer;
