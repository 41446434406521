import { makeStyles } from "@mui/styles";
import PromptTemplet from "../reusable/PromptTemplet";
import LFBox from "../../shared/LFBox";
import {
  apiEndpoints,
  apiMethods,
  locationPath,
  statusCodes,
} from "../../utils/constant";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import ApiContainer from "../../container/api.container";
import {
  equal,
  roundToTwoDecimalPlaces,
  ternary,
} from "../../utils/javascript";
import userImage from "../../assets/png/user.jpeg";
import { isGreaterThan72Hours } from "../../utils/moment";
import Loader from "../reusable/Loader";
import { showToast } from "../../utils/toastService";
import theme from "../../themes/theme";

const useStyles = makeStyles(() => ({
  promptWrapper: ({ isLoading }) => ({
    ...(isLoading && { minHeight: "calc(100vh - 270px)" }),
    display: "flex",
    padding: [[0, 20]],
    marginTop: 24,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  }),
}));

const formPath = {
  parent: "cancelLoader",
};

const ConfirmApplication = () => {
  const { performRequest } = ApiContainer();

  const { id } = useParams();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showSuccessPage, setSuccessPage] = useState(false);
  const [showForbiddenPage, setForbiddenPage] = useState(false);
  const [forbiddenAttributes, setForbiddenAttributes] = useState({});
  const [invoiceData, setInvoiceData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const shiftId = searchParams.get("shiftId");
  const locumData = state?.locumData;
  const classes = useStyles({ isLoading });

  useEffect(() => {
    getLocumInvoiceData();
  }, [shiftId]);

  const getLocumInvoiceData = async () => {
    setIsLoading(true);
    const { statusCode, data } = await performRequest({
      endPoint: `${apiEndpoints?.acceptLocumInvoice?.replace(
        ":shiftId",
        shiftId,
      )}`,
      method: apiMethods?.get,
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.cancelLoader,
      showFailedMessage: true,
    });

    setIsLoading(false);

    if (equal(statusCode, statusCodes?.OK)) {
      setInvoiceData(data?.invoice?.attributes);
    } else {
      setInvoiceData({});
      navigate(-1, { replace: true });
    }
  };

  const shiftStartDate = state?.shiftStartDate;
  const locumName = ternary(
    locumData?.preferred_name,
    locumData?.preferred_name,
    ternary(
      locumData?.first_name && locumData?.surname,
      `${locumData?.first_name} ${locumData?.surname}`,
      `${locumData?.first_name}`,
    ),
  );

  const {
    locum_amount,
    work_cover,
    original_work_cover,
    payroll_tax,
    original_payroll_tax,
    service_fee,
    original_service_fee,
    gst,
    amount_paid,
    shift,
    total_discount_amount,
    voucher,
  } = { ...invoiceData };

  const discountPercentage = Number(shift?.discount_percentage);

  const showDiscount = discountPercentage || !!voucher;

  const discountStyle = { color: theme.palette.secondary.main };

  const tableData = [
    {
      name: "Rate",
      value: `$${invoiceData?.rate}/hr (Total $${
        roundToTwoDecimalPlaces(locum_amount) ?? 0
      })`,
    },
    {
      name: "Workcover",
      value: (
        <>
          {ternary(
            showDiscount,
            <>
              <s>${roundToTwoDecimalPlaces(original_work_cover)}</s>{" "}
              <span style={discountStyle}>
                {ternary(
                  equal(work_cover, 0),
                  "Free",
                  `$${roundToTwoDecimalPlaces(work_cover) ?? 0}`,
                )}
              </span>
            </>,
            `$${roundToTwoDecimalPlaces(work_cover)}`,
          )}
        </>
      ),
    },
    {
      name: "Payroll Tax",
      value: (
        <>
          {ternary(
            showDiscount,
            <>
              <s>${roundToTwoDecimalPlaces(original_payroll_tax)}</s>{" "}
              <span style={discountStyle}>
                {" "}
                {ternary(
                  equal(payroll_tax, 0),
                  "Free",
                  `$${roundToTwoDecimalPlaces(payroll_tax) ?? 0}`,
                )}
              </span>
            </>,
            `$${roundToTwoDecimalPlaces(payroll_tax)}`,
          )}
        </>
      ),
    },
    {
      name: "LocumFind Service Fee",
      value: (
        <>
          {ternary(
            showDiscount,
            <>
              <s>${roundToTwoDecimalPlaces(original_service_fee)}</s>{" "}
              <span style={discountStyle}>
                {ternary(
                  equal(service_fee, 0),
                  "Free",
                  `$${roundToTwoDecimalPlaces(service_fee) ?? 0}`,
                )}
              </span>
            </>,
            `$${roundToTwoDecimalPlaces(service_fee)}`,
          )}
        </>
      ),
    },
    {
      name: "GST",
      value: `$${roundToTwoDecimalPlaces(gst) ?? 0}`,
    },

    {
      name: "Total",
      value: `$${roundToTwoDecimalPlaces(amount_paid) ?? 0}`,
    },
  ];

  if (showDiscount) {
    const dsc = {
      name: "Discount",
      value: (
        <span style={discountStyle}>
          -${roundToTwoDecimalPlaces(total_discount_amount) ?? 0}{" "}
        </span>
      ),
    };
    tableData?.splice(tableData.length - 1, 0, dsc);
  }

  const handleConfirmClick = async () => {
    setIsLoading(true);
    const { errorData, statusCode, errorMsg } = await performRequest({
      endPoint: `${apiEndpoints?.businessShifts}/${shiftId}/applicants/${id}`,
      method: apiMethods?.put,
      data: {},
      tokenKey: "Authorization",
      needLoader: true,
      parent: formPath?.cancelLoader,
      showFailedMessage: false,
    });
    setIsLoading(false);

    if (equal(statusCode, statusCodes?.OK)) return setSuccessPage(true);
    if (
      equal(statusCode, statusCodes?.FORBIDDEN) &&
      errorData?.data?.include_hyperlink
    ) {
      setForbiddenAttributes({ url: errorData?.data?.url, errorMsg: errorMsg });
      return setForbiddenPage(true);
    } else {
      showToast(errorMsg, "error");
    }
  };

  const handleCancelClick = async () => {
    return navigate(-1, { replace: true });
  };

  const handleDoneClick = () => {
    return navigate(locationPath?.myCalendar);
  };

  const buttonAttributes = [
    {
      label: "CONFIRM",
      onClick: handleConfirmClick,
      variant: "contained",
    },
    {
      label: "cancel",
      onClick: handleCancelClick,
      variant: "",
      sx: {
        color: "secondary.dark",
        textTransform: "capitalize",
        fontSize: { xs: 12, xts: 16 },
        mt: { xs: 0.5, xts: 1.5 },
        minWidth: 280,
        "&:hover": { backgroundColor: "transparent" },
      },
    },
  ];

  const successButtonAttributes = [
    {
      label: "DONE",
      onClick: handleDoneClick,
      variant: "contained",
    },
  ];
  const forbiddenButtonAttributes = [
    {
      label: "Add payment method",
      onClick: () => navigate(forbiddenAttributes?.url),
      variant: "contained",
      sx: { padding: "12px", maxWidth: "60%" },
    },
  ];

  return (
    <LFBox className={classes.promptWrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!showSuccessPage ? (
            !showForbiddenPage ? (
              <PromptTemplet
                confirmLocum={true}
                mainText={`Accept ${
                  locumData?.preferred_name
                    ? locumData?.preferred_name
                    : locumData?.first_name
                }'s application for this shift?`}
                subText={
                  isGreaterThan72Hours(shiftStartDate)
                    ? "Once confirmed, a hold for the full payment amount will be placed on your account 72 hours before the shift is to be worked."
                    : "Once confirmed, a hold for the full payment amount will be placed on your account"
                }
                buttonAttributes={buttonAttributes}
                iconSrc={locumData?.avatar_url || userImage}
                bgGray={false}
                locumName={locumName}
                showTable={true}
                tableData={tableData}
                voucherApplied={!!voucher}
              />
            ) : (
              <PromptTemplet
                mainText={forbiddenAttributes?.errorMsg}
                buttonAttributes={forbiddenButtonAttributes}
                iconSrc={true}
                bgGray={false}
                alertType={true}
              />
            )
          ) : (
            <PromptTemplet
              mainText={"Application confirmed"}
              buttonAttributes={successButtonAttributes}
              iconSrc={true}
              bgGray={false}
            />
          )}
        </>
      )}
    </LFBox>
  );
};

export default ConfirmApplication;
