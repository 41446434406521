import { clearLocalStorage } from "../../utils/localStorage";
import { LOGOUT, SET_AUTHENTICATED } from "../constant";

const logoutAction = (redirect) => (dispatch) => {
  clearLocalStorage();
  dispatch({
    type: LOGOUT,
  });
  dispatch({
    type: SET_AUTHENTICATED,
    payload: false,
  });
  redirect && redirect();
};

export default logoutAction;
