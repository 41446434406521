import React from "react";
import FormHelperText from "@mui/material/FormHelperText";
import LFCheckbox from "./LFCheckbox";
import LFBox from "./LFBox";
import LFTypography from "./LFTypograpy";
import theme from "../themes/theme";
import LFStack from "./LFStack";

const LFCheckboxGroup = ({
  onChange,
  value,
  name,
  helperText,
  error,
  label,
  options,
}) => {
  const handleOnChange = (e) => {
    onChange({
      target: {
        value: e.target.name,
        name,
      },
    });
  };

  return (
    <LFBox name={name} sx={{ position: "relative" }}>
      <LFTypography
        sx={{
          fontSize: "14px",
          color: theme.palette.secondary.dark,
          marginBottom: "5px",
        }}
      >
        {label}
      </LFTypography>
      <LFStack
        direction="row"
        justifyContent="space-between"
        className="checkboxRow"
      >
        {options?.map(({ name, label, order }) => (
          <LFStack
            direction="row"
            key={name}
            sx={{ order: { xs: order, xsm: "unset" } }}
          >
            <LFCheckbox
              name={name}
              label={label}
              onChange={handleOnChange}
              checked={name === value}
              error={error}
            />
          </LFStack>
        ))}
      </LFStack>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </LFBox>
  );
};

export default LFCheckboxGroup;
