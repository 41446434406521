export const addTemplateAttribute = [
  {
    name: "name",
    type: "text",
    label: "Template name",
    isRequired: true,
    error: ["Template name is required"],
    xs: 12,
    fullWidth: true,
  },
];

export const defaultValues = {
  name: "My Template 1",
};

export const addTemplateFormPath = {
  parent: "add-template",
};
