import React, { useEffect } from "react";
import FooterContainer from "../../container/layout/footer.container";
import LFBox from "../../shared/LFBox";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import { Link, useNavigate } from "react-router-dom";
import LFButton from "../../shared/LFButton";
import { ReactComponent as EditIcon } from "../../assets/svg/editIcon.svg";
import { makeStyles } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { locationPath } from "../../utils/constant";
import theme from "../../themes/theme";

const useStyles = makeStyles((theme) => ({
  helperMenu: {
    padding: 14,
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent",
      padding: 0,
    },
    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
      textDecoration: "none",
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      padding: "17px 0",
      width: "100%",
      textAlign: "left",
      borderRadius: 0,
      borderBottom: `1px solid ${theme.palette.gray.dark}`,
      [theme.breakpoints.down("xxsm")]: {
        padding: "11px 0",
      },
      "&.active": {
        [theme.breakpoints.up("sm")]: {
          backgroundColor: theme.palette.white.main,
        },
      },
      "& span": {
        verticalAlign: "middle",
        marginRight: "auto",
        fontSize: 20,
      },
    },
  },
  socialIcon: {
    alignItems: "center",
    "& a": {
      display: "inherit",
      color: theme.palette.primary.main,
      "& svg": {
        heihgt: 28,
        width: 28,
      },
    },
  },
}));

const HelpCenterMenu = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (!matches) {
      navigate(locationPath?.account);
    }
  }, [matches]);

  const { navLink, socialMediaLinks } = FooterContainer();

  const handleLinkClick = (path) => {
    navigate(path);
  };

  return (
    <>
      <LFBox>
        <LFTypography
          variant="h5"
          component="h5"
          color="primary"
          fontWeight="500"
          mb={{ xxsm: 3, xs: 0 }}
          sx={{
            borderBottom: {
              xxsm: "none",
              xs: `1px solid ${theme.palette.secondary.dark}`,
            },
            pb: 1.5,
          }}
        >
          Help Centre
        </LFTypography>
        <nav className={classes.helperMenu}>
          {navLink?.map(({ label, icon, path }, index) => (
            <LFButton
              key={index}
              disableRipple
              onClick={() => handleLinkClick(path)}
            >
              {icon}
              <LFTypography component="span" variant="body1">
                {label}
              </LFTypography>

              <EditIcon />
            </LFButton>
          ))}
        </nav>
      </LFBox>
      <LFStack mt="auto" pt={3}>
        <LFTypography
          color="primary.main"
          variant="body2"
          textAlign="center"
          mb={2}
          fontSize="12px"
        >
          Connect with us
        </LFTypography>
        <LFStack
          className={classes.socialIcon}
          direction="row"
          justifyContent={{ sm: "flex-end", xs: "center" }}
          spacing={4}
        >
          {socialMediaLinks.map((link, index) => {
            const { icon, url } = link;
            return (
              <Link key={index} to={url} target="_blank">
                {icon}
              </Link>
            );
          })}
        </LFStack>
      </LFStack>
    </>
  );
};

export default HelpCenterMenu;
