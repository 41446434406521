import React, { useState } from "react";
import LFTextField from "../../../shared/LFTextField";
import FromActionButtons from "./FromActionButtons";
import { ternary } from "../../../utils/javascript";
import { makeStyles } from "@mui/styles";
import { FormLabel } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-root": {
      padding: 8,
      borderRadius: 10,
      marginTop: 10,

      "& textarea": {
        fontSize: 14,
      },
    },

    "& .MuiInputBase-root.MuiFilledInput-root:before": {
      display: "none",
    },
    "& .MuiInputBase-root.MuiFilledInput-root:after": {
      display: "none",
    },
    "& textarea": {
      "&::-webkit-scrollbar": {
        width: 5,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: 4,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
    },
  },
}));

const ExtraNotesForm = ({ defaultValue, setCurrentForm, label, onSave }) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue ?? "");
  const [error, setError] = useState(false);
  const maxLength = 150;
  const handleOnChange = (e) => {
    setValue(e.target.value);
    if (e.target.value?.length > maxLength) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const handleSaveClick = () => {
    onSave(value);
  };
  return (
    <>
      <FormLabel style={{ fontSize: 13 }}>{label}</FormLabel>
      <LFTextField
        value={value}
        variant="filled"
        onChange={handleOnChange}
        fullWidth
        error={error}
        radius={10}
        multiline
        rows={5}
        className={classes.root}
        helperText={ternary(
          error,
          `Maximum ${maxLength} characters are allowed`,
          "",
        )}
      />
      <FromActionButtons
        setCurrentForm={setCurrentForm}
        handleSaveClick={handleSaveClick}
        isDisabled={error}
      />
    </>
  );
};

export default ExtraNotesForm;
