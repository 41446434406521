import React from "react";
import LFBox from "../../shared/LFBox";
import LFTextField from "../../shared/LFTextField";
import LFDatePicker from "../../shared/LFDatePicker";
import { ReactComponent as Calendar } from "../../assets/svg/calendar.svg";
import { dateStringToDate, ternary } from "../../utils/javascript";
import LFStack from "../../shared/LFStack";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  calendarInput: {
    margin: [[20, 0]],
    gap: 10,
    "& .MuiInputBase-root": {
      border: `1px solid ${theme.palette.gray.dark}`,
      marginTop: 20,
      borderRadius: "50px !important",
      fontSize: 14,
      padding: [[5, 15]],
      "&::before, &::after": {
        display: "none",
      },
      "& svg": {
        height: 20,
        width: 20,
      },
    },
  },
}));

const LocumFilterCalender = ({ startDate, endDate, onChange }) => {
  const classes = useStyles();
  const dateAttributes = [
    {
      type: "text",
      label: "From",
      readOnly: true,
      value: ternary(startDate, dateStringToDate(startDate), ""),
    },
    {
      type: "text",
      label: "To",
      readOnly: true,
      value: ternary(endDate, dateStringToDate(endDate), ""),
    },
  ];
  return (
    <LFBox>
      <LFStack direction="row" className={classes.calendarInput}>
        {dateAttributes?.map((dateAttr, i) => (
          <LFTextField
            key={i}
            {...dateAttr}
            placeholder={"dd/mm/yyyy"}
            InputLabelProps={{ shrink: true }}
            endAdornment={<Calendar />}
            sx={{ "& .MuiInputBase-input": { fontSize: { lg: 16, xs: 12 } } }}
          />
        ))}
      </LFStack>
      <LFDatePicker
        startDate={startDate}
        endDate={endDate}
        isRangePicker={true}
        onChange={onChange}
        fromSearchLocum={true}
      />
    </LFBox>
  );
};

export default LocumFilterCalender;
