import React from "react";
import MyCalendarContainer from "../../container/myCalendar/myCalendar.container";
import LFButton from "../../shared/LFButton";
import LFCalender from "../../shared/LFCalender";
import LFContainer from "../../shared/LFContainer";
import LFDropDown from "../../shared/LFDropDown";
import LFFormLabel from "../../shared/LFFormLabel";
import LFGrid from "../../shared/LFGrid";
import LFStack from "../../shared/LFStack";
import LFTypography from "../../shared/LFTypograpy";
import { ReactComponent as LeftIcon } from "../../assets/svg/leftIcon.svg";
import { ReactComponent as RightIcon } from "../../assets/svg/rightIcon.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/filter.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close.svg";
import { LFStyled } from "../../shared/LFStyled";
import FilterMenu from "./FilterMenu";
import filterMenuObject, {
  myCalendarFormPath,
} from "../../description/calendar/myCalendar.description";
import Loader from "../reusable/Loader";
import { length, ternary, keys } from "../../utils/javascript";
import CalendarHelperContainer from "../../container/myCalendar/calendarHelper.container";
import { makeStyles } from "@mui/styles";
import LFBox from "../../shared/LFBox";
import moment from "moment";
import BusinessShiftBox from "./BusinessShiftBox";
import { useSwipeable } from "react-swipeable";
import MobileCopyright from "../reusable/MobileCopyright";

const CalenderView = LFStyled(LFStack)(({ theme }) => ({
  backgroundColor: theme.palette.gray.main,
  borderRadius: 10,
  padding: 10,
  "& .fc-view-harness": {
    maxHeight: "unset",
    height: "max-content !important",
    "& .fc-scroller": {
      overflow: "unset !important",
      position: "relative",
      "& .fc-scrollgrid-sync-table": {
        height: "max-content !important",
      },
    },
    "& .fc-dayGridMonth-view": {
      position: "relative",
    },
  },
  "& .fc-theme-standard": {
    "& .fc-scrollgrid": {
      border: 0,
      "& th.fc-col-header-cell": {
        paddingBottom: 10,
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        color: theme.palette.secondary.dark,
        fontSize: 14,
        fontWeight: 400,
        fontFamily: "DM Sans, sans-serif",
      },
      "& td, & th": {
        border: 0,
      },
      "& .fc-daygrid-day-top": {
        justifyContent: "center",
        "& .fc-daygrid-day-number": {
          "& .MuiBox-root": {
            fontFamily: "DM Sans, sans-serif",
            [theme.breakpoints.down("xsm")]: {
              fontSize: 12,
              height: 20,
              width: 20,
            },
          },
        },
      },
      "& .fc-daygrid-day-events": {
        [theme.breakpoints.down("md")]: {
          minHeight: "1rem",
        },
        "& .fc-daygrid-event": {
          [theme.breakpoints.down("xsm")]: {
            marginTop: 0,
          },
        },
      },
      "& .fc-scrollgrid-section": {
        "& td": {
          maxHeight: 97,
          height: 97,
          paddingTop: 10,
          [theme.breakpoints.down("md")]: {
            height: 75,
            minHeight: 75,
          },
          [theme.breakpoints.down("xsm")]: {
            height: 58,
            paddingTop: 6,
          },
          "& .fc-daygrid-day-frame": {
            minHeight: 87,
            [theme.breakpoints.down("md")]: {
              minHeight: 78,
            },
            [theme.breakpoints.down("xsm")]: {
              minHeight: 36,
            },
          },
        },
      },
      "& .fc-day-disabled": {
        visibility: "hidden",
      },
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  cardDate: {
    "&.MuiTypography-root": {
      margin: [[0, 0, 12]],
      fontSize: 20,
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        margin: [[20, 0, 4]],
      },
      [theme.breakpoints.down("xsm")]: {
        margin: [[16, 0, 0]],
        fontSize: 12,
      },
    },
  },
  calendarCards: {
    MsOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      paddingBottom: 30,
    },
  },
  calendarCardsInner: {
    "&.MuiStack-root": {
      height: "100%",
      maxHeight: "calc(100vh - 293px)",

      [theme.breakpoints.down("md")]: {
        flexDirection: "row",
        maxHeight: "unset",
        overflow: "auto",
      },
      [theme.breakpoints.down("xsm")]: {
        flexDirection: "column",
      },
      "&>.MuiBox-root": {
        marginBottom: 16,
        [theme.breakpoints.down("md")]: {
          marginBottom: 0,
        },
        "&:last-child": {
          margin: 0,
        },
      },
    },
  },
  close: {
    [theme.breakpoints.down("xsm")]: {
      display: "block",
      position: "absolute",
      top: "-1%",
      left: 0,
      right: 0,
      background: theme.palette.white.main,
      zIndex: 2,
    },
  },
  open: {
    [theme.breakpoints.down("xsm")]: {
      display: "none",
    },
  },
  FilterIcon: {
    display: "none",
    marginLeft: "auto",
    height: 40,
    width: 40,
    alignItems: "center",
    justifyContent: "center",
    border: `1px solid ${theme.palette.gray.dark}`,
    borderRadius: 50,
    [theme.breakpoints.down("xsm")]: {
      display: "flex",
      "& line": {
        stroke: theme.palette.secondary.dark,
      },
    },
  },
  calandarDropdown: {
    "&.MuiOutlinedInput-root": {
      marginTop: "8px !important",
    },
  },
  monthWrapper: {
    "& svg": {
      "&:hover": {
        "& path": {
          stroke: theme.palette.dark.main,
        },
      },
    },
  },
}));

const MyCalendar = () => {
  const classes = useStyles();
  const {
    initailLoadingStatus,
    filterState,
    filterType,
    filterStatus,
    selectBusinessAttribute,
    selectBusinessLabel,
    addNewShiftClick,
    selectedBusinessId,
    isOpen,
    toggleFilterButton,
  } = MyCalendarContainer({
    filterMenuObject,
    formPath: myCalendarFormPath,
  });

  const {
    calendarRef,
    calendarHead,
    statusData,
    shiftDataLoading,
    handleNextClick,
    handlePrevClick,
    handleCalenderDateClick,
    selectedDate,
    filteredShiftData,
    filterHandler,
  } = CalendarHelperContainer({
    formPath: myCalendarFormPath,
    businessId: selectedBusinessId,
  });
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleNextClick(),
    onSwipedRight: () => handlePrevClick(),
  });
  if (initailLoadingStatus) return <Loader />;

  return (
    <>
      <LFStack pt={{ xsm: 5, xs: 1.5 }}>
        <LFContainer>
          <LFStack
            direction="row"
            flexWrap="wrap"
            alignItems="center"
            mb={{ xs: 0, xsm: 1.5 }}
            position="relative"
          >
            <LFStack
              direction="row"
              flexWrap="wrap"
              flex={1}
              alignItems="center"
            >
              <LFTypography
                variant="h5"
                component="h5"
                color="primary"
                fontWeight="500"
              >
                My Calendar
              </LFTypography>
              <LFBox
                onClick={toggleFilterButton}
                className={classes.FilterIcon}
              >
                {ternary(isOpen, <CloseIcon />, <FilterIcon />)}
              </LFBox>
              <LFStack
                display={{ md: "flex", xsm: "none", xs: "flex" }}
                flex={1}
                maxWidth={{ xsm: "200px", xs: "180px" }}
                minWidth={{ xsm: "200px", xs: "160px" }}
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                ml="auto"
                className={classes.monthWrapper}
                sx={{
                  position: { xsm: "static", xs: "absolute" },
                  bottom: 5,
                  left: 0,
                }}
              >
                <LeftIcon onClick={handlePrevClick} cursor="pointer" />
                <LFTypography color="secondary.dark" fontWeight="500">
                  {calendarHead}
                </LFTypography>
                <RightIcon onClick={handleNextClick} cursor="pointer" />
              </LFStack>
            </LFStack>
            <LFStack
              ml="auto"
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mt={{ xsm: 0, xs: 2 }}
              sx={{
                width: { xsm: "27%", xs: "100%" },
                "& svg": { cursor: "pointer" },
              }}
            >
              <LFButton
                onClick={() => addNewShiftClick(selectedDate)}
                variant={"contained"}
                sx={{
                  fontSize: 14,
                  minHeight: 32,
                  ml: { sm: 20, xsm: 5, xs: "auto" },
                  minWidth: { xts: 173, xs: "auto" },
                  p: { xsm: 1.4, xs: "7px 12px" },
                }}
              >
                ADD SHIFT
              </LFButton>
            </LFStack>
          </LFStack>
          <LFGrid container columnSpacing={4.125} position="relative">
            <LFGrid
              item
              md={8.9}
              xs={12}
              className={ternary(isOpen, classes.close, classes.open)}
            >
              <LFStack mb={{ md: 0, xsm: 0, xs: 2 }}>
                <LFGrid
                  container
                  columnSpacing={3}
                  rowSpacing={{ xsm: 0, xs: 2 }}
                >
                  {length(selectBusinessAttribute?.options) > 2 && (
                    <LFGrid item md={4} xsm={6} xs={12}>
                      <LFFormLabel>{selectBusinessLabel}</LFFormLabel>
                      <LFDropDown
                        className={classes.calandarDropdown}
                        {...selectBusinessAttribute}
                      />
                    </LFGrid>
                  )}

                  <LFGrid item md={8} xsm={6} xs={12}>
                    <FilterMenu
                      roles={filterType?.roles}
                      status={filterStatus?.status}
                      filterHandler={filterHandler}
                      filterValues={filterState}
                    />
                  </LFGrid>
                </LFGrid>
              </LFStack>
            </LFGrid>
            <LFGrid
              item
              xs={12}
              display={{ md: "none", xsm: "block", xs: "none" }}
            >
              <LFStack
                direction="row"
                alignItems="center"
                width={{ xsm: "auto", xs: "100%" }}
                mt={{ xsm: 0, xs: 2 }}
                sx={{ "& svg": { cursor: "pointer" } }}
                maxWidth="200px"
                justifyContent="space-between"
              >
                <LeftIcon
                  onClick={handlePrevClick}
                  cursor="pointer"
                  className={classes.arrowIcon}
                />
                <LFTypography
                  color="secondary.dark"
                  fontWeight="500"
                  mx={{ sm: 3, xs: 2 }}
                >
                  {calendarHead}
                </LFTypography>
                <RightIcon
                  onClick={handleNextClick}
                  cursor="pointer"
                  className={classes.arrowIcon}
                />
              </LFStack>
            </LFGrid>
          </LFGrid>
          <LFGrid
            container
            columnSpacing={4.125}
            position="relative"
            sx={{ mt: 1.75 }}
          >
            <LFGrid item md={8.9} xs={12}>
              <LFBox {...swipeHandlers} pb={2}>
                <CalenderView>
                  <LFCalender
                    ref={calendarRef}
                    dateClick={handleCalenderDateClick}
                    statusData={statusData}
                    selectedDate={selectedDate}
                    shiftDataLoading={shiftDataLoading}
                  />
                </CalenderView>
              </LFBox>
            </LFGrid>
            <LFGrid
              item
              md={3.1}
              xs={12}
              sx={{ overflow: "auto" }}
              className={classes.calendarCards}
            >
              {shiftDataLoading ? (
                <Loader
                  height={{ md: "100%", xs: "max-content" }}
                  sx={{ padding: { md: "unset", xs: "70px 0" } }}
                />
              ) : (
                <LFStack className={classes.calendarCardsInner}>
                  {keys(filteredShiftData)?.map((curDate) => {
                    return (
                      <LFBox
                        key={curDate}
                        sx={{ flexDirection: { md: "column", xs: "row" } }}
                      >
                        <LFTypography
                          color="secondary.dark"
                          fontWeight="500"
                          className={classes.cardDate}
                        >
                          {moment(curDate)?.format("dddd D MMMM")}
                        </LFTypography>
                        <LFBox
                          sx={{
                            display: { md: "unset", xsm: "flex", xs: "unset" },
                          }}
                        >
                          {keys(filteredShiftData?.[curDate])?.map(
                            (businessName) => {
                              const businessShifts =
                                filteredShiftData?.[curDate]?.[businessName];
                              return (
                                <BusinessShiftBox
                                  key={businessName}
                                  businessName={businessName}
                                  selectedBusinessId={selectedBusinessId}
                                  businessShifts={businessShifts}
                                />
                              );
                            },
                          )}
                        </LFBox>
                      </LFBox>
                    );
                  })}
                </LFStack>
              )}
            </LFGrid>
          </LFGrid>
        </LFContainer>
      </LFStack>
      <MobileCopyright />
    </>
  );
};

export default MyCalendar;
